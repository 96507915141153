@import '@styles/main.v5';
@import '@styles/common';

#side-drawer-portal {
    .fds-activity-indicator {
        margin-top: 0;
    }

    #preferred-dealer-drawer-close-button,
    button {
        &:focus-visible {
            outline: 2px solid black;
        }
    }

    #mapFocus {
        display: none;
    }

    .fds-activity-indicator--circular:not(.fds--ie11)
        .fds-activity-indicator__progress-wrapper {
        position: relative !important;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .side-drawer__container {
        padding: 0;

        .preferred-dealer-drawer__container {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            padding: 0 pToR(16);

            @include tablet {
                padding: 0 pToR(56);
            }

            .preferred-dealer-drawer-header-text {
                font-size: pToR(36);
                line-height: pToR(44);
                color: #00142e;
                font-family: $ford-f1-font-semibold;
                letter-spacing: normal;
                align-self: flex-start;
                margin-top: pToR(80);
                text-align: start;

                @include mobile {
                    text-align: start;
                }

                @include tablet {
                    margin-top: pToR(82);
                    margin-bottom: pToR(29);
                }
            }

            hr {
                box-shadow: none;
                margin-bottom: pToR(24);
                border-bottom: 0.84px solid #b2b2b2;

                &.full-line--dark {
                    border-bottom: 1px solid $fds-reimagine-disabled-text-color;
                }
            }

            .preferred-dealer-drawer-subtitle-text {
                font-family: $ford-f1-font-medium;
                font-size: pToR(24);
                line-height: pToR(32);
                color: black;
                letter-spacing: normal;
                text-align: start;
            }

            .preferred-dealer-drawer__vehicle-list {
                margin-top: pToR(32);
                padding: 0 0 pToR(64);

                .line-before-individual-vehicle {
                    display: none;
                }

                &--individual-vehicle {
                    display: grid;
                    grid-template-columns: 1fr auto;
                    grid-template-rows: repeat(3, auto);
                    grid-row-gap: 4px;
                    margin-bottom: pToR(32);

                    &:last-of-type {
                        .full-line {
                            display: none;
                        }
                    }

                    .full-line {
                        margin-bottom: 0;

                        @include tablet {
                            margin-top: pToR(28);
                        }
                    }

                    &.has-dealer-error {
                        grid-template-rows: repeat(5, auto);

                        .dealer-info-error {
                            grid-area: 4 / 1 / span 1 / span 2;
                            font-family: $ford-f1-font-regular;
                            font-size: pToR(16);
                            line-height: pToR(24);
                            letter-spacing: normal;
                            color: black;
                            text-align: start;
                        }

                        .full-line {
                            grid-area: 5 / 1 / span 1 / span 2;
                        }
                    }

                    &.has-map {
                        grid-template-rows: repeat(5, auto);

                        .map {
                            display: flex;
                            grid-area: 1 / 1 / 2 / 3;
                            height: pToR(150);
                            margin-bottom: pToR(34);

                            &-holder {
                                width: 100%;
                                height: pToR(150);

                                #myMap {
                                    height: pToR(150);
                                }
                            }

                            canvas {
                                &:first-of-type {
                                    border-top-left-radius: pToR(16);
                                    border-top-right-radius: pToR(16);
                                }
                            }

                            .bm_bottomLeftOverlay,
                            .bm_bottomRightOverlay {
                                display: none;
                            }
                        }

                        .ymm {
                            grid-area: 2 / 1 / span 1 / span 1;
                        }

                        .vin {
                            grid-area: 3 / 1 / span 1 / span 1;
                        }

                        .preferred-dealer-info {
                            grid-area: 4 / 1 / span 1 / span 1;
                            margin-top: pToR(13);

                            & + .full-line {
                                grid-area: 5 / 1 / span 1 / span 2;
                            }
                        }

                        button {
                            grid-area: 2 / 2 / span 1 / span 1;

                            &:hover {
                                color: $fds-reimagine-link-hover-blue;
                                text-decoration-color: $fds-reimagine-link-hover-blue;
                            }
                        }
                    }

                    .ymm {
                        grid-area: 1 / 1 / span 1 / span 1;
                        color: black;
                        font-family: $ford-f1-font-bold;
                        font-size: pToR(16);
                        line-height: pToR(24);
                        letter-spacing: normal;
                        margin-bottom: 0;
                        width: fit-content;
                    }

                    .vin {
                        grid-area: 2 / 1 / span 1 / span 1;
                        color: black;
                        font-family: $ford-f1-font-regular;
                        font-size: pToR(12);
                        line-height: pToR(16);
                        letter-spacing: normal;
                        width: fit-content;
                    }

                    button {
                        grid-area: 1 / 2 / span 1 / span 1;
                        font-family: $ford-f1-font-regular;
                        color: black;
                        font-size: pToR(16);
                        line-height: pToR(24);
                        letter-spacing: normal;
                        text-decoration: underline;
                        width: fit-content;
                    }

                    hr {
                        grid-area: 3 / 1 / span 1 / span 2;
                        margin-top: pToR(31);
                    }

                    .preferred-dealer-info {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        .half-line {
                            width: 50%;
                            margin: unset;
                            margin-bottom: pToR(22);
                        }

                        .dealer-name {
                            font-family: $ford-f1-font-medium;
                            font-size: pToR(20);
                            line-height: pToR(28);
                            letter-spacing: normal;
                            color: $charcoal;
                            text-align: start;
                        }

                        .address,
                        .phone-number {
                            color: $charcoal;
                            font-family: $ford-f1-font-regular;
                            letter-spacing: normal;
                            font-size: pToR(14);
                            line-height: pToR(20);
                            text-align: start;
                        }

                        & + .full-line {
                            margin-top: pToR(31);
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}
