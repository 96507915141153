// ================================
// Typography
// ================================
@import '../functions';
// Change scale
$base-font-size: 1vw !default;
$fds-min-screen: 360px !default;
$fds-max-mobile: 600px !default;
$fds-min-tablet: 767.99px !default;
$fds-min-desktop: 1024px !default;
$fds-max-screen: 1920px !default;

// FONT SIZE
$fds-font-size__heading1--min: 40px !default;
$fds-font-size__heading1--max: 90px !default;
$fds-font-size__heading2--min: 36px !default;
$fds-font-size__heading2--max: 75px !default;
$fds-font-size__heading3--min: 32px !default;
$fds-font-size__heading3--max: 50px !default;
$fds-font-size__heading4--min: 28px !default;
$fds-font-size__heading4--max: 36px !default;
$fds-font-size__heading5--min: 24px !default;
$fds-font-size__heading5--max: 32px !default;
$fds-font-size__heading6--min: 20px !default;
$fds-font-size__heading6--max: 24px !default;
$fds-font-size__subtitle1: #{fds-rem(18px)} !default;
$fds-font-size__subtitle2: #{fds-rem(16px)} !default;
$fds-font-size__body1: #{fds-rem(16px)} !default;
$fds-font-size__body2: #{fds-rem(14px)} !default;
$fds-font-size__body3: #{fds-rem(13px)} !default;
$fds-font-size__content1: #{fds-rem(18px)} !default;
$fds-font-size__content2: #{fds-rem(11px)} !default;
$fds-font-size__content3: #{fds-rem(12px)} !default;

// LINE HEIGHT
$fds-line-height__heading1: 1.2 !default;
$fds-line-height__heading2: 1.2 !default;
$fds-line-height__heading3: 1.2 !default;
$fds-line-height__heading4: 1.2 !default;
$fds-line-height__heading5: 1.2 !default;
$fds-line-height__heading6: 1.2 !default;
$fds-line-height__subtitle1: 1.3 !default;
$fds-line-height__subtitle2: 1.5 !default;
$fds-line-height__body1: 1.625 !default;
$fds-line-height__body2: 1.5 !default;
$fds-line-height__body3: 1.3 !default;
$fds-line-height__content1: 1.55 !default;
$fds-line-height__content2: 1.45 !default;
$fds-line-height__content3: 1.75 !default;

// LETTER SPACING
$fds-letter-spacing__heading1: 0 !default;
$fds-letter-spacing__heading2: 0 !default;
$fds-letter-spacing__heading3: 0 !default;
$fds-letter-spacing__heading4: 0 !default;
$fds-letter-spacing__heading5: 0 !default;
$fds-letter-spacing__heading6: 0 !default;
$fds-letter-spacing__subtitle1: 0 !default;
$fds-letter-spacing__subtitle2: 1px !default;
$fds-letter-spacing__body1: 1px !default;
$fds-letter-spacing__body2: 1px !default;
$fds-letter-spacing__body3: 0 !default;
$fds-letter-spacing__content1: 1px !default;
$fds-letter-spacing__content2: 1px !default;
$fds-letter-spacing__content3: 1px !default;

// WEIGHT
$fds-weight__heading1: var(--fmc-type__weight-medium) !default;
$fds-weight__heading2: var(--fmc-type__weight-medium) !default;
$fds-weight__heading3: var(--fmc-type__weight-medium) !default;
$fds-weight__heading4: var(--fmc-type__weight-medium) !default;
$fds-weight__heading5: var(--fmc-type__weight-normal) !default;
$fds-weight__heading6: var(--fmc-type__weight-medium) !default;
$fds-weight__subtitle1: var(--fmc-type__weight-normal) !default;
$fds-weight__subtitle2: var(--fmc-type__weight-medium) !default;
$fds-weight__body1: var(--fmc-type__weight-normal) !default;
$fds-weight__body2: var(--fmc-type__weight-normal) !default;
$fds-weight__body3: var(--fmc-type__weight-normal) !default;
$fds-weight__content1: var(--fmc-type__weight-normal) !default;
$fds-weight__content2: var(--fmc-type__weight-medium) !default;
$fds-weight__content3: var(--fmc-type__weight-normal) !default;

// --------------------------------
// Component Typography
// --------------------------------

// FONT SIZE
$fds-font-size__accordion: #{fds-rem(16px)} !default;
$fds-font-size__activity-indicator: #{fds-rem(12px)} !default;
$fds-font-size__checkbox-label--sm: #{fds-rem(16px)} !default;
$fds-font-size__dropdown-option: #{fds-rem(16px)} !default;
$fds-font-size__dropdown-label: #{fds-rem(12px)} !default;
$fds-font-size__floating-action-button: #{fds-rem(18px)} !default;
$fds-font-size__primary-button--sm: #{fds-rem(18px)} !default;
$fds-font-size__radio-label--sm: #{fds-rem(16px)} !default;
$fds-font-size__secondary-button--sm: #{fds-rem(18px)} !default;
$fds-font-size__snackbar: #{fds-rem(11px)} !default;
$fds-font-size__specialty-button--sm: #{fds-rem(16px)} !default;
$fds-font-size__tabs__tab: #{fds-rem(16px)} !default;
$fds-font-size__textfield-input-label--sm: #{fds-rem(12px)} !default;
$fds-font-size__toggle-label: #{fds-rem(16px)} !default;
$fds-font-size__toggle-value: #{fds-rem(12px)} !default;
$fds-font-size__tracker: #{fds-rem(11px)} !default;

// LINE HEIGHT
$fds-line-height__accordion: #{fds-rem(16px)} !default;
$fds-line-height__activity-indicator: #{fds-rem(21px)} !default;
$fds-line-height__checkbox-label--sm: #{fds-rem(16px)} !default;
$fds-line-height__dropdown-option: #{fds-rem(16px)} !default;
$fds-line-height__dropdown-label: #{fds-rem(12px)} !default;
$fds-line-height__floating-action-button: #{fds-rem(18px)} !default;
$fds-line-height__primary-button--sm: #{fds-rem(18px)} !default;
$fds-line-height__radio-label--sm: #{fds-rem(16px)} !default;
$fds-line-height__secondary-button--sm: #{fds-rem(18px)} !default;
$fds-line-height__snackbar: #{fds-rem(21px)} !default;
$fds-line-height__specialty-button--sm: #{fds-rem(25px)} !default;
$fds-line-height__tabs__tab: #{fds-rem(16px)} !default;
$fds-line-height__textfield-input-label--sm: #{fds-rem(12px)} !default;
$fds-line-height__toggle-label: #{fds-rem(16px)} !default;
$fds-line-height__toggle-value: #{fds-rem(12px)} !default;
$fds-line-height__tracker: #{fds-rem(45px)} !default;

// LETTER SPACING
$fds-letter-spacing__accordion: 1px !default;
$fds-letter-spacing__activity-indicator: 1px !default;
$fds-letter-spacing__checkbox-label--sm: 1px !default;
$fds-letter-spacing__dropdown-option: 1px !default;
$fds-letter-spacing__dropdown-label: 1px !default;
$fds-letter-spacing__floating-action-button: 1px !default;
$fds-letter-spacing__primary-button--sm: 1px !default;
$fds-letter-spacing__radio-label--sm: 1px !default;
$fds-letter-spacing__secondary-button--sm: 1px !default;
$fds-letter-spacing__snackbar: 1px !default;
$fds-letter-spacing__specialty-button--sm: #{fds-rem(4px)} !default;
$fds-letter-spacing__tabs__tab: #{fds-rem(2px)} !default;
$fds-letter-spacing__textfield-input-label--sm: 1px !default;
$fds-letter-spacing__toggle-label: 1px !default;
$fds-letter-spacing__toggle-value: 1px !default;
$fds-letter-spacing__tabs__tab: #{fds-rem(2px)} !default;
$fds-letter-spacing__tracker: 1px !default;

// Sets
$fds-type-sets: (
    'heading1': (
        'ford': (
            'base': (
                --fmc-type-family: inherit,
                --fmc-type-weight: #{$fds-weight__heading1},
                --fmc-type-size: $fds-font-size__heading3--min,
                --fmc-type-leading: #{$fds-line-height__heading1},
                --fmc-type-tracking: normal,
                --fmc-type-transform: initial,
                font-family: var(--fmc-type-family),
                font-weight: var(--fmc-type-weight),
                font-size: var(--fmc-type-size),
                line-height: var(--fmc-type-leading),
                letter-spacing: var(--fmc-type-tracking),
                text-transform: var(--fmc-type-transform),
            ),
            'fds-sm': (
                --fmc-type-size: #{fds-rem(40px)},
            ),
            'fds-md': (
                --fmc-type-size: #{fds-rem(40px)},
            ),
        ),
        'lincoln': (
            'base': (
                --fmc-type-family: var(--fmc-font--miller),
                --fmc-type-weight: var(--fmc-type__weight-bold),
                --fmc-type-size: #{fds-rem(32px)},
                --fmc-type-leading: 1.625,
                --fmc-type-tracking: initial,
                --fmc-type-transform: initial,
            ),
            'fds-sm': (
                --fmc-type-size: #{fds-rem(40px)},
                --fmc-type-leading: 1.12,
            ),
            'fds-md': (
                --fmc-type-size: #{fds-rem(40px)},
                --fmc-type-leading: 1.16,
            ),
        ),
    ),
    'heading2': (
        'ford': (
            'base': (
                --fmc-type-family: inherit,
                --fmc-type-weight: #{$fds-weight__heading2},
                --fmc-type-size: #{fds-rem(36px)},
                --fmc-type-leading: #{$fds-line-height__heading2},
                --fmc-type-tracking: initial,
                --fmc-type-transform: initial,
                font-family: var(--fmc-type-family),
                font-weight: var(--fmc-type-weight),
                font-size: var(--fmc-type-size),
                line-height: var(--fmc-type-leading),
                letter-spacing: var(--fmc-type-tracking),
                text-transform: var(--fmc-type-transform),
            ),
            'fds-sm': (
                --fmc-type-size: #{fds-rem(55px)},
            ),
            'fds-md': (
                --fmc-type-size: #{fds-rem(75px)},
            ),
        ),
        'lincoln': (
            'base': (
                --fmc-type-family: var(--fmc-font--miller),
                --fmc-type-weight: var(--fmc-type__weight-bold),
                --fmc-type-size: #{fds-rem(34px)},
                --fmc-type-leading: 1.625,
                --fmc-type-tracking: initial,
                --fmc-type-transform: initial,
            ),
            'fds-sm': (
                --fmc-type-size: #{fds-rem(47px)},
                --fmc-type-leading: 1.12,
            ),
            'fds-md': (
                --fmc-type-size: #{fds-rem(60px)},
                --fmc-type-leading: 1.16,
            ),
        ),
    ),
    'heading3': (
        'ford': (
            'base': (
                --fmc-type-family: inherit,
                --fmc-type-weight: #{$fds-weight__heading3},
                --fmc-type-size: #{fds-rem(32px)},
                --fmc-type-leading: #{$fds-line-height__heading3},
                --fmc-type-tracking: initial,
                --fmc-type-transform: initial,
                font-family: var(--fmc-type-family),
                font-weight: var(--fmc-type-weight),
                font-size: var(--fmc-type-size),
                line-height: var(--fmc-type-leading),
                letter-spacing: var(--fmc-type-tracking),
                text-transform: var(--fmc-type-transform),
            ),
            'fds-sm': (
                --fmc-type-size: #{fds-rem(32px)},
            ),
            'fds-md': (
                --fmc-type-size: #{fds-rem(32px)},
            ),
        ),
        'lincoln': (
            'base': (
                --fmc-type-family: inherit,
                --fmc-type-weight: var(--fmc-type__weight-normal),
                --fmc-type-size: #{fds-rem(24px)},
                --fmc-type-leading: 1.2,
                --fmc-type-tracking: initial,
                --fmc-type-transform: initial,
            ),
            'fds-sm': (
                --fmc-type-size: #{fds-rem(32px)},
                --fmc-type-leading: 1.2,
            ),
            'fds-md': (
                --fmc-type-size: #{fds-rem(40px)},
                --fmc-type-leading: 1.2,
            ),
        ),
    ),
    'heading4': (
        'ford': (
            'base': (
                --fmc-type-family: inherit,
                --fmc-type-weight: #{$fds-weight__heading4},
                --fmc-type-size: #{fds-rem(28px)},
                --fmc-type-leading: #{$fds-line-height__heading4},
                --fmc-type-tracking: initial,
                --fmc-type-transform: initial,
                font-family: var(--fmc-type-family),
                font-weight: var(--fmc-type-weight),
                font-size: var(--fmc-type-size),
                line-height: var(--fmc-type-leading),
                letter-spacing: var(--fmc-type-tracking),
                text-transform: var(--fmc-type-transform),
            ),
            'fds-sm': (
                --fmc-type-size: #{fds-rem(32px)},
            ),
            'fds-md': (
                --fmc-type-size: #{fds-rem(36px)},
            ),
        ),
        'lincoln': (
            'base': (
                --fmc-type-family: inherit,
                --fmc-type-weight: var(--fmc-type__weight-normal),
                --fmc-type-size: #{fds-rem(19px)},
                --fmc-type-leading: 1.68,
                --fmc-type-tracking: #{fds-rem(2px)},
                --fmc-type-transform: uppercase,
            ),
            'fds-sm': (
                --fmc-type-size: #{fds-rem(25px)},
                --fmc-type-leading: 1.6,
            ),
            'fds-md': (
                --fmc-type-size: #{fds-rem(32px)},
                --fmc-type-leading: 1.5,
            ),
        ),
    ),
    'heading5': (
        'ford': (
            'base': (
                --fmc-type-family: inherit,
                --fmc-type-weight: #{$fds-weight__heading5},
                --fmc-type-size: #{fds-rem(24px)},
                --fmc-type-leading: #{$fds-line-height__heading5},
                --fmc-type-tracking: initial,
                --fmc-type-transform: initial,
                font-family: var(--fmc-type-family),
                font-weight: var(--fmc-type-weight),
                font-size: var(--fmc-type-size),
                line-height: var(--fmc-type-leading),
                letter-spacing: var(--fmc-type-tracking),
                text-transform: var(--fmc-type-transform),
            ),
            'fds-sm': (
                --fmc-type-size: #{fds-rem(28px)},
            ),
            'fds-md': (
                --fmc-type-size: #{fds-rem(32px)},
            ),
        ),
        'lincoln': (
            'base': (
                --fmc-type-family: inherit,
                --fmc-type-weight: var(--fmc-type__weight-normal),
                --fmc-type-size: #{fds-rem(15px)},
                --fmc-type-leading: 1.17,
                --fmc-type-tracking: #{fds-rem(2px)},
                --fmc-type-transform: uppercase,
            ),
            'fds-sm': (
                --fmc-type-size: #{fds-rem(19px)},
                --fmc-type-leading: 1.36,
            ),
            'fds-md': (
                --fmc-type-size: #{fds-rem(24px)},
                --fmc-type-leading: 1.33,
            ),
        ),
    ),
    'heading6': (
        'ford': (
            'base': (
                --fmc-type-family: inherit,
                --fmc-type-weight: var(--fmc-type__weight-normal),
                --fmc-type-size: #{fds-rem(20px)},
                --fmc-type-leading: #{$fds-line-height__heading6},
                --fmc-type-tracking: #{$fds-letter-spacing__heading6},
                --fmc-type-transform: initial,
                font-family: var(--fmc-type-family),
                font-weight: var(--fmc-type-weight),
                font-size: var(--fmc-type-size),
                line-height: var(--fmc-type-leading),
                letter-spacing: var(--fmc-type-tracking),
                text-transform: var(--fmc-type-transform),
            ),
            'fds-sm': (
                --fmc-type-size: #{fds-rem(20px)},
            ),
            'fds-md': (
                --fmc-type-size: #{fds-rem(20px)},
            ),
        ),
        'lincoln': (
            'base': (
                --fmc-type-family: inherit,
                --fmc-type-weight: var(--fmc-type__weight-normal),
                --fmc-type-size: #{fds-rem(10px)},
                --fmc-type-leading: 1.4,
                --fmc-type-tracking: #{fds-rem(1.25px)},
                --fmc-type-transform: uppercase,
            ),
            'fds-sm': (
                --fmc-type-size: #{fds-rem(13px)},
                --fmc-type-leading: 1.3,
            ),
            'fds-md': (
                --fmc-type-size: #{fds-rem(17px)},
                --fmc-type-leading: 1.17,
            ),
        ),
    ),
    'subtitle1': (
        'ford': (
            'base': (
                --fmc-type-family: inherit,
                --fmc-type-weight: #{$fds-weight__subtitle1},
                --fmc-type-size: #{$fds-font-size__subtitle1},
                --fmc-type-leading: #{$fds-line-height__subtitle1},
                --fmc-type-tracking: normal,
                --fmc-type-transform: initial,
                font-family: var(--fmc-type-family),
                font-weight: var(--fmc-type-weight),
                font-size: var(--fmc-type-size),
                line-height: var(--fmc-type-leading),
                letter-spacing: var(--fmc-type-tracking),
                text-transform: var(--fmc-type-transform),
            ),
        ),
        'lincoln': (
            'base': (
                --fmc-type-family: inherit,
                --fmc-type-weight: var(--fmc-type__weight-medium),
                --fmc-type-size: #{fds-rem(18px)},
                --fmc-type-leading: 1.44,
                --fmc-type-tracking: 0,
                --fmc-type-transform: initial,
                --fmc-type-stretch: initial,
            ),
        ),
    ),
    'subtitle2': (
        'ford': (
            'base': (
                --fmc-type-family: inherit,
                --fmc-type-weight: #{$fds-weight__subtitle2},
                --fmc-type-size: #{$fds-font-size__subtitle2},
                --fmc-type-leading: #{$fds-line-height__subtitle2},
                --fmc-type-tracking: #{fds-rem(1px)},
                --fmc-type-transform: uppercase,
                --fmc-type-stretch: condensed,
                font-family: var(--fmc-type-family),
                font-weight: var(--fmc-type-weight),
                font-stretch: var(--fmc-type-stretch),
                font-size: var(--fmc-type-size),
                line-height: var(--fmc-type-leading),
                letter-spacing: var(--fmc-type-tracking),
                text-transform: var(--fmc-type-transform),
            ),
        ),
        'lincoln': (
            'base': (
                --fmc-type-family: inherit,
                --fmc-type-weight: var(--fmc-type__weight-normal),
                --fmc-type-size: #{fds-rem(15px)},
                --fmc-type-leading: 1.2,
                --fmc-type-tracking: #{fds-rem(1.5px)},
                --fmc-type-transform: uppercase,
                --fmc-type-stretch: initial,
            ),
            'fds-sm': (
                --fmc-type-size: #{fds-rem(16px)},
            ),
            'fds-md': (
                --fmc-type-size: #{fds-rem(17px)},
            ),
        ),
    ),
    'body1': (
        'ford': (
            'base': (
                --fmc-type-family: inherit,
                --fmc-type-weight: #{$fds-weight__body1},
                --fmc-type-size: #{$fds-font-size__body1},
                --fmc-type-leading: #{$fds-line-height__body1},
                --fmc-type-tracking: #{fds-rem(1px)},
                --fmc-type-transform: initial,
                --fmc-type-stretch: normal,
                font-family: var(--fmc-type-family),
                font-weight: var(--fmc-type-weight),
                font-size: var(--fmc-type-size),
                line-height: var(--fmc-type-leading),
                letter-spacing: var(--fmc-type-tracking),
                text-transform: var(--fmc-type-transform),
            ),
        ),
        'lincoln': (
            'base': (
                --fmc-type-family: inherit,
                --fmc-type-weight: var(--fmc-type__weight-normal),
                --fmc-type-size: #{fds-rem(16px)},
                --fmc-type-leading: 1.33,
                --fmc-type-tracking: 0,
                --fmc-type-transform: initial,
            ),
        ),
    ),
    'body2': (
        'ford': (
            'base': (
                --fmc-type-family: inherit,
                --fmc-type-weight: #{$fds-weight__body2},
                --fmc-type-size: #{$fds-font-size__body2},
                --fmc-type-leading: #{$fds-line-height__body2},
                --fmc-type-tracking: #{fds-rem(1px)},
                --fmc-type-transform: initial,
                font-family: var(--fmc-type-family),
                font-weight: var(--fmc-type-weight),
                font-size: var(--fmc-type-size),
                line-height: var(--fmc-type-leading),
                letter-spacing: var(--fmc-type-tracking),
                text-transform: var(--fmc-type-transform),
            ),
        ),
        'lincoln': (
            'base': (
                --fmc-type-family: inherit,
                --fmc-type-weight: var(--fmc-type__weight-normal),
                --fmc-type-size: #{fds-rem(16px)},
                --fmc-type-leading: 1.25,
                --fmc-type-tracking: initial,
                --fmc-type-transform: initial,
            ),
        ),
    ),
    'body3': (
        'ford': (
            'base': (
                --fmc-type-family: inherit,
                --fmc-type-weight: #{$fds-weight__body3},
                --fmc-type-size: #{$fds-font-size__body3},
                --fmc-type-leading: #{$fds-line-height__body3},
                --fmc-type-tracking: #{$fds-letter-spacing__body3},
                --fmc-type-transform: initial,
                font-family: var(--fmc-type-family),
                font-weight: var(--fmc-type-weight),
                font-size: var(--fmc-type-size),
                line-height: var(--fmc-type-leading),
                letter-spacing: var(--fmc-type-tracking),
                text-transform: var(--fmc-type-transform),
            ),
        ),
        'lincoln': (
            'base': (
                --fmc-type-family: inherit,
                --fmc-type-weight: var(--fmc-type__weight-normal),
                --fmc-type-size: #{fds-rem(13px)},
                --fmc-type-leading: 1.3,
                --fmc-type-tracking: initial,
                --fmc-type-transform: initial,
            ),
        ),
    ),
    'content1': (
        'ford': (
            'base': (
                --fmc-type-family: inherit,
                --fmc-type-weight: #{$fds-weight__content1},
                --fmc-type-size: #{$fds-font-size__content1},
                --fmc-type-leading: #{$fds-line-height__content1},
                --fmc-type-tracking: #{$fds-letter-spacing__content1},
                --fmc-type-transform: initial,
                font-family: var(--fmc-type-family),
                font-weight: var(--fmc-type-weight),
                font-size: var(--fmc-type-size),
                line-height: var(--fmc-type-leading),
                letter-spacing: var(--fmc-type-tracking),
                text-transform: var(--fmc-type-transform),
            ),
        ),
        'lincoln': (
            'base': (
                --fmc-type-family: inherit,
                --fmc-type-weight: var(--fmc-type__weight-medium),
                --fmc-type-size: #{fds-rem(16px)},
                --fmc-type-leading: 1.25,
                --fmc-type-tracking: initial,
                --fmc-type-transform: initial,
            ),
        ),
    ),
    'content2': (
        'ford': (
            'base': (
                --fmc-type-family: inherit,
                --fmc-type-weight: #{$fds-weight__content2},
                --fmc-type-size: #{$fds-font-size__content2},
                --fmc-type-leading: #{$fds-line-height__content2},
                --fmc-type-tracking: #{$fds-letter-spacing__content2},
                --fmc-type-transform: initial,
                font-family: var(--fmc-type-family),
                font-weight: var(--fmc-type-weight),
                font-size: var(--fmc-type-size),
                line-height: var(--fmc-type-leading),
                letter-spacing: var(--fmc-type-tracking),
                text-transform: var(--fmc-type-transform),
            ),
        ),
        'lincoln': (
            'base': (
                --fmc-type-family: inherit,
                --fmc-type-weight: var(--fmc-type__weight-normal),
                --fmc-type-size: #{fds-rem(13px)},
                --fmc-type-leading: 1,
                --fmc-type-tracking: initial,
                --fmc-type-transform: initial,
            ),
        ),
    ),
    'content3': (
        'ford': (
            'base': (
                --fmc-type-family: inherit,
                --fmc-type-weight: #{$fds-weight__content3},
                --fmc-type-size: #{$fds-font-size__content3},
                --fmc-type-leading: #{$fds-line-height__content3},
                --fmc-type-tracking: #{$fds-letter-spacing__content3},
                --fmc-type-transform: initial,
                font-family: var(--fmc-type-family),
                font-weight: var(--fmc-type-weight),
                font-size: var(--fmc-type-size),
                line-height: var(--fmc-type-leading),
                letter-spacing: var(--fmc-type-tracking),
                text-transform: var(--fmc-type-transform),
            ),
        ),
        'lincoln': (
            'base': (
                --fmc-type-family: inherit,
                --fmc-type-weight: var(--fmc-type__weight-normal),
                --fmc-type-size: #{fds-rem(11px)},
                --fmc-type-leading: 1.18,
                --fmc-type-tracking: initial,
                --fmc-type-transform: initial,
            ),
        ),
    ),
    'section': (
        'ford': (
            'base': (
                --fmc-type-family: inherit,
                --fmc-type-weight: var(--fmc-type__weight-light),
                --fmc-type-stretch: condensed,
                --fmc-type-size: #{fds-rem(24px)},
                --fmc-type-leading: 1.15,
                --fmc-type-tracking: initial,
                --fmc-type-transform: uppercase,
                font-family: var(--fmc-type-family),
                font-weight: var(--fmc-type-weight),
                font-size: var(--fmc-type-size),
                font-stretch: var(--fmc-type-stretch),
                line-height: var(--fmc-type-leading),
                letter-spacing: var(--fmc-type-tracking),
                text-transform: var(--fmc-type-transform),
            ),
            'fds-sm': (
                --fmc-type-size: #{fds-rem(36px)},
            ),
            'fds-md': (
                --fmc-type-size: #{fds-rem(48px)},
                --fmc-type-leading: 1.3,
            ),
        ),
        'lincoln': (
            'base': (
                --fmc-type-family: inherit,
                --fmc-type-weight: var(--fmc-type__weight-normal),
                --fmc-type-stretch: initial,
                --fmc-type-size: #{fds-rem(19px)},
                --fmc-type-leading: 1.3,
                --fmc-type-tracking: #{fds-rem(2px)},
                --fmc-type-transform: uppercase,
            ),
            'fds-sm': (
                --fmc-type-size: #{fds-rem(25px)},
            ),
            'fds-md': (
                --fmc-type-size: #{fds-rem(32px)},
            ),
        ),
    ),
    'legal': (
        'ford': (
            'base': (
                --fmc-type-family: inherit,
                --fmc-type-weight: var(--fmc-type__weight-normal),
                --fmc-type-size: #{fds-rem(11px)},
                --fmc-type-leading: 1.8,
                --fmc-type-tracking: #{fds-rem(1px)},
                --fmc-type-transform: initial,
                font-family: var(--fmc-type-family),
                font-weight: var(--fmc-type-weight),
                font-size: var(--fmc-type-size),
                line-height: var(--fmc-type-leading),
                letter-spacing: var(--fmc-type-tracking),
                text-transform: var(--fmc-type-transform),
            ),
        ),
        'lincoln': (
            'base': (
                --fmc-type-family: inherit,
                --fmc-type-weight: var(--fmc-type__weight-normal),
                --fmc-type-size: #{fds-rem(11px)},
                --fmc-type-leading: 1.2,
                --fmc-type-tracking: initial,
                --fmc-type-transform: initial,
            ),
        ),
    ),
) !default;

$fds-component-type-sets: (
    'accordion': (
        'base': (
            font-size: $fds-font-size__accordion,
            line-height: $fds-line-height__accordion,
            letter-spacing: $fds-letter-spacing__accordion,
        ),
    ),
    'activity-indicator': (
        'base': (
            font-size: $fds-font-size__activity-indicator,
            line-height: $fds-line-height__activity-indicator,
            letter-spacing: $fds-letter-spacing__activity-indicator,
        ),
    ),
    'checkbox-label': (
        'base': (
            font-size: $fds-font-size__checkbox-label--sm,
            line-height: $fds-line-height__checkbox-label--sm,
            letter-spacing: $fds-letter-spacing__checkbox-label--sm,
        ),
    ),
    'dropdown-option': (
        'base': (
            font-size: $fds-font-size__dropdown-option,
            line-height: $fds-line-height__dropdown-option,
            letter-spacing: $fds-letter-spacing__dropdown-option,
        ),
    ),
    'dropdown-label': (
        'base': (
            font-size: $fds-font-size__dropdown-label,
            line-height: $fds-line-height__dropdown-label,
            letter-spacing: $fds-letter-spacing__dropdown-label,
        ),
    ),
    'floating-action-button': (
        'base': (
            font-size: $fds-font-size__floating-action-button,
            font-stretch: condensed,
            line-height: $fds-line-height__floating-action-button,
            letter-spacing: $fds-letter-spacing__floating-action-button,
        ),
    ),
    'primary-button': (
        'base': (
            font-size: $fds-font-size__primary-button--sm,
            font-stretch: condensed,
            line-height: $fds-line-height__primary-button--sm,
            letter-spacing: $fds-letter-spacing__primary-button--sm,
        ),
    ),
    'radio-label': (
        'base': (
            font-size: $fds-font-size__radio-label--sm,
            line-height: $fds-line-height__radio-label--sm,
            letter-spacing: $fds-letter-spacing__radio-label--sm,
        ),
    ),
    'secondary-button': (
        'base': (
            font-size: $fds-font-size__secondary-button--sm,
            font-stretch: condensed,
            line-height: $fds-line-height__secondary-button--sm,
            letter-spacing: $fds-letter-spacing__secondary-button--sm,
        ),
    ),
    'snackbar': (
        'base': (
            font-size: $fds-font-size__snackbar,
            line-height: $fds-line-height__snackbar,
            letter-spacing: $fds-letter-spacing__snackbar,
        ),
    ),
    'specialty-button': (
        'base': (
            font-size: $fds-font-size__specialty-button--sm,
            font-stretch: condensed,
            line-height: $fds-line-height__specialty-button--sm,
            letter-spacing: $fds-letter-spacing__specialty-button--sm,
            text-transform: uppercase,
        ),
    ),
    'tabs': (
        'base': (
            font-size: $fds-font-size__tabs__tab,
            font-stretch: condensed,
            line-height: $fds-line-height__tabs__tab,
            letter-spacing: $fds-letter-spacing__tabs__tab,
        ),
    ),
    'textfield-input-label': (
        'base': (
            font-size: $fds-font-size__textfield-input-label--sm,
            line-height: $fds-line-height__textfield-input-label--sm,
            letter-spacing: $fds-letter-spacing__textfield-input-label--sm,
        ),
    ),
    'toggle-label': (
        'base': (
            font-size: $fds-font-size__toggle-label,
            line-height: $fds-line-height__toggle-label,
            letter-spacing: $fds-letter-spacing__toggle-label,
        ),
    ),
    'toggle-value': (
        'base': (
            font-size: $fds-font-size__toggle-value,
            line-height: $fds-line-height__toggle-value,
            letter-spacing: $fds-letter-spacing__toggle-value,
        ),
    ),
    'tracker': (
        'base': (
            font-size: $fds-font-size__tracker,
            line-height: $fds-line-height__tracker,
            letter-spacing: $fds-letter-spacing__tracker,
        ),
    ),
) !default;

// creates %placeholder extends from type-sets

@include fds-set-type-sets('%fmc-type', $fds-type-sets);
