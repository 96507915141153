@import '@styles/main.v5';
@import '@styles/common';

.toggle {
    &__container {
        display: flex;

        /* The switch - the box around the slider */
        .switch {
            position: relative;
            display: inline-block;
            width: pToR(48);
            height: pToR(24);
            border-radius: pToR(24);
            background-color: #999999;

            .brand-lincoln & {
                background-color: $fds-preimagine-lincoln-primary;
            }

            &:has(> input:focus-visible) {
                outline: 2px solid $fds-preimagine-button-blue;
                outline-offset: 2px;

                .brand-lincoln & {
                    outline-color: $brand-border;
                }
            }

            &:has(> input:checked) {
                background-color: $fds-preimagine-button-blue;

                .brand-lincoln & {
                    background-color: $brand-border;
                }
            }

            /* Hide default HTML checkbox */
            input {
                opacity: 0;
                width: 0;
                height: 0;

                &:checked + .slider {
                    background-color: $fds-preimagine-button-blue;

                    .brand-lincoln & {
                        background-color: $brand-border;
                    }
                }

                &:checked + .slider .slider-knob {
                    -webkit-transform: translateX(24px);
                    -ms-transform: translateX(24px);
                    transform: translateX(24px);
                }

                &:focus + .slider {
                    box-shadow: 0 0 1px $fds-preimagine-button-blue;

                    .brand-lincoln & {
                        box-shadow: 0 0 1px $brand-border;
                    }
                }
            }
        }

        /* The slider */
        .slider {
            position: relative;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #999999;
            -webkit-transition: 0.4s;
            transition: 0.4s;

            .brand-lincoln & {
                background-color: $fds-preimagine-lincoln-primary;
            }

            &-knob {
                position: absolute;
                content: '';
                height: pToR(20);
                width: pToR(20);
                left: 2px;
                bottom: 2px;
                top: -1px;
                border-radius: 50%;
                background-color: white;
                -webkit-transition: 0.4s;
                transition: 0.4s;
                display: flex;
                justify-content: center;
                align-items: center;

                .brand-lincoln & {
                    top: -2px;
                }
            }

            /* Rounded sliders */
            &.round {
                border-radius: pToR(34);

                &:before {
                    border-radius: 50%;
                }
            }
        }
    }
}
