@import '@styles/main.scss';

.fmc-cards__card {
    position: relative;
    min-height: pToR(482);
    height: 100%;
    border-radius: pToR(8);
    background-color: $white;
    margin-right: pToR(24);
    box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1),
        0 10px 10px 0 rgba(0, 0, 0, 0.1);

    @include mobile {
        margin-right: pToR(16);
    }

    @include tablet {
        width: pToR(400);
    }

    &.gvt-image-alignment {
        .fmc-cards__media-area {
            padding-bottom: pToR(40);
        }
    }

    .brand-lincoln & {
        border: 1px solid #e0e0e0;

        &.ordered-vehicle-card {
            border: 0;
        }
    }

    .fmc-cards__media-area {
        height: pToR(200);

        @include mobile {
            height: pToR(176);
        }

        img {
            max-height: 100%;
            max-width: 100%;
            width: auto;
            margin: auto;
        }

        .placeholder-image {
            max-width: pToR(232);
            margin: auto;
        }

        .ordered-vehicle-image {
            max-width: pToR(302);
            height: auto;
            transform: translateY(-16px);

            @include mobile {
                max-width: pToR(272);
                transform: none;
                width: 100%;
            }
        }

        .reset-transform {
            transform: translateY(10px);
        }

        .fmc-cards__alert {
            height: pToR(30);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: pToR(12);
            line-height: pToR(18);
        }

        // GVT styles
        .gvt-banner {
            color: white;
            background-color: $dark-blue;
            display: flex;
            align-items: center;
            position: absolute;
            bottom: 0;
            min-height: pToR(40);
            width: 100%;

            img {
                width: pToR(30);
                padding-right: pToR(12);
            }

            .secondary-button-text {
                display: flex;
                align-items: center;
                padding: 0 pToR(12);
                height: pToR(40);
                width: 100%;
            }

            &__main-copy {
                font-size: pToR(12);
                padding-right: pToR(12);
                margin-right: auto;
                letter-spacing: pToR(0.83);
            }

            &__cta {
                display: flex;

                .fds-icon--offset-right {
                    display: flex;
                    align-items: center;
                    font-size: pToR(12);
                    margin-left: pToR(8);

                    &:before {
                        color: white;
                    }
                }
            }

            .secondary-button {
                display: flex;
                align-items: center;
                width: 100%;
                height: pToR(40);

                &:hover {
                    .fmc-type--content2 {
                        text-decoration: underline;
                    }
                }

                .fmc-text-button {
                    display: inline-flex;
                    height: pToR(30);
                    width: 100%;
                    color: white;
                    font-stretch: unset;
                    --underline-color: white;

                    &:hover {
                        text-decoration: none;
                    }

                    .fmc-type--content2 {
                        display: flex;
                        align-items: center;
                        font-size: pToR(12);
                        letter-spacing: pToR(0.83);
                    }
                }
            }
        }
    }

    .fmc-text-button.fmc-text-button--chevron-right:after {
        font-size: pToR(14);
        line-height: pToR(13);
        margin-left: pToR(8);
        margin-top: pToR(-4);

        .brand-lincoln & {
            display: none;
        }
    }

    .fmc-cards__title {
        max-width: calc(100% - 38px);
        margin: 0 auto pToR(8);

        [data-brand='lincoln'] & {
            font-size: pToR(24);
        }
    }

    .fmc-cards__subtitle {
        h3 {
            font-stretch: unset;
        }

        &.model-unavailable {
            margin: 0 pToR(16) pToR(16);
        }
    }

    .order-number-and-vin-box {
        margin-bottom: 0;
        height: pToR(47);

        @include mobile {
            margin-bottom: pToR(2);
        }

        &.has-both {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            padding: 0 pToR(5);

            @include media('<768px') {
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            span {
                font-size: pToR(12);
                line-height: pToR(18);
                color: $disabled-dark;
                padding: 0 pToR(10);

                @include media('<768px') {
                    display: none;
                }
            }

            p {
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
    }

    .fmc-cards__order-status--box {
        min-height: pToR(72);
        max-width: 100%;
        background-color: #f2f2f2;
        border-radius: pToR(8);
        display: flex;
        flex-direction: column;
        justify-content: center;

        .fmc-cards__order-status--box-label {
            font-size: pToR(12);
            line-height: pToR(16);
            letter-spacing: 1px;
            color: $dropdown-text;
            text-align: center;
        }

        .fmc-cards__order-status--value {
            font-size: pToR(16);
            line-height: pToR(24);
            letter-spacing: normal;
            font-weight: 700;
            color: black;
            text-align: center;
        }
    }

    .mmota-container {
        display: flex;
        justify-content: center;
    }

    .mmota-box {
        width: 31%;
        background-color: #f2f2f2;
        border-radius: pToR(8);
        padding: pToR(16) pToR(8);
        margin: 0 pToR(4) 0 pToR(4);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;

        h5 {
            margin: 0;
            font-feature-settings: 'clig' off, 'liga' off;
            font-size: pToR(12);
            font-weight: 400;
            line-height: pToR(16);
            overflow-wrap: break-word;
        }

        p {
            font-weight: 700;
            line-height: pToR(24);
            letter-spacing: normal;
            overflow-wrap: break-word;

            @include mobile-v5 {
                font-size: pToR(14);
            }

            @media (max-width: 400px) {
                font-size: pToR(12);
            }
        }
    }

    .fmc-cards__menu-links {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: pToR(20);

        &.flipped {
            flex-direction: column-reverse;
        }

        .fmc-cards__menu-link {
            height: pToR(52);

            .secondary-button {
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                a {
                    height: 100%;
                    width: 100%;
                    border-bottom: 1px solid #e9e9e9;
                    display: flex;
                    position: relative;
                    align-items: center;

                    @include mobile {
                        margin: 0;
                    }

                    .brand-lincoln & {
                        letter-spacing: normal;

                        &:hover {
                            .underline-overlay__card-menu-link {
                                border-bottom: 2px solid $brand-border;
                            }

                            span {
                                span {
                                    color: $brand-border;
                                }
                            }

                            .fds-font--ford-icons__chevron-right:before {
                                color: $brand-border;
                            }
                        }
                    }

                    .underline-overlay__card-menu-link {
                        display: none;
                    }

                    &:hover {
                        .underline-overlay__card-menu-link {
                            position: absolute;
                            display: block;
                            bottom: 0;
                            height: 3px;
                            width: 100%;
                            border-bottom: 2px solid $fds-color--secondary;
                        }

                        span {
                            span {
                                color: $fds-color--secondary;
                            }
                        }

                        .fds-font--ford-icons__chevron-right:before {
                            color: $fds-color--secondary;
                        }
                    }
                }

                .secondary-button-text {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    line-height: pToR(24);
                }
            }

            .fds-icon {
                color: $fds-color--primary;
                align-self: center;
                font-weight: 700;
            }
        }
    }

    &.cancelled-order-card {
        .fmc-cards__content {
            .block-second {
                .tooltip-list {
                    height: unset;
                    top: pToR(3);

                    &:before {
                        top: 32%;
                    }

                    &:after {
                        top: 25%;
                    }
                }
            }
        }
    }

    .fmc-cards__content {
        padding: pToR(4) pToR(16) 0;
        position: relative;

        @include desktop {
            padding: pToR(4) pToR(24) 0;
        }

        .fmc-cards__title {
            font-size: pToR(24);
            line-height: pToR(32);
            padding-top: pToR(25);
            align-self: center;
            margin-bottom: pToR(8);

            @include mobile {
                padding-top: pToR(19);
            }
        }

        h3.fmc-cards__subtitle {
            margin-bottom: pToR(6);
            margin-top: 0;
            line-height: pToR(24);

            @include mobile {
                margin-bottom: pToR(7);
            }
        }

        .fmc-type--content3 {
            font-size: pToR(12);
            line-height: pToR(18);
            letter-spacing: 1px;

            &:nth-of-type(2) {
                margin-bottom: pToR(11);
            }
        }

        .block-second {
            position: absolute;
            right: 0;
            top: 0;

            .fds-font--ford-icons__ellipsis {
                --icon-size: 24px;
                color: $fds-color--primary;
            }

            .tooltip-list {
                content: '';
                top: pToR(-45);
                right: pToR(38);
                margin-left: -2rem;
                min-width: pToR(150);
                height: pToR(100);
                white-space: normal;
                background-color: white;
                border: 1px solid $fds-color--gray2;
                border-radius: pToR(3);
                color: $fds-color--gray3;
                position: absolute;
                z-index: 100;
                box-shadow: 0 1rem 1rem 0 rgba(0, 0, 0, 0.1),
                    0 2rem 2rem 0 rgba(0, 0, 0, 0.1),
                    0 3rem 3rem 0 rgba(0, 0, 0, 0.15);

                .brand-lincoln & {
                    height: pToR(73);
                    min-width: pToR(69);
                    border: 1px solid #e0e0e0;
                    top: pToR(-27);
                }

                .tooltip-ul {
                    list-style: none;
                    text-align: center;
                    position: relative;
                    z-index: 101;
                    height: 100%;
                    margin-left: 0;

                    li {
                        line-height: pToR(26);
                        font-size: 16px;
                        letter-spacing: 1px;
                        font-family: $antenna-font;
                        height: 50%;
                    }
                }
            }

            .tooltip-list:before {
                content: '';
                display: block;
                position: absolute;
                left: 100%;
                top: 65%;
                width: 0;
                height: 0;
                border-width: 10px;
                border-style: solid;
                border-color: transparent transparent transparent black;

                .brand-lincoln & {
                    border-width: pToR(8);
                }
            }

            .tooltip-list:after {
                content: '';
                display: block;
                position: absolute;
                left: 97.5%;
                top: 62%;
                width: 0;
                height: 0;
                border-width: 13px;
                border-style: solid;
                border-color: transparent transparent transparent white;

                .brand-lincoln & {
                    border-width: pToR(10);
                    border-left-color: #efefef;
                }

                .delete-hover & {
                    border-left-color: $fds-color--gray2;
                }
            }

            .hamburger-menu {
                &:hover,
                &:focus {
                    background-color: unset;
                }
            }

            &.delete-hover {
                .tooltip-list:after {
                    border-left-color: $fds-color--gray2;

                    .brand-lincoln & {
                        border-left-color: #233337;
                    }
                }
            }
        }
    }

    .fmc-cards__body {
        p {
            font-size: pToR(12);
            line-height: pToR(17);
            letter-spacing: 1px;
            font-weight: unset;
        }
    }

    .fmc-cards__footer {
        border-top: 0;
        padding-top: 0;

        .fmc-text-button {
            line-height: pToR(24);
            padding-bottom: pToR(24);
        }

        .brand-lincoln & {
            align-items: center;
        }

        .add-vehicle-container {
            margin-bottom: 0;
        }
    }

    .card-header {
        background-size: cover;
        background-position: center center;
        height: 16.25rem;
        background-color: $card-bg;
    }

    .card-body {
        padding: 20px;
        font-family: $antenna-font-condensed-light;
        color: $text-gray;
        background-color: $white;
        height: pToR(250);

        @include mobile {
            min-height: 14.55rem;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
        }

        .titleList {
            display: flex;
            @include mobile {
                position: relative;
                top: 0;
            }

            .blockOne {
                width: 95%;

                .card-title {
                    letter-spacing: pToR(4);
                    margin-bottom: pToR(16);
                    font-family: $antenna-font-condensed-light;
                    font-size: pToR(34);
                    line-height: pToR(44);
                    text-align: center;
                    color: $text-gray;

                    .brand-lincoln & {
                        font-family: $proxima-nova-regular;
                        font-size: pToR(30);
                        letter-spacing: 3.13px;
                        line-height: pToR(30);
                        color: $brand-secondary;
                    }
                }
            }

            .block-second {
                position: absolute;
                width: 10%;
                padding: pToR(3);
                right: 0;

                @include mobile {
                    right: pToR(-16);
                }

                .tooltip-list {
                    content: '';
                    top: -6px;
                    right: 34px;
                    margin-left: -2rem;
                    min-width: pToR(150);
                    height: pToR(100);
                    white-space: normal;
                    background-color: white;
                    border: 1px solid $fds-color--gray2;
                    border-radius: 0.3rem;
                    color: $fds-color--gray3;
                    position: absolute;
                    z-index: 100;
                    box-shadow: 0 1rem 1rem 0 rgba(0, 0, 0, 0.1),
                        0 2rem 2rem 0 rgba(0, 0, 0, 0.1),
                        0 3rem 3rem 0 rgba(0, 0, 0, 0.15);

                    .tooltip-ul {
                        list-style: none;
                        text-align: center;
                        margin: 0;
                        position: relative;
                        z-index: 101;
                        height: 100%;

                        li {
                            line-height: pToR(26);
                            font-size: 16px;
                            letter-spacing: 1px;
                            font-family: $antenna-font;
                            height: 50%;
                            padding: 0 pToR(10);
                        }
                    }
                }

                .tooltip-list:before {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 100%;
                    top: 11%;
                    width: 0;
                    height: 0;
                    border-width: 10px;
                    border-style: solid;
                    border-color: transparent transparent transparent black;
                }

                .tooltip-list:after {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 97.5%;
                    top: 7%;
                    width: 0;
                    height: 0;
                    border-width: 13px;
                    border-style: solid;
                    border-color: transparent transparent transparent white;

                    .delete-hover & {
                        border-left-color: $fds-color--gray2;

                        .brand-lincoln & {
                            border-left-color: #233337;
                        }
                    }
                }

                &.delete-hover {
                    .tooltip-list:after {
                        border-left-color: $fds-color--gray2;

                        .brand-lincoln & {
                            border-left-color: #233337;
                        }
                    }
                }
            }

            img {
                width: pToR(20);
            }
        }

        .card-title-secondary {
            font-size: pToR(16);
            line-height: pToR(26);
            letter-spacing: pToR(1);
            font-family: $antenna-font;
            margin-bottom: pToR(12);
            @include mobile {
                line-height: pToR(10);
                margin-bottom: pToR(0);
            }
        }

        .card-description {
            margin-bottom: pToR(5);
            font-family: $antenna-font;
            font-size: pToR(12);
            letter-spacing: pToR(1);
            line-height: pToR(21);
        }

        .secondary-button {
            .card-button {
                color: rgb(16, 43, 78);
                border: none;
                position: absolute;
                bottom: pToR(20);
                letter-spacing: 0 !important;
                left: 50%;
                transform: translateX(-50%);
                cursor: pointer;
                width: 100%;
                padding: 0;
                margin: 0;

                @include mobile {
                    position: unset;
                    left: unset;
                    transform: unset;
                    display: flex;
                }

                .fds-dot {
                    color: $fds-color--primary;
                }

                .brand-lincoln & {
                    color: $brand-secondary;

                    img {
                        display: none;
                    }
                }
            }

            .fds-chevron-wrap {
                width: min-content;
            }

            @include mobile {
                display: block;
                position: relative;
                top: pToR(10);
            }
        }
    }

    .card-button:hover .fds-dot {
        color: $light-blue;
    }

    .card-button:hover {
        .brand-ford & {
            color: $light-blue;
        }

        .brand-lincoln & {
            color: $brand-secondary;
        }
    }

    .card-button-new {
        background-color: $white;

        color: $fds-color--primary !important;
        border: none;

        height: 2.5rem;
        position: absolute;
        bottom: pToR(25);
        letter-spacing: 0 !important;
        width: fit-content;

        &:hover {
            transform: scale(1.07);
            cursor: pointer;
        }

        .fds-dot {
            color: $fds-color--primary;
        }
    }

    &.dynamic-card-style {
        height: pToR(202);
        @include mobile {
            height: pToR(202);
        }
    }

    &.eu-card {
        height: pToR(218);
        @include mobile {
            height: pToR(218);
        }
    }
}

#add-vehicle-card {
    &.fmc-cards__card {
        .fmc-cards__content {
            .fmc-cards__title {
                letter-spacing: normal;
            }

            .fmc-cards__body {
                margin-top: pToR(8);
            }
        }

        .fmc-cards__image {
            max-width: pToR(260);
        }
    }
}

.deleteText {
    font-family: $antenna-font-condensed-light;
    color: $text-gray;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 3px;

    .brand-lincoln & {
        color: $brand-secondary;
        font-family: $proxima-nova-regular;
        letter-spacing: 0;
    }
}

.edit-title {
    color: $text-gray;
    font-family: $antenna-font-condensed-light;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 3px;

    .brand-lincoln & {
        font-family: $proxima-nova-regular;
        font-size: pToR(38);
        letter-spacing: pToR(2.38);
        line-height: pToR(38);
        color: $brand-secondary;
        padding-bottom: pToR(40);
        margin: 0;
    }
}

.hamburger-menu {
    position: relative;
    width: pToR(38);
    height: pToR(51);

    &:focus-visible {
        outline: outset;
    }

    img {
        width: pToR(25);
        height: pToR(25);
        top: 0;
    }
}

.li-link-button {
    font-size: 16px;
    font-family: $antenna-font;
    border: none;
    letter-spacing: 1px;
    color: $fds-color--primary;
    width: 100%;
    cursor: pointer;
    line-height: pToR(26);
    background-color: white;
    height: 100%;
    margin: 0;
    padding: pToR(10);

    .brand-lincoln & {
        font-family: $proxima-nova-regular;
        font-size: pToR(13);
        letter-spacing: 0;
        color: $brand-light-scrollbar;
        line-height: pToR(13);
        background-color: #efefef;
    }

    &:hover,
    &:active,
    &:focus-visible {
        background-color: $fds-color--gray2;
        color: white;

        .brand-lincoln & {
            background-color: $brand-light-scrollbar;
            color: $white;
        }
    }
}

.modal {
    .modal-header {
        .brand-lincoln & {
            @include tablet {
                margin-bottom: 0;
            }
        }
    }

    .modal-body {
        .fmc-type--heading5 {
            font-size: $fds-font-size__heading6--max;
            line-height: pToR(29);
            font-weight: $fds-weight__heading6;
            letter-spacing: normal;
            text-align: center;

            .brand-ford & {
                text-align: left;
                font-family: $ford-f1-font-regular;
                font-size: pToR(24);
                font-style: normal;
                line-height: pToR(32);
                letter-spacing: normal;
                color: $fds-preimagine-dark-text;
                width: 85%;
            }

            [data-brand='lincoln'] & {
                max-width: unset;
                @include mobile-v5 {
                    margin-bottom: pToR(30);
                }

                .edit-modal & {
                    font-size: pToR(38);
                    line-height: pToR(38);
                    letter-spacing: pToR(2.38);
                    text-transform: uppercase;
                }
            }

            &.error-text {
                max-width: unset;
            }
        }

        .delete-vehicle-modal-body1 {
            text-align: left;
            font-family: $ford-f1-font-regular;
            font-size: pToR(16);
            font-style: normal;
            line-height: pToR(24);
            letter-spacing: normal;
            color: $fds-preimagine-dark-text;
            width: 85%;
            margin-top: pToR(8);
        }

        .brand-lincoln & {
            .delete-vehicle-modal-header {
                text-transform: none;

                @include tablet {
                    margin-bottom: pToR(5);
                }
            }

            @include tablet {
                margin-top: pToR(2);
            }
        }
    }

    .modal-footer {
        .brand-lincoln & {
            @include tablet {
                margin-top: pToR(22);
            }
        }

        .brand-ford & {
            .connected-services-modal & {
                @include tablet {
                    margin-bottom: pToR(60);
                }
            }
        }

        .max-vehicles-error-modal & {
            margin-bottom: unset;
        }
    }

    &.max-vehicles-error-modal {
        .fmc-type--heading6 {
            [data-brand='lincoln'] & {
                font-size: pToR(24);
            }
        }

        .brand-lincoln & {
            .modal-footer {
                margin-bottom: pToR(32);
            }
        }
    }
}

.delete-vehicle-modal {
    .modal-content {
        .modal-footer {
            .secondary-button {
                .brand-lincoln & {
                    margin-left: pToR(40);
                    @include mobile-v5 {
                        margin-left: unset;
                    }
                }
            }
        }
    }
}

.brand-lincoln {
    .card {
        box-shadow: none;

        .card-body {
            .titleList {
                .block-second {
                    .tooltip-list {
                        border-radius: 0;
                        background-color: $brand-while-smoke;
                        border: 1px solid $brand-gray3;
                        height: pToR(74);
                        width: pToR(78);
                    }

                    .tooltip-list:before {
                        border-color: transparent transparent transparent white;
                    }
                }
            }
        }
    }
}
