@import '@styles/main.v5';
@import '@styles/common';
@import '../../styles/print';

.return-confirmation-view {
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        padding: 0 pToR(16);

        @include tablet {
            max-width: pToR(1440);
            padding: 0 pToR(56);
            margin: auto;
        }

        @media print {
            display: none;
        }

        .breadcrumbs.syndicated-header {
            align-self: flex-start;
            padding-top: pToR(27);

            .breadcrumbs-content {
                padding-left: 0;
            }

            @include tablet {
                padding-top: pToR(29);
            }
        }

        .return-confirmation-view-notification-container {
            display: flex;
            max-width: pToR(1105);
            width: 100%;
            flex-direction: column;
            margin: pToR(8) auto;

            @include mobile {
                max-width: -webkit-fill-available;
                max-width: -moz-available;
            }

            @include tablet {
                max-width: -webkit-fill-available;
                max-width: -moz-available;
            }

            @include desktop {
                max-width: pToR(1105);
            }
        }
    }

    &__content {
        width: 100%;
        padding: pToR(14) 0 pToR(64);

        @include mobile {
            grid-template-columns: pToR(84) auto auto;
            grid-column-gap: pToR(8);
        }

        @include tablet {
            max-width: pToR(1105);
            padding-bottom: pToR(96);
        }

        @media print {
            display: none;
        }

        .return-confirmation-view__header {
            color: $charcoal;
            font-family: $ford-f1-font-regular;
            font-size: pToR(32);
            line-height: pToR(40);
            letter-spacing: normal;
            text-align: start;
            margin-bottom: pToR(18);

            @include tablet {
                margin-bottom: pToR(24);
            }
        }

        .return-confirmation-view__subheader {
            color: $fds-preimagine-high-contrast;
            font-family: $ford-f1-font-regular;
            font-size: pToR(16);
            line-height: pToR(24);
            letter-spacing: normal;
            text-align: start;
        }

        .return-confirmation-item__container {
            display: grid;
            grid-template-columns: pToR(96) auto auto;
            grid-template-rows: repeat(5, auto);
            grid-column-gap: pToR(16);
            margin-top: pToR(36);

            .item {
                &__image {
                    grid-area: 2 / 1 / span 3 / span 1;
                    width: pToR(84);
                    border-radius: pToR(8);

                    @include tablet {
                        width: pToR(96);
                    }

                    &.install-icon-sizing {
                        max-height: pToR(48);
                        width: pToR(48);
                        margin: auto;

                        @include mobile {
                            margin: pToR(16) 0 0 pToR(16);
                        }
                    }
                }
                &__title {
                    font-family: $ford-f1-font-bold;
                    font-size: pToR(14);
                    line-height: pToR(20);
                    letter-spacing: normal;
                    color: $fds-preimagine-dark-text;
                    margin-bottom: pToR(8);
                }
                &__id {
                    font-family: $ford-f1-font-regular;
                    font-size: pToR(12);
                    line-height: pToR(16);
                    letter-spacing: normal;
                    color: $text-gray;
                    margin-bottom: pToR(4);
                }
                &__quantity,
                &__price,
                &__status p {
                    font-family: $ford-f1-font-regular;
                    font-size: pToR(14);
                    line-height: pToR(20);
                    letter-spacing: normal;
                    color: $fds-preimagine-dark-text;
                }
                &__status {
                    grid-area: 1 / 1 / span 1 / span 3;
                    margin-bottom: pToR(16);
                    max-width: max-content;
                    border-radius: pToR(4);
                }
                &__quantity {
                    @include mobile {
                        margin-bottom: pToR(21);
                    }
                }
                &__buttons-container {
                    display: flex;
                    align-self: flex-start;
                    justify-content: flex-end;

                    @include mobile {
                        grid-area: 4 / 1 / span 1 / span 3;
                    }

                    .print-button {
                        @include ford-secondary-button-default();
                        min-width: pToR(120);
                        margin-left: pToR(22);
                        padding: 10px 20px;
                    }
                    @media print {
                        display: none;
                    }
                }
            }
            @media print {
                display: none;
            }
        }
    }

    &__divider {
        box-shadow: none;
        border-color: $fds-reimagine-divider-color;
        @media print {
            display: none;
        }
    }
    .print-return {
        display: none;
        @media print {
            display: block;
        }
    }
}
