@import '@styles/main.v5';

.status-chip-alerts {
    background-color: $fds-preimagine-danger-subtle;
    color: $fds-preimagine-dark-text;
    border-radius: 4px;
    height: pToR(20);
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    font-size: pToR(14);
    font-family: $ford-f1-font-regular;
    margin-right: auto;
    margin-left: pToR(8);
    padding: 0 pToR(8);
    line-height: pToR(20);
    letter-spacing: normal;

    &.services-needed {
        @include mobile {
            .services-needed__text {
                display: none;
            }
        }
        @include tablet {
            margin-right: pToR(12);
        }
    }
}
