@import '@styles/main.scss';
/* Custom properties */
:root {
    --tooltip-margin: 18px;
    --tooltip-arrow-size: 11px;
}

/* Wrapping */
.tooltip-wrapper {
    display: inline-block;
    position: relative;
    width: 100%;

    .brand-ford & {
        position: absolute;
    }
}

/* Absolute positioning */
.tooltip-tip {
    position: absolute;
    left: 27%;
    transform: translateX(-50%);
    background: $white;
    z-index: 100;
    height: auto;
    white-space: nowrap;
    word-break: break-word;
    border: 0.5px solid $text-gray;
    border-radius: pToR(5);
    box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1),
        0 10px 10px 0 rgba(0, 0, 0, 0.1);
    padding: pToR(4);
    color: $text-gray !important;
    font-family: $antenna-font !important;
    font-size: pToR(16);
    letter-spacing: pToR(1);
    line-height: pToR(26);

    .brand-ford & {
        padding: pToR(16);
    }

    @include mobile {
        left: 0;
        transform: none;
    }
    @include desktop {
        width: 100%;

        .shared-view & {
            width: 53%;
            left: 21.5%;
            transform: translateX(-41%);
        }
        .eu-view & {
            width: 46%;
            left: 28.3%;
            transform: translateX(-53%);
        }
    }
}

/* CSS border triangles/arrow */
.tooltip-tip {
    .brand-lincoln & {
        &::before {
            content: ' ';
            left: 20%;
            border: solid;
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-width: var(--tooltip-arrow-size);

            .shared-view & {
                left: 20%;
            }

            .eu-view & {
                @include mobile {
                    left: 34%;
                }
                @include tablet {
                    left: 30%;
                }
                @include desktop {
                    left: 37%;
                }
            }
        }
    }
}

.tooltip-tip {
    .brand-lincoln & {
        &::after {
            content: ' ';
            left: 20%;
            border: solid;
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-width: var(--tooltip-arrow-size);

            .shared-view & {
                left: 20%;
            }

            .eu-view & {
                @include mobile {
                    left: 34%;
                }
                @include tablet {
                    left: 30%;
                }
                @include desktop {
                    left: 37%;
                }
            }
        }
    }
}

/* Absolute positioning */
.tooltip-tip.top {
    top: calc(var(--tooltip-margin) * -2);
}

/* CSS border triangles */
.tooltip-tip.top::before {
    top: 100%;
    border-color: $text-gray transparent transparent;
    margin-top: pToR(2);
}

.tooltip-tip.top::after {
    top: 100%;
    border-color: $white transparent transparent;

    .brand-lincoln & {
        border-color: $brand-secondary transparent transparent;
    }
}

/* Absolute positioning */
.tooltip-tip.right {
    left: calc(100% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-27%);

    .brand-ford & {
        padding: pToR(16);
        box-shadow: unset;
        width: pToR(400);
        min-height: pToR(320);
        left: pToR(140);
        border: pToR(1) solid #6b7786;
        border-radius: pToR(8);
        font-family: $ford-f1-font-regular;
        font-size: pToR(16);
        font-style: normal;
        line-height: pToR(24);
        letter-spacing: normal;
        white-space: initial;
        top: pToR(-150);

        .vin-tooltip-content {
            font-family: $ford-f1-font-regular;
            font-size: pToR(16);
            font-style: normal;
            line-height: pToR(24);
            color: $dropdown-text;
            letter-spacing: normal;
            margin-right: pToR(20);
            width: pToR(316);
            margin-bottom: unset;

            p {
                letter-spacing: normal;
            }
        }
    }
}

/* CSS border triangles */
.tooltip-tip.right::before {
    left: calc(var(--tooltip-arrow-size) * -1);
    top: 27%;
    transform: translateX(0) translateY(-50%);
    border-color: transparent $text-gray transparent transparent;
    margin-left: calc(var(--tooltip-arrow-size) * -1);

    .brand-lincoln & {
        border-color: transparent $brand-secondary transparent transparent;
    }
}

.tooltip-tip.right::after {
    left: calc(var(--tooltip-arrow-size) * -1);
    top: 27%;
    transform: translateX(0) translateY(-50%);
    border-color: transparent $white transparent transparent;
    margin-left: calc((var(--tooltip-arrow-size) * -1) + (+1px));

    .brand-lincoln & {
        border-color: transparent $brand-secondary transparent transparent;
    }
}

/* Absolute positioning */
.tooltip-tip.bottom {
    bottom: calc(var(--tooltip-margin) * -2);
}

/* CSS border triangles */
.tooltip-tip.bottom::before {
    bottom: 100%;
    border-color: transparent transparent $text-gray transparent;
    margin-bottom: pToR(1);

    .brand-lincoln & {
        border-color: transparent transparent $brand-secondary transparent;
    }
}

.tooltip-tip.bottom::after {
    bottom: 100%;
    border-color: transparent transparent $white transparent;

    .brand-lincoln & {
        border-color: transparent transparent $brand-secondary transparent;
    }
}

/* Absolute positioning */
.tooltip-tip.left {
    left: auto;
    right: calc(100% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
}

/* CSS border triangles */
.tooltip-tip.left::before {
    left: auto;
    right: calc(var(--tooltip-arrow-size) * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-color: transparent transparent transparent $text-gray;
    margin-right: calc(var(--tooltip-arrow-size) * -1);

    .brand-lincoln & {
        border-color: transparent transparent transparent $brand-secondary;
    }
}

.tooltip-tip.left::after {
    left: auto;
    right: calc(var(--tooltip-arrow-size) * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-color: transparent transparent transparent $white;
    margin-right: calc((var(--tooltip-arrow-size) * -1) + (+1px));

    .brand-lincoln & {
        border-color: transparent transparent transparent $brand-secondary;
    }
}
