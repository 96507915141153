@import '@styles/main.v5';

.smart-tile-fpr {
    .icon {
        margin: 0 auto;
        width: pToR(32);
        height: pToR(32);

        @include mobile-v5 {
            height: pToR(24);
            width: pToR(24);
        }
    }

    .tier-title {
        font-weight: 400;
        font-size: pToR(14);

        @include mobile {
            font-size: pToR(12);
            margin-bottom: pToR(2);
        }
    }

    .smart-tile__content--container {
        @include media('<340px') {
            padding: pToR(6) 0;
        }
    }

    .smart-tile-fpr__points-and-expiration {
        display: flex;
        flex-direction: row;
        align-items: center;

        @include media('<340px') {
            flex-direction: column;
            align-items: unset;
            padding-bottom: pToR(4);
        }

        .smart-tile-fpr__points-expiration {
            height: pToR(16);
            color: #1f3047;
            font-family: $ford-f1-font-regular;
            font-size: pToR(12);
            line-height: pToR(16);
            letter-spacing: normal;
            border-radius: pToR(4);
            padding: 0 pToR(4);
            margin-left: pToR(10);
            margin-top: pToR(2);

            @include media('<340px') {
                margin-left: 0;
                width: fit-content;
            }

            &--danger {
                background-color: #f9e8ea;
            }
            &--caution {
                background-color: #fcf2e6;
            }
        }
    }

    .labeled-number {
        display: flex;
        flex-direction: column;
        padding: 0 0.5rem;

        .container {
            height: pToR(70);
            margin: auto;
        }

        .title {
            font-family: $antenna-font-condensed-light;
            font-size: pToR(20);
            margin-top: pToR(13);
            margin-bottom: pToR(12);
            letter-spacing: pToR(3);
        }

        .number {
            font-size: pToR(24);
            font-family: $antenna-font-condensed-bold;
            letter-spacing: pToR(3);
            display: flex;
            margin: 0 auto;
            color: $dark-blue;

            @include mobile {
                display: none;
            }

            span {
                display: inline-block;
                font-size: pToR(20);
                line-height: pToR(29);
                font-weight: 700;
                letter-spacing: normal;
                margin: auto;
            }
        }

        .label {
            letter-spacing: pToR(0.77);
            font-size: pToR(10);
            line-height: pToR(26);
            text-align: center;
            font-family: $antenna-font;
            color: $text-gray;
        }
    }

    .fmc-type--heading6 {
        margin-bottom: pToR(16);

        @include mobile-v5 {
            display: none;
        }
    }

    .fmc-type--subtitle1 {
        @include tablet-v5 {
            display: none;
        }
    }
}
