@import '@styles/main.v5';

.ford-pass-rewards {
    &__section {
        border-radius: pToR(8);
        background: linear-gradient(128.12deg, #f5f6f7 35.5%, #fefefe 104.67%);
        height: pToR(64);
        width: 100%;
        grid-area: 2 / 2 / span 1 / span 1;
        padding: pToR(8) pToR(16);

        &.has-points.expiring-soon {
            @include desktop {
                height: pToR(88);
            }
        }

        @include mobile-and-tablet {
            height: fit-content;
            grid-area: 3 / 1 / 4 / 3;
            margin-top: pToR(12);
            padding: pToR(16);
        }
    }

    &__content {
        display: grid;
        grid-template-columns: auto pToR(5) auto;
        grid-template-rows: repeat(3, auto);
        height: fit-content;

        @include desktop {
            grid-template-rows: repeat(2, auto);
            grid-template-columns: auto pToR(16) auto auto;
        }

        .has-points & {
            @include desktop {
                grid-template-rows: repeat(3, auto);
            }
        }

        .expiring-soon & {
            @include desktop {
                transform: translateY(4px);
            }
        }

        .row-1 {
            grid-area: 1 / 1 / 2 / 3;
            display: flex;

            @include mobile-and-tablet {
                grid-area: 1 / 1 / 2 / 4;
            }
        }

        .row-2 {
            grid-area: 2 / 1 / 3 / 3;
            width: fit-content;

            @include mobile-and-tablet {
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                .ford-pass-rewards__content--points {
                    width: fit-content;
                    margin-right: pToR(12);
                }

                .fpr__points-expiration {
                    width: fit-content;
                    margin: 0;
                }
            }
        }

        .row-3 {
            grid-area: 1 / 3 / span 2 / span 1;
            display: flex;

            .has-points & {
                @include desktop {
                    grid-area: 1 / 3 / span 3 / span 1;
                }
            }

            @include mobile-and-tablet {
                grid-area: 3 / 1 / span 1 / span 3;
                height: fit-content;
                margin-top: pToR(11);
            }
        }

        .row-4 {
            grid-area: 3 / 1 / span 1 / span 3;
            width: fit-content;

            .fpr__points-expiration {
                margin: unset;
            }
        }

        &--title {
            font-family: $ford-f1-font-semibold;
            color: $charcoal;
            font-size: pToR(12);
            line-height: pToR(16);
            letter-spacing: normal;
            margin-bottom: 0;
        }

        &--tier {
            font-family: $ford-f1-font-regular;
            color: $charcoal;
            font-size: pToR(12);
            line-height: pToR(16);
            letter-spacing: normal;
        }

        &--points {
            font-family: $ford-f1-font-medium;
            color: $charcoal;
            font-size: pToR(24);
            line-height: pToR(32);
            letter-spacing: normal;
            grid-area: 2 / 1 / span 1 / span 3;
            width: fit-content;
        }

        .divider {
            font-size: pToR(12);
            line-height: pToR(16);
            letter-spacing: normal;
            font-family: $ford-f1-font-bold;
            color: $charcoal;
            width: pToR(16);
            text-align: center;
            grid-area: 1 / 2 / span 1 / span 1;
        }

        &--actions {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            @include desktop {
                grid-area: 1 / 4 / span 2 / span 1;
                justify-content: flex-end;
                margin-left: pToR(64);
            }

            &.not-english {
                @include media('<=390px') {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
        }

        .secondary-button {
            height: fit-content;
        }

        .tertiary-button {
            height: pToR(40);
            min-width: pToR(129);

            .brand-ford & {
                .secondary-button {
                    width: 100%;

                    a {
                        width: 100%;
                        justify-content: center;
                    }
                }
            }
        }

        &--manage-cta,
        &--activate-cta {
            border-radius: pToR(400);
            border: 1px solid $fds-reimagine-secondary-blue;
            height: pToR(40);
            min-width: pToR(109);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: pToR(12) pToR(16);

            .secondary-button-text {
                color: $fds-reimagine-secondary-blue;
                font-family: $ford-f1-font-bold;
                font-size: pToR(14);
                line-height: pToR(20);
                letter-spacing: normal;

                &:hover {
                    color: $fds-preimagine-primary-button-hover;
                }
            }

            &:hover {
                border-color: $fds-preimagine-primary-button-hover;
            }

            &:focus-visible {
                outline: 2px solid $fds-reimagine-secondary-blue;
                outline-offset: pToR(3);
            }
        }

        .fpr__points-expiration {
            height: pToR(16);
            color: $fds-preimagine-dark-text;
            font-family: $ford-f1-font-regular;
            font-size: pToR(12);
            line-height: pToR(16);
            letter-spacing: normal;
            border-radius: pToR(4);
            padding: 0 pToR(4);
            margin-left: pToR(10);
            margin-top: pToR(2);

            @include media('<340px') {
                margin-left: 0;
                width: fit-content;
            }

            &--danger {
                background-color: $fds-preimagine-danger-subtle;
            }

            &--caution {
                background-color: $fds-reimagine-caution-red;
            }
        }
    }
}
