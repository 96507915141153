@import '@styles/main.scss';

.parts-list {
    margin-left: pToR(41);
    margin-bottom: pToR(14);

    .part-link {
        font-size: pToR(20);
        text-align: left;

        &:focus-visible {
            outline: 2px solid $fds-preimagine-primary-blue;
            outline-offset: 0;

            .brand-lincoln & {
                outline: 1px solid $brand-secondary;
            }
        }
    }

    .part-link.enabled {
        color: $fds-preimagine-primary-blue;

        .brand-lincoln & {
            color: $brand-primary;
            padding-bottom: 2px;
        }
    }

    .part-link.enabled:hover {
        .brand-ford & {
            color: $fds-preimagine-primary-button-hover;
        }

        .brand-lincoln & {
            border-bottom: 2px solid $brand-border;
        }
    }

    .part-link.disabled {
        color: inherit;
        cursor: text;
    }
}

.part-info-modal-container {
    padding: 0 pToR(10);
    align-self: center;
}

.part-info-modal-details {
    margin-top: pToR(12);
}
