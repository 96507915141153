@import '@styles/main.scss';

.accordion-item {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: pToR(112);
    border-top: 1px $grey solid;

    &:focus-visible {
        outline: 2px solid $fds-preimagine-primary-blue;
        outline-offset: 0;
        border-color: transparent;

        .brand-lincoln & {
            outline: 1px solid $brand-secondary;
        }
    }

    .accordion-item-label {
        margin-left: pToR(16);
        line-height: pToR(32);
        font-family: $ford-f1-font-medium;
        font-size: pToR(24);
        text-align: left;

        .brand-lincoln & {
            font-family: $proxima-nova-semibold;
        }
    }

    .accordion-item-label.open {
        color: #0562d2;

        .brand-lincoln & {
            color: #ce553d;
        }
    }

    .accordion-item-icon {
        margin-left: auto;
        margin-right: pToR(21);
    }
}

.accordion-item:hover {
    cursor: pointer;
}

.accordion-item-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
}

.accordion-item-content.open {
    max-height: pToR(2000);
}
