@import '@styles/main.v5';

.smart-tile {
    position: relative;
    display: grid;
    grid-template-columns: pToR(24) 1fr;
    width: 100%;
    height: 100%;
    padding: 0 pToR(22) 0 pToR(16);
    text-align: start;
    align-items: center;
    font-size: pToR(16);
    background: white;

    .brand-ford & {
        grid-gap: pToR(8);
        border: 1px solid $fds-preimagine-gray-border;
        border-radius: pToR(12);
        color: $charcoal;
        min-height: pToR(64);

        @include tablet {
            grid-template-columns: pToR(24) 1fr;
        }

        &:hover {
            cursor: pointer;
            border: 1px solid $fds-preimagine-darker-gray-border;
            background-color: $fds-preimagine-background-gray;
        }

        .fmc-type--heading6 {
            margin-bottom: 0;
            font-family: $ford-f1-font-bold;
            font-size: 16px;
            line-height: 24px;
            color: $charcoal;
        }
        .fmc-type--subtitle1 {
            font-family: $ford-f1-font-bold;
            font-size: 16px;
            line-height: 24px;
        }
        .fmc-type--body1 {
            font-family: $ford-f1-font-regular;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: normal;
            .brand-lincoln & {
                @include tablet-v5 {
                    font-size: 16px;
                    line-height: 1.5;
                    padding-bottom: unset;
                }
            }
        }
    }

    .brand-lincoln & {
        display: flex;
        flex-direction: column;
        height: 302px;
        text-align: center;
        color: $brand-secondary;
        border: pToR(1) solid $brand-gray3;

        @include mobile-v5 {
            height: 205px;
            grid-template-columns: 24px 1fr;
        }
        &:hover {
            @include mobile-v5 {
                transform: scale(1.07);
                transition: ease all 0.3s;
            }
        }
        .fmc-type--subtitle1 {
            max-width: 11ch;
            margin: 25px auto auto;

            &.privacy-settings-tile,
            &.reservations-and-orders-tile {
                max-width: 10ch;
            }

            @include mobile-v5 {
                max-width: 14ch;
            }
        }
        .fmc-type--body1 {
            @include tablet-v5 {
                font-size: 16px;
                line-height: 1.5;
                padding-bottom: unset;
            }
        }
    }

    .smart-tile__content--container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .fmc-text-button,
    .secondary-button {
        @include mobile-v5 {
            display: none;
        }
    }
    .fmc-text-button {
        img {
            .brand-lincoln & {
                display: none;
            }
        }
    }
    .smart-tile-button {
        position: absolute;
        bottom: 0;
        left: 0;
        text-align: center;
        width: 100%;
        margin-bottom: 2.438rem;
        cursor: pointer;
        text-transform: capitalize;

        .brand-lincoln & {
            margin-bottom: pToR(32);

            @include mobile {
                img {
                    display: none;
                }

                .secondary-button-text {
                    border-bottom: 2px solid $brand-border;
                }
            }
        }
    }

    .dynamic-description {
        letter-spacing: pToR(0.77);
        font-size: pToR(10);
        line-height: pToR(26);
        text-align: center;
        font-family: $antenna-font;
    }
    .label {
        font-size: pToR(34);
        font-family: $antenna-font-condensed-bold;
        letter-spacing: pToR(4);
        line-height: pToR(44);
        font-weight: bold;
        text-align: center;
    }
    .fds-chevron-wrap {
        display: none;
    }
    .fmc-text-button.fmc-text-button--chevron-right:after {
        margin-left: 10px;

        .brand-lincoln & {
            display: none;
        }
    }
    .secondary-button {
        justify-content: center;
        left: 0;
        right: 0;
        margin: auto;
        bottom: pToR(25);

        [data-brand='lincoln'] & {
            bottom: pToR(28);
            margin-bottom: pToR(21);
        }
        .brand-ford & {
            display: none;
        }
    }
    .ford-insure-tile ~ .secondary-button {
        position: absolute;
        margin: auto;
        bottom: pToR(26);

        [data-brand='lincoln'] & {
            bottom: pToR(21);
        }
    }
}
