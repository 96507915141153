@import '@styles/main.v5';

.hero-tiles-container {
    text-align: center;
    width: 100%;
    padding: 0 1rem;
    position: relative;
    top: pToR(-100);

    .brand-lincoln & {
        top: pToR(-178);
        width: pToR(800);

        @include mobile {
            padding: 0 pToR(18);
            top: pToR(-178);
            width: 100%;
        }
        @include mobile-v5 {
            top: pToR(-298);
        }
    }

    @include desktop {
        max-width: pToR(1240);
    }

    .tile-grid {
        display: grid;
        justify-content: center;
        grid-gap: pToR(15);
        grid-template-columns: 1fr;

        & > div {
            width: 100%;
            margin: auto;
            .brand-lincoln & {
                @include desktop {
                    width: pToR(266);
                }
            }
        }

        @include mobile {
            margin: auto;
        }
        @include tablet-v5 {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: pToR(17.5);
        }
        @include desktop {
            grid-gap: pToR(25);
        }
    }
}
