@font-face {
    font-family: 'lincoln-icons';
    src: url('./lincoln-icons/lincoln-icons.eot');
    src: url('./lincoln-icons/lincoln-icons.eot?#iefix')
            format('embedded-opentype'),
        url('./lincoln-icons/lincoln-icons.woff') format('woff'),
        url('./lincoln-icons/lincoln-icons.ttf') format('truetype'),
        url('./lincoln-icons/lincoln-icons.svg#lincoln-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

.icon {
    font-family: 'lincoln-icons', sans-serif;
    font-style: normal;
    line-height: 1;
}

.linc1638-lw-icons-38px-c16-expanded-06:before {
    content: '\67';
}

.linc1638-lw-icons-38px-c16-expanded-07:before {
    content: '\68';
}

.icon-search-glass-25px:before {
    content: '\47';
}

.linc1638-lw-icons-38px-c16-expanded-65:before {
    content: '\36';
}

.linc1638-lw-icons-38px-c16-expanded-77:before {
    content: '\6b';
}

.linc1638-lw-icons-38px-c16-expanded-22:before {
    content: '\4d';
}

.linc1638-lw-icons-38px-c16-expanded-23:before {
    content: '\4e';
}

.iconlrte-cta-chevron:after {
    content: '\48';
}

.iconl-linc1638-lw-icons-38px-c16-expanded-42:before {
    content: '\74';
}

.iconl-linc1638-lw-icons-38px-c16-expanded-35:before {
    content: '\48';
}

.icon-new-window-25px:before {
    content: '\30';
}

.icon-chevron-outline-25px:before {
    content: '\4b';
}

.iconl-chevron-toggle:before {
    content: '\4f';
}

.iconl-linc1638-lw-icons-38px-c16-expanded-65:before {
    content: '\36';
}

.iconl-linc1638-lw-icons-38px-c16-expanded-16:before {
    content: '\34';
}

.iconl-linc1638-lw-icons-38px-c16-expanded-22:before {
    content: '\4d';
}

.iconl-linc1638-lw-icons-38px-c16-expanded-23:before {
    content: '\4e';
}
