@import '@styles/main.v5';

.delete-account-component-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @include mobile-v5 {
        margin-bottom: pToR(64);
    }

    .fmc-type--heading5 {
        font-size: pToR(32);
        line-height: pToR(38);
        margin-bottom: pToR(30);
    }

    .delete-account-member-id {
        color: $fds-color--gray3;
        font-weight: 500;
        margin-bottom: pToR(24);

        [data-brand='lincoln'] & {
            letter-spacing: normal;
        }
    }

    .delete-account-body-text,
    .delete-account__legal-copy {
        text-align: center;
        max-width: 90%;
        margin: auto auto pToR(58);

        @include tablet-v5 {
            max-width: unset;
        }

        @include desktop-v5 {
            max-width: pToR(819);
        }
    }

    .delete-account-button-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .fmc-button {
            margin-bottom: pToR(45);
        }
    }

    .delete-account__legal-copy {
        margin-top: pToR(64);
        margin-bottom: 0;

        p {
            font-size: pToR(12);
        }
    }
}
