@import '@styles/main.scss';

.eu-login-container {
    @include mobile {
        height: 41px;
        width: 41px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 4px;
    }

    @include desktop {
        position: relative;
        z-index: 100;

        .eu-login-button:not(:focus) {
            outline: none;
        }
    }

    .eu-login-button {
        color: $text-gray;
        text-align: left;
        z-index: 10000;
        width: pToR(35);

        @include desktop {
            width: pToR(45);
        }

        img {
            width: pToR(25);

            @include desktop {
                width: pToR(25);
            }
        }

        &.active {
            color: $text-gray;
        }

        .eu-login-text {
            display: none;
            font-family: $antenna-font-antenna;
            position: relative;
            letter-spacing: 1px;

            @include desktop {
                display: inline-block;
                margin-left: pToR(10);
            }
        }
    }

    .eu-login-dropdown {
        display: none;
        position: fixed;
        top: pToR(60);
        right: 1rem;
        background-color: $gray;
        color: $highlight-blue;
        border: 1px solid $gray;
        padding: 0 pToR(30);

        &.active {
            display: block;
        }

        .eu-login-dropdown-list {
            list-style: none;
            min-width: pToR(188);
            min-height: pToR(50);

            .eu-login-dropdown-list-item {
                padding: 15px;
                &:nth-child(1) {
                    border-bottom: 1px solid $lightgrey;
                }
                button {
                    letter-spacing: 1px;
                }
            }
            @include mobile {
                min-width: pToR(150);
            }
        }
    }
}
