@import '@styles/main.scss';

.map-holder {
    #myMap {
        position: relative;
        width: -webkit-fill-available;
        height: pToR(344);

        @include mobile {
            margin-bottom: pToR(8);
            height: pToR(202);
        }

        .MicrosoftMap {
            div:first-child {
                border-radius: pToR(8);
            }

            .bm_bottomLeftOverlay {
                display: none;
            }

            .bm_bottomRightOverlay {
                display: none;
            }

            #MicrosoftNav {
                .NavBar_MapTypeButtonContainerWrapper {
                    display: none;
                }

                .bm_LocateMeControl {
                    display: none;
                }

                #ZoomInButton {
                    width: pToR(34);
                    height: pToR(34);
                    margin-top: 20px;
                }

                #ZoomOutButton {
                    width: pToR(34);
                    height: pToR(34);
                }
            }
        }
    }
}
