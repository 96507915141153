@import '@styles/main.scss';

.wallet-view-container {
    display: flex;
    padding: pToR(12) pToR(30);
    flex-direction: column;

    @include desktop {
        padding: pToR(12) pToR(120);
    }

    #fordPayWalletDiv {
        .fordpay {
            padding-bottom: 2.75rem;
            padding-top: pToR(56);
        }
    }
}
