@import '@styles/main.v5';

.order-history {
    &-container {
        display: flex;
        flex-direction: column;

        .order-history-view-notification-container {
            display: flex;
            max-width: pToR(1105);
            width: 100%;
            flex-direction: column;
            margin: pToR(8) auto;

            @include mobile {
                max-width: -webkit-fill-available;
                max-width: -moz-available;
                margin-left: pToR(16);
                margin-right: pToR(16);
            }

            @include tablet {
                max-width: -webkit-fill-available;
                max-width: -moz-available;
                padding-left: pToR(58);
                padding-right: pToR(58);
            }

            @include desktop {
                max-width: pToR(1105);
            }
        }

        .order-history-component {
            &-container {
                display: flex;
                max-width: pToR(1105);
                width: 100%;
                flex-direction: column;
                margin: auto;

                @include mobile {
                    max-width: -webkit-fill-available;
                    max-width: -moz-available;
                    margin-left: pToR(16);
                    margin-right: pToR(16);
                }

                @include tablet {
                    max-width: -webkit-fill-available;
                    max-width: -moz-available;
                    padding-left: pToR(58);
                    padding-right: pToR(58);
                }

                @include desktop {
                    max-width: pToR(1105);
                }
                .order-history-component-title {
                    font-size: pToR(48);
                    font-family: $ford-f1-font-regular;
                    color: $charcoal;
                    letter-spacing: normal;
                    @include mobile {
                        font-size: pToR(36);
                    }
                }

                .order-history-component-subtitle {
                    font-size: pToR(16);
                    font-family: $ford-f1-font-regular;
                    color: $fds-preimagine-dark-charcoal;
                    padding-bottom: pToR(48);
                    letter-spacing: normal;
                    @include mobile {
                        padding-bottom: pToR(56);
                    }
                    &.zero-subtitle {
                        padding-bottom: pToR(304);
                        @include mobile {
                            padding-bottom: pToR(216);
                        }
                    }
                }

                .order-history-component-item {
                    &-container {
                        @include desktop {
                            display: flex;
                            flex-direction: row;
                        }
                    }

                    @include desktop {
                        min-width: pToR(200);
                    }

                    .order-history-component-order-date-label {
                        font-size: pToR(12);
                        line-height: pToR(16);
                        font-family: $ford-f1-font-regular;
                        color: $charcoal;
                        padding-bottom: pToR(9);
                        letter-spacing: normal;
                    }
                    .order-history-component-order-date {
                        font-size: pToR(20);
                        line-height: pToR(26);
                        font-family: $ford-f1-font-semibold;
                        color: $fds-reimagine-dark-blue;
                        padding-bottom: pToR(12);
                        letter-spacing: normal;
                    }
                    .order-history-component-order-info {
                        font-size: pToR(14);
                        line-height: pToR(15);
                        font-family: $ford-f1-font-regular;
                        color: $charcoal;
                        padding-bottom: pToR(4);
                        letter-spacing: normal;
                    }
                    .secondary-button {
                        font-size: pToR(14);
                        line-height: pToR(20);
                        font-family: $ford-f1-font-bold;
                        color: $fds-reimagine-secondary-blue;
                        padding-bottom: pToR(24);
                        letter-spacing: normal;
                    }
                }

                .order-history-component-order-items-images-container {
                    display: grid;
                    row-gap: pToR(24);

                    @include desktop {
                        margin-left: pToR(23);
                        row-gap: pToR(16);
                    }

                    @include tablet {
                        margin-bottom: pToR(15);
                    }

                    .order-history-component-item-status {
                        border-radius: pToR(4);
                        margin-bottom: pToR(16);
                        padding: 0 pToR(6);
                        width: max-content;
                        color: $fds-preimagine-dark-text;
                        text-align: center;
                        font-family: $ford-f1-font-regular;
                        font-size: pToR(12);
                        letter-spacing: normal;
                        line-height: pToR(16);

                        @include tablet {
                            margin-bottom: pToR(8);
                        }
                    }

                    .order-history-component-item-link {
                        margin-right: pToR(8);
                        display: inline-grid;
                        overflow: hidden;
                        position: relative;
                        width: pToR(96);
                        height: pToR(96);
                        border-radius: pToR(8);

                        @include mobile {
                            width: pToR(83);
                            height: pToR(83);
                        }
                    }

                    .order-history-component-item-image {
                        width: auto;
                        height: 100%;
                        position: absolute;
                        transform: translate(-12%, 0%);
                    }

                    .individual-items-container {
                        display: flex;
                        flex-wrap: wrap;
                    }
                }

                .hr-line {
                    height: pToR(1);
                    border-bottom: pToR(1) solid $lightgrey;
                    box-shadow: none;
                    margin-bottom: pToR(22);
                    width: 100%;

                    @include mobile {
                        margin-top: pToR(7);
                        margin-bottom: pToR(18);
                        visibility: visible;
                    }

                    @include tablet {
                        visibility: visible;
                    }

                    @include desktop {
                        width: 100%;
                        margin-left: 0;
                        visibility: visible;
                    }

                    .brand-lincoln & {
                        border: 0;
                        border-bottom: pToR(1) solid $brand-gray3;

                        @include mobile {
                            border: 0;
                            border-bottom: pToR(1) solid $brand-gray3;
                        }
                    }
                }

                .past-orders-separator {
                    background: #fafafa;
                    width: 100vw;
                    height: pToR(16);
                    margin-left: calc(-50vw + 50%);
                    margin-top: pToR(23);
                    margin-bottom: pToR(25);
                    border-bottom: none;
                    box-shadow: none;
                }

                .past-orders-header {
                    margin-bottom: pToR(46);
                    font-family: $ford-f1-font-semibold;
                    line-height: pToR(32);
                    letter-spacing: 0;
                    color: black;
                }
                .order-history-component-footer-container {
                    margin-bottom: pToR(106);
                    @include mobile {
                        margin-bottom: pToR(74);
                    }
                    &-show-more {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        margin-bottom: pToR(106);
                        @include mobile {
                            margin-bottom: pToR(74);
                        }
                        .secondary-button {
                            font-size: pToR(14);
                            font-family: $ford-f1-font-bold;
                            line-height: pToR(20);
                            letter-spacing: 0;
                            color: $fds-reimagine-secondary-blue;
                            .button-style {
                                font-size: pToR(14);
                                font-family: $ford-f1-font-bold;
                                line-height: pToR(20);
                                letter-spacing: 0;
                                color: $fds-reimagine-secondary-blue;
                            }
                        }
                        .order-history-component-pagination-container {
                            margin-top: pToR(4);
                            .order-history-component-pagination-label {
                                display: flex;
                                justify-content: right;
                                font-family: $ford-f1-font-regular;
                                font-weight: 400;
                                font-size: pToR(12);
                                line-height: pToR(20);
                                letter-spacing: 0;
                            }
                        }
                    }
                    .order-history-component-pagination-container {
                        margin-top: pToR(4);
                        .order-history-component-pagination-label {
                            display: flex;
                            justify-content: right;
                            font-family: $ford-f1-font-regular;
                            font-weight: 400;
                            font-size: pToR(12);
                            line-height: pToR(20);
                            letter-spacing: 0;
                        }
                    }
                }
            }
        }
    }
}

.hr-line {
    height: pToR(1);
    border-bottom: pToR(1) solid $disabled-dark;
    box-shadow: none;
    margin-bottom: pToR(8);
    width: 100%;

    @include mobile {
        visibility: visible;
    }

    @include tablet {
        visibility: visible;
    }

    @include desktop {
        width: 100%;
        margin-left: 0;
        visibility: visible;
    }

    .brand-lincoln & {
        border: 0;
        border-bottom: pToR(1) solid $brand-gray3;

        @include mobile {
            border: 0;
            border-bottom: pToR(1) solid $brand-gray3;
        }
    }
}
