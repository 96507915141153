@import '@styles/main.v5';

.smart-tile-ford-credit {
    .icon {
        margin: pToR(48) auto pToR(25);
        max-width: pToR(70);
        max-height: pToR(70);

        @include mobile-v5 {
            margin: pToR(32) auto 0;
            max-width: pToR(60);
            max-height: pToR(60);
        }
    }

    .fmc-type--heading5 {
        font-size: pToR(20);
        line-height: pToR(29);
        font-family: $antenna-font-condensed-bold;
        letter-spacing: normal;
        font-weight: 700;
    }

    .fmc-type--heading6 {
        display: none;

        @include tablet-v5 {
            display: block;
            margin-bottom: pToR(16);
        }
    }

    .fmc-type--subtitle1 {
        display: block;

        @include mobile-v5 {
            margin-bottom: pToR(8);
        }

        @include tablet-v5 {
            display: none;
        }
    }
}
