@import '@styles/main.scss';

.connected-services-modal {
    .modal-content {
        @include tablet {
            min-height: pToR(336);
            height: unset;
        }
        @include desktop {
            .brand-lincoln & {
                max-width: pToR(867);
            }
        }

        .modal-footer {
            .secondary-button {
                .brand-ford & {
                    border-radius: unset;
                    border: unset;
                    padding: unset;

                    &:visited {
                        text-decoration: none;
                    }

                    &:hover {
                        text-decoration: none;
                        color: #055ecb;
                    }

                    &:focus {
                        outline: 1px solid #066fef;
                        outline-offset: 3px;
                        outline-width: 2px;
                        --scale-hover: unset;
                        text-decoration: none;
                        border-radius: pToR(20);
                    }
                }
            }
        }
    }

    .modal-body {
        @include mobile-v5 {
            padding-left: pToR(36);
            padding-right: pToR(36);
            .brand-ford & {
                padding-right: unset;
                padding-left: unset;
            }
        }
        @include tablet-v5 {
            padding-left: pToR(50);
            padding-right: pToR(50);
            .brand-ford & {
                padding-right: unset;
                padding-left: unset;
            }
        }
        @include desktop {
            padding-left: 0;
            padding-right: 0;
        }

        .brand-lincoln & {
            @include tablet {
                margin-top: 0;
            }
        }

        .brand-ford & {
            .fmc-type--heading5 {
                font-family: $ford-f1-font-medium;
                font-size: pToR(24);
                font-style: normal;
                line-height: pToR(32);
                text-align: left;
                color: $fds-preimagine-dark-text;
                margin-bottom: pToR(16);
            }

            .vehicle-listing {
                .vehicle-info {
                    .fmc-type--heading4 {
                        font-family: $ford-f1-font-medium;
                        font-size: pToR(24);
                        font-style: normal;
                        line-height: pToR(32);
                        color: $fds-preimagine-dark-text;
                        margin-bottom: pToR(8);
                        letter-spacing: normal;
                    }

                    .fmc-type--body1 {
                        font-family: $ford-f1-font-medium;
                        font-size: pToR(24);
                        font-style: normal;
                        line-height: pToR(32);
                        color: $fds-preimagine-dark-text;
                        margin-bottom: pToR(8);
                        letter-spacing: normal;
                    }

                    .fmc-type--content2 {
                        font-family: $ford-f1-font-regular;
                        font-size: pToR(16);
                        font-style: normal;
                        line-height: pToR(24);
                        margin-bottom: unset;
                        letter-spacing: normal;
                    }
                }
            }
        }
    }

    .modal-header {
        margin-bottom: pToR(55);

        @include tablet {
            margin-bottom: pToR(50);
        }

        .brand-lincoln & {
            @include tablet {
                margin-bottom: 0;
                padding-right: 0;
            }
        }
    }

    .modal-close {
        background: none;

        .brand-lincoln & {
            @include tablet {
                margin-right: pToR(32);
                margin-top: pToR(29);
                line-height: pToR(19);
            }
        }
    }

    .connected-services-modal__header {
        font-family: $antenna-font-condensed-light;
        font-size: pToR(24);
        line-height: pToR(34);
        color: $text-gray;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: pToR(27);

        .brand-lincoln & {
            font-family: $proxima-nova-regular;
            font-size: pToR(38);
            letter-spacing: pToR(2.38);
            line-height: pToR(38);
            color: $brand-secondary;
            padding-bottom: pToR(40);
            margin: 0;

            @include tablet {
                padding-bottom: pToR(10);
            }
        }

        @include mobile {
            font-weight: 300;
            letter-spacing: pToR(3);
            text-align: center;
            margin-bottom: pToR(40);
        }
    }

    .connected-services-modal-content {
        @include mobile {
            height: auto;
        }

        @include tablet {
            .modal-footer {
                .link-button {
                    margin-left: unset;
                }

                .brand-lincoln & {
                    margin-top: pToR(6);
                }
            }
        }

        .modal-footer {
            .secondary-button {
                margin: auto;
            }

            .brand-ford & {
                @include tablet {
                    margin-bottom: pToR(47);
                }
            }
        }
    }

    .vehicle-listing {
        border-bottom: 1px solid $border-grey;
        width: 100%;
        padding: pToR(47) 0 pToR(30);
        display: flex;
        flex-direction: column;

        .brand-ford & {
            justify-content: space-between;
            align-items: end;
            flex-direction: unset;
        }

        @include mobile {
            padding: 0 0 pToR(43);
        }

        @include tablet {
            flex-direction: row;
            justify-content: space-between;
            padding-left: pToR(30);
            padding-right: pToR(30);
        }

        @include desktop {
            padding: pToR(30) pToR(40) pToR(30) pToR(20);
        }

        .brand-ford & {
            padding: pToR(30) pToR(30) pToR(22) pToR(8);
            @include mobile {
                padding-right: unset;
                padding-left: unset;
            }
        }

        &:last-child {
            border-bottom: none;

            @include mobile {
                padding-bottom: pToR(5);
            }

            .brand-lincoln & {
                @include tablet {
                    padding-bottom: 0;
                }
            }
        }
    }

    .multiple-vehicle {
        @include mobile {
            padding-top: pToR(35);
            padding-bottom: pToR(43);
        }
    }

    .vehicle-info {
        text-align: left;
    }

    .vehicle-info__name {
        font-weight: 400;
        margin-bottom: pToR(16);

        .brand-lincoln & {
            color: $brand-secondary;
            font-family: $proxima-nova-regular;
            font-size: pToR(24);
            letter-spacing: 0;
            line-height: pToR(32);

            @include tablet {
                margin-bottom: pToR(12);
            }
        }

        @include mobile {
            text-align: center;
            margin-bottom: pToR(18);
        }
        @include tablet {
            font-size: pToR(34);
            line-height: pToR(40);
        }

        .brand-ford & {
            @include mobile {
                text-align: left;
            }
        }
    }

    .vehicle-info__ymm {
        margin-bottom: pToR(12);

        .brand-lincoln & {
            color: $brand-scrollbar;
            font-family: $proxima-nova-semibold;
            font-size: pToR(14);
            letter-spacing: pToR(1.75);
            line-height: pToR(14);
            margin-bottom: pToR(11);
            max-width: unset;
        }

        @include mobile {
            text-align: center;
            margin-bottom: pToR(14);
        }

        .brand-ford & {
            text-align: left;
        }
    }

    .vehicle-info__vin {
        line-height: pToR(21);
        font-weight: normal;

        .brand-lincoln & {
            color: $brand-secondary;
            font-family: $proxima-nova-regular;
            font-size: pToR(18);
            letter-spacing: 0;
            line-height: pToR(26);
        }

        @include mobile {
            text-align: center;
        }
    }

    .vehicle-info__subscription-count {
        line-height: pToR(18);

        .brand-lincoln & {
            color: $brand-secondary;
            font-family: $proxima-nova-regular;
            font-size: pToR(18);
            letter-spacing: 0;
            line-height: pToR(26);
        }

        @include mobile {
            text-align: center;
        }

        &.active {
            color: $good;
        }

        &.expiring {
            color: $severe;
        }
    }

    .subscription-counts-container {
        margin-top: pToR(5);
    }

    .vehicle-listing-btn {
        color: $white;
        letter-spacing: 0.064rem;
        text-decoration: none;

        .fds-chevron-wrap {
            display: none;
        }
    }

    .modal-footer {
        margin-top: pToR(12);

        .brand-lincoln & {
            @include tablet {
                margin-bottom: pToR(20);
            }

            .secondary-button {
                margin-left: unset;
            }
        }

        .brand-ford & {
            justify-content: left;

            .connected-services-modal & {
                @include tablet {
                    margin-bottom: pToR(60);
                }
            }
        }
    }

    .modal-footer .link-button.secondary {
        font-family: $antenna-font-condensed-regular;
        font-size: pToR(18);
        line-height: pToR(13);
        letter-spacing: pToR(1);
        text-decoration: underline;

        @include mobile {
            padding-left: 0;
            margin-bottom: pToR(32);
        }

        @include tablet {
            margin-bottom: pToR(32);
        }

        .brand-lincoln & {
            font-family: $proxima-nova-regular;
        }
    }

    .connected-services-button-container {
        padding-bottom: pToR(10);
        @include mobile {
            margin: pToR(33) auto auto auto;
        }
        @include tablet {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .brand-ford & {
            @include mobile {
                margin: unset;
            }
        }

        .fmc-button {
            [data-brand='lincoln'] & {
                width: pToR(213);
            }
        }
    }
}
