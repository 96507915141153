@import '@styles/main.v5';
@import '@styles/common';

.order-details-item__container {
    display: grid;
    grid-template-columns: pToR(96) auto auto;
    grid-template-rows: repeat(5, auto);
    grid-column-gap: pToR(16);
    padding-bottom: pToR(24);

    @include mobile {
        grid-template-columns: pToR(84) auto auto;
        grid-column-gap: pToR(8);
    }

    .item {
        &__image {
            grid-area: 2 / 1 / span 3 / span 1;
            width: pToR(84);
            border-radius: pToR(8);

            @include tablet {
                width: pToR(96);
            }

            &.install-icon-sizing {
                max-height: pToR(48);
                width: pToR(48);
                margin: auto;

                @include mobile {
                    margin: pToR(16) 0 0 pToR(16);
                }
            }
        }
        &__title {
            font-family: $ford-f1-font-bold;
            font-size: pToR(14);
            line-height: pToR(20);
            letter-spacing: normal;
            color: $fds-preimagine-dark-text;
            margin-bottom: pToR(8);
        }
        &__id {
            font-family: $ford-f1-font-regular;
            font-size: pToR(12);
            line-height: pToR(16);
            letter-spacing: normal;
            color: $text-gray;
            margin-bottom: pToR(4);
        }
        &__quantity,
        &__price,
        &__status p {
            font-family: $ford-f1-font-regular;
            font-size: pToR(14);
            line-height: pToR(20);
            letter-spacing: normal;
            color: $fds-preimagine-dark-text;
        }
        &__status {
            grid-area: 1 / 1 / span 1 / span 3;
            margin-bottom: pToR(16);
            max-width: max-content;
            border-radius: pToR(4);

            p {
                padding: 0 pToR(8);
            }
        }
        &__quantity {
            @include mobile {
                margin-bottom: pToR(21);
            }
        }
        &__buttons-container {
            display: flex;
            align-self: flex-start;
            justify-content: flex-end;

            @include mobile {
                grid-area: 4 / 1 / span 1 / span 3;
            }

            .buy-again-button {
                @include ford-secondary-button-default();
                min-width: pToR(120);
            }
        }
    }
    .return-disabled-grayscale {
        filter: grayscale(100%);
        opacity: 0.5;
        pointer-events: none;
        display: flex;
        grid-template-columns: pToR(96) auto auto;
        grid-template-rows: repeat(5, auto);
        grid-column-gap: pToR(16);

        @include mobile {
            grid-template-columns: pToR(84) auto auto;
            grid-column-gap: pToR(8);
        }
        .item {
            &__image {
                grid-area: 2 / 1 / span 3 / span 1;
                width: pToR(84);
                border-radius: pToR(8);

                @include tablet {
                    width: pToR(96);
                }

                &.install-icon-sizing {
                    max-height: pToR(48);
                    width: pToR(48);
                    margin: auto;

                    @include mobile {
                        margin: pToR(16) 0 0 pToR(16);
                    }
                }
            }
            &__title {
                font-family: $ford-f1-font-bold;
                font-size: pToR(14);
                line-height: pToR(20);
                letter-spacing: normal;
                color: $fds-preimagine-dark-text;
                margin-bottom: pToR(8);
            }
            &__id {
                font-family: $ford-f1-font-regular;
                font-size: pToR(12);
                line-height: pToR(16);
                letter-spacing: normal;
                color: $text-gray;
                margin-bottom: pToR(4);
            }
            &__quantity,
            &__price,
            &__status p {
                font-family: $ford-f1-font-regular;
                font-size: pToR(14);
                line-height: pToR(20);
                letter-spacing: normal;
                color: $fds-preimagine-dark-text;
            }
            &__status {
                grid-area: 1 / 1 / span 1 / span 3;
                margin-bottom: pToR(16);
                max-width: max-content;
                border-radius: pToR(4);

                p {
                    padding: 0 pToR(8);
                }
            }
            &__quantity {
                @include mobile {
                    margin-bottom: pToR(21);
                }
            }
            &__buttons-container {
                display: flex;
                align-self: flex-start;
                justify-content: flex-end;

                @include mobile {
                    grid-area: 4 / 1 / span 1 / span 3;
                }

                .buy-again-button {
                    @include ford-secondary-button-default();
                    min-width: pToR(120);
                }
            }
        }
    }
    .return-items {
        display: flex;
        grid-template-columns: pToR(96) auto auto;
        grid-template-rows: repeat(5, auto);
        grid-column-gap: pToR(16);

        @include mobile {
            grid-template-columns: pToR(84) auto auto;
            grid-column-gap: pToR(8);
        }
        .item {
            &__image {
                grid-area: 2 / 1 / span 3 / span 1;
                width: pToR(84);
                border-radius: pToR(8);

                @include tablet {
                    width: pToR(96);
                }

                &.install-icon-sizing {
                    max-height: pToR(48);
                    width: pToR(48);
                    margin: auto;

                    @include mobile {
                        margin: pToR(16) 0 0 pToR(16);
                    }
                }
            }
            &__title {
                font-family: $ford-f1-font-bold;
                font-size: pToR(14);
                line-height: pToR(20);
                letter-spacing: normal;
                color: $fds-preimagine-dark-text;
                margin-bottom: pToR(8);
            }
            &__id {
                font-family: $ford-f1-font-regular;
                font-size: pToR(12);
                line-height: pToR(16);
                letter-spacing: normal;
                color: $text-gray;
                margin-bottom: pToR(4);
            }
            &__quantity,
            &__price,
            &__status p {
                font-family: $ford-f1-font-regular;
                font-size: pToR(14);
                line-height: pToR(20);
                letter-spacing: normal;
                color: $fds-preimagine-dark-text;
            }
            &__status {
                grid-area: 1 / 1 / span 1 / span 3;
                margin-bottom: pToR(16);
                max-width: max-content;
                border-radius: pToR(4);

                p {
                    padding: 0 pToR(8);
                }
            }
            &__quantity {
                @include mobile {
                    margin-bottom: pToR(21);
                }
            }
            &__buttons-container {
                display: flex;
                align-self: flex-start;
                justify-content: flex-end;

                @include mobile {
                    grid-area: 4 / 1 / span 1 / span 3;
                }

                .buy-again-button {
                    @include ford-secondary-button-default();
                    min-width: pToR(120);
                }
            }
        }
    }
}
