@import '@styles/main.scss';

.login-container {
    position: absolute;
    right: pToR(10);
    white-space: nowrap;
    margin: 0 0.5rem;
    height: pToR(32);
    display: inline-block;

    &.unauthenticated {
        & ~ .desktop.language-toggle {
            margin-right: pToR(72.5);
        }
    }

    @include desktop-v5 {
        right: pToR(42);
    }

    .login-button {
        color: $fds-color--primary;
        &.active {
            color: $dark-blue;
        }

        .login-text {
            position: relative;
            top: -1.2rem;
            padding-left: pToR(15);
        }
    }

    .login-dropdown {
        display: none;
        position: fixed;
        top: pToR(55);
        right: pToR(29);
        color: $fds-color--primary;
        background: $white;
        border: 1px solid $lightgrey;
        padding: 0 pToR(30);

        &.active {
            display: block;
        }

        @include desktop-v5 {
            top: pToR(60);
            right: pToR(60);
        }

        .login-dropdown-list {
            list-style: none;
            min-width: pToR(238);
            min-height: pToR(50);

            .login-dropdown-list-item {
                padding: pToR(15);

                &:nth-child(1) {
                    border-bottom: 1px solid $lightgrey;
                }

                a,
                button {
                    font-size: pToR(16);
                    line-height: pToR(26);
                }
                .fmc-text-button {
                    display: inline-block;
                }
            }
            @include mobile {
                min-width: pToR(180);
            }
        }
    }
}
