@import '@styles/main.v5';
@import '@styles/common';

.image-card {
    &__container {
        position: relative;
        z-index: 1;
        width: 100%;
        height: pToR(440);
        border-radius: pToR(16);

        @include media('<=935px') {
            max-width: pToR(356);
            height: pToR(259);
            border-radius: pToR(8);
        }

        &.blue-gradient-card {
            @include media('<=935px') {
                background-attachment: fixed;
            }
        }
    }

    &__overlay {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 2;
        border-radius: pToR(16);

        @include media('<=935px') {
            border-radius: pToR(8);
        }
    }

    &__content {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        z-index: 4;
        top: 0;
        left: 0;
        padding: pToR(24) pToR(24) pToR(20);

        &--title {
            font-family: $ford-f1-font-semibold;
            color: white;
            font-size: pToR(24);
            line-height: pToR(32);
            letter-spacing: normal;
            position: relative;

            &.saved-items {
                .heart-icon__container {
                    position: absolute;
                    transform: translateY(-3px);
                }
                .title {
                    padding-left: pToR(46);
                    position: absolute;
                    transform: translateY(2px);
                }
            }
        }
        &--cta {
            @include ford-secondary-button-default();
            border-color: white;
            padding: pToR(12) pToR(24);
            line-height: normal;

            .secondary-button-text {
                color: white;
            }
        }
    }
}
