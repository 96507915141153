@import '@styles/main.v5';

.country-selector-view {
    background-color: $background-color;
    height: 100vh;
    padding: pToR(64) pToR(96);
    overflow-y: auto;

    @include mobile {
        padding: pToR(40) pToR(24) pToR(24);
    }

    .country-selector-content {
        max-width: pToR(1360);
        background-color: white;
        margin: 0 auto;
        padding: pToR(48) pToR(24) pToR(36);
        box-shadow: var(--fmc-elevation__box-shadow--layer1);

        &__title {
            font-family: $antenna-font-extra-light;
            font-size: pToR(48);
            line-height: pToR(46);
            letter-spacing: normal;

            @include mobile {
                font-size: pToR(34);
                line-height: pToR(32);
            }
        }

        &__subtitle {
            font-family: $antenna-font-extra-light;
            font-size: pToR(24);
            font-weight: 100;
            letter-spacing: normal;

            @include mobile {
                font-size: pToR(18);
            }
        }

        &__input-container {
            max-width: pToR(345);
            width: 100%;
            margin-bottom: pToR(48);
        }

        hr {
            max-width: pToR(600);
            width: 100%;
            margin: unset unset pToR(4);
            box-shadow: none;
        }

        &__grid-container {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            grid-gap: pToR(24);
            max-width: 100%;

            @include tablet {
                grid-template-columns: repeat(2, 300px);
            }
            @include media('>1100px') {
                grid-template-columns: repeat(3, 300px);
            }
            @include media('>=1440px') {
                grid-template-columns: repeat(4, 300px);
            }

            .individual-country {
                display: flex;
                align-items: center;

                a {
                    color: $light-skyblue;
                    padding-left: pToR(16);
                    letter-spacing: normal;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .flag-icon-background {
                    position: relative;
                    display: inline-block;
                    width: pToR(50);
                    height: pToR(50);
                    border-radius: 50%;
                    border: 1px solid #979797;
                    overflow: hidden;

                    img {
                        position: absolute;
                        top: -2%;
                        left: -19%;
                        height: pToR(50);
                    }
                }
            }
        }
    }
}
