@import '@styles/main.v5';
@import '@styles/common';

.order-history-vehicle-item {
    &__container {
        display: grid;
        grid-template-columns: pToR(152) 1fr;
        grid-gap: pToR(8);

        @include mobile {
            grid-template-columns: pToR(128) 1fr;
        }

        @include media('<390px') {
            grid-template-columns: pToR(96) 1fr;
        }
    }

    &__image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: pToR(152);
        height: pToR(152);
        border-radius: pToR(8);
        background-color: $fds-reimagine-accessories-card-background-color;

        img {
            max-width: 100%;
        }

        @include mobile {
            width: pToR(128);
            height: pToR(128);
        }

        @include media('<390px') {
            width: pToR(96);
            height: pToR(96);
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .order-date {
            &__label {
                color: $charcoal;
                font-size: pToR(12);
                line-height: pToR(16);
                letter-spacing: normal;
                margin-bottom: pToR(4);
            }

            &__value {
                color: $fds-reimagine-dark-blue;
                font-family: $ford-f1-font-semibold;
                font-size: pToR(20);
                line-height: pToR(38);
                letter-spacing: normal;
                margin-bottom: pToR(11);
                text-align: start;

                @include media('<360px') {
                    line-height: pToR(28);
                }
            }
        }

        .order-status {
            font-family: $ford-f1-font-regular;
            background-color: $fds-preimagine-background-gray;
            color: $fds-preimagine-dark-text;
            font-size: pToR(12);
            line-height: pToR(16);
            letter-spacing: normal;
            width: fit-content;
            border-radius: pToR(4);
            padding: 0 pToR(4);
            margin-bottom: pToR(8);
        }

        .ymm,
        .order-number {
            color: $charcoal;
            font-family: $ford-f1-font-regular;
            font-size: pToR(16);
            line-height: pToR(24);
            letter-spacing: normal;
            text-align: start;
        }
    }
}
