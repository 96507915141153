@import '@styles/main.v5';

.fmc-carousel-container {
    @include mobile {
        max-width: calc(100vw - 40px);
    }

    .carousel {
        max-width: pToR(510);
        position: relative;
        height: 100%;
        scrollbar-color: transparent transparent;
        scrollbar-width: none;
        overflow-x: hidden;

        @include desktop {
            width: 100vw;
        }

        &__image-list {
            list-style-type: none;

            @include mobile-and-tablet {
                position: relative;
                padding-bottom: pToR(16);
                touch-action: pan-y;
            }

            li {
                position: relative;
                flex: 0 0 100%;
                width: 100%;
            }

            .vehicle-image {
                max-width: pToR(510);
                width: 100%;

                @include mobile-and-tablet {
                    max-width: pToR(500);
                }
            }
        }
    }
}
.vehicle-order-tracking__status-view {
    .fmc-carousel-indicator {
        &__container {
            &--left {
                position: absolute;
                left: 0;
                top: 40%;
            }
            &--right {
                position: absolute;
                right: 0;
                top: 40%;
            }
            &--left,
            &--right {
                button {
                    &:hover {
                        svg path:first-of-type {
                            fill: rgba(0, 0, 0, 0.7);
                        }
                    }
                    &:focus-visible {
                        transition: none;
                        outline: 2px solid $fds-preimagine-primary-blue;

                        .brand-lincoln & {
                            outline-color: $brand-border;
                        }
                    }

                    &:not([disabled]) {
                        transition: 500ms all ease-in;
                        opacity: 1;
                        visibility: visible;
                        pointer-events: auto;
                    }

                    &[disabled] {
                        transition: 500ms all ease-out;
                        opacity: 0;
                        visibility: hidden;
                    }
                }

                span {
                    font-size: 0;
                }
            }
            &--mobile {
                top: unset;
                left: 0;
                right: 0;
                bottom: 0;
                margin: 0 auto;

                .brand-lincoln & {
                    .dot--active {
                        background-color: $brand-border;
                    }
                }
            }
        }
    }
}
