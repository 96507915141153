@import '@styles/main.scss';

.error-404-text-container {
    text-align: center;
    padding: 4rem 1.344rem;

    @include tablet {
        padding: 5rem 1.344rem 0;
    }

    .title {
        font-family: $antenna-font-condensed-light;
        text-transform: uppercase;
        font-size: 3rem;

        margin-bottom: 1.125rem;
        letter-spacing: 3px;

        @include desktop {
            margin-bottom: 0.875rem;
            line-height: 3.625rem;
            max-width: inherit;
        }
    }

    .paragraph {
        font-size: 1rem;
        line-height: 1.625rem;
        letter-spacing: 1px;
        max-width: 20.25rem;
        margin: 0 auto;
    }

    .home-page-button {
        margin: 1.9rem auto 0;
    }
}
