@import '@styles/main.scss';

.vehicle-selector {
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.modal {
    &.congrats-modal {
        .fmc-type--heading3 {
            letter-spacing: normal;
            margin-bottom: pToR(38);
        }
        &-body {
            padding: 0;

            li {
                margin-bottom: pToR(16);
            }
        }
        .modal-footer {
            margin-bottom: pToR(38);
        }
    }
}
.fmc-button::after {
    .dynamic-masthead-content & {
        --bg-color: white;
    }
}
.preferred-dealer-consent {
    &__notification-container {
        max-width: pToR(1329);
        width: 100%;
        margin: pToR(16) auto pToR(0);

        @include mobile {
            margin-top: pToR(20);
            margin-bottom: pToR(10);
        }
        .notification-sub-copy {
            p {
                letter-spacing: normal;
            }
        }
    }
}
