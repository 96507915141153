@import '@styles/main.v5';

.delete-account-section {
    .hr-line {
        padding-top: pToR(34);
        margin-top: pToR(60);
    }
    @include mobile-v5 {
        .hr-line {
            padding-top: unset;
        }
        .fmc-text-button {
            padding-top: pToR(34);
            padding-bottom: pToR(34);
        }
    }
}
