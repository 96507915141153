@import '@styles/main';

.eu-header {
    position: sticky;
    z-index: 2001;
    top: 0;

    .first-header-link {
        @include tablet {
            padding-right: pToR(16);
        }
        @include desktop {
            box-sizing: border-box;
            font-family: $antenna-font-antenna;
            text-transform: uppercase;
            border-bottom-width: 0;
            transition: 0.3s;
            color: $dark-blue;
            letter-spacing: 0.83px;
            text-align: center;
            border-right: pToR(2) solid $brand-while-smoke;
        }
    }

    .eyebrows {
        @include desktop {
            width: 82%;
            font-family: $antenna-font-antenna;
            background-color: $gray;
            font-size: pToR(16);
            color: $eyebrow-color;
            line-height: pToR(30);
            text-align: center;
            align-items: center;
            justify-content: space-around;
            cursor: pointer;
        }

        a.link-1-general-navigation-head {
            height: 0;
            width: 0;
            display: none;
            padding-left: 0;
            padding-right: 0;
            text-decoration: none;
            color: $dropdown-text;
            font-size: 0;
            letter-spacing: 0;
            line-height: 0;
            text-align: center;
            @include desktop {
                height: pToR(20);
                width: pToR(89);
                text-decoration: none;
                color: $dropdown-text;
                font-size: pToR(12.5);
                letter-spacing: pToR(1);
                justify-content: space-between;
                line-height: pToR(20);
                margin: 0 pToR(32);
            }
        }

        .input {
            z-index: 10000;
            padding: pToR(47) pToR(100);
        }

        .quick-link-button {
            border: transparent;
            font-family: $antenna-font-antenna;
            font-size: pToR(11);
            background-color: $gray;
            color: $eyebrow-color;
            line-height: pToR(30);
            outline: none;
            text-align: center;
            justify-content: space-around;
            cursor: pointer;

            .active {
                outline: 0;
            }

            .quick-link-button:focus {
                border: none;
                outline: none;
            }

            .fds-chevron .fds-dot,
            .fds-chevron .fds-arrow {
                font-style: normal;
                font-weight: 400;
                padding-right: pToR(7);
                font-variant: normal;
                text-transform: none;
                line-height: pToR(20);
                font-size: pToR(11);
                letter-spacing: normal;
                font-feature-settings: normal;
            }
        }
    }

    .eu-logo-link {
        .eu-ford-logo {
            background: transparent no-repeat -200px 0;
            width: pToR(93);
            height: pToR(35);
            position: relative;
            background-size: pToR(400) pToR(200);
            display: inline-block;
            padding: 0;
        }
    }

    .eu-header-nav {
        display: flex;
        align-items: center;
        flex-direction: row;
        background-color: $eyebrow-grey;

        @include desktop {
            flex-direction: column;
            box-shadow: 0 4px 8px 0 rgba(179, 179, 179, 0.28),
                0 -3px 8px 0 rgba(179, 179, 179, 0.28);
            background: linear-gradient(180deg, $eyebrow-grey 0%, $white 100%);
        }

        .search-icon-submit {
            display: none;

            @include desktop {
                display: inline-block;
            }
        }
        .search-label {
            letter-spacing: 1px;
            padding-left: pToR(12);
            display: none;

            @include desktop {
                display: inline-block;
            }
        }

        a:focus,
        button:focus {
            outline: 1.5px solid $black;
        }

        .site-search-container {
            @include mobile {
                right: 0;
                padding-top: 0;

                .search-icon {
                    line-height: unset;
                }
            }
            @include tablet {
                button {
                    padding: 0;
                    margin: 0;
                }
            }
            @include desktop {
                .search-icon {
                    font-size: large;
                }
            }
        }

        .site-search-container :hover {
            outline: none;
        }
        .site-search-container .input-container .input .close {
            position: absolute;
            top: pToR(16);
            right: pToR(16);
            color: gray;
            font-size: xxx-large;

            @include desktop {
                position: absolute;
                color: gray;
                font-size: xxx-large;
            }
        }

        @include mobile {
            top: 0;
            left: pToR(-50);
            bottom: 0;
            right: 0;
        }

        li {
            list-style: none;
            padding-left: 0;
        }

        .hide-nav {
            display: none;
        }

        .background-overlay {
            display: block;
            position: absolute;
            top: pToR(84);
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100vh;
            opacity: 0.7;
            background: #000 none;
        }

        .eu-sub-nav-title-container {
            max-width: 100%;
            padding-left: 0;
            padding-right: pToR(16);
            @include desktop {
                width: unset;
                padding-left: pToR(11);
                padding-bottom: 0;
            }

            .eu-drawer-title {
                display: inline-block;
                font-family: $antenna-font-condensed-regular;
                color: $dark-blue;
                font-size: pToR(19);
                padding-bottom: pToR(15);
                padding-top: pToR(12);
                white-space: break-spaces;
                width: 100%;
                text-align: left;

                @include desktop {
                    font-size: pToR(26);
                    line-height: pToR(30);
                    letter-spacing: 2.5px;
                    font-weight: 500;
                    text-transform: uppercase;
                    padding-top: pToR(16);
                    padding-bottom: pToR(19);
                }
            }
        }

        .desktop-sub-nav {
            position: absolute;
            left: 0;
            top: pToR(135);
            margin-right: pToR(8);
            margin-top: pToR(19);
            width: 100%;
            background: white;
            transition: all 0.4s cubic-bezier(0.7, 0, 0.3, 1);

            .sidebar-sub-nav {
                text-align: left;
                margin-top: pToR(5);
                padding-left: pToR(20);

                @include desktop {
                    padding-bottom: pToR(43);
                    justify-content: flex-start;
                    padding-left: pToR(117);
                    height: pToR(500);
                    overflow: auto;
                }
                .quick-link-sidebar-sub-nav {
                    padding-left: pToR(120);
                    padding-right: pToR(48);
                    border-top: solid pToR(1);
                    text-align: left;
                    margin-top: pToR(5);
                    margin-bottom: pToR(64);
                }

                .sub-nav-drawers {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;

                    ul {
                        padding: 0;
                    }

                    .sub-nav-drawer {
                        margin-right: pToR(32);
                        padding-top: pToR(16);

                        .sub-nav-link {
                            color: $dark-blue;
                            font-family: $antenna-font-antenna-light;
                            font-size: pToR(14);
                            padding-top: pToR(10);
                            padding-right: pToR(10);
                            padding-bottom: pToR(10);
                            text-align: left;

                            .a {
                                padding: 0;
                            }
                        }

                        .hide-me {
                            display: flex;
                            flex-direction: column;
                            list-style: none;
                        }
                    }
                }

                .chevron-icon-container {
                    display: none;
                }
            }
        }

        .visible-in-mobile {
            box-sizing: initial;
            padding-right: 0;
            text-transform: uppercase;
            transition: 0.3s;
            margin-right: auto;
            border: 0;
            height: 0;
            color: $dropdown-text;
            font-size: pToR(5);
            font-weight: 100;
            letter-spacing: pToR(0.83);
            text-align: center;
            display: inline-block;
            z-index: 99;
            position: relative;

            @include desktop {
                display: none;
            }
        }

        a,
        button {
            text-decoration: none;
            font-weight: lighter;
            position: relative;
            font-size: pToR(12);
            outline: none;
            max-height: 100%;
            white-space: break-spaces;
            display: flex;
            align-items: center;
            color: $dark-blue;
            cursor: pointer;

            @include desktop {
                text-decoration: none;
                position: relative;
                font-size: pToR(12);
                padding-right: pToR(19);
                outline: none;
                height: pToR(41);
                color: $fds-color--gray3;
                font-weight: 400;
                cursor: pointer;

                &.close {
                    height: unset;
                    top: pToR(16);
                }
            }

            .main-nav {
                color: $fds-v4-primary;
                font-family: $antenna-font-condensed-medium;
                padding-left: pToR(16);

                &:hover,
                &:focus,
                &:active,
                .active & {
                    color: $light-blue;
                }
                @include desktop {
                    padding-left: pToR(30);
                }
            }

            @include mobile {
                display: flex;
                text-decoration: none;
                text-transform: none;
                font-weight: lighter;
                position: relative;
                font-family: $antenna-font-antenna-light;
                font-size: pToR(11);
                color: $dark-blue;
                border-right: 0 !important;
                letter-spacing: pToR(1.25);
                cursor: pointer;
            }

            &.desktop {
                text-transform: uppercase;
                border-bottom-width: 0;
                transition: 0.3s;
                height: pToR(28);
                color: $eyebrow-text;
                font-weight: 500;
                font-size: pToR(11);
                letter-spacing: pToR(1.83);
                padding-right: 0;
                text-align: center;
                border-right: 1px solid #d7d7d7;

                &.active {
                    outline: none;
                }
                .close {
                    cursor: pointer;
                    position: absolute;
                    outline: none;
                    right: pToR(15);
                    font-size: pToR(40);
                    top: 10%;
                }
            }

            sup {
                vertical-align: baseline;
                position: absolute;
                top: pToR(-15);
                font-size: pToR(10);
                right: pToR(-2);
            }

            &.login {
                margin-left: pToR(16);
                top: pToR(-1);
            }
        }

        button {
            border: none;
            background-color: unset;
            width: max-content;
        }

        .right-align {
            margin-left: auto;
        }

        @include mobile {
            height: pToR(70);
            padding: 0;

            .desktop,
            .desktop-sub-nav {
                display: none;
            }

            .eu-logo-link,
            .mobile-fill,
            .menu {
                flex: 1 1 calc(100% / 3);
            }

            .eu-logo-link {
                display: flex;
                justify-content: center;
                height: unset;
                padding: 0;
            }
        }
        .menu {
            text-align: left;
            padding: 0;
            left: pToR(22);
            height: pToR(14);

            span {
                &,
                &::before,
                &::after {
                    border-radius: 0;
                    height: pToR(2);
                }
                &::after {
                    top: pToR(-8);
                }
            }
        }
    }

    .main-nav-item {
        display: grid;
        grid-gap: pToR(20);
        @include tablet {
            grid-template-columns: repeat(auto-fill, 1fr);
        }
        @include desktop {
            grid-template-columns: repeat(auto-fill, pToR(315));
        }
    }
    .main-nav-items {
        display: inline-block;
        list-style: none;
        font-size: pToR(13);
        letter-spacing: 3px;
        line-height: pToR(16);
        padding: pToR(20);
    }
    .fds-chevron-wrap {
        position: relative;
        display: inline-flex;
        padding-left: pToR(16);
        margin-right: pToR(40);
    }

    @include mobile {
        .eu-header-nav {
            padding: 0;
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
        }
        .menu {
            text-align: left;
            font-size: 0.01px;
            background-color: $gray;
            outline: none;
        }

        .fds-chevron .fds-dot,
        .fds-chevron .fds-arrow {
            font-style: normal;
            font-weight: 100;
            font-variant: normal;
            text-transform: none;
            line-height: pToR(20);
            font-size: pToR(11);
            letter-spacing: normal;
            font-feature-settings: normal;
        }
    }
    .block-header {
        display: none;

        .nav-header {
            display: flex;
            align-items: center;
        }

        @include desktop {
            display: flex;
            width: 100%;
            height: pToR(84);
            background: linear-gradient(-180deg, $eyebrow-grey, white);
            box-shadow: 0 4px 8px 0 #b3b3b3;
        }
    }
    .left-nav {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;

        nav {
            z-index: 1;

            @include desktop {
                display: flex;
                align-items: center;

                a:first-of-type {
                    padding-right: pToR(30);
                }
            }
        }

        nav:last-child {
            button {
                &.desktop {
                    border-right: 0;
                }
            }
        }
        a {
            color: $fds-v4-primary;
            font-size: pToR(11);
            line-height: pToR(20);
            letter-spacing: 1.83px;
            font-family: $antenna-font-condensed-medium;
            font-weight: 500;

            &:hover,
            &:focus,
            &:active {
                color: $light-blue;
            }
        }

        .tab-active {
            border-bottom: 3px solid $fds-color--primary;
            border-radius: 1.5px;

            .main-nav {
                margin-top: 3px;
                color: $fds-v4-secondary;
            }
        }
    }
    .eyebrow {
        width: 100%;
        background-color: $eyebrow-grey;
        min-height: pToR(70);
        font-family: $antenna-font-antenna;
        font-size: pToR(11);
        color: $dark-blue;
        letter-spacing: pToR(1);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        list-style: none;
        right: 0;

        .ford-logo {
            position: absolute;
            left: calc(100% / 2 - 46.5px);
        }

        @include desktop {
            height: pToR(60);
            padding-right: pToR(52);
            justify-content: flex-end;

            .ford-logo {
                position: relative;
                height: 100%;
                display: flex;
                align-items: center;
                margin-left: pToR(100);
                left: unset;
            }
        }

        .nav-right {
            display: flex;
            align-items: center;

            &.sidebar-open {
                display: none;
            }
        }
        .thin-header-content {
            display: none;

            @include desktop {
                display: flex;
                margin-left: auto;
                align-items: center;
            }
        }
        .eu-login-container {
            margin-left: auto;
            @include desktop {
                margin-left: unset;
            }
        }

        @include desktop {
            font-family: $antenna-font-antenna;
            font-size: pToR(11);
            color: $dark-blue;
            letter-spacing: pToR(1);
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            list-style: none;
            right: 0;
        }
        @include mobile {
            top: pToR(-42);
            padding-left: 0;
            min-width: 100%;

            a:not(:last-of-type) {
                display: none;
            }
        }
    }
    .eyebrow-text {
        padding-left: 0;
        padding-right: 0;
    }
    .quick-link-eyebrow {
        font-family: $antenna-font-antenna;
        transition: max-height 0.3s, visibility 0.3s;
        letter-spacing: pToR(1);
        font-size: pToR(11);
        right: 0;
        display: none;

        @include mobile {
            display: none;
        }
    }
    .quick-link-text {
        font-size: pToR(11);
        font-family: $antenna-font-antenna;
        transition: max-height 0.3s, visibility 0.3s;
        padding-left: pToR(11);
        letter-spacing: pToR(1);
    }

    .quick-link-container {
        position: absolute;
        right: 0;
        transition: max-height 0.3s, visibility 0.3s;
        font-size: pToR(12);
        border: 1px solid darkcyan;
        display: inline-block;
        top: 87%;
        background-color: $gray;
    }

    .quick-link-drawer-title {
        font-family: $antenna-font-antenna;
        padding: 0;
        font-size: pToR(12);
        text-decoration: none;
        text-align: left;
        white-space: nowrap;
        margin-left: pToR(16);
        right: 0;
        line-height: 3.3;

        a:hover {
            color: blue;
        }
    }
    .desktop:hover {
        outline: none;
    }
    .sub-nav-link:focus {
        outline: none;
    }
    .sub-nav-link:hover {
        outline: none;
    }
    .active {
        a {
            color: $light-blue;
        }
    }

    .eu-site-search-container {
        font-size: x-large;
        z-index: 50000;
        padding: 0 pToR(20) 0 pToR(8);

        @include mobile {
            .site-search-container {
                margin: unset;
            }
        }
        @include desktop {
            height: pToR(41);
            position: relative;
            border: none;

            .input-container {
                position: fixed;
                top: 0;
                left: 0;
                z-index: 10000;
                width: 100%;
                height: 100%;
            }

            .site-search-container {
                margin: 0;
                padding: 0;
            }
        }
    }

    .sub-nav-title {
        white-space: break-spaces;
        padding: 0;
        margin: 0;
        max-width: 32ch;

        @include desktop {
            color: $fds-color--gray3;
            font-family: $antenna-font-condensed-light;
            font-weight: 400;
            font-size: pToR(16);
            line-height: pToR(26);
            letter-spacing: 2px;
        }
    }
}
