@import '@styles/main.v5';

.form-field {
    margin-bottom: pToR(24);

    .brand-lincoln & {
        height: pToR(60);
        position: relative;
        display: block;

        .lincoln-visibility-button {
            position: absolute;
            right: pToR(10);
            bottom: pToR(14);
        }
    }

    .form-field__eyebrow {
        display: flex;
        align-items: flex-end;
        height: pToR(10);

        button {
            margin-right: pToR(12);
        }

        .brand-lincoln & {
            display: none;
            position: absolute;
            width: 100%;
            top: 33%;
            height: unset;

            button {
                margin-right: pToR(13);
            }

            .lincoln-visibility-icon {
                width: pToR(20);
                height: pToR(20);
            }
        }
    }

    .user-input-error-notice {
        width: pToR(16);
        margin-left: pToR(5);
        padding-bottom: pToR(8);

        .brand-lincoln & {
            width: pToR(20);
            height: pToR(20);
            position: absolute;
            padding: 0;
            margin: 0;
            bottom: pToR(20);
            right: pToR(13);

            & ~ .form-field__input:focus {
                & ~ .form-field__label {
                    color: $brand-light-error;
                }
            }
        }
    }

    .form-field__label {
        font-size: pToR(12);
        position: relative;
        font-family: $antenna-font;
        line-height: pToR(12);
        color: $fds-color--gray3;
        letter-spacing: pToR(1);
        padding-bottom: pToR(8);

        .brand-lincoln & {
            position: absolute;
            font-size: pToR(16);
            line-height: pToR(20);
            letter-spacing: 0;
            font-family: $proxima-nova-regular;
            color: $brand-secondary;
            top: 0;
            left: pToR(14);
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translate3d(0, 20px, 0) scale3d(1, 1, 1);
            -webkit-transition: transform 200ms ease;
            -webkit-transition-timing-function: linear;
            transform: translate3d(0, 20px, 0) scale3d(1, 1, 1);
            transition: transform 200ms ease;
            transition-timing-function: linear;
            will-change: transform;

            &.success {
                color: $brand-success;
            }
        }
    }

    &__checkbox {
        &:not(:checked),
        &:checked {
            position: absolute;
            left: -9999px;
        }

        &:not(:checked) + label,
        &:checked + label {
            position: relative;
            padding-left: pToR(29);
            padding-top: pToR(3);
            cursor: pointer;
        }

        &:checked + label:before,
        &:not(:checked) + label:before {
            content: ' ';
            background-image: url('./../../../assets/uncheck.svg');
            background-position: left center;
            background-size: auto 100%;
            background-repeat: no-repeat;
            position: absolute;
            left: 0;
            top: pToR(5);
            width: pToR(16);
            height: pToR(16);
        }

        &:not(:checked) + label:after,
        &:checked + label:after {
            content: ' ';
            background-image: url('./../../../assets/check.svg');
            background-position: right center;
            position: absolute;
            top: pToR(5);
            left: 0;
            width: pToR(16);
            height: pToR(16);

            .brand-lincoln & {
                background-image: url('./../../../assets/checkboxFilled.svg');
            }
        }

        &:not(:checked) + label:after {
            opacity: 0;
        }

        &:checked + label:after {
            opacity: 1;
        }

        &:disabled {
            &:not(:checked) + label:before {
                box-shadow: none;
                border-color: $text-gray;
                background-color: $white;
            }

            &:checked + label {
                &:before {
                    box-shadow: none;
                    border-color: $text-gray;
                    background-color: $white;
                }

                &:after {
                    color: $text-gray;
                }
            }

            + label {
                color: $text-gray;
            }
        }

        &:checked:focus-visible + label:before,
        &:not(:checked):focus-visible + label:before {
            outline: 5px auto Highlight; // to support firefox browser
            outline: 5px auto -webkit-focus-ring-color;
            outline-offset: 3px;
        }
    }
}

.form-field-error {
    order: 3;
    line-height: pToR(16);

    .brand-lincoln & {
        margin-top: pToR(0);
        padding-left: pToR(14);

        & ~ label {
            color: $brand-light-error;
        }

        & ~ input {
            border-bottom: 1px solid $brand-light-error;

            &:invalid:focus {
                border-bottom: 2px solid $brand-light-error;

                & ~ label {
                    color: $brand-light-error;
                }
            }

            &:focus,
            &:valid {
                border-bottom: 2px solid $brand-light-error;

                & ~ label {
                    color: $brand-light-error;
                }
            }
        }
    }

    .error-text {
        color: $fds-preimagine-ford-error;
        font-size: pToR(12);
        letter-spacing: 1px;
        font-family: $antenna-font;

        .brand-lincoln & {
            color: $brand-light-error;
            font-size: pToR(13);
            letter-spacing: 0;
            font-family: $proxima-nova-regular;
        }
    }
}

.select-container {
    margin-top: pToR(10);

    label {
        font-size: pToR(11);
    }

    .brand-ford & .form-field-error {
        margin-top: pToR(8);
    }
}

.checkbox_ctn {
    display: flex;
    align-items: baseline;
}

.fmc-input {
    font-size: pToR(16);

    &:focus {
        outline: none;
    }

    &:focus-within {
        outline: none;
    }

    .brand-lincoln & {
        height: pToR(60);
    }
}

.fmc-select {
    height: pToR(48);
    font-size: pToR(16);
    background-position: top pToR(16) right pToR(24);

    &:focus {
        outline: none;
    }

    &:focus-within {
        outline: none;
    }

    [data-brand='lincoln'] & {
        background-position: top pToR(27) right pToR(24);
        border-radius: 0;
    }
}

.fmc-select,
.fmc-input {
    .brand-ford & {
        border: 1px solid $fds-color--gray2;
    }
}

.brand-lincoln {
    .add-vehicle-modal .fmc-floating-label {
        margin-bottom: 0;
    }

    .user-account-edit-container label:after {
        top: 37%;
    }
}

.fmc-floating-label {
    margin-bottom: pToR(20);

    .brand-lincoln & {
        gap: unset;
        margin-bottom: pToR(28);

        label:after {
            --label-icon-font-size: 20px;
        }
    }

    &:focus-within {
        outline: 0;
    }

    .password-field {
        display: flex;
        justify-content: space-between;
    }

    .icon-button {
        margin-left: auto;
        background: none;
        border: none;
        position: absolute;
        left: auto;
        right: 3%;
        top: 28%;
    }

    .fmc-floating-label__text {
        letter-spacing: normal;
        display: inline-flex;
        align-items: baseline;
        gap: var(--label-gap);
        color: var(--label-color);
        font-size: var(--label-text-font-size);
        user-select: none;

        .brand-ford & {
            font-family: $ford-f1-font-regular;
            color: $fds-preimagine-dark-text;
            font-size: pToR(14);
            line-height: pToR(20);
            transform: none;
        }
        .brand-lincoln & {
            transition: 300ms var(--label-transition-timing);
            transform: var(--label-default-text-transform);
        }
    }

    .syndicated-input {
        .brand-ford & {
            color: $fds-reimagine-dark-blue;
            font-family: $ford-f1-font-regular;
            font-size: pToR(14);
            line-height: pToR(20);
            padding: 0 pToR(16);
            height: pToR(40);
            margin: 0;
            border: pToR(1) solid $fds-preimagine-disabled-text-color;
            border-radius: pToR(8);

            &:focus {
                box-shadow: none;
            }
            &:focus-visible {
                outline: 2px solid $fds-preimagine-primary-blue;
                outline-offset: 0;
                border-color: transparent;
            }
            &:disabled {
                background-color: #e6e8ea;
                border: 1px solid $fds-preimagine-disabled-text-color;
            }
        }
    }
}

.fmc-floating-label--error {
    .brand-lincoln & {
        --label-gap: auto;

        .error-text {
            display: block;
        }
    }
}

.fmc-floating-label:not(.fmc-floating-label--wrapped-input) label {
    .brand-lincoln & {
        max-height: pToR(60);
    }
}
.fmc-floating-label input:focus ~ label .fmc-floating-label__text {
    .brand-ford & {
        transform: none;
    }
}
.fmc-floating-label
    input:not(:placeholder-shown)
    ~ label
    .fmc-floating-label__text {
    .brand-ford & {
        transform: none;
    }
}
