@import '@styles/main.v5';

.order-details {
    &-container {
        display: flex;
        flex-direction: column;

        .order-details-view-notification-container {
            display: flex;
            max-width: pToR(1105);
            width: 100%;
            flex-direction: column;
            margin: pToR(8) auto;

            @include mobile {
                max-width: -webkit-fill-available;
                max-width: -moz-available;
                margin-left: pToR(16);
                margin-right: pToR(16);
            }

            @include tablet {
                max-width: -webkit-fill-available;
                max-width: -moz-available;
                padding-left: pToR(58);
                padding-right: pToR(58);
            }

            @include desktop {
                max-width: pToR(1105);
            }
        }

        .order-details-component {
            &-container {
                display: flex;
                max-width: pToR(1105);
                width: 100%;
                flex-direction: column;
                margin: auto;

                @include mobile {
                    max-width: -webkit-fill-available;
                    margin-left: pToR(16);
                    margin-right: pToR(16);
                    padding-bottom: pToR(64);
                }

                @include tablet {
                    max-width: -webkit-fill-available;
                    padding-left: pToR(58);
                    padding-right: pToR(58);
                    padding-bottom: pToR(129);
                }

                @include desktop {
                    max-width: pToR(1105);
                }

                .order-details-component-title {
                    font-size: pToR(32);
                    font-family: $ford-f1-font-regular;
                    color: $charcoal;
                    letter-spacing: normal;
                    line-height: pToR(40);
                    padding-bottom: pToR(24);
                    padding-top: pToR(8);
                    margin-bottom: pToR(0);
                    @include mobile {
                        font-size: pToR(28);
                        line-height: pToR(36);
                    }
                }
                .order-details-component-title-unauthenticated {
                    font-size: pToR(32);
                    font-family: $ford-f1-font-regular;
                    color: $charcoal;
                    letter-spacing: normal;
                    line-height: pToR(40);
                    padding-bottom: pToR(24);
                    margin-bottom: pToR(0);
                    padding-top: pToR(48);
                    @include mobile {
                        padding-top: pToR(32);
                        font-size: pToR(28);
                        line-height: pToR(36);
                    }
                }

                .order-totals__container {
                    margin-top: pToR(24);

                    @include tablet {
                        margin-top: pToR(24);
                    }
                }

                .order-details-component-details {
                    .order-details-component-order-date {
                        font-size: pToR(12);
                        line-height: pToR(16);
                        font-family: $ford-f1-font-regular;
                        color: $charcoal;
                        padding-bottom: pToR(8);
                        letter-spacing: normal;
                    }
                    .order-details-component-date {
                        font-size: pToR(20);
                        line-height: pToR(28);
                        font-family: $ford-f1-font-semibold;
                        color: $fds-reimagine-dark-blue;
                        padding-bottom: pToR(44);
                        letter-spacing: normal;
                        @include mobile {
                            padding-bottom: pToR(24);
                        }
                    }
                    .order-details-component-address-container {
                        display: flex;
                        flex-direction: row;
                        padding-bottom: pToR(48);
                        @include mobile {
                            flex-direction: column;
                            padding-bottom: pToR(32);
                        }
                        .order-details-component-billing-address-container {
                            flex-direction: column;
                            @include mobile {
                                margin-bottom: pToR(24);
                            }
                            .order-details-component-billing-address-title {
                                font-size: pToR(12);
                                line-height: pToR(16);
                                font-family: $ford-f1-font-regular;
                                color: $charcoal;
                                padding-bottom: pToR(8);
                                letter-spacing: normal;
                            }
                            .order-details-component-billing-address {
                                flex-direction: row;
                                font-size: pToR(16);
                                line-height: pToR(24);
                                font-family: $ford-f1-font-regular;
                                color: $fds-preimagine-high-contrast;
                                letter-spacing: normal;
                            }
                        }
                        .order-details-component-shipping-address-container {
                            flex-direction: column;
                            padding-left: pToR(130);
                            @include mobile {
                                padding-left: 0;
                            }
                            .order-details-component-shipping-address-title {
                                font-size: pToR(12);
                                line-height: pToR(16);
                                font-family: $ford-f1-font-regular;
                                color: $charcoal;
                                padding-bottom: pToR(8);
                                letter-spacing: normal;
                            }
                            .order-details-component-shipping-address {
                                font-size: pToR(16);
                                line-height: pToR(24);
                                font-family: $ford-f1-font-regular;
                                color: $fds-preimagine-high-contrast;
                                letter-spacing: normal;
                            }
                        }
                    }
                    .order-details-component-return-buttons-container {
                        display: flex;
                        flex-direction: row;
                        margin-bottom: pToR(48);
                        @include mobile {
                            margin-bottom: pToR(32);
                        }
                        .secondary-button {
                            color: $fds-preimagine-button-blue;
                            font-family: $ford-f1-font-bold;
                            font-size: pToR(14);
                            align-items: center;
                            border: 1px solid #066fef;
                            border-radius: 25rem;
                            display: flex;
                            flex-direction: row;
                            font-style: normal;
                            height: 2.5rem;
                            letter-spacing: normal;
                            line-height: 1.25rem;
                            padding: 0.75rem 1.25rem;
                            margin-right: pToR(16);
                            .secondary-button-text {
                                color: $fds-preimagine-button-blue;
                            }
                            &:hover {
                                border: 1px solid
                                    $fds-preimagine-link-hover-blue;
                                .secondary-button-text {
                                    color: $fds-preimagine-link-hover-blue;
                                }
                            }
                        }
                        .tertiary-button {
                            .secondary-button {
                                border: none;
                                .secondary-button-text {
                                    color: $fds-preimagine-button-blue;
                                    font-family: $ford-f1-font-bold;
                                    font-size: pToR(14);
                                    &:hover {
                                        color: $fds-preimagine-link-hover-blue;
                                    }
                                }
                            }
                        }
                    }
                    .order-details-component-return-policy-container {
                        display: flex;
                        flex-direction: row;
                        margin-bottom: pToR(48);
                        @include mobile {
                            margin-bottom: pToR(32);
                        }
                        .secondary-button {
                            color: $fds-preimagine-button-blue;
                            font-family: $ford-f1-font-bold;
                            font-size: pToR(14);
                            align-items: center;
                            border: 1px solid #066fef;
                            border-radius: pToR(400);
                            display: flex;
                            flex-direction: row;
                            height: pToR(40);
                            letter-spacing: normal;
                            line-height: pToR(20);
                            padding: 0;
                            margin-right: pToR(16);

                            a {
                                height: 100%;
                                display: flex;
                                align-items: center;
                                padding: pToR(12) pToR(20);
                                border-radius: pToR(400);

                                .secondary-button-text {
                                    color: $fds-preimagine-button-blue;
                                }

                                &:hover {
                                    .secondary-button-text {
                                        color: $fds-preimagine-link-hover-blue;
                                    }
                                }

                                &:focus-visible {
                                    outline: 2px solid
                                        $fds-preimagine-button-blue;
                                    outline-offset: 2px;
                                }
                            }
                        }
                        .tertiary-button {
                            .secondary-button {
                                border: none;

                                .secondary-button-text {
                                    color: $fds-preimagine-button-blue;
                                    font-family: $ford-f1-font-bold;
                                    font-size: pToR(14);

                                    &:hover {
                                        color: $fds-preimagine-link-hover-blue;
                                    }
                                }
                            }
                        }
                    }
                }

                .hr-line {
                    height: pToR(1);
                    border-bottom: pToR(1) solid $disabled-dark;
                    box-shadow: none;
                    margin-bottom: pToR(36);
                    width: 100%;

                    @include mobile {
                        visibility: visible;
                        margin-bottom: pToR(24);
                    }

                    @include tablet {
                        visibility: visible;
                        margin-bottom: pToR(21);
                    }

                    @include desktop {
                        width: 100%;
                        margin-left: 0;
                        visibility: visible;
                    }

                    .brand-lincoln & {
                        border: 0;
                        border-bottom: pToR(1) solid $brand-gray3;

                        @include mobile {
                            border: 0;
                            border-bottom: pToR(1) solid $brand-gray3;
                        }
                    }
                }
            }
        }
    }
    .hr-line {
        height: pToR(1);
        border-bottom: pToR(1) solid $disabled-dark;
        box-shadow: none;
        margin-bottom: pToR(36);
        width: 100%;

        @include mobile {
            margin-bottom: pToR(24);
            visibility: visible;
        }

        @include tablet {
            visibility: visible;
        }

        @include desktop {
            width: 100%;
            margin-left: 0;
            visibility: visible;
        }

        .brand-lincoln & {
            border: 0;
            border-bottom: pToR(1) solid $brand-gray3;

            @include mobile {
                border: 0;
                border-bottom: pToR(1) solid $brand-gray3;
            }
        }
    }
}
.sub-order__container {
    &.shipping {
        margin-bottom: pToR(72);

        @include mobile {
            margin-bottom: pToR(46);
        }
    }
    .pick-up-at-dealer {
        color: $charcoal;
        font-family: $ford-f1-font-regular;
        font-size: pToR(16);
        line-height: pToR(24);
        letter-spacing: normal;
        margin-bottom: pToR(28);

        span {
            color: $charcoal;
            font-family: $ford-f1-font-bold;
            text-decoration: underline;
        }
    }
}
.sub-order__heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: pToR(13);
    height: pToR(40);

    @include mobile {
        margin-bottom: pToR(14);
    }

    &--number {
        font-size: pToR(16);
        line-height: pToR(24);
        letter-spacing: normal;
        color: $charcoal;
        font-family: $ford-f1-font-semibold;
        margin-right: pToR(28);
    }

    .secondary-button {
        color: $fds-preimagine-button-blue;
        font-family: $ford-f1-font-bold;
        font-size: pToR(14);
        align-items: center;
        border-radius: pToR(400);
        display: flex;
        flex-direction: row;
        height: pToR(40);
        letter-spacing: normal;
        line-height: pToR(20);
        padding: 0;
        margin-right: pToR(16);

        a,
        button {
            height: 100%;
            padding: pToR(12) pToR(20);
            display: flex;
            align-items: center;
            border-radius: pToR(400);

            .secondary-button-text {
                color: $fds-preimagine-button-blue;
            }

            &:hover {
                .secondary-button-text {
                    color: $fds-preimagine-link-hover-blue;
                }
            }
            &:focus-visible {
                outline: 2px solid $fds-preimagine-button-blue;
                outline-offset: 2px;
            }
        }
    }

    .tertiary-button {
        .secondary-button {
            border: none;

            a {
                text-decoration: unset;
            }
            .secondary-button-text {
                color: $fds-preimagine-button-blue;
                font-family: $ford-f1-font-bold;
                font-size: pToR(14);

                &:hover {
                    color: $fds-preimagine-link-hover-blue;
                }
            }
        }
    }
}
.brand-ford
    .self-help-container
    .banner
    .top.no-bottom
    .vehicle-tabs
    .search-container
    .fmc-type--heading1 {
    font-size: pToR(32);
    line-height: pToR(40);

    @include mobile {
        font-size: pToR(28);
        line-height: pToR(36);
    }
}
