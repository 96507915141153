@import '@styles/main.v5';

.dynamic-masthead {
    height: pToR(532);
    margin: 0 pToR(56) pToR(95);

    @include mobile {
        height: pToR(450);
        margin: 0 pToR(56) pToR(48);
    }

    @include mobile-v5 {
        padding: 0 pToR(16) pToR(96);
        margin: unset;
        margin-bottom: pToR(48);
    }

    .background-image {
        background-size: cover;
        min-height: pToR(532);
        border-radius: pToR(8);

        @include mobile {
            min-height: pToR(450);
        }

        .dynamic-masthead-state__orientation--left & {
            align-items: flex-end;

            .dynamic-masthead-content-wrapper {
                position: relative;
                top: pToR(220);

                @media (max-width: 1288px) {
                    top: pToR(30);
                }
                @include mobile {
                    position: unset;
                }

                .dynamic-masthead-content {
                    color: $fds-color--white;
                    display: flex;
                    flex-direction: column;
                    font-size: pToR(40);
                    font-family: $ford-f1-font-regular;
                    margin-right: pToR(132);
                    align-items: last baseline;

                    @media (max-width: 1288px) {
                        margin-top: unset;
                        margin-right: unset;
                        padding-right: pToR(30);
                        padding-top: pToR(200);
                    }

                    @media (max-width: 1185px) {
                        padding-top: pToR(294);
                        width: unset;
                    }

                    @include mobile {
                        padding-top: pToR(200);
                        width: unset;
                        align-items: flex-end;
                        text-align: right;
                        padding-bottom: pToR(20);
                    }

                    @include mobile-v5 {
                        padding-top: pToR(242);
                        padding-bottom: pToR(24);
                        width: unset;
                    }

                    @media (max-width: 1288px) {
                        font-size: pToR(40);
                        line-height: pToR(40);
                        letter-spacing: normal;
                    }

                    .fmc-type-heading4 {
                        font-size: pToR(40);
                        line-height: pToR(40);
                        max-width: 11ch;
                        margin-bottom: pToR(10);
                        font-family: $ford-f1-font-regular;
                        letter-spacing: normal;

                        @include mobile {
                            max-width: 16ch;
                            font-size: pToR(32);
                        }

                        @include mobile-v5 {
                            max-width: 13ch;
                        }

                        @include tablet {
                            line-height: pToR(48);
                            max-width: 22ch;
                            letter-spacing: normal;
                        }
                    }

                    .fmc-type-body1 {
                        font-size: pToR(16);
                        line-height: pToR(24);
                        max-width: 30ch;
                        margin: 0;
                        margin-bottom: pToR(12);
                        font-family: $ford-f1-font-regular;
                        letter-spacing: normal;

                        @media (max-width: 1288px) {
                            .dynamic-masthead-state__orientation--left & {
                                text-align: right;
                            }
                        }

                        @include tablet {
                            margin-bottom: pToR(22);
                            letter-spacing: normal;
                        }

                        @include mobile {
                            max-width: 20ch;
                        }
                    }

                    .secondary-button {
                        font-family: $ford-f1-font-bold;
                        height: pToR(52);
                        letter-spacing: normal;

                        a {
                            font-family: $ford-f1-font-bold;
                            height: pToR(42);
                            padding: 0 pToR(22);
                            font-size: pToR(14);
                            color: $fds-preimagine-dark-text;
                            background-color: $white;
                            letter-spacing: normal;
                        }
                    }
                }
            }
        }

        .dynamic-masthead-state__orientation--right & {
            align-items: flex-start;

            .dynamic-masthead-content-wrapper {
                margin-left: pToR(132);

                position: relative;
                top: pToR(220);

                @media (max-width: 1288px) {
                    .dynamic-masthead-content-wrapper {
                        margin-left: unset;
                    }

                    margin-top: unset;
                    margin-left: unset;
                    padding-left: pToR(30);
                }

                @include mobile {
                    top: unset;
                }

                .dynamic-masthead-content {
                    color: $fds-color--white;
                    display: flex;
                    flex-direction: column;
                    font-size: pToR(40);
                    font-family: $ford-f1-font-regular;
                    letter-spacing: normal;

                    @media (max-width: 1288px) {
                        .dynamic-masthead-content-wrapper {
                            margin-left: unset;
                        }

                        margin-top: unset;
                        margin-left: unset;
                        padding-left: pToR(30);
                    }

                    @media (max-width: 1185px) {
                        width: unset;
                    }

                    @include mobile {
                        padding-top: pToR(200);
                        top: unset;
                        padding-left: unset;
                    }

                    @include mobile-v5 {
                        padding-bottom: pToR(24);
                        top: unset;
                        padding-left: unset;
                    }

                    .fmc-type-heading4 {
                        font-size: pToR(40);
                        line-height: pToR(48);
                        max-width: 11ch;
                        margin-bottom: pToR(10);
                        font-family: $ford-f1-font-regular;
                        letter-spacing: normal;

                        @media (max-width: 1288px) {
                            max-width: 22ch;
                            font-size: pToR(40);
                            line-height: pToR(40);
                            letter-spacing: normal;
                        }

                        @include tablet {
                            line-height: pToR(48);
                            max-width: 22ch;
                            letter-spacing: normal;
                        }

                        @include mobile {
                            max-width: 16ch;
                            font-size: pToR(32);
                        }

                        @include mobile-v5 {
                            min-width: 12ch;
                        }
                    }

                    .fmc-type-body1 {
                        font-size: pToR(16);
                        line-height: pToR(24);
                        max-width: 30ch;
                        margin: 0;
                        margin-bottom: pToR(12);
                        font-family: $ford-f1-font-regular;
                        letter-spacing: normal;

                        @include mobile {
                            margin-bottom: pToR(16);
                            max-width: 20ch;
                        }

                        @media (max-width: 1288px) {
                            .dynamic-masthead-state__orientation--left & {
                                text-align: right;
                            }
                        }

                        @include tablet {
                            margin-bottom: pToR(22);
                            letter-spacing: normal;
                        }
                    }

                    .secondary-button {
                        font-family: $ford-f1-font-bold;
                        height: pToR(52);
                        letter-spacing: normal;

                        a {
                            font-family: $ford-f1-font-bold;
                            height: pToR(40);
                            padding: 0 pToR(22);
                            font-size: pToR(14);
                            color: $fds-preimagine-dark-text;
                            background-color: $white;
                            letter-spacing: normal;
                        }
                    }
                }
            }
        }
    }
}
