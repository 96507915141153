@import '@styles/main.v5';

.brand-ford {
    .greeting-container {
        margin-top: pToR(24);
        align-self: flex-start;

        @include tablet {
            margin-top: 0;
        }

        .fmc-type--heading1 {
            font-size: pToR(54);
            line-height: pToR(64);
            margin-bottom: pToR(6);
            text-align: start;
            letter-spacing: normal;
            color: $dark-blue;
            font-family: $ford-f1-font-regular;
            word-wrap: break-word;

            @include mobile {
                font-size: pToR(28);
                line-height: pToR(36);
                text-align: start;
                margin-bottom: pToR(10);
                padding-bottom: 0;
                margin-top: pToR(10);
            }
            @include tablet {
                margin-bottom: 17px;
            }
        }
    }

    .featured-articles-carousel-container {
        @include tablet {
            padding-top: pToR(7);
        }
    }

    .greeting-wrapper {
        display: grid;
        width: fit-content;
        grid-template-columns: auto auto;
        grid-template-rows: repeat(2, auto);
        padding-top: pToR(18);
        margin-bottom: pToR(64);

        @include mobile-and-tablet {
            grid-template-columns: 80px auto;
            grid-template-rows: repeat(3, auto);
            grid-column-gap: pToR(16);
            margin-bottom: pToR(48);
            padding-top: pToR(13);
            width: 100%;
        }

        @include desktop {
            display: flex;
            flex-direction: column;
            padding-top: 0;
        }
        &:has(> .initials__container) {
            @include mobile-and-tablet {
                grid-column-gap: pToR(10);
            }
        }

        .masthead-row-1 {
            margin-bottom: pToR(12);

            .greeting-container {
                margin-right: pToR(13);
            }
        }
        .masthead-row-2 {
            align-items: center;

            .initials__container {
                margin-right: pToR(20);
            }
        }

        .greeting-container {
            margin-top: 0;
            align-self: unset;
            grid-area: 1 / 2 / 1 / 2;

            @include mobile-and-tablet {
                display: flex;
                align-items: flex-end;
                transform: translateY(4px);
            }

            @include desktop {
                grid-area: 1 / 1 / 2 / 2;
            }

            .greeting__name {
                font-size: pToR(24);
                line-height: pToR(32);
                color: black;
                font-family: $ford-f1-font-medium;
                letter-spacing: normal;
                margin-bottom: 0;
                width: max-content;

                @include mobile {
                    width: 100%;
                }
            }
        }

        .ford-member-id {
            color: black;
            font-size: pToR(16);
            line-height: pToR(24);
            font-family: $ford-f1-font-regular;
            letter-spacing: normal;
            display: flex;
            align-items: center;

            @include mobile-and-tablet {
                grid-area: 2 / 2 / span 1 / span 1;
                text-align: start;
                height: fit-content;
                display: inline-flex;
                align-self: center;
                transform: translateY(3px);
            }
        }

        .initials__container,
        .person-icon__container {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: pToR(80);
            height: pToR(80);
            grid-area: 1 / 1 / span 2 / span 1;
            z-index: 1;

            @include desktop {
                width: pToR(75);
                height: pToR(75);
                margin-top: pToR(3);
            }
        }

        .initials__container {
            span {
                position: absolute;
                color: white;
                font-family: $ford-f1-font-semibold;
                font-size: pToR(30);
                line-height: pToR(48);
                letter-spacing: normal;
                z-index: 2;
                transform: translateY(3px);
            }

            @include desktop {
                grid-area: unset;
            }
        }
        .person-icon__container {
            span {
                position: absolute;
                z-index: 2;
                transform: translateY(3px);
            }

            @include desktop {
                grid-area: 1 / 1 / 3 / 2;
            }
        }

        @include desktop {
            padding-left: pToR(14);
        }
    }

    &.na {
        .greeting-wrapper {
            @include desktop {
                margin-top: pToR(8);
            }
        }
        .masthead-row-1,
        .masthead-row-2 {
            display: flex;
            justify-content: flex-start;
        }
        .masthead-row-1 {
            @include desktop {
                margin-bottom: pToR(15);
            }
        }
        .masthead-row-2 {
            @include desktop {
                padding-left: pToR(7);
            }
        }
    }
    &.not-na {
        .greeting-wrapper {
            grid-gap: unset;

            @include mobile-and-tablet {
                padding-top: pToR(21);
                grid-column-gap: pToR(8);
            }
            @include desktop {
                grid-column-gap: pToR(24);
                padding-top: 0;
                display: flex;
                flex-direction: row-reverse;
            }
            .greeting-container {
                display: flex;
                grid-area: 1 / 2 / span 1 / span 1;
                align-items: flex-end;

                &:not(:has(~ .ford-member-id)) {
                    grid-area: 1 / 2 / span 2 / span 1;
                    align-items: center;
                    transform: none;
                }
                @include desktop {
                    &:has(~ .ford-member-id) {
                        transform: none;
                    }
                }
            }
            .ford-member-id {
                @include desktop {
                    transform: translateY(5px);
                }
            }
            .masthead-row-1 {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-bottom: 0;
                transform: translateY(5px);
            }
            .masthead-row-2 {
                @include desktop {
                    margin-right: pToR(3);
                }
            }
        }
    }

    .featured-articles-carousel-container {
        @include tablet {
            padding-top: 0;
        }
    }

    .fmc-type--content2 {
        letter-spacing: normal;
        line-height: pToR(24);
        font-family: $ford-f1-font-regular;
        color: $fds-reimagine-dark-blue;
        font-size: pToR(16);
        margin-bottom: pToR(20);
        height: pToR(30);
        text-align: start;

        @include mobile {
            margin-bottom: pToR(40);
        }
    }
    .add-vehicle-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        @include mobile {
            align-items: flex-end;
        }

        .add-vehicle-button-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: pToR(16);

            @include mobile {
                flex-direction: column;
                margin-left: 0;
            }

            @include tablet {
                margin-left: 0;
            }

            .my-vehicles-header {
                display: flex;
                align-items: center;
                font-size: pToR(24);
                line-height: pToR(32);
                letter-spacing: normal;
                font-family: $ford-f1-font-semibold;
                color: $fds-preimagine-high-contrast;

                @include mobile {
                    font-size: pToR(20);
                    line-height: pToR(28);
                    margin-left: pToR(-24);
                    margin-bottom: pToR(13);
                }
            }
            .no-vehicles-button {
                padding: 0;

                @include mobile {
                    margin-left: 0;
                }
                @include tablet {
                    margin-left: pToR(20);
                }
                .add-vehicle-container {
                    align-items: center;
                    border: 1px solid $fds-preimagine-button-blue;
                    border-radius: pToR(400);
                    color: $fds-preimagine-button-blue;
                    display: flex;
                    font-family: FordF1Bold, sans-serif;
                    font-size: pToR(14);
                    height: pToR(40);
                    justify-content: center;
                    letter-spacing: normal;
                    line-height: pToR(20);
                    min-width: pToR(93);
                    width: -webkit-fit-content;
                    width: -moz-fit-content;
                    width: fit-content;

                    @include mobile {
                        margin: 0;
                    }

                    .add-vehicle-button-icon {
                        padding-left: pToR(8);
                    }
                    .secondary-button {
                        height: 100%;
                        display: flex;
                        align-items: center;

                        &-text {
                            padding: pToR(12) pToR(20);
                            display: flex;
                            align-items: center;
                        }
                    }
                }
            }
        }
        .display-count {
            display: flex;
            justify-content: flex-end;
            letter-spacing: normal;
            line-height: pToR(20);
            font-family: $ford-f1-font-regular;
            color: $fds-preimagine-high-contrast;
            font-size: pToR(14);
            @include mobile {
                font-size: pToR(12);
                line-height: pToR(16);
                align-self: flex-end;
            }
        }
    }
}

.dynamic-masthead__content {
    color: $fds-color--white;
    display: flex;
    flex-direction: column;

    .no-vehicles & {
        margin-top: 0;
    }

    .has-vehicles & {
        @include tablet {
            margin-top: pToR(40);
            margin-bottom: pToR(72);
        }
    }

    .fmc-type--heading1 {
        font-size: pToR(32);
        line-height: pToR(38);
        font-weight: 700;
        letter-spacing: -1px;
        max-width: 11ch;
        margin-bottom: pToR(8);

        @include tablet {
            font-size: pToR(50);
            line-height: pToR(60);
            max-width: 15ch;
        }
    }

    .fmc-type--body1 {
        font-size: pToR(14);
        line-height: pToR(24);
        font-weight: 300;
        max-width: 25ch;
        margin: 0 0 pToR(17);

        @include tablet {
            margin-bottom: pToR(22);
            max-width: 55ch;
        }
    }

    .secondary-button {
        @include mobile {
            margin-bottom: pToR(74);
        }
    }

    @include tablet {
        margin-top: pToR(-9);
    }
}

.vehicle-selector-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: pToR(10);
}

.add-vehicle-container-tabs {
    margin-left: pToR(40);
}

.user-member-id-dashboard {
    letter-spacing: pToR(1);
    padding: pToR(1) 0;
    color: $white;

    .brand-lincoln & {
        font-family: $proxima-nova-regular;
        letter-spacing: normal;
    }

    @include mobile {
        padding: pToR(9) 0 pToR(6);
    }
    @include tablet {
        padding: 10px 0 0;
    }
}

.primary-button {
    padding: 0 pToR(22.5);

    @include desktop {
        padding: 0 pToR(18.75);
    }
}

.brand-ford {
    .primary-button {
        .button-label {
            font-size: 18px;
        }
    }
}

.no-vehicles-button {
    text-align: center;

    .add-vehicle-container {
        margin-top: pToR(16);
        @include mobile {
            margin-bottom: pToR(32);
        }

        .add-vehicle-button {
            &:after {
                box-shadow: unset;
                background-color: $fds-preimagine-primary-blue;
            }

            &:hover {
                --scale-hover: unset;

                &:after {
                    background-color: #055ecb;
                }
            }

            &:focus-visible {
                outline: 1px solid #066fef;
                outline-offset: 2px;
                outline-width: 2px;
                --scale-hover: unset;
                text-decoration: none;
                width: max-content;
                border-radius: pToR(400);
            }
        }
    }

    .brand-lincoln & {
        padding: 0;
    }

    .dynamic-masthead-state & {
        align-self: flex-end;
        padding-right: 0;

        .dynamic-masthead-state__orientation--left & {
            align-self: flex-start;
            padding-left: 0;
        }

        &__orientation--right {
            align-self: flex-end;
            padding-right: 0;
        }

        @include mobile {
            .fmc-button {
                margin-bottom: pToR(34);
            }
        }
    }
}

.vehicle-tabs {
    padding-top: pToR(20);
    width: 100%;
    overflow-x: initial;

    @include mobile {
        padding-top: 0;
    }
}

.vehicle-tabs-dropdown {
    width: pToR(210);

    @include desktop {
        width: pToR(380);
        background-color: transparent;
    }

    .dropdown-button {
        background-color: transparent;
        color: white;

        &:active {
            background-color: transparent;
        }
    }

    .dropdown-items-panel {
        background-color: $white;
        color: $dark-blue;
        height: pToR(155);

        .dropdown-item {
            &.selected-item {
                color: white;
            }
        }
    }
}

.dropdown:hover .dropdown-button .vehicle-tabs-dropdown {
    background-color: white;
    color: $dark-blue;
}

.tab-container .tab {
    margin: pToR(0) pToR(40);
}

.fmc-type--heading1 {
    .vehicle-masthead-dashboard & {
        [data-brand='lincoln'] & {
            font-size: pToR(32);
            line-height: pToR(38);
            letter-spacing: normal;
        }
    }
}

.fmc-type--body1 {
    .vehicle-masthead-dashboard & {
        [data-brand='lincoln'] & {
            &.vin-tooltip-content {
                max-width: unset;
                padding-bottom: unset;
            }

            @include tablet-v5 {
                max-width: 70%;
                margin: 0 auto;
            }
        }

        &.vin-tooltip-content {
            @include mobile-v5 {
                p {
                    margin-bottom: pToR(16);
                }
            }
        }
    }
}

.fmc-type--content2 {
    .vehicle-masthead-dashboard & {
        [data-brand='lincoln'] & {
            letter-spacing: normal;
        }
    }
}
