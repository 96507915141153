@import '@styles/main.v5';
@import '@styles/common';

.greeting-wrapper {
    &:has(+ .active-orders-carousel__container) {
        margin-bottom: pToR(56);
    }
}

.fmc-carousel-indicator__button--right,
.fmc-carousel-indicator__button--left {
    span {
        font-size: 0;
    }
}

.active-orders-carousel {
    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-bottom: pToR(96);

        @include mobile {
            max-width: calc(100vw - 32px);
            margin-bottom: pToR(64);
        }

        .fmc-carousel-container {
            touch-action: pan-y;
            max-width: calc(100vw - 16px);
        }

        .fmc-carousel-indicator__container {
            button {
                &:focus-visible {
                    outline: 2px solid $fds-preimagine-primary-blue;
                    outline-offset: 2px;
                }
            }

            @include md-desktop {
                width: pToR(1359);
                z-index: 11;
            }
        }

        .view-order-history-link {
            font-family: $ford-f1-font-regular;
            color: $charcoal;
            font-size: pToR(16);
            line-height: pToR(24);
            letter-spacing: normal;
            text-decoration: underline;
            text-underline-offset: pToR(3);

            @include mobile {
                text-align: end;
            }

            &:focus-visible {
                outline: 2px solid $fds-preimagine-primary-blue;
            }
        }

        .active-orders__carousel {
            scrollbar-color: transparent transparent;
            scrollbar-width: none;

            @include tablet {
                overflow-x: hidden;
            }

            &::after {
                content: '';
                z-index: 10;
                position: absolute;
                top: 0;
                bottom: 0;
                left: pToR(1336);
                right: 0;
                background: linear-gradient(
                    90.19deg,
                    rgba(255, 255, 255, 0) -0.52%,
                    #ffffff 9.41%
                );
                width: pToR(432);
            }

            .fmc-carousel {
                -webkit-backface-visibility: hidden;
                width: 100vw;

                @include mobile {
                    width: unset;
                }
                @include md-desktop {
                    width: pToR(1440);
                }
            }

            ul {
                display: flex;
                flex-direction: row;
                list-style-type: none;
                scroll-behavior: smooth;
                scroll-snap-type: x mandatory;
                gap: pToR(16);

                @include mobile {
                    overflow-x: auto;

                    &::-webkit-scrollbar {
                        -webkit-appearance: none;
                        width: 0;
                        height: 0;
                        display: none;
                    }
                }

                li {
                    position: relative;
                    width: 100%;
                    max-width: calc(100vw - 32px);
                    scroll-snap-align: start;
                    transition: transform 325ms ease;

                    @include tablet {
                        max-width: pToR(320);
                    }

                    &:last-of-type {
                        @include mobile {
                            margin-right: pToR(400);
                        }
                    }
                }
            }
        }
    }

    &__header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-bottom: pToR(7);

        @include tablet {
            margin-bottom: pToR(13);
        }
    }

    &__header-text {
        font-family: $ford-f1-font-semibold;
        color: $fds-preimagine-high-contrast;
        font-size: pToR(20);
        line-height: pToR(28);
        letter-spacing: normal;
        text-align: start;

        @include tablet {
            font-size: pToR(24);
            line-height: pToR(32);
        }
    }
}
