@import './lincoln/fonts';
@import './lincoln/colors';
@import './lincoln/icons';

.brand-lincoln {
    font-family: $proxima-nova-regular;

    .fgx-brand-css {
        .fordMainNavigation {
            .main-nav-items.navbar-nav {
                display: block;
            }
            @include desktop {
                .container-fluid.eyebrow {
                    .eyebrow-right {
                        width: 49%;
                        text-align: right;
                    }
                    .eyebrow-left {
                        width: 50%;
                        display: inline-block;
                    }
                }
            }
        }
    }
}
