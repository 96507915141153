@import './fonts';
@import './colors';
@import './breakpoints';
@import './shadows';
@import './borders';
@import './common';
@import './main.ford.scss';
@import './main.lincoln.scss';
@import 'fds-v5-overrides/fds-base';

html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fgx-brand-css {
    .fordMainNavigation {
        .navbar.navbar-static-top {
            max-width: unset;
        }

        .main-nav-items.navbar-nav {
            display: block;
        }

        @include desktop {
            .container-fluid.eyebrow {
                .eyebrow-right {
                    width: 49%;
                    text-align: right;
                }

                .eyebrow-left {
                    width: 50%;
                    display: inline-block;
                }
            }
        }
    }

    .lincolnMainNavigation {
        -webkit-font-smoothing: antialiased;

        .navbar.navbar-default.navbar-static-top {
            max-width: unset;
        }

        .navbar-form {
            button {
                .fgx-nav-icon {
                    font-size: 1rem;

                    @include mobile {
                        font-size: 1.25rem;
                    }
                }
            }
        }

        .utility-item {
            font-size: pToR(13);
            letter-spacing: 1px;
            line-height: 1.22;

            .fgx-nav-icon {
                font-size: 1rem;

                @include mobile {
                    font-size: 1.25rem;
                }
            }

            @include mobile {
                letter-spacing: 0.5px;
            }
        }

        .navbar-toggle {
            .lines:before,
            .lines:after {
                width: 2.1875rem;
            }

            &.collapsed.has-menu-txt {
                width: 2.1875rem;
            }

            &.opened {
                .lines:before,
                .lines:after {
                    width: 2.1875rem;
                }
            }

            &:hover,
            &:focus {
                width: 2.1875rem;
            }
        }

        .fgx-fs-small .utility-item {
            font-size: pToR(16);
        }

        .mbl-arrow-icon {
            width: pToR(25);
            font-size: 1.25rem;
        }

        .iconl-chevron-toggle {
            font-size: pToR(10);
        }
    }

    .cookie-notice-overlay {
        .overlay-content {
            font-size: 1rem;
        }
    }

    .fgx-btn-content-wrap {
        .title-text {
            font-size: 16px;
        }
    }

    .disclosures-container {
        color: $dropdown-text;
        font-family: $antenna-font;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 26px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 15px;

        @include desktop {
            padding-left: 102px;
            padding-right: 103px;
        }
    }

    .globalFooter {
        .nav.navbar-nav {
            .nav.navbar-nav {
                .text {
                    font-size: 0.875rem;
                    @include desktop {
                        font-size: 1rem;
                    }
                }
            }

            .list-heading {
                padding-top: 15px !important;
                padding-bottom: 15px !important;
                @include desktop {
                    padding-top: 25px !important;
                    padding-bottom: 25px !important;
                }

                .text {
                    @include desktop {
                        font-size: 1.4rem;
                    }
                }
            }
        }
    }
}

body {
    font-family: $antenna-font;
    margin: 0 auto;
}

.block {
    display: block;
}

.none {
    display: none;
}
