@import '@styles/main';

.change-email {
    &-container {
        max-width: pToR(1240);
        width: 100%;
        margin: 0 auto;

        h1 {
            .brand-lincoln & {
                font-size: pToR(32);
            }
        }
        .thank-you-container {
            display: flex;
            justify-content: center;
            text-align: center;

            h1 {
                .brand-lincoln & {
                    font-size: pToR(32);
                }
            }
            .thank-you-description {
                max-width: 38ch;
            }
        }

        .change-email-form {
            border: 1px solid $brand-header-border;
            border-radius: pToR(3);
            box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1),
                0 10px 10px 0 rgba(0, 0, 0, 0.1);
            background-color: $brand-white;
            padding: 0 pToR(120);

            @include mobile {
                padding: 0 pToR(20);
            }

            .fmc-floating-label {
                max-width: pToR(400);

                @include mobile {
                    max-width: 100%;
                }
            }

            &__error-text {
                color: #d62d14;
                font-size: pToR(12);
                letter-spacing: 1px;
                font-family: $antenna-font;

                .brand-lincoln & {
                    color: $brand-light-error;
                    font-size: pToR(13);
                    letter-spacing: 0;
                    font-family: $proxima-nova-regular;
                }
            }

            .update-email-button-container {
                @include mobile {
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
}
