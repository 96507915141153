@import '@styles/main.v5';
@import '@styles/common';

.units-of-measurement {
    .units-of-measurement-section-title {
        display: inline-block;
        position: relative;
        color: $dark-blue;
        padding-left: 0;
        font-size: pToR(34);
        font-family: $antenna-font-condensed-light;
        letter-spacing: pToR(4);

        @include mobile {
            font-size: pToR(24);
            width: 96%;
            padding-left: pToR(15);
            margin-top: 0;
        }
    }

    .edit-button-container {
        padding-right: pToR(0);
        width: pToR(70);

        @include mobile {
            top: unset;
            padding-top: pToR(10);
        }
    }

    &-info-container {
        height: auto;

        .brand-ford & {
            margin-top: pToR(52);

            @include mobile {
                margin-top: pToR(23);
            }
            .fmc-type--heading6 {
                font-family: $ford-f1-font-medium;
                color: $fds-preimagine-dark-charcoal;
                font-size: pToR(24);
                line-height: pToR(32);

                @include mobile {
                    margin-bottom: 0;
                }
            }
            &.edit {
                .fmc-type--heading6 {
                    @include mobile {
                        margin-bottom: pToR(23);
                    }
                }
            }
            .fmc-type--body1 {
                font-family: $ford-f1-font-regular;
                color: $fds-preimagine-dark-charcoal;
                font-size: pToR(16);
                line-height: pToR(24);
                letter-spacing: normal;
            }
            .fds-layout-grid__inner {
                @include mobile {
                    grid-gap: pToR(10);
                }
            }
        }

        &.edit {
            background-color: $white;

            .brand-lincoln & {
                border: 1px solid #979797;
                border-radius: 3px;

                .fmc-check {
                    padding-bottom: pToR(34);

                    label > span {
                        letter-spacing: normal;
                    }
                }
            }

            @include mobile-v5 {
                width: 100%;
                .units-of-measurement-container {
                    display: flex;
                    flex-direction: column;
                }
            }

            &-wrapper:nth-of-type(2) {
                padding-top: 4px;
            }
            .brand-ford & {
                max-width: pToR(542);

                .fmc-check {
                    --button-diameter: 24px;
                    --button-label-gap: 8px;
                    --button-outline-thickness: 1px;
                    --button-outline-color: #6b7786;
                    --dot-color: #066fef;
                    --dot-diameter: 18px;
                    --vertical-gap: 24px;

                    input[type='radio']:checked {
                        --button-outline-color: #066fef;
                        --button-outline-thickness-checked: 1px;
                    }
                    input[type='radio']:focus-visible {
                        outline-offset: 0;
                        outline: 2px solid $fds-preimagine-primary-blue;
                    }

                    label {
                        color: $fds-preimagine-dark-text;
                        font-family: $ford-f1-font-regular;
                        font-size: pToR(14);
                        line-height: pToR(20);
                        letter-spacing: normal;
                    }
                }
            }
        }

        @include mobile-v5 {
            margin-left: auto;
            margin-right: auto;
            margin-top: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
        }
    }

    &-wrapper {
        color: $text-gray;
        align-items: baseline;

        &.edit {
            .brand-ford & {
                margin-bottom: pToR(30);
            }
            .units-of-measurement-name {
                padding-bottom: pToR(38);
            }
        }
    }

    &-container {
        width: 100%;
        height: 100%;
    }

    &-buttons-container {
        width: 100%;
        padding: pToR(36) 0 pToR(65);
        display: flex;
        justify-content: center;

        .brand-lincoln & {
            justify-content: center;
            padding-top: 0;

            @include media('<fds-xs') {
                flex-direction: column;

                .fmc-button {
                    min-width: pToR(122);
                    margin: auto auto pToR(16);
                }
            }
            @include media('>fds-xs') {
                .fmc-button {
                    margin-right: pToR(45);
                }
            }
        }
        .brand-ford & {
            justify-content: flex-end;

            .fmc-button {
                margin-right: 0;
                margin-left: pToR(24);
            }
            .fmc-text-button {
                @include ford-secondary-button-default();
            }
        }

        .primary-button {
            font-size: pToR(18);
            line-height: pToR(13);
            letter-spacing: 1px;
            font-weight: $font-weight-300;
            font-family: $antenna-font-condensed-regular;
            border-radius: pToR(20);
            @include tablet-v5 {
                margin-right: pToR(45);
            }
        }

        .secondary {
            width: pToR(77);
            text-align: left;
        }
        .units-of-measurement-cancel {
            margin-left: pToR(28);
        }
    }

    &-cancel {
        font-family: $antenna-font-condensed-regular;
        letter-spacing: pToR(1);
        font-size: pToR(18);
        line-height: pToR(13);
        color: $dark-blue;
    }

    &-cancel:hover {
        color: $light-blue;
    }
}

.hr-line {
    height: pToR(1);
    transform: scaleY(-1);
    box-shadow: none;
    margin-bottom: pToR(8);
}
.mB0 {
    margin-bottom: 0;
}
.units-of-measurement-wrapper.edit:last-of-type {
    .brand-lincoln & {
        .fmc-check {
            padding-bottom: 0;
        }
    }
}
