@import '@styles/main.scss';
.carousel-container {
    @include tablet {
        touch-action: pan-y;

        .dynamic-masthead-state__orientation--left {
            margin-left: pToR(37);
        }
    }

    #Carousel {
        @include tablet {
            width: 100vw;
            overflow-x: clip;
        }

        .brand-ford & {
            @include md-desktop {
                width: none;
            }
        }

        .brand-lincoln & {
            @include mobile {
                width: 100vw;
            }
        }

        .fmc-carousel {
            &.fmc-cards {
                list-style-type: none;
                margin: 0;
                .brand-ford & {
                    li.carousel-gradient {
                        &::before {
                            content: '';
                            z-index: 10;
                            width: 100%;
                            height: 100%;
                            display: block;
                            position: absolute;
                            background: linear-gradient(
                                90.19deg,
                                rgba(255, 255, 255, 0) -3%,
                                #ffffff 33%
                            );
                            margin-top: pToR(42);
                            transition: opacity 0.3s ease-in-out;

                            @include tablet {
                                content: '';
                                z-index: 10;
                                width: 150%;
                                height: 110%;
                                display: block;
                                position: absolute;
                                background: linear-gradient(
                                    90.19deg,
                                    rgba(255, 255, 255, 0) -0.52%,
                                    #ffffff 9.41%
                                );
                                margin-top: pToR(10);
                                transition: opacity 0.3s ease-in-out;
                            }

                            @include tablet {
                                content: '';
                                z-index: 10;
                                width: 150%;
                                height: 110%;
                                display: block;
                                position: absolute;
                                background: linear-gradient(
                                    90.19deg,
                                    rgba(255, 255, 255, 0) -0.52%,
                                    #ffffff 9.41%
                                );
                                margin-top: pToR(10);
                                transition: opacity 0.3s ease-in-out;
                            }

                            @include desktop-v5 {
                                content: '';
                                z-index: 10;
                                width: 150%;
                                height: 110%;
                                display: block;
                                position: absolute;
                                background: linear-gradient(
                                    90.19deg,
                                    rgba(255, 255, 255, 0) -0.52%,
                                    #ffffff 9.41%
                                );
                                margin-top: pToR(10);
                                transition: opacity 0.3s ease-in-out;
                            }

                            @include md-desktop {
                                content: '';
                                z-index: 10;
                                width: 30%;
                                height: 110%;
                                display: block;
                                position: absolute;
                                background: linear-gradient(
                                    90.19deg,
                                    rgba(255, 255, 255, 0) -0.52%,
                                    #ffffff 9.41%
                                );
                                margin-top: pToR(10);
                                transition: opacity 0.3s ease-in-out;
                            }
                        }

                        &.swiping {
                            &::before {
                                opacity: 0;
                                width: 0;
                            }
                        }

                        .slide__content-area {
                            transition: padding-left 0.5s linear;
                            @include tablet {
                                padding-left: 25px;
                            }
                        }
                    }
                }

                .card-container {
                    height: 100%;
                    margin-right: pToR(16);

                    .brand-ford & {
                        margin-right: pToR(16);
                    }
                    @include mobile {
                        margin-right: pToR(8);
                    }

                    @include mobile {
                        li {
                            height: 50%;
                        }
                    }

                    .brand-lincoln & {
                        width: pToR(421);

                        @include mobile {
                            width: calc(100vw - 32px);
                            max-width: pToR(421);
                        }
                    }
                }

                @include tablet {
                    margin: pToR(32) auto pToR(40);
                }
            }
        }
    }

    .fmc-carousel-indicator {
        width: fit-content;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: auto auto pToR(10);

        @include mobile {
            width: pToR(165);
            padding-top: pToR(32);
        }

        .fmc-type--body1 {
            padding: 0 pToR(15);

            .vehicle-position {
                min-width: pToR(40);
                max-width: pToR(57);
                display: inline-block;
                text-align: left;
            }
            .total-vehicle {
                width: pToR(25);
                display: inline-block;
                text-align: right;
            }
            .ofLabel {
                padding: 0 pToR(5);
            }
        }
    }
    .fmc-carousel-indicator__directional-button {
        width: pToR(40);
        height: pToR(40);
    }
}

.featured-articles-carousel-container {
    touch-action: pan-y;

    .brand-ford & {
        padding-right: 0;
        padding-left: 0;
    }

    @include tablet {
        width: 100%;

        .no-carousel-market & {
            padding-bottom: pToR(53);
        }
    }
    @include mobile {
        max-width: 100%;
        padding-bottom: pToR(20);

        .no-carousel-market & {
            padding-bottom: pToR(35);
        }
    }

    .featured-articles-carousel-title {
        font-family: $antenna-font-condensed-light;
        font-size: pToR(30);
        letter-spacing: 3px;
        color: $white;
        text-align: center;
        text-transform: uppercase;

        @include mobile {
            font-size: pToR(20);
        }

        &-name {
            @include mobile {
                display: block;
                text-align: center;
            }
        }
    }
    .feature-articles-carousel-button-add {
        .brand-ford & {
            font-family: $antenna-font-condensed-regular;
            font-size: pToR(20);
            border-radius: pToR(30);
            color: $white;
            line-height: pToR(16);
            border: 1px solid white;
            box-shadow: none;
            transition: transform 0.3s ease-in-out,
                background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
            padding: 0 pToR(34);
            height: pToR(40);
            width: unset;
            min-width: pToR(182);
            text-align: center;
            display: block;

            .fds-dot {
                display: none;
            }
        }
    }

    .featured-articles-carousel-subtitle {
        font-family: $antenna-font;
        font-size: pToR(16);
        letter-spacing: pToR(1);
        line-height: pToR(26);
        color: white;
        margin: pToR(16) auto 0;
        text-align: center;

        @include mobile {
            font-size: pToR(10);
            margin: auto;
            max-width: 30ch;

            .brand-ford & {
                padding-top: pToR(13);
            }
        }

        @include tablet {
            max-width: calc(100% - 200px);
        }

        @include desktop {
            max-width: pToR(510.5);
        }
    }

    .featured-articles-carousel-controls {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        max-width: fit-content;

        & > span {
            color: $text-gray;
            font-size: pToR(16);
            padding: 0 pToR(25);
        }

        .primary-button {
            .brand-lincoln & {
                background-color: inherit;
            }
        }

        .featured-articles-carousel-button {
            border-radius: 2px;
            padding: 0;
            width: 40px;
            height: 40px;
            .brand-lincoln & {
                border-radius: 30px;
                box-sizing: border-box;
                padding: 0;
                width: 30px;
                height: 30px;
                border: 1px solid $brand-border;
                color: $brand-border;
                cursor: pointer;
            }

            &.disabled {
                background-color: $disabled-dark;
                .brand-lincoln & {
                    color: $gray-text;
                    border: 1px solid $gray-text;
                    background-color: inherit;
                    cursor: auto;
                }
            }

            .underline-overlay {
                .brand-lincoln & {
                    border-bottom: none;
                }
            }

            .button-label {
                display: none;
            }

            .fds-arrow {
                &.unfilled {
                    &::before {
                        font-size: pToR(24);
                        vertical-align: middle;

                        .brand-lincoln & {
                            font-size: pToR(12);
                        }

                        &:first-of-type {
                            content: $fds-chevron-left-unfilled;
                        }
                        &:nth-of-type(2) {
                            content: $fds-chevron-right-unfilled;
                        }
                    }
                }
            }
        }
        .featured-articles-carousel-slider-indicator {
            border: none;
            background: transparent;
            margin: 0;
            padding: 0;

            .featured-articles-carousel-slider-indicator-dash {
                border-bottom: 3px solid #a9a9a9;
                margin: 16px 5px 21px 7px;
                padding: 0 7px;
                &.active {
                    border-bottom: 3px solid $dark-blue;
                }
            }
        }
    }
}

.featured-articles-carousel-container-margin {
    margin-bottom: pToR(-135);
    position: relative;

    .dynamic-masthead-state & {
        @include tablet {
            margin-bottom: pToR(-210);
        }
    }

    .no-carousel-market & {
        margin-bottom: unset;

        @include mobile {
            padding-bottom: pToR(35);
        }
    }
}

.brand-lincoln {
    .featured-articles-carousel-container {
        .featured-articles-carousel-subtitle {
            font-family: $proxima-nova-regular;
            font-size: pToR(18);
            line-height: pToR(26);
            letter-spacing: 0;

            @include mobile {
                font-size: pToR(16);
                line-height: pToR(20);
            }
        }
        .featured-articles-carousel {
            .featured-articles-carousel-cards {
                #add-vehicle-card {
                    .card-description {
                        font-family: $proxima-nova-regular;
                        line-height: pToR(24);
                        color: $brand-secondary;
                    }
                    .card-title {
                        font-family: $proxima-nova-regular;
                        font-size: pToR(30);
                        letter-spacing: 3.13px;
                        line-height: pToR(30);
                        color: $brand-secondary;
                    }
                }

                .card {
                    .card-body {
                        .titleList {
                            .blackOne {
                                .card-title {
                                    font-family: $proxima-nova-regular;
                                    font-size: pToR(30);
                                    letter-spacing: 3.13px;
                                    line-height: pToR(30);
                                    color: $brand-secondary;
                                }
                            }
                        }

                        .card-title-secondary {
                            font-family: $proxima-nova-bold;
                            font-size: pToR(17);
                            letter-spacing: pToR(2);
                            line-height: pToR(27);
                            color: $brand-secondary;
                        }

                        .card-description {
                            font-family: $proxima-nova-regular;
                            font-size: pToR(16);
                            line-height: pToR(24);
                            color: $brand-secondary;
                        }
                    }
                }
            }
        }
        .featured-articles-carousel-controls {
            font-family: $proxima-nova-semibold;
        }
    }
}
.fmc-carousel-container {
    .brand-ford & {
        &.multiple-vehicles-state {
            margin-bottom: pToR(64);
        }
    }
}
.fmc-carousel-indicator__container {
    position: absolute;
    top: 40%;
    width: calc(1440px - 50px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    pointer-events: none;

    @include md-desktop {
        width: calc(1440px - 50px);
    }

    &--left,
    &--right {
        position: relative;
        z-index: 100;
    }

    button {
        transition: 500ms all ease-in;
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
        margin-right: pToR(30);

        &:hover {
            svg path:first-of-type {
                fill: rgba(0, 0, 0, 0.7);
            }
        }
    }
    button[disabled] {
        transition: 500ms all ease-out;
        opacity: 0;
        visibility: hidden;
    }
}
.fmc-carousel-indicator__container--mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: fit-content;
    position: absolute;
    top: pToR(200);
    right: pToR(100);

    .fmc-carousel-indicator__dots {
        &:last-of-type {
            margin-right: 0;
        }
    }
    .multiple-vehicles-state & {
        @include mobile {
            top: pToR(168);
        }
    }

    .dot {
        &--active {
            height: pToR(8);
            width: pToR(24);
            transition: 300ms width ease;
            border-radius: pToR(20);
            background-color: $fds-preimagine-button-blue;
            margin-right: pToR(8);
        }
        &--inactive {
            height: pToR(8);
            width: pToR(8);
            transition: 300ms width ease;
            border-radius: pToR(20);
            margin-right: pToR(8);
            background-color: $fds-preimagine-neutral-fill;

            &.dot--small {
                height: pToR(4);
                width: pToR(4);
                border-radius: pToR(20);
                transition: 300ms all ease;
            }
        }
    }
}
