@import '@styles/main.v5';

.preferred-dealer__view {
    .fmc-carousel-container {
        max-width: calc(100vw - 40px);
        margin-left: pToR(16);

        .eu-mobile-carousel {
            max-width: pToR(510);
            position: relative;
            height: 100%;
            scrollbar-color: transparent transparent;
            scrollbar-width: none;
            overflow-x: visible;

            .carousel__dealer-list {
                list-style-type: none;
                touch-action: pan-y;

                li {
                    position: relative;
                    flex: 0 0 100%;
                    width: 100%;
                    border: 1px solid #b2b2b2;
                    border-radius: pToR(8);
                    max-width: calc(100vw - 90px);

                    .dealer-info-box-wrapper {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .fmc-carousel-indicator__container--mobile {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        position: relative;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: pToR(16) auto auto;
        top: unset;
        right: 0;
        left: 0;
        bottom: 0;
    }
}
