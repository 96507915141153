@import '@styles/main.scss';

.delete-account {
    &-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f4f4f4;
        @include mobile {
            height: pToR(500);
        }
    }

    &-title {
        &.fds-p--b-2 {
            padding-bottom: pToR(4);

            .brand-lincoln & {
                font-size: pToR(32);
            }
        }
    }

    &-description {
        &.fds-p--t-1 {
            max-width: 64ch;
            padding-top: pToR(9);
            @include mobile {
                padding-left: pToR(15);
                padding-right: pToR(15);
            }

            a {
                text-decoration: none !important;
            }
        }
    }
}
