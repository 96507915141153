@import '@styles/main.scss';
@import '@styles/main.v5';

.vehicle-portal {
    .vehicle-portal-header {
        padding-top: 16px;
        letter-spacing: normal;

        @include desktop {
            font-size: pToR(40);
            line-height: pToR(48);
            letter-spacing: normal;
        }

        &.eu-style-greetings {
            @include mobile {
                padding-top: pToR(36);
            }
        }

        @include mobile {
            .brand-ford & {
                padding-top: 6px;
                max-width: 19ch;
                margin: auto;
                margin-bottom: 10px;
            }
        }
    }

    .vehicle-portal-cta {
        padding-top: 16px;
        @include desktop {
            padding: 48px 21.5px 0;
        }

        &.no-image {
            padding: 0;
        }

        .vin {
            margin: 16px auto 24px;
            width: 100%;
            max-width: $max-content-width;
        }

        .vehicle-name {
            font-family: $antenna-font-condensed-light;
            text-transform: uppercase;
            font-size: pToR(30);
            letter-spacing: 3px;
            margin: 0;

            @include desktop {
                line-height: 58px;
                max-width: inherit;
            }
        }

        .cta-button {
            margin: 24px auto;
        }
    }

    .vehicle-image {
        width: auto;
        height: 208px;
        margin-top: -50px;
        display: block;
        margin-right: auto;
        margin-left: auto;

        @include tablet {
            height: 475px;
            margin-top: -75px;
        }
        @include desktop {
            margin-top: -75px;
        }
    }

    .vehicle-image.three-fourths {
        height: 475px;

        @include mobile {
            height: 205px;
        }
    }

    .vehicle-image.unauth {
        height: 475px;
        margin-top: 100px;

        @include mobile {
            height: 205px;
        }
    }

    .info-text {
        flex-direction: column;
        display: inline-flex;
        width: 100%;
        justify-content: center;
        padding-bottom: 20px;
        @include desktop {
            flex-direction: row;
            padding-bottom: 70px;
        }
    }

    .vin-auth {
        border-left: none;
        font-size: pToR(10);
        border-left-color: white;
        font-family: $antenna-font;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .vehicle-masthead-dashboard {
        height: 100%;
        padding: 0 pToR(56) pToR(20);
        margin: auto;
        width: 100%;

        @include mobile {
            padding: 0 pToR(16) 0;
        }

        .brand-lincoln & {
            max-width: pToR(1240);
            padding: 0 pToR(56) pToR(50);
            margin-bottom: pToR(-76);

            @include mobile {
                padding: unset;
            }
        }

        @include mobile-v5 {
            margin-bottom: pToR(-67);
            padding: 0 pToR(16) 0;

            &.no-vehicle-state {
                margin: 0;
            }
        }

        &.no-indicators-desktop {
            margin-bottom: pToR(-28);

            .smart-tile-grid {
                margin-top: pToR(87);
            }
        }

        .tab {
            color: $dark-blue;
            height: 100%;
            max-height: inherit;
        }

        .tab-active {
            color: $dark-blue;
            border-bottom-color: $dark-blue;
        }

        .primary-button {
            &.outline {
                color: $dark-blue;
                border: 1px solid $dark-blue;

                &:hover {
                    color: $light-blue;
                    border: 1px solid $light-blue;
                }
            }
        }

        .dropdown-current-item {
            color: $dark-blue;
        }

        .vehicle-tabs-dropdown
            .dropdown-items-panel
            .dropdown-item.selected-item {
            color: $dark-blue;
        }
    }

    .masthead-background {
        margin-top: -175px;
        margin-bottom: 25px;

        @include desktop {
            margin-top: -180px;
            margin-bottom: 50px;
        }
    }

    .masthead-background-empty {
        margin-bottom: -300px;
        margin-top: -25px;
    }

    .masthead-background.three-fourths {
        margin-top: -100px;
        margin-bottom: 200px;

        @include mobile {
            margin-top: -175px;
            margin-bottom: 150px;
        }
    }

    .masthead-background-model-name {
        height: 300px;
        color: #ffffff;
        font-family: $antenna-font-condensed-black;
        font-weight: 900;
        letter-spacing: 3.85px;
        line-height: 350px;
        text-shadow: 0 2px 2px rgba(0, 0, 0, 0.1), 0 5px 5px rgba(0, 0, 0, 0.1),
            0 9px 9px rgba(0, 0, 0, 0.1), 0 18px 18px rgba(0, 0, 0, 0.15);
        text-align: center;
    }

    .name-image-available {
        margin-bottom: -125px;
    }

    .three-fourths {
        margin-bottom: -200px;
        text-align: center;

        @include mobile {
            margin-bottom: -150px;
        }
    }

    .side-view {
        margin-bottom: -320px;

        @include mobile {
            margin-bottom: -200px;
        }
    }

    .unauth {
        margin-bottom: -250px;

        @include mobile {
            margin-bottom: -200px;
        }
    }

    .top {
        background-color: white;
    }

    .dashboard {
        margin-top: -105px;
        background-color: transparent;
    }
}

.brand-lincoln {
    .vehicle-portal {
        .vehicle-portal-header {
            font-family: $proxima-nova-regular;
            font-size: pToR(44);
            line-height: pToR(42);
            letter-spacing: 2.75px;

            @include mobile {
                font-size: pToR(32);
                line-height: pToR(34);
                letter-spacing: 2px;
            }
        }
    }
}

.image-card__section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    max-width: 100%;
    gap: pToR(16);
    padding: 0 pToR(56);

    &--divider {
        box-shadow: none;
        max-width: calc(100% - 112px);
        width: 100%;

        @include mobile {
            max-width: calc(100% - 32px);
        }
    }

    &.one-card {
        .image-card__container {
            @include tablet {
                max-width: pToR(655);
            }
        }
    }

    &.two-cards,
    &.one-card {
        .image-card__container {
            border-radius: pToR(8);

            @include tablet {
                height: pToR(400);
            }
        }
    }

    &.three-cards {
        @include media('<=935px') {
            flex-wrap: wrap;
        }
    }

    @include mobile {
        flex-direction: column;
        padding: 0 pToR(16);
        margin-bottom: pToR(64);

        .image-card__container {
            width: 100%;
            max-width: pToR(356);
        }
    }

    @include tablet {
        margin-bottom: pToR(96);
    }

    @include media('>935px') {
        max-width: pToR(1440);

        .image-card__container {
            width: 100%;
        }
    }
}
