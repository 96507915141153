@import '~@ford/ford-design-system/src/scss/variables/colors';

//This is from the InVision design (as of 4/1/2020)
$border-grey: #979797;
$disabled-dropdown: #c4c3c3;
$disabled-dark: #6e6e6e;
$dropdown-text: #4d4d4d;
$eyebrow-background: #ccc;
$darkgrey: #666666;
$error-text: #d62d14;
$background-color: #f3f3f3;
$gray-text: #919191;
//Color added for accessibility (story 182)
$search-text: $fds-color--primary;

//Official FDS colors
$highlight-blue: #0672b3;
$light-skyblue: #2d96cd;
$light-blue: #2861a4;
$light-skyblue: #2d96cd;
$hover-blue: #0276b3;
$link-blue: #0078a0;
$link-blue-and-bold: #008cba;
$border-grey: #a7a7a7;
$fds-color-surface: #f4f4f4;

//Official Owner Colors
$dark-blue: #00095b;
$fds-reimagine-dark-blue: #00142e;
$fds-v4-primary: #102b4e;
$fds-v4-secondary: #2861a4;
$gray: #f2f2f1;
$brand-while-smoke: #efefef;
$lightgrey: #e5e5e5;
$quicklink-textactive: #555;
$eugrey: #1b394e;
$white: #ffffff;
$whitesmoke: #f5f5f5;
$grey: #808080;
$black: #090a09;
$help-text: #949494;
$eyebrow-color: #666;
$eyebrow-grey: #f7f7f7;
$quicklink-text: #2d96cd;
$eyebrow-text: #0e2a4f;
$box-shadow-gray: $help-text;
$border-blue: #062b51;
$grabber-blue: #1700f4;
$background-lightgray: #f1f0f0;

// HiFi Colors
$text-gray: #4d4d4d;
$medium-gray: #eeeeee;

$good: #008200;
$moderate: #ba4e00;
$severe: #d62d14;
$card-bg: #f2f2f2;
$error: #d62d0a;

// FDS v5 Colors
$fds-v5-primary: #00095b;
$fds-grayed-out: #9c9c9c;
$fds-order-status-gray-background: #f2f2f2;

// Ford Motion Colors
$charcoal: #333333;

// Preimagine/Reimagine Colors
$fds-preimagine-dark-text: #1f3047;
$fds-preimagine-gray-border: #cccccc;
$fds-preimagine-primary-blue: #066fef;
$fds-preimagine-background-gray: #f5f6f7;
$fds-preimagine-darker-gray-border: #d1d5d9;
$fds-preimagine-primary-button-hover: #055ecb;
$fds-preimagine-dark-charcoal: #010203;
$fds-preimagine-button-blue: #066fef;
$fds-preimagine-neutral-fill: #9ca3ad;
$fds-preimagine-danger-subtle: #f9e8ea;
$fds-preimagine-lincoln-primary: #324047;
$fds-preimagine-ford-error: #bf152c;
$fds-preimagine-disabled-background-color: #e6e8ea;
$fds-preimagine-disabled-text-color: #6b7786;
$fds-reimagine-disabled-background-color: #e5e5e5;
$fds-reimagine-disabled-text-color: #808080;
$fds-reimagine-secondary-blue: #0562d2;
$fds-reimagine-caution-red: #fcf2e6;
$fds-preimagine-high-contrast: #000000;
$fds-preimagine-success-subtitle: #e5f3e6;
$fds-preimagine-link-hover-blue: #044ea7;
$fds-reimagine-link-hover-blue: #044ea7;
$fds-reimagine-divider-color: #b2b2b2;
$fds-reimagine-accessories-card-background-color: #fafafa;
$fds-reimagine-order-card-divider-color: #d8dadd;
$fds-reimagine-dark-gray: #666666;
