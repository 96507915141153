$lincoln-proxima-font-directory: './proxima-nova';
$lincoln-miller-font-directory: './miller';

/* proxima nova regular */
@font-face {
    font-family: 'proxima-nova-regular';
    src: url('#{$lincoln-proxima-font-directory}/ProximaNova-Reg-webfont.eot');
    src: url('#{$lincoln-proxima-font-directory}/ProximaNova-Reg-webfont.eot?#iefix')
            format('embedded-opentype'),
        url('#{$lincoln-proxima-font-directory}/ProximaNova-Reg-webfont.woff')
            format('woff'),
        url('#{$lincoln-proxima-font-directory}/ProximaNova-Reg-webfont.ttf')
            format('truetype'),
        url('#{$lincoln-proxima-font-directory}/ProximaNova-Reg-webfont.svg#ProximaNovaRgRegular')
            format('svg');
    font-weight: normal;
    font-style: normal;
}

/* proxima nova light */
@font-face {
    font-family: 'proxima-nova-light';
    src: url('#{$lincoln-proxima-font-directory}/ProximaNova-Light-webfont.eot');
    src: url('#{$lincoln-proxima-font-directory}/ProximaNova-Light-webfont.eot?#iefix')
            format('embedded-opentype'),
        url('#{$lincoln-proxima-font-directory}/ProximaNova-Light-webfont.woff')
            format('woff'),
        url('#{$lincoln-proxima-font-directory}/ProximaNova-Light-webfont.ttf')
            format('truetype'),
        url('#{$lincoln-proxima-font-directory}/ProximaNova-Light-webfont.svg#ProximaNovaLight')
            format('svg');
    font-weight: normal;
    font-style: normal;
}

/* proxima nova semibold */
@font-face {
    font-family: 'proximanova-sbold';
    src: url('#{$lincoln-proxima-font-directory}/ProximaNova-Sbold-webfont.eot');
    src: url('#{$lincoln-proxima-font-directory}/ProximaNova-Sbold-webfont.eot?#iefix')
            format('embedded-opentype'),
        url('#{$lincoln-proxima-font-directory}/ProximaNova-Sbold-webfont.woff')
            format('woff'),
        url('#{$lincoln-proxima-font-directory}/ProximaNova-Sbold-webfont.ttf')
            format('truetype'),
        url('#{$lincoln-proxima-font-directory}/ProximaNova-Sbold-webfont.svg#ProximaNova-Sbold')
            format('svg');
    font-weight: normal;
    font-style: normal;
}

/* proxima nova bold */
@font-face {
    font-family: 'proxima-nova-bold';
    src: url('#{$lincoln-proxima-font-directory}/ProximaNova-Bold-webfont.eot');
    src: url('#{$lincoln-proxima-font-directory}/ProximaNova-Bold-webfont.eot?#iefix')
            format('embedded-opentype'),
        url('#{$lincoln-proxima-font-directory}/ProximaNova-Bold-webfont.woff')
            format('woff'),
        url('#{$lincoln-proxima-font-directory}/ProximaNova-Bold-webfont.ttf')
            format('truetype'),
        url('#{$lincoln-proxima-font-directory}/ProximaNova-Bold-webfont.svg#ProximaNovaRgBold')
            format('svg');
    font-weight: normal;
    font-style: normal;
}

/* lincoln miller semi-bold */
@font-face {
    font-family: 'lincolnmillerbsemibold';
    src: url('#{$lincoln-miller-font-directory}/lincolnmillerb-semibold-webfont.eot');
    src: url('#{$lincoln-miller-font-directory}/lincolnmillerb-semibold-webfont.eot?#iefix')
            format('embedded-opentype'),
        url('#{$lincoln-miller-font-directory}/lincolnmillerb-semibold-webfont.woff')
            format('woff'),
        url('#{$lincoln-miller-font-directory}/lincolnmillerb-semibold-webfont.ttf')
            format('truetype'),
        url('#{$lincoln-miller-font-directory}/lincolnmillerb-semibold-webfont.svg#lincolnmillerbsemibold')
            format('svg');
    font-weight: normal;
    font-style: normal;
}

/* lincoln miller light italic */
@font-face {
    font-family: 'lincolnmillerblightitalic';
    src: url('#{$lincoln-miller-font-directory}/lincolnmillerb-lightitalic-webfont.eot');
    src: url('#{$lincoln-miller-font-directory}/lincolnmillerb-lightitalic-webfont.eot?#iefix')
            format('embedded-opentype'),
        url('#{$lincoln-miller-font-directory}/lincolnmillerb-lightitalic-webfont.woff')
            format('woff'),
        url('#{$lincoln-miller-font-directory}/lincolnmillerb-lightitalic-webfont.ttf')
            format('truetype'),
        url('#{$lincoln-miller-font-directory}/lincolnmillerb-lightitalic-webfont.svg#lincolnmillerblightitalic')
            format('svg');
    font-weight: normal;
    font-style: normal;
}

/* lincoln miller light */
@font-face {
    font-family: 'lincolnmillerblight';
    src: url('#{$lincoln-miller-font-directory}/LincolnMillerBWGL-Light.eot');
    src: url('#{$lincoln-miller-font-directory}/LincolnMillerBWGL-Light.eot?#iefix')
            format('embedded-opentype'),
        url('#{$lincoln-miller-font-directory}/LincolnMillerBWGL-Light.woff')
            format('woff'),
        url('#{$lincoln-miller-font-directory}/LincolnMillerBWGL-Light.ttf')
            format('truetype'),
        url('#{$lincoln-miller-font-directory}/LincolnMillerBWGL-Light.svg#lincolnmillerblight')
            format('svg');
    font-weight: normal;
    font-style: normal;
}

/* lincoln miller roman */
@font-face {
    font-family: 'lincolnmillerbroman';
    src: url('#{$lincoln-miller-font-directory}/LincolnMillerBWGL-Roman.eot');
    src: url('#{$lincoln-miller-font-directory}/LincolnMillerBWGL-Roman.eot?#iefix')
            format('embedded-opentype'),
        url('#{$lincoln-miller-font-directory}/LincolnMillerBWGL-Roman.woff')
            format('woff'),
        url('#{$lincoln-miller-font-directory}/LincolnMillerBWGL-Roman.ttf')
            format('truetype'),
        url('#{$lincoln-miller-font-directory}/LincolnMillerBWGL-Roman.svg#lincolnmillerbroman')
            format('svg');
    font-weight: normal;
    font-style: normal;
}

//TYPEFACES
$proxima-nova-light: 'proxima-nova-light', Arial, Helvetica, sans-serif;
$proxima-nova-regular: 'proxima-nova-regular', Arial, Helvetica, sans-serif;
$proxima-nova-bold: 'proxima-nova-bold', Arial, Helvetica, sans-serif;
$proxima-nova-semibold: 'proximanova-sbold', Arial, Helvetica, sans-serif;

$lincoln-miller-b-semibold: 'lincolnmillerbsemibold', Arial, Helvetica,
    sans-serif;
$lincoln-millerb-lightitalic: 'lincolnmillerblightitalic', Arial, Helvetica,
    sans-serif;
$lincoln-millerb-light: 'lincolnmillerblight', Arial, Helvetica, sans-serif;
$lincoln-millerb-roman: 'lincolnmillerbroman', Arial, Helvetica, sans-serif;
