@import '../../../styles/main.v5';

.voi-banner__container {
    width: 100%;
    margin: auto auto pToR(64);
    background-color: white;

    .voi-banner__inner-container {
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: pToR(16);
        grid-auto-rows: min-content;
        width: 100%;
        max-width: pToR(1440);
        background-color: white;
        padding: pToR(40) pToR(24);
        margin: auto;
        justify-items: center;

        @include tablet-v5 {
            padding: pToR(58) pToR(64) pToR(48);
        }
        @include desktop {
            grid-template-columns: 1fr 1fr;
            justify-items: unset;
        }

        .voi-banner__header-text {
            color: $fds-color--primary;
            font-family: $antenna-font-light;
            font-size: pToR(24);
            line-height: pToR(28);
            text-align: center;
            letter-spacing: normal;

            @include tablet-v5 {
                font-size: pToR(28);
                line-height: pToR(36);
                max-width: 18ch;
            }

            @include desktop {
                font-size: pToR(32);
                line-height: pToR(40);
                text-align: start;
                max-width: 28ch;
                grid-area: 1 / 1 / 1 / 1;
                margin-bottom: pToR(24);
            }

            @include md-desktop {
                max-width: 28ch;
            }
        }
        .voi-banner__vehicle-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: pToR(32);
            font-family: $antenna-font-regular;

            @include desktop {
                align-items: unset;
                grid-area: 2 / 1 / 2 / 1;
            }
            .voi-banner__ymm {
                font-size: pToR(20);
                line-height: pToR(24);
                font-weight: 500;
                letter-spacing: normal;
                color: $charcoal;
                margin-bottom: pToR(8);
                font-family: $antenna-font-medium;

                @include tablet-v5 {
                    font-size: pToR(22);
                    line-height: pToR(28);
                }

                @include desktop {
                    font-size: pToR(24);
                    text-align: start;
                }
            }
            .voi-banner__dealer-note,
            .voi-banner__vin {
                font-size: pToR(16);
                line-height: pToR(24);
                letter-spacing: 0.01em;
                color: $charcoal;
                text-align: center;
                font-family: $antenna-font-light;

                span {
                    font-weight: bold;
                }

                @include desktop {
                    text-align: start;
                }
            }
        }

        .voi-banner__buttons-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: max-content;

            .fmc-button {
                font-size: pToR(18);
                line-height: pToR(24);
                letter-spacing: 1px;
                font-family: $antenna-font-condensed-regular;
                margin-bottom: pToR(30);
                min-width: pToR(225);

                @include desktop {
                    grid-area: 3 / 1 / 3 / 1;
                }
            }
            .fmc-button:not(.fmc-button--no-shadow):after {
                box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.08),
                    0px 10px 10px 0px rgba(0, 0, 0, 0.08);
            }
            .secondary-button {
                @include desktop {
                    grid-area: 4 / 1 / 4 / 1;
                }
            }
            .fmc-text-button {
                margin-bottom: 9px;
            }
        }

        .voi-banner__carousel-container {
            grid-area: 2 / 1 / 2 / 1;
            height: pToR(163);
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: white;
            margin-bottom: pToR(20);

            @include desktop {
                grid-area: 1 / 2 / 5 / 2;
                height: 100%;
            }
        }

        .voi-banner__vehicle-image {
            max-height: 100%;
            @include desktop {
                height: auto;
                width: 100%;
            }
        }
    }
}
