@import '@styles/main.scss';

.eu-map-container {
    height: pToR(610);

    .eu-map-marker-position {
        bottom: pToR(6);
        left: 0;
        position: relative;
    }
}
