@import '@styles/main.scss';

.modal {
    &.preferred-dealer-consent-modal {
        .brand-ford & {
            .modal-content {
                .modal-body {
                    margin-top: unset;
                    flex-direction: unset;
                    width: 100%;
                    text-align: left;
                    padding: pToR(24) pToR(40);
                    margin-bottom: unset;

                    .preferred-dealer-consent-message {
                        width: 100%;
                        font-family: $ford-f1-font-medium;
                        font-size: pToR(24);
                        font-weight: 500;
                        font-style: normal;
                        line-height: pToR(32);
                        letter-spacing: normal;
                        color: $fds-preimagine-dark-text;
                        text-align: left;
                        margin-bottom: pToR(23);
                    }

                    .vehicle-section {
                        margin-top: pToR(16);
                        color: $fds-preimagine-dark-text;
                        font-family: $ford-f1-font-regular;
                        font-size: pToR(12);
                        font-style: normal;
                        font-weight: 400;
                        line-height: pToR(16);
                        letter-spacing: normal;
                    }

                    .vehicle-details-section {
                        color: $fds-preimagine-dark-text;
                        font-family: $ford-f1-font-regular;
                        font-size: pToR(14);
                        font-style: normal;
                        font-weight: 700;
                        line-height: pToR(20);
                        letter-spacing: normal;
                    }

                    p {
                        font-family: $ford-f1-font-regular;
                        font-size: pToR(14);
                        font-style: normal;
                        font-weight: 400;
                        line-height: pToR(20);
                        letter-spacing: normal;
                    }
                }

                .modal-footer {
                    padding: pToR(16) pToR(40);
                    @include desktop {
                        margin-bottom: unset;
                    }

                    .primary-button {
                        margin-right: 0;
                    }

                    .secondary-button {
                        margin-left: pToR(30);

                        .fmc-text-button {
                            margin-left: unset;
                        }
                    }
                }
            }
        }
        .brand-lincoln & {
            .modal-content {
                background-color: #f4f4f4;

                .modal-body {
                    align-items: center;
                    margin-top: pToR(50);

                    .preferred-dealer-consent-message {
                        width: 100%;
                        font-size: pToR(24);
                        font-weight: 500;
                        font-style: normal;
                        line-height: pToR(32);
                        letter-spacing: normal;
                        color: $fds-preimagine-lincoln-primary;
                        font-family: $proxima-nova-regular;
                        text-align: center;
                        margin-bottom: pToR(23);
                        text-transform: uppercase;
                    }

                    p {
                        color: $fds-preimagine-lincoln-primary;
                        font-family: $proxima-nova-regular;
                        font-size: pToR(16);
                        line-height: pToR(24);
                        letter-spacing: normal;
                        margin-top: pToR(12);
                        margin-bottom: pToR(14);
                        text-align: center;
                    }
                }
                .modal-footer {
                    flex-direction: column;
                    align-items: center;

                    .fmc-button {
                        width: fit-content;
                    }
                    .secondary-button {
                        margin-top: pToR(40);
                    }
                }
            }
        }
    }
}
