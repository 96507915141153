@import '@styles/main.v5';

.slide__content-area {
    .ymm {
        .order-slide & {
            margin-bottom: pToR(4);
        }
    }
    .order-and-reservation-number,
    .order-number-and-vin-box p {
        color: $fds-preimagine-dark-charcoal;
        font-family: $ford-f1-font-regular;
        font-size: pToR(16);
        line-height: pToR(24);
        letter-spacing: normal;
        margin-bottom: pToR(4);
    }
    .order-status-box {
        color: $fds-preimagine-dark-charcoal;
        background-color: $fds-order-status-gray-background;
        max-width: pToR(654);
        width: 100%;
        height: pToR(72);
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: pToR(8);
        padding-left: pToR(16);
        margin-top: pToR(15);

        @include tablet {
            padding-left: pToR(24);
        }

        p:first-of-type {
            font-family: $ford-f1-font-regular;
            font-size: pToR(12);
            line-height: pToR(16);
            letter-spacing: normal;
        }
        p:nth-of-type(2) {
            font-family: $ford-f1-font-bold;
            font-size: pToR(16);
            line-height: pToR(24);
            letter-spacing: normal;
        }
    }
}
