@import '@styles/main.scss';

.disclosures-button-section {
    background-color: $white;
    .disclosures-container {
        display: inline-block;
        text-align: center;
        margin: 1.25rem auto 1rem;
        width: 100%;
        background-color: $white;
        .disclosures-button {
            display: inline-block;
            max-width: 100%;
            border: 1px solid transparent;

            .fds-arrow {
                color: $black;
            }
            .disclosures-button-link {
                display: inline-flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                background: $white;
                margin-bottom: 26px;
                padding-top: 10px;
                padding-bottom: 10px;
                border: 0;
                max-width: 100%;
                cursor: pointer;
                text-align: center;
                font-family: $antenna-font;
                text-decoration: none;
                color: $dark-blue;
                font-size: 16px;
                padding-right: 1rem;

                .fds-chevron-wrap {
                    padding-left: 0.5rem;
                }
            }
            .disclosures-button-link:focus {
                outline: 2px solid $black;
            }
        }
    }
}
.disclosures-button-link:focus {
    outline: 0px;
}
.disclosure-content {
    padding: 1rem;
    text-align: left;
    padding-top: 40px;
    padding-bottom: 40px;
    max-height: 240px;
    overflow-y: scroll;
    font-family: inherit;
    font-weight: lighter;
    font-size: 16px;
    background: $white;

    @include desktop {
        max-width: 90rem;
        padding: 2rem 1rem;
        margin: 0 auto;
    }

    ol {
        margin: 2rem;
        padding-left: 130px;
        padding-top: 10px;
        li {
            color: $text-gray;
            font-family: inherit;
            font-size: 16px;
            letter-spacing: 1px;
            line-height: 26px;
            padding-bottom: 1rem;
        }
    }
    p {
        color: $text-gray;
        font-family: inherit;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 26px;
        padding-left: 102px;
        padding-right: 103px;
        padding-bottom: 15px;
        b {
            color: $text-gray;
            font-family: inherit;
            font-size: 28px;
            font-weight: lighter;
            letter-spacing: 2px;
            line-height: 26px;
            text-transform: uppercase;
            padding-bottom: 29px;
        }
    }
}
