@import '../../../styles/main.scss';

.brand-ford {
    .help-router {
        .help-router-starting-pop-up {
            background-color: $white;
            border-radius: pToR(10);
            bottom: pToR(175);
            box-shadow: 0 20px 20px 0 rgba($black, 0.1),
                0 10px 10px 0 rgba($black, 0.1);
            display: flex;
            margin-left: pToR(20);
            max-width: pToR(450);
            padding: pToR(15) pToR(50) pToR(15) pToR(30);
            position: fixed;
            right: pToR(15);
            top: auto;
            z-index: 12;
            color: $text-gray;
            line-height: pToR(28);

            &::after {
                background-color: white;
                bottom: pToR(-15);
                box-shadow: 20px 20px 20px 0 rgba(black, 0.1),
                    10px 10px 10px 0 rgba(black, 0.1);
                content: '';
                height: pToR(30);
                position: absolute;
                right: pToR(10);
                transform: rotate(51deg) skew(11deg);
                width: pToR(30);
            }
            .close-icon {
                height: pToR(14);
                width: pToR(14);
                background-color: transparent;
                border: 0;
                padding: 0;
                position: absolute;
                right: pToR(10);
                top: pToR(10);
            }
        }
        .help-router-analog-cta {
            background: $fds-v4-primary;
            color: $white;
            border-radius: 50%;
            border-width: pToR(1);
            border-color: $white;
            border-style: solid;
            height: pToR(40);
            width: fit-content;
            cursor: pointer;
            position: fixed;
            right: pToR(20);
            z-index: 998;
            bottom: pToR(100);
            font-family: $ford-f1-font-regular;
            font-size: pToR(18);
            box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1),
                0 20px 20px 0 rgba(0, 0, 0, 0.1);
            justify-content: center;
            align-items: center;
            display: inline-block;
            white-space: nowrap;
            p {
                display: none;
            }
            img {
                height: pToR(30);
                width: pToR(30);
                padding-top: pToR(3);
                padding-right: pToR(1);
                margin: 0 pToR(4);
            }
            @include desktop {
                display: inline-flex;
                border-radius: pToR(999);
                width: fit-content;
                height: pToR(44);
                border-width: pToR(1);
                border-color: $white;
                border-style: solid;
                p {
                    display: block;
                    padding-left: pToR(15);
                    margin: 0 pToR(10) 0 0;
                }
                img {
                    padding-top: 0;
                    height: pToR(30);
                    width: pToR(30);
                    margin-left: pToR(10);
                }
            }
            @include tablet {
                display: inline-flex;
                transition: all 0.3s;
                img {
                    padding-top: 0;
                    padding-bottom: pToR(1);
                    transition: all 0.3s;
                }
                p {
                    transition: all 1s;
                }
                &:hover {
                    background: $grabber-blue;
                    border-radius: pToR(999);
                    width: fit-content;
                    height: pToR(44);
                    border-width: pToR(1);
                    border-color: $white;
                    border-style: solid;
                    p {
                        overflow: hidden;
                        display: block;
                        padding-left: pToR(15);
                        margin: 0 pToR(10) 0 0;
                    }
                    img {
                        padding-top: 0;
                        height: pToR(30);
                        width: pToR(30);
                    }
                }
            }
        }

        .help-router-container {
            .visually-hidden {
                clip: rect(0, 0, 0, 0);
                border: 0;
                height: pToR(1);
                margin: pToR(-1);
                overflow: hidden;
                padding: 0;
                position: absolute;
                white-space: nowrap;
                width: pToR(1);
            }
            visibility: hidden;
            background: $background-lightgray;
            height: 100%;
            width: 100%;
            right: 0;
            bottom: 0;
            position: fixed;
            z-index: 2003;
            @include mobile {
                top: pToR(63);
            }
            @include tablet {
                height: pToR(620);
                width: pToR(370);
            }
            .help-router-title {
                color: $white;
                background: $fds-v4-primary;
                height: pToR(63);
                width: 100%;
                border-bottom: $white solid 1px;
                align-items: center;
                padding: pToR(20) pToR(25) pToR(13);
                font-family: $ford-f1-font-regular;
                font-size: pToR(16);
                display: inline-flex;
                justify-content: space-between;
                .help-router-cta-close {
                    background-color: transparent;
                    border: 0;
                    cursor: pointer;
                    height: pToR(21);
                    width: pToR(17);
                    &::before {
                        background-color: $white;
                        content: '';
                        display: block;
                        height: pToR(1);
                        width: pToR(17);
                    }
                }
                .help-router-cta-close:focus {
                    outline: 1px solid white;
                    outline-offset: pToR(2);
                    border-radius: pToR(2);
                }
            }

            .help-router-container-inner {
                height: pToR(246);
                width: 100%;
                padding: pToR(0.5) pToR(25);
                background: $fds-v4-primary;

                .chatbot-header {
                    color: $white;
                    font-size: pToR(16);
                    margin: pToR(10) 0;
                    font-family: $ford-f1-font-regular;
                    line-height: 1.63;
                }
                .return-to-chatbot-cta {
                    width: 100%;
                    background: $white;
                    border-radius: pToR(20);
                    color: $fds-v4-primary;
                    font-size: pToR(18);
                    height: pToR(40);
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    padding: pToR(10) pToR(32);
                    cursor: pointer;
                    border-style: none;
                    font-family: $ford-f1-font-regular;
                }
                .chatbot-container {
                    overflow: scroll;
                    top: pToR(63);
                    left: 0;
                    background: $white;
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    padding: pToR(13) pToR(25) 0;

                    .chatbot-error {
                        position: relative;
                        color: $error;
                        font-size: pToR(12);
                        font-family: $ford-f1-font-regular;
                        line-height: 1.5;
                        display: flex;
                        justify-content: right;
                        margin-top: pToR(6);
                        margin-bottom: pToR(6);

                        .chatbot-button-error-retry {
                            color: $error;
                            font-size: pToR(12);
                            font-family: $ford-f1-font-regular;
                            padding: 0 pToR(6) 0 pToR(6);
                            text-decoration: underline;
                            line-height: 1.5;
                        }
                    }

                    .chatbot-user {
                        border: 1px solid $fds-v4-primary;
                        color: $text-gray;
                        display: table;
                        margin-left: auto;
                        margin-right: 0;
                        margin-top: pToR(7);
                        border-radius: pToR(999);
                        padding: pToR(10) pToR(14);

                        .chatbot-user-message {
                            font-family: $ford-f1-font-regular;
                            font-size: pToR(12);
                            line-height: 1.5;
                        }
                    }

                    .chatbot-bot {
                        background: $fds-color-surface;
                        color: $text-gray;
                        display: table;
                        margin: pToR(15) pToR(60) pToR(8) 0;
                        border-radius: pToR(30);
                        padding: pToR(10) pToR(14);

                        .chatbot-bot-message {
                            border-radius: pToR(20);
                            font-family: $ford-f1-font-regular;
                            font-size: pToR(12);
                            line-height: 1.5;
                            max-width: pToR(260);

                            .topicLink {
                                background-color: transparent;
                                border: 0;
                                color: $fds-reimagine-dark-blue;
                                padding: pToR(6) 0;
                                text-align: left;
                                text-decoration: underline;
                            }

                            img {
                                width: 100%;
                                height: auto;
                            }

                            ul {
                                padding-inline-start: pToR(40);
                                margin-block-start: 1em;
                                margin-block-end: 1em;
                            }

                            h1,
                            h2,
                            h3,
                            h4,
                            h5,
                            h6 {
                                font-size: pToR(12);
                                margin: pToR(3.2) 0 0 0;
                            }

                            p {
                                line-height: 2;
                                margin: 0;
                            }
                        }

                        a {
                            color: $fds-v4-primary;
                        }
                    }

                    .chatbot__message--typing {
                        display: inline-flex;
                        flex-direction: row;
                        padding: pToR(16) pToR(12);

                        .chatbot__typing--dot {
                            animation: wave 1.4s linear infinite;
                            color: $fds-color-surface;
                            border-radius: 50%;
                            display: inline-block;
                            height: pToR(8);
                            margin-right: pToR(8);
                            width: pToR(8);

                            &:last-child {
                                margin-right: 0;
                            }

                            &:nth-child(2) {
                                animation-delay: 0.2s;
                            }

                            &:nth-child(3) {
                                animation-delay: 0.4s;
                            }
                        }
                    }
                    .chatbot__quick-replies--vertical {
                        background-color: $white;
                        border: 1px solid $border-grey;
                        border-radius: pToR(12);
                        display: flex;
                        flex-direction: column;
                        max-width: fit-content;
                        overflow: hidden;

                        .chatbot__quick-replies--item-vertical {
                            margin: 0;
                            border-bottom: 1px solid $border-grey;
                            .chatbot-replies-button-vertical {
                                background-color: transparent;
                                border: 0;
                                color: $fds-v4-primary;
                                font-size: pToR(12);
                                line-height: 1.15;
                                padding: pToR(12) pToR(16);
                                text-align: left;
                                width: 100%;

                                &:hover {
                                    background: $grabber-blue;
                                    color: $white;
                                    cursor: pointer;
                                }
                            }
                        }
                    }

                    .chatbot__quick-replies--horizontal {
                        display: flex;
                        flex-wrap: wrap;
                        list-style: none;
                        margin-left: pToR(-8);
                        margin-right: pToR(-8);
                        max-width: none;

                        .chatbot__quick-replies--item-horizontal {
                            margin: pToR(8);

                            .chatbot-replies-button-horizontal {
                                border: 1px solid $fds-v4-primary;
                                border-radius: pToR(20);
                                background-color: transparent;
                                color: $fds-v4-primary;
                                font-size: pToR(12);
                                line-height: 1.15;
                                padding: pToR(12) pToR(16);
                                text-align: left;
                                width: 100%;

                                &:hover {
                                    background: $grabber-blue;
                                    color: $white;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
                .chatbot__history {
                    padding-bottom: pToR(210);
                    @include tablet {
                        padding-bottom: pToR(140);
                    }
                }
                .chatbot-menu {
                    background-color: $white;
                    border-top: 1px solid $border-grey;
                    bottom: 0;
                    left: 0;
                    display: flex;
                    align-items: flex-start;
                    padding: pToR(15) pToR(24) pToR(13) pToR(16);
                    position: fixed;
                    width: 100%;
                    height: pToR(140);
                    @include tablet {
                        height: pToR(79);
                    }
                    .chatbot-menu-button {
                        align-items: center;
                        color: $fds-v4-primary;
                        font-size: pToR(10);
                        margin-right: pToR(14);
                        padding: 0;
                        cursor: pointer;
                        .menu-icon {
                            margin: 0 0 pToR(10);
                        }
                    }
                }
            }
        }
        .help-router-popular-links {
            color: $white;
            font-family: $ford-f1-font-regular;

            p {
                margin: pToR(8) 0;
                font-size: pToR(12);
            }
            p:focus {
                outline: 1px solid white;
                outline-offset: pToR(2);
                border-radius: pToR(2);
            }
            a {
                color: $white;
                text-decoration: underline;
                line-height: 1.75;
                &:hover {
                    text-decoration: none;
                    cursor: pointer;
                }
            }
            a:focus {
                outline: 1px solid white;
                outline-offset: pToR(2);
                border-radius: pToR(2);
            }
        }

        p {
            letter-spacing: normal;
        }
        a {
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
        .primary-button {
            margin: 0 auto;
        }
        .chatbot__button--show-more {
            font-family: $ford-f1-font-regular;
            color: $fds-v4-primary;
            display: block;
            font-size: pToR(12);
            line-height: 1.5;
            margin-top: pToR(10);
            padding: 0;
        }
        .chatbot__snackbar {
            font-size: pToR(11);
            animation: snackbar-fade-in 0.3s;
            background-color: $fds-v4-primary;
            border: 1px solid $white;
            border-radius: pToR(3);
            bottom: pToR(70);
            @include tablet {
                bottom: pToR(30);
            }
            box-shadow: 0 10px 10px 0 rgba($black, 0.1),
                0 20px 20px 0 rgba($black, 0.1),
                0 30px 30px 0 rgba($black, 0.15);
            color: $white;
            display: none;
            font-family: $ford-f1-font-regular;
            justify-content: space-between;
            left: pToR(8);
            line-height: 1.45;
            padding: pToR(20) pToR(6) pToR(19) pToR(10);
            position: fixed;
            right: pToR(8);
            z-index: 1;

            &.chatbot__snackbar--open {
                display: flex;
            }

            .chatbot__button--snackbar-action {
                font-size: pToR(11);
                text-transform: uppercase;
            }
            .chatbot__button {
                background: transparent;
                border: 0;
            }
        }
    }

    .help-router-sticky-cta-inner {
        display: flex;
        flex-direction: column;
        padding: pToR(10) pToR(25) pToR(15);
        gap: pToR(6);
        align-items: flex-start;
        letter-spacing: none;

        .help-router-inner-links-wrapper {
            background-color: $white;
            padding: pToR(16) pToR(20);
            display: flex;
            align-items: center;
            width: 100%;

            img {
                height: pToR(25);
                width: pToR(25);
                padding-top: 0;
                margin-right: pToR(15);
            }

            .help-router-inner-link {
                color: $fds-v4-primary;
                font-family: $ford-f1-font-regular;
                font-size: pToR(18);
                line-height: 0.72;
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                letter-spacing: normal;
                &:hover {
                    color: $grabber-blue;
                    text-decoration: underline;
                }
            }
            a {
                letter-spacing: normal;
            }
            p {
                letter-spacing: none;
            }
        }

        .livechat {
            margin: 0 auto;
            .LPMcontainer {
                width: pToR(
                    180
                ) !important; //override container width to fit in the helprouter
                .LPMimage {
                    width: 100%;
                    top: 0 !important; //override image position
                    left: 0 !important; //override image position
                }
            }
        }
        .ly-parent-olv2 {
            //override sophus chat container styles
            align-self: center;
            margin-top: pToR(23);
        }
    }
    .help-router-container.open {
        animation: slideup;
        animation-fill-mode: forwards;
        animation-duration: 1s;
        visibility: unset;
    }

    .help-router-container.closed {
        animation: closeOut;
        animation-fill-mode: forwards;
        animation-duration: 1s;
    }

    .background.open {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.7;
        background: $fds-preimagine-high-contrast none;
        z-index: 2002;
        overflow-y: hidden;
    }
    .background.closed {
        visibility: hidden;
    }
    .no-scroll {
        height: 100%;
        overflow: hidden;
    }
    @keyframes slideup {
        0% {
            transform: translateY(100%);
        }
        100% {
            transform: translateY(0);
            transition: ease;
        }
    }
    @keyframes closeOut {
        0% {
            transform: translateY(0);
            opacity: 1;
        }
        100% {
            transform: translateY(100%);
            transition: ease;
        }
    }
    @keyframes wave {
        0% {
            animation-timing-function: linear;
            transform: initial;
        }

        14.28% {
            animation-timing-function: ease;
            transform: initial;
        }

        21.42% {
            background-color: $disabled-dark;
            transform: translateY(-2px);
        }

        28.57%,
        100% {
            animation-timing-function: linear;
            background-color: $fds-grayed-out;
            transform: initial;
        }
    }
}
