@import '@styles/main.scss';

.search-input-group {
    width: 100%;
    padding: 0 0.5rem;
    &.active {
        border: 1px solid $hover-blue;
    }
    .search-icon-submit {
        display: none;
        color: gray;
        position: relative;
        top: 0.8rem;
        right: 1.5rem;
        font-size: 2rem;
        font-weight: bold;

        @include tablet {
            right: -0.5rem;
        }
    }
    input {
        background-color: #f2f2f2;
        letter-spacing: 3px;
        float: left;
        font-size: 1.5rem;
        height: 100%;
        width: 85%;
        outline: none;
        border: unset;
        min-height: pToR(60);

        @include mobile {
            font-size: 1rem;
        }
        @include tablet {
            width: 90%;
        }
    }
}
