@import '@styles/main.v5';

.smart-tile-account {
    .icon {
        height: pToR(24);
        width: pToR(24);

        .brand-lincoln & {
            height: pToR(70);
            width: pToR(70);
            margin: pToR(47) auto pToR(25);
        }

        @include mobile-v5 {
            .brand-lincoln & {
                height: pToR(60);
                width: pToR(60);
                margin: pToR(32) auto 0;
            }
        }
    }

    .fmc-type--heading6 {
        @include mobile-v5 {
            display: none;
        }
        @include tablet-v5 {
            margin-bottom: 17px;

            .brand-lincoln & {
                font-size: 24px;
                line-height: 32px;
                letter-spacing: 2.5px;
                margin-bottom: 10px;
            }
        }
    }
    .fmc-type--subtitle1 {
        .brand-ford & {
            margin-bottom: unset;
        }
        @include tablet-v5 {
            display: none;
        }
    }
    .fmc-type--body1 {
        .brand-lincoln & {
            @include mobile-v5 {
                display: none;
            }
        }
    }
}
