@import '@styles/main.scss';

.error-page {
    &-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: pToR(780);
        background: #f4f4f4;
        @include mobile {
            height: pToR(500);
        }
    }

    &-title {
        .brand-lincoln & {
            font-size: pToR(32);
        }
    }

    &-description {
        max-width: 64ch;
        @include mobile {
            padding-left: pToR(15);
            padding-right: pToR(15);
        }

        a {
            text-decoration: none !important;
        }
    }
}
