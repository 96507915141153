@import '@styles/main';

.eu-sidebar-container {
    background-color: $eyebrow-grey;
    display: flex;
    flex-direction: row;
    width: 100%;
    z-index: 999;
    flex-wrap: nowrap;
    font-size: 1rem;
    position: fixed;
    top: pToR(70);
    color: $eyebrow-grey;
    line-height: 1.56;
    letter-spacing: 1.25px;
    font-family: inherit;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    height: 100%;

    ul {
        margin: 0;
        list-style: none;
        padding-left: 15px;
        font-size: 0.1rem;

        li {
            list-style-type: none;
            text-align: left;

            span {
                font-size: pToR(15);
            }
        }
    }

    .eu-sidebar-menu-container {
        margin-top: 1px;
        width: 100%;
        text-align: left;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        overflow-x: hidden;

        &.eu-sidebar-minimized {
            width: 1rem;
        }

        li {
            margin-left: 0.5rem;
            min-height: pToR(52);
            display: flex;
            flex-direction: column;

            &.eu-unselected-sidebar-menu-item {
                border-right: 0;
            }

            &.eu-selected-sidebar-menu-item {
                border-right: 0;
            }
        }

        .eu-sidebar-menu-item1 {
            font: inherit;
            border-top: 0.5px solid $lightgrey;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            min-height: unset;
            height: pToR(44);
            letter-spacing: 1.25px;
            padding-left: 0.075rem;
            text-align: left;
            font-size: 0.69rem;
        }

        .eu-sidebar-menu-item {
            font: inherit;
            border-top: 0.5px solid $lightgrey;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            outline: none;
            align-items: center;
            text-transform: uppercase;
            height: pToR(52);
            line-height: 4.56;
            width: 100%;
            text-align: left;
            font-size: 0.9rem;
            font-weight: 500;
            color: $fds-v4-primary;
            letter-spacing: 1.35px;
            font-family: $antenna-font-condensed-medium;

            a {
                padding: 10.5px 0 18.5px 6px;
                font-weight: bold;
                color: $fds-v4-primary;
                line-height: 4.56;
                height: 3.5rem;
                font-size: 0.9rem;
                letter-spacing: 1.25px;
            }
            .eu-sidebar-menu-item-text {
                width: 100%;
                font-size: pToR(16);
            }
            .eu-sidebar-menu-item-text-active {
                width: 100%;
                color: $light-blue;
            }

            .chevron-icon-container {
                flex-grow: 1;
                margin-right: 1rem;

                .drawer-open-chevron {
                    transform: rotate(270deg);
                    float: right;
                    position: relative;
                }
            }
        }
    }
}
.show-nav {
    display: block;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    max-width: 100%;
    height: 100%;
    transition: max-height ease 0.6s;

    .eu-main-nav-title {
        color: $dropdown-text;
        font-size: 0.8rem;
        display: block;
        margin-top: -5px;
        padding: pToR(7) 0 0;

        span {
            font-family: $antenna-font-condensed-light;
            padding-bottom: pToR(12);
        }

        li ul li {
            padding-bottom: pToR(17);
            min-height: unset;

            .category-items-links {
                text-transform: none;
            }
        }

        &:last-child {
            border-bottom: 0.5px solid $eyebrow-grey;
            box-shadow: inset 0 -11px 8px -10px $eyebrow-background;
            transition: max-height 0.6s;
        }
    }
}

.show-nav-last {
    display: block;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    width: 500px;
    border-bottom: 0.5px solid $eyebrow-grey;
    box-shadow: inset 0 -11px 8px -10px $eyebrow-background;
    transition: max-height 0.6s;
}

.hide-me {
    display: none;
    transition: max-height ease 0.6s;
}

a .right-content {
    padding: 0;
}

.show-nav-quick {
    display: block;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    width: 500px;
}
.show-nav-quick:last-child {
    display: block;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    width: 500px;
    border-bottom: 0.5px solid $eyebrow-grey;
    box-shadow: inset 0 -11px 8px -10px $eyebrow-background;
    transition: max-height 0.6s;
}
