@import '@styles/main.v5';

#side-drawer-portal {
    position: fixed;
    z-index: 2200;
    height: 100vh;
    width: 100vw;
    top: 0;
    right: 0;
    background-color: white;
    transform: translateX(100%);
    transition: 325ms transform ease-in-out;

    @include tablet {
        width: pToR(600);
    }

    &:has(> .side-drawer__container) {
        transform: translateX(0);
    }

    &.closed {
        transform: translateX(100%);
    }

    .side-drawer {
        &__container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100vw;
            height: 100%;
            background-color: white;
            position: relative;
            z-index: 2300;
            text-align: center;
            padding: 0 pToR(20);

            @include tablet {
                width: pToR(600);
            }

            .children {
                font-size: pToR(24);
                color: $charcoal;
                font-family: $ford-f1-font-medium;
                letter-spacing: normal;
            }

            .close-button {
                position: absolute;
                top: pToR(16);
                right: pToR(16);
                padding: pToR(4);

                &:focus-visible {
                    outline: 2px solid black;
                }
            }
        }
        &__content {
            display: flex;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
}

#side-drawer-backdrop {
    opacity: 0;
    transition: 325ms opacity cubic-bezier(0.42, 0, 0.58, 1);

    &.open {
        opacity: 1;
        z-index: 2100;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
    }
}
