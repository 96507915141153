@import '@styles/main.scss';

.modal {
    &.create-return-modal {
        .modal-content {
            .modal-body {
                flex-direction: column;
                margin-bottom: pToR(8);
                @include mobile-v5 {
                    margin-bottom: pToR(10);
                    flex-direction: column;
                    padding: 0;
                }
                @include tablet-v5 {
                    flex-direction: column;
                    margin-top: pToR(51);
                    padding: 0;
                }

                .fmc-type--heading6,
                h2 {
                    font-size: pToR(24);
                    line-height: pToR(29);
                    font-weight: $fds-weight--heading6;
                    letter-spacing: 0;
                    margin-bottom: pToR(16);

                    .brand-ford & {
                        font-family: $ford-f1-font-medium;
                        font-size: pToR(24);
                        font-style: normal;
                        line-height: pToR(32);
                        letter-spacing: normal;
                        color: $fds-preimagine-dark-text;
                        text-align: left;
                    }
                }

                .fmc-type--body2 {
                    .brand-ford & {
                        font-family: $ford-f1-font-regular;
                        font-size: pToR(16);
                        font-style: normal;
                        line-height: pToR(24);
                        letter-spacing: normal;
                        color: $fds-preimagine-dark-text;
                        text-align: left;
                    }
                }

                .brand-ford & {
                    margin-top: unset;
                    flex-direction: unset;
                    width: 85%;
                    text-align: left;
                }
                .order-details-item__container {
                    display: block;
                    padding-top: pToR(24);
                    padding-bottom: pToR(8);
                    .item__image {
                        @include mobile {
                            width: pToR(96);
                            height: pToR(96);
                        }
                    }
                }
            }

            .modal-footer {
                padding-top: pToR(48);
                @include mobile-v5 {
                    padding-top: pToR(50);
                }
                @include tablet-v5 {
                    margin-bottom: pToR(17);
                }

                .primary-button {
                    margin-right: 0;
                }

                .secondary-button {
                    margin-left: 0;

                    .brand-lincoln & {
                        @include mobile-v5 {
                            margin-left: unset;
                        }
                    }

                    .fmc-text-button {
                        margin-left: unset;
                    }
                }

                .brand-ford & {
                    margin-bottom: unset;
                }
            }
        }
    }
}
