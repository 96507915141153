@import '@styles/main.v5';

.dynamic-dropdown-container {
    width: 100%;
    position: relative;
    margin-bottom: pToR(30);

    .dynamic-dropdown-field-label {
        color: $fds-preimagine-dark-text;
        font-family: $ford-f1-font-regular;
        font-size: pToR(14);
        line-height: pToR(20);
        letter-spacing: normal;
        display: block;
        margin-bottom: pToR(8);
    }

    .dynamic-dropdown-input-group {
        position: relative;

        .fmc-input {
            outline: none;
            font-family: $ford-f1-font-regular;
            height: pToR(40);
            color: $fds-preimagine-dark-text;
            letter-spacing: normal;
            border-radius: pToR(8);

            &::placeholder {
                color: $fds-preimagine-dark-text;
                font-family: $ford-f1-font-regular;
                font-size: pToR(14);
                letter-spacing: normal;
            }
        }

        .invisible-text {
            color: transparent;
        }

        .clear-button {
            position: absolute;
            top: pToR(14);
            z-index: 6;
            display: inline-block;
            width: pToR(12);
            right: pToR(62);

            &:focus {
                outline: 1px solid;
                outline-offset: 3px;
            }

            .fds-icon {
                font-size: pToR(12);
                font-weight: 700;
            }

            @include mobile-v5 {
                right: pToR(30);
            }
        }

        .menu-toggle {
            position: absolute;
            right: pToR(12);
            top: pToR(14);
            height: pToR(16);
            transition: all 0.3s;

            &.open {
                transform: rotate(180deg);
            }

            span:nth-of-type(2) {
                color: transparent;
            }

            @include mobile-v5 {
                display: none;
            }
        }

        .selected-country {
            position: absolute;
            top: 0;
            left: 0;
            background: transparent;
            max-height: 100%;

            &-text {
                display: inline-block;
                color: $fds-preimagine-dark-text;
                letter-spacing: normal;
                font-family: $ford-f1-font-regular;
                font-size: pToR(14);
            }
        }
    }

    .dynamic-dropdown-results {
        .country-list {
            position: absolute;
            display: block;
            list-style-type: none;
            width: 100%;
            height: pToR(250);
            z-index: 5;
            background-color: white;
            overflow-y: auto;
            border: 1px solid $border-grey;
            border-top: none;

            .country-option {
                color: $fds-preimagine-dark-text;
                font-family: $ford-f1-font-regular;
                font-size: pToR(14);
                letter-spacing: normal;

                &:hover,
                &:focus {
                    cursor: pointer;
                    background-color: $disabled-dropdown;
                }
            }
        }
    }

    .country-option,
    .selected-country {
        display: flex;
        align-items: center;
        padding: pToR(12);

        .flag-icon {
            width: pToR(32);
            margin-right: pToR(8);
        }
    }
}
