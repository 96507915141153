@import '@styles/main';

.dealer-info-box-container {
    max-height: pToR(330);
    overflow-y: auto;
    display: flex;

    @include mobile {
        max-height: pToR(310);
        max-width: 100%;
    }
    /* Customize the scrollbar */
    &::-webkit-scrollbar {
        width: pToR(3);
        height: pToR(5);
    }

    &::-webkit-scrollbar-track {
        background-color: lightgray;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $quicklink-textactive;
        border-radius: pToR(10);
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: $quicklink-textactive;
    }
    .dealer-info-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        @include tablet {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: pToR(12);
            align-items: center;
        }
        @include mobile {
            flex-direction: column;
            align-items: flex-start;
        }

        .dealer-info {
            flex-grow: 1;
            width: 100%;

            .dealer-name {
                color: $fds-preimagine-primary-blue;
                font-family: $ford-f1-font-bold;
                font-size: pToR(16);
                line-height: pToR(24);
                letter-spacing: normal;
            }

            .dealer-address {
                padding-top: pToR(15);
                color: $darkgrey;
                font-family: $ford-f1-font-regular;
                font-size: pToR(12);
                line-height: pToR(18);
                letter-spacing: normal;
            }
            .dealer-distance-label {
                padding-top: pToR(5);
                padding-bottom: pToR(10);
                color: $darkgrey;
                letter-spacing: normal;

                .distance-label-text {
                    margin-right: pToR(5);
                    font-family: $ford-f1-font-regular;
                    font-size: pToR(12);
                    line-height: pToR(18);
                }
                .distance-value {
                    width: pToR(60);
                    color: $darkgrey;
                    font-family: $ford-f1-font-regular;
                    font-size: pToR(12);
                    line-height: pToR(18);
                }
            }

            .dealer-earliest-available-slot {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                background-color: $fds-v4-primary;
                color: $fds-color--white;
                font-family: $ford-f1-font-regular;
                font-size: pToR(11);
                line-height: pToR(21);
                text-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
                padding: 0 pToR(10);
                border-radius: 2px;

                .earliest-slot-label-text {
                    margin-right: pToR(5);
                }
            }

            .dealer-services-container {
                padding-top: pToR(15);
                padding-bottom: pToR(15);
                display: flex;
                flex-wrap: wrap;
                max-width: pToR(255);
                justify-content: space-between;

                .dealer-service-item {
                    display: flex;
                    align-items: center;
                    margin-bottom: pToR(5);
                    margin-right: pToR(20);

                    .dealer-service-image {
                        width: pToR(20);
                        height: pToR(20);
                        margin-right: pToR(5);
                        vertical-align: middle;
                    }

                    .dealer-service-text {
                        color: $darkgrey;
                        font-family: $ford-f1-font-regular;
                        font-size: pToR(12);
                        line-height: pToR(21);
                    }
                }
            }
            .dealer-rating-container {
                display: flex;
                align-items: center;

                .dealer-star-rating {
                    font-size: pToR(24);
                    color: gold;
                }

                .dealer-number-rating {
                    font-size: pToR(18);
                    margin-left: pToR(10);
                }

                .dealer-review-rating {
                    font-size: pToR(14);
                    color: $grabber-blue;
                    text-decoration: none;
                    margin-left: pToR(10);
                }
            }
        }
        .dealer-actions {
            display: flex;

            @include mobile {
                max-width: 100%;
                flex-direction: column;
                margin-top: pToR(15);
            }
            @include tablet {
                padding: pToR(16) 0;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
            }

            .individual-dealer {
                &__container {
                    border: 1px solid #c5c5c5;
                    border-radius: pToR(8);
                    padding: pToR(24);

                    @include mobile {
                        padding-bottom: pToR(22);
                    }

                    .brand-lincoln & {
                        border-radius: pToR(4);
                    }
                }
                &__header {
                    color: #0068d0;
                    font-family: $ford-f1-font-regular;
                    font-size: pToR(20);
                    line-height: pToR(24);
                    letter-spacing: normal;
                    margin-bottom: pToR(20);

                    .brand-lincoln & {
                        color: $fds-preimagine-lincoln-primary;
                        font-family: $proxima-nova-regular;
                        text-transform: uppercase;
                    }
                }
                &__address,
                &__phone-number {
                    color: $fds-preimagine-dark-charcoal;
                    font-family: $ford-f1-font-regular;
                    font-size: pToR(14);
                    line-height: pToR(24);
                    letter-spacing: normal;

                    .brand-lincoln & {
                        color: $fds-preimagine-lincoln-primary;
                        font-family: $proxima-nova-regular;
                    }
                }
                &__my-dealer-button {
                    min-width: pToR(170);
                    height: pToR(56);
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: 0;
                    padding: 0 pToR(15);
                    white-space: nowrap;

                    .brand-ford & {
                        color: #00142e;
                        border: 1px solid #b2b2b2;
                        border-radius: pToR(8);
                        font-family: $ford-f1-font-bold;
                        font-size: pToR(14);
                        line-height: pToR(20);

                        &:hover {
                            background-color: #fafafa;
                        }

                        &:focus-visible {
                            outline: 2px solid black;
                            outline-offset: 1px;
                        }
                        &.is-my-dealer {
                            border: 2px solid $fds-preimagine-primary-blue;

                            &:focus-visible {
                                outline: 2px solid black;
                                outline-offset: 1px;
                            }
                        }
                    }

                    .brand-lincoln & {
                        color: $fds-preimagine-lincoln-primary;
                        font-family: $proxima-nova-bold;
                        font-size: pToR(14);
                        line-height: pToR(20);
                        border-radius: pToR(4);
                        border: 1px solid #d1d5d9;

                        &.is-my-dealer {
                            border: 2px solid $fds-preimagine-lincoln-primary;
                        }

                        &:focus-visible {
                            outline: 2px solid $fds-preimagine-lincoln-primary;
                        }
                    }

                    span {
                        &:last-of-type {
                            margin-left: pToR(8);
                        }
                    }
                }
            }
            .secondary-button {
                display: flex;
                height: pToR(40);
                width: fit-content;
                margin-top: pToR(24);

                .dealer-website-button {
                    display: flex;
                    height: 100%;
                    color: $fds-preimagine-primary-blue;
                    font-family: $ford-f1-font-bold;
                    background-color: white;
                    border: 1px solid $fds-preimagine-primary-blue;
                    width: fit-content;
                    border-radius: pToR(400);
                    padding: pToR(12) pToR(24);
                    text-align: center;
                    font-size: pToR(14);
                    line-height: pToR(20);
                    letter-spacing: normal;

                    &:hover {
                        color: $fds-preimagine-primary-button-hover;
                        border-color: $fds-preimagine-primary-button-hover;
                    }
                    &:focus {
                        outline: 2px solid $fds-preimagine-primary-blue;
                        outline-offset: 2px;
                    }

                    .brand-lincoln & {
                        display: flex;
                        align-items: center;
                        height: pToR(50);
                        color: white;
                        background-color: $fds-preimagine-lincoln-primary;
                        border-radius: 0;
                        font-family: $proxima-nova-regular;
                        font-size: pToR(16);
                        line-height: pToR(20);
                        padding: pToR(8) pToR(20);
                        border: 0;
                        position: relative;
                        z-index: 1;

                        &:focus {
                            outline: 2px solid $fds-preimagine-lincoln-primary;
                        }
                        &:hover {
                            .lincoln-underline-button-overlay {
                                @include lincoln-underline-overlay();
                            }
                        }
                    }
                    .secondary-button-text {
                        display: inline-flex;
                        align-items: center;
                    }
                }
            }
        }
    }
}
