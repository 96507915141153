@import '@styles/main.v5';
@import '@styles/common';

.individual-dealer {
    &__container {
        border: 1px solid #c5c5c5;
        border-radius: pToR(8);
        padding: pToR(24);

        @include mobile {
            padding-bottom: pToR(22);
        }

        .brand-lincoln & {
            border-radius: pToR(4);
        }

        .secondary-button {
            display: flex;
            height: pToR(40);
            width: fit-content;
            margin-top: pToR(24);

            .dealer-website-button {
                display: flex;
                height: 100%;
                color: $fds-preimagine-primary-blue;
                font-family: $ford-f1-font-bold;
                background-color: white;
                border: 1px solid $fds-preimagine-primary-blue;
                width: fit-content;
                border-radius: pToR(400);
                padding: pToR(12) pToR(24);
                text-align: center;
                font-size: pToR(14);
                line-height: pToR(20);
                letter-spacing: normal;

                &:hover {
                    color: $fds-preimagine-primary-button-hover;
                    border-color: $fds-preimagine-primary-button-hover;
                }
                &:focus {
                    outline: 2px solid $fds-preimagine-primary-blue;
                    outline-offset: 2px;
                }

                .brand-lincoln & {
                    display: flex;
                    align-items: center;
                    height: pToR(50);
                    color: white;
                    background-color: $fds-preimagine-lincoln-primary;
                    border-radius: 0;
                    font-family: $proxima-nova-regular;
                    font-size: pToR(16);
                    line-height: pToR(20);
                    padding: pToR(8) pToR(20);
                    border: 0;
                    position: relative;
                    z-index: 1;

                    &:focus {
                        outline: 2px solid $fds-preimagine-lincoln-primary;
                    }
                    &:hover {
                        .lincoln-underline-button-overlay {
                            @include lincoln-underline-overlay();
                        }
                    }
                }
                .secondary-button-text {
                    display: inline-flex;
                    align-items: center;
                }
            }
        }
    }
    &__header {
        color: #0068d0;
        font-family: $ford-f1-font-regular;
        font-size: pToR(20);
        line-height: pToR(24);
        letter-spacing: normal;
        margin-bottom: pToR(20);

        .brand-lincoln & {
            color: $fds-preimagine-lincoln-primary;
            font-family: $proxima-nova-regular;
            text-transform: uppercase;
        }
    }
    &__address,
    &__phone-number {
        color: $fds-preimagine-dark-charcoal;
        font-family: $ford-f1-font-regular;
        font-size: pToR(14);
        line-height: pToR(24);
        letter-spacing: normal;

        .brand-lincoln & {
            color: $fds-preimagine-lincoln-primary;
            font-family: $proxima-nova-regular;
        }
    }
    &__my-dealer-button {
        min-width: pToR(164);
        height: pToR(56);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: pToR(17);
        padding: 0 pToR(15);

        .brand-ford & {
            color: #00142e;
            border: 1px solid #b2b2b2;
            border-radius: 8px;
            font-family: $ford-f1-font-bold;
            font-size: pToR(14);
            line-height: pToR(20);

            &:hover {
                background-color: #fafafa;
            }

            &:focus-visible {
                outline: 2px solid black;
                outline-offset: 1px;
            }
            &.is-my-dealer {
                border: 2px solid $fds-preimagine-primary-blue;

                &:focus-visible {
                    outline: 2px solid black;
                    outline-offset: 1px;
                }
            }
        }

        .brand-lincoln & {
            color: $fds-preimagine-lincoln-primary;
            font-family: $proxima-nova-bold;
            font-size: pToR(14);
            line-height: pToR(20);
            border-radius: pToR(4);
            border: 1px solid #d1d5d9;

            &.is-my-dealer {
                border: 2px solid $fds-preimagine-lincoln-primary;
            }

            &:focus-visible {
                outline: 2px solid $fds-preimagine-lincoln-primary;
            }
        }

        span {
            &:last-of-type {
                margin-left: pToR(8);
            }
        }
    }
}
