@import '@styles/main';

.breadcrumbs {
    font-family: $ford-f1-font-regular;
    color: $charcoal;
    font-size: pToR(14);
    letter-spacing: normal;
    line-height: pToR(20);
    padding: pToR(16) 0 pToR(50);
    .brand-lincoln & {
        font-family: $proxima-nova-regular;
        color: $brand-mild-gray1;
    }
    @include mobile {
        padding-bottom: pToR(24);
    }
    @include tablet {
        padding-bottom: pToR(24);
    }

    .breadcrumbs-content {
        max-width: $max-content-width;
        padding-left: pToR(16);
        @include tablet {
            padding-left: pToR(58);
        }
        @include desktop {
            padding-left: pToR(56);
            margin-left: auto;
            margin-right: auto;
        }
        .brand-lincoln & {
            @include tablet {
                padding-left: pToR(45);
            }
            @include desktop {
                padding-left: pToR(45);
                margin-left: 0;
                margin-right: 0;
            }
        }
        .back-slash {
            padding: 0 pToR(8);
            .brand-lincoln & {
                color: $brand-secondary;
            }
        }

        .breadcrumb-link {
            color: $fds-reimagine-secondary-blue;
            text-decoration: none;
            .brand-lincoln & {
                font-family: $proxima-nova-regular;
                color: $brand-secondary;
            }
        }

        .beta-superscript {
            font-style: italic;
        }
    }
    &.syndicated-header {
        .breadcrumbs-content {
            padding-left: pToR(16);
            @include tablet {
                padding-left: pToR(58);
            }
            @include desktop {
                padding-left: pToR(56);
                margin-left: auto;
                margin-right: auto;
            }
            .brand-lincoln & {
                @include tablet {
                    padding-left: pToR(45);
                }
                @include desktop {
                    padding-left: pToR(45);
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
    }
}
