@import '@styles/main.v5';

.smart-tile.smart-tile-connected-services:hover {
    transform: none;
    transition: none;
}
.smart-tile {
    &.smart-tile-connected-services {
        .fmc-text-button {
            margin: auto auto 27px;

            [data-brand='lincoln'] & {
                margin-bottom: 0;
            }
            .eu & {
                margin-bottom: 0;
            }
        }
        .connected-services-modal-content {
            .secondary-button,
            .fmc-text-button {
                display: block;
            }
        }
    }
}
.smart-tile-connected-services {
    .brand-lincoln & {
        &.toggle-off-hover:hover {
            @include mobile-v5 {
                transform: scale(1.07);
                transition: ease all 0.3s;
            }
        }
    }

    .fmc-text-button {
        margin-bottom: pToR(25);

        [data-brand='lincoln'] & {
            margin-bottom: unset;
        }
    }
    .icon {
        .brand-ford & {
            height: pToR(24);
            width: pToR(24);

            @include tablet {
                height: pToR(24);
                width: pToR(24);
            }
        }

        .brand-lincoln & {
            height: pToR(70);
            width: pToR(70);
            margin: pToR(47) auto auto;

            @include mobile-v5 {
                max-height: pToR(60);
                max-width: pToR(60);
                margin: pToR(32) auto 0;
            }
            @include tablet-v5 {
                margin: pToR(48) auto pToR(25);
                width: pToR(70);
            }
        }
    }
    .add-vehicle-container {
        cursor: pointer;
        margin: auto;
    }
    .fmc-type--heading6 {
        @include mobile-v5 {
            display: none;
        }
        @include tablet-v5 {
            margin-bottom: 15px;
            [data-brand='lincoln'] & {
                font-size: 24px;
                line-height: 32px;
                letter-spacing: 2.5px;
            }
        }
    }
    .fmc-type--subtitle1 {
        @include tablet-v5 {
            display: none;
        }
    }
    .fmc-type--body1 {
        .brand-lincoln & {
            @include mobile-v5 {
                display: none;
            }
        }

        [data-brand='lincoln'] & {
            margin-bottom: auto;
        }
    }

    .smart-tile-subscription-counts-container {
        .brand-lincoln & {
            @media (max-width: 835px) {
                margin-top: pToR(-3);
            }
        }
    }

    .smart-tile-subscription-count {
        .brand-lincoln & {
            @media (max-width: 835px) {
                font-size: pToR(15);
                line-height: pToR(17);
            }
        }
    }
}
