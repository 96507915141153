@import '@styles/main.v5';

.header {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 0 pToR(75);
    position: sticky;
    z-index: 2050;
    top: 0;
    background: white;
    height: pToR(70);

    li {
        list-style: none;
    }
    .hide-nav {
        display: none;
    }
    nav {
        margin-right: pToR(45);
        height: pToR(70);
        display: flex;
        align-items: center;

        .fmc-text-button {
            text-decoration: none;
        }
        @include mobile {
            margin-right: unset;
            border-top: 1px solid $lightgrey;
        }
    }

    .background-overlay {
        display: block;
        position: absolute;
        top: pToR(84);
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100vh;
        opacity: 0.7;
        background: #000 none;
    }

    .desktop-subnav {
        position: absolute;
        top: pToR(70);
        left: 0;
        margin-right: pToR(8);
        width: 100%;
        background: white;
        transition: all 0.4s cubic-bezier(0.7, 0, 0.3, 1);

        .sidebar-subnav {
            padding: 0 pToR(50) pToR(52);
            border-top: solid 1px lightgray;
            border-bottom: solid 1px lightgray;
            max-height: calc(70vh + 64px);
            overflow: auto;
            justify-content: flex-start;

            .subnav-title-container {
                padding: pToR(30) pToR(8) pToR(30) pToR(23);

                @include tablet-v5 {
                    padding-top: pToR(37);
                }
                @include desktop-v5 {
                    width: pToR(360);
                }

                .fmc-type--heading5 {
                    font-size: pToR(24);
                    line-height: pToR(28);
                    display: block;
                }
                .fmc-text-button {
                    display: block;
                    color: $fds-color--primary;
                    font-size: pToR(14);
                    line-height: pToR(22);
                }
                .fmc-type--body1 {
                    display: block;
                    font-size: pToR(14);
                    line-height: pToR(22);
                    letter-spacing: normal;
                }
            }
            .subnav-link {
                font-size: pToR(14);
                padding: pToR(10) pToR(10) pToR(10) 0;

                a {
                    padding: 0;
                    border: none;
                }

                a.selected-item,
                a:focus {
                    color: $fds-color--secondary;
                }
            }
        }
    }

    .visible-in-mobile {
        display: none;

        & ~ a {
            height: pToR(35);
            margin-right: pToR(40);
            outline-offset: pToR(4);
        }
    }
    .first-header-link {
        &.selected-item,
        &:focus {
            outline: 1px solid $fds-color--secondary;
        }
    }
    .logo-link {
        &.selected-item,
        &:focus {
            outline: 1px solid $fds-color--secondary;
        }

        .ford-logo {
            background: transparent no-repeat -200px 0;
            width: pToR(93);
            height: pToR(35);
            background-size: pToR(400) pToR(200);
            display: inline-block;
            padding: 0;
        }
    }

    a,
    button {
        &.desktop {
            padding: pToR(16) pToR(12.8);
            box-sizing: border-box;
            transition: 0.3s;
            border: 1px solid transparent;

            &.active {
                color: $fds-color--secondary;
            }
        }

        &.locate-dealer {
            display: inline-block;
            height: 100%;
            &.selected-item,
            &:focus {
                outline: 1px solid $fds-color--secondary;
            }

            img {
                margin-right: pToR(15);
                vertical-align: middle;
                display: inline-flex;
            }

            span {
                @media screen and (max-width: $small-desktop-width) {
                    display: none;
                }
            }
        }

        sup {
            vertical-align: baseline;
            position: absolute;
            top: pToR(-15);
            font-size: pToR(10);
            right: pToR(-2);
        }

        &.login {
            margin-left: pToR(16);
            top: -1px;
        }
    }

    button {
        border: none;
        background-color: unset;

        &:focus,
        &.selected-item {
            color: $fds-color--secondary;
        }
    }

    .right-align {
        display: none;
    }

    @media screen and (max-width: $fds-min-desktop) {
        height: pToR(64);
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .desktop,
        .desktop-subnav,
        .fmc-text-button {
            display: none;
        }

        .visible-in-mobile {
            display: block;

            & ~ a {
                margin-right: unset;
            }
        }

        .logo-link,
        .mobile-fill,
        .menu {
            flex: 1 1 calc(100% / 3);
            outline: none;
        }

        .logo-link {
            height: unset;
            padding: 0;
            position: absolute;
        }

        .menu {
            text-align: left;
            position: absolute;
            display: inline-block;
            top: pToR(12);

            &.menu-right-aligned {
                right: pToR(15);
            }

            span {
                &,
                &::before,
                &::after {
                    border-radius: 0;
                    height: 2px;
                }
            }
        }

        a.logo-link {
            margin: 0;
            text-align: center;
        }
    }
    .language-toggle {
        &:focus,
        &.selected-item {
            outline: 1px solid $fds-color--secondary;
        }
    }
}
.main-nav-item {
    display: flex;
    flex-wrap: wrap;
}
.main-nav-items {
    .brand-ford & {
        display: inline-block;
        list-style: none;
        font-size: pToR(13);
        letter-spacing: pToR(3);
        line-height: pToR(16);
        padding: pToR(20);
    }

    @include mobile {
        .brand-lincoln & {
            display: inherit;
        }
    }
}

.desktop.language-toggle {
    margin-left: auto;

    @include tablet-v5 {
        margin-right: pToR(43);
    }
}
