// ================================
// Typography
// ================================
//@import "fds-base";
@import 'variables';
@import '~@ford/ford-design-system/src/scss/mixins';
//================================
//Base: Default
//================================

@at-root {
    @viewport {
        width: device-width;
    }
}

a {
    text-decoration: none;
}

sup {
    font-size: 0.6em;
}

html {
    font-family: var(--fmc-type__base-font);
}

body,
p {
    @extend %fmc-type--body1;
}

.fmc-type--heading1,
.fmc-type--heading2,
.fmc-type--heading3,
.fmc-type--heading4,
.fmc-type--heading5,
.fmc-type--heading6,
.fmc-type--subtitle1,
.fmc-type--subtitle2,
.fmc-type--body1,
.fmc-type--body2,
.fmc-type--content2 {
    & a,
    & a:link,
    & a:active {
        text-decoration: underline;
    }
}

// --------------------------------
// Font Family
// --------------------------------

.fds-font--system {
    font-family: var(--fmc-font--system);
}

.fds-font--mono {
    font-family: var(--fmc-font--system);
}

// --------------------------------
// Style
// --------------------------------

.fds-style--italic {
    font-style: italic;
}

.fds-style--normal {
    font-style: normal;
}

.fds-transform--uppercase {
    text-transform: uppercase;
}

.fds-transform--lowercase {
    text-transform: lowercase;
}

.fds-transform--capitalize {
    text-transform: capitalize;
}

.fds-transform--none {
    text-transform: none;
}

.fds-decoration--underline {
    text-decoration: underline;
}

.fds-decoration--overline {
    text-decoration: overline;
}

.fds-decoration--none {
    text-decoration: none;
}

.fds-decoration--strike {
    text-decoration: line-through;
}

// --------------------------------
// Whitespace & Wrapping
// --------------------------------

.fds-wrap--normal {
    white-space: normal;
}

.fds-wrap--nowrap {
    white-space: nowrap;
}

.fds-wrap--break-word {
    word-wrap: break-word;
}

.fds-wrap--break-normal {
    word-wrap: normal;
}

.fds-wrap--truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// --------------------------------
// Font weight
// --------------------------------

.fds-weight--light {
    font-weight: var(--fds-type__weight-light);
}

.fds-weight--normal {
    font-weight: var(--fds-type__weight-normal);
}

.fds-weight--medium {
    font-weight: var(--fds-type__weight-medium);
}

.fds-weight--bold {
    font-weight: var(--fds-type__weight-bold);
}

// --------------------------------
// Font stretch
// --------------------------------
.fds-stretch--condensed {
    font-stretch: condensed;
}

.fds-stretch--normal {
    font-stretch: normal;
}

// --------------------------------
// Lists
// --------------------------------

.fds-list-reset {
    padding: 0;
    list-style-type: none;
}

// --------------------------------
// Type Set Utility Classes
// --------------------------------

// creates classes from type-set
@include fds-set-type-sets('.fmc-type', $fds-type-sets);
