@import '@styles/main.v5';
@import '@styles/common';

.communication-center-view {
    &__wrapper {
        width: 100%;

        .brand-lincoln & {
            background-color: $fds-color-surface;
        }
    }

    &__container {
        min-height: 50vh;
        width: 100%;
        padding: 0 pToR(16);

        @include tablet {
            padding: 0 pToR(56);
        }

        .brand-lincoln & {
            background-color: $fds-color-surface;
            margin-left: auto;
            margin-right: auto;
            max-width: pToR(1440);
        }

        .breadcrumbs {
            padding: pToR(32) 0 pToR(52);

            .brand-lincoln & {
                padding-bottom: pToR(40);
            }

            @include mobile {
                padding: pToR(25) 0 pToR(28);
            }

            &.syndicated-header {
                @include media('>1440px') {
                    max-width: pToR(1322);
                }

                .breadcrumbs-content {
                    padding-left: 0;

                    .brand-lincoln & {
                        font-size: pToR(16);
                    }

                    .breadcrumb-link {
                        &:focus-visible {
                            outline: 2px solid $fds-preimagine-button-blue;
                            outline-offset: 3px;

                            .brand-lincoln & {
                                outline: 1px solid
                                    $fds-preimagine-lincoln-primary;
                                outline-offset: pToR(2);
                            }
                        }
                    }
                }
            }
        }

        .hr-line {
            height: pToR(1);
            border-bottom: pToR(1) solid $disabled-dark;
            box-shadow: none;
            margin-bottom: pToR(8);
            width: 100%;

            .brand-lincoln & {
                border: 0;
                border-bottom: pToR(1) solid $brand-gray3;

                @include mobile {
                    border: 0;
                    border-bottom: pToR(1) solid $brand-gray3;
                }
            }
        }
    }

    &__content {
        margin: 0 auto;
        max-width: pToR(1105);

        .brand-lincoln & {
            padding-bottom: pToR(40);
        }

        @include tablet {
            margin-bottom: pToR(64);

            .brand-lincoln & {
                margin-bottom: auto;
                padding-bottom: pToR(70);
            }
        }
    }

    &__notification-container {
        margin-top: pToR(4);
        margin-bottom: pToR(35);

        @include tablet {
            margin-top: pToR(-19);
            margin-bottom: pToR(40);
        }
    }

    &__header {
        color: $charcoal;
        font-family: $ford-f1-font-regular;
        font-size: pToR(48);
        line-height: pToR(56);
        letter-spacing: normal;

        @include mobile {
            font-size: pToR(36);
            line-height: pToR(44);
        }

        .brand-lincoln & {
            font-family: $lincoln-miller-b-semibold;
            font-size: pToR(38);
        }
    }

    &__description {
        margin-bottom: pToR(28);

        @include mobile {
            margin-bottom: pToR(39);
        }

        .brand-lincoln & {
            margin-bottom: pToR(48);
            @include mobile {
                margin-bottom: pToR(34);
            }
        }

        p {
            color: $fds-preimagine-high-contrast;
            font-family: $ford-f1-font-regular;
            font-size: pToR(16);
            line-height: pToR(24);
            letter-spacing: normal;
            max-width: 81ch;

            @include tablet {
                margin-bottom: pToR(40);
            }

            .brand-lincoln & {
                color: $fds-preimagine-lincoln-primary;
                font-family: $proxima-nova-regular;
            }
        }

        a {
            color: $fds-preimagine-high-contrast;
            text-decoration: underline;

            .brand-lincoln & {
                color: $fds-preimagine-lincoln-primary;
            }
        }
    }
}

.brand-ford .communication-center-view__container .self-help-container {
    padding: pToR(43) 0 pToR(118);

    @include mobile {
        padding: pToR(30) 0 pToR(100);
    }

    .banner .top.no-bottom .vehicle-tabs .search-container .fmc-type--heading1 {
        font-size: pToR(32);
        line-height: pToR(40);

        @include mobile {
            font-size: pToR(28);
            line-height: pToR(36);
        }
    }
}
