@import '@styles/main.scss';

.connected-services-view-container {
    display: flex;
    padding: pToR(12) pToR(20);
    background-color: $gray;
    flex-direction: column;

    @include desktop {
        padding: pToR(12) pToR(96);
    }

    .fds-chevron-subscription {
        font-size: 18px;
    }

    .connected-services-error-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: pToR(100);
        margin-bottom: pToR(100);

        h2 {
            margin-bottom: pToR(48);
            font-size: pToR(24);
            letter-spacing: 0;
            padding: 0 pToR(16);

            @include desktop {
                font-size: pToR(28);
                padding: 0;
            }
        }
    }
    .return-button {
        margin-top: 7px;
    }
}
