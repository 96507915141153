@import '@styles/main.v5';
@import '@styles/common';

.view-360-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    touch-action: none;

    .view-360-close-button {
        background-color: rgba(0, 0, 0, 0.7);
        height: pToR(70);
        width: pToR(70);
        position: absolute;
        right: 0;

        &__icon {
            filter: brightness(5) saturate(0);
        }

        &:focus {
            outline: 2px solid $fds-preimagine-primary-blue;
            outline-offset: 0;
            border-color: transparent;

            .brand-lincoln & {
                outline: 2px solid #a2a2a2;
            }
        }
    }
}

.parts-accordion-container {
    width: 100%;
    margin-top: pToR(15);
}

.vehicle-order-tracking {
    &__status-view {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: pToR(20);
        padding: pToR(24) pToR(20) pToR(48);

        @include desktop {
            padding: pToR(24) pToR(56);
        }

        &--content-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            max-width: pToR(1105);
            width: 100%;

            .vehicle-details-and-image {
                &__container {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: auto auto;
                    grid-column-gap: pToR(24);
                    width: 100%;

                    @include mobile-and-tablet {
                        grid-template-columns: 1fr;
                        grid-template-rows: auto;
                    }

                    .vehicle-details__container {
                        grid-area: 1 / 1 / span 1 / span 1;

                        @include mobile-and-tablet {
                            grid-area: 2 / 1 / span 1 / span 1;
                        }
                    }
                    .delivery-details__container {
                        grid-area: 2 / 1 / span 1 / span 1;

                        @include mobile-and-tablet {
                            grid-area: 3 / 1 / span 1 / span 1;
                        }
                    }
                    .vehicle-image__container {
                        grid-area: 1 / 2 / span 2 / span 1;
                        position: relative;
                        width: fit-content;

                        @include mobile-and-tablet {
                            grid-area: 1 / 1 / span 1 / span 1;
                            margin-bottom: pToR(36);
                        }

                        .view-360-button {
                            position: absolute;
                            right: 0;
                            z-index: 5;

                            .view-360-icon {
                                width: pToR(55);
                            }
                        }
                    }
                    .vehicle-details__container,
                    .delivery-details__container {
                        margin-bottom: pToR(24);

                        &-row {
                            display: grid;
                            grid-template-columns: pToR(150) auto;
                            grid-column-gap: pToR(16);

                            @include mobile-and-tablet {
                                grid-row-gap: pToR(16);
                            }
                            @include media('<=360px') {
                                grid-template-columns: pToR(96) auto;
                            }

                            h5 {
                                font-size: pToR(16);
                                line-height: pToR(24);
                                letter-spacing: normal;
                                font-family: $ford-f1-font-regular;
                                margin-left: pToR(20);
                                margin-bottom: pToR(8);

                                @include mobile-and-tablet {
                                    margin-left: unset;
                                }
                                @include media('<=360px') {
                                    font-size: pToR(14);
                                    line-height: pToR(20);
                                }

                                .brand-lincoln & {
                                    font-family: $proxima-nova-regular;
                                }
                            }

                            p {
                                font-size: pToR(16);
                                line-height: pToR(24);
                                letter-spacing: normal;
                                font-family: $ford-f1-font-regular;
                                margin-bottom: pToR(8);

                                @include media('<=360px') {
                                    font-size: pToR(14);
                                    line-height: pToR(20);
                                }

                                .brand-lincoln & {
                                    font-family: $proxima-nova-regular;
                                }
                            }

                            &:has(> .window-sticker-button__container) {
                                grid-template-columns: 1fr;
                            }
                        }

                        .brand-lincoln & {
                            color: $fds-preimagine-lincoln-primary;
                        }

                        h4 {
                            font-size: pToR(24);
                            line-height: pToR(32);
                            letter-spacing: normal;
                            font-family: $ford-f1-font-medium;
                            color: #333333;
                            margin-bottom: pToR(24);

                            .brand-lincoln & {
                                color: $fds-preimagine-lincoln-primary;
                                font-family: $proxima-nova-semibold;
                            }
                        }
                    }
                    .delivery-details {
                        &__content {
                            @include desktop {
                                margin-left: pToR(20);
                            }

                            p,
                            a {
                                color: #333333;
                                font-family: $ford-f1-font-regular;
                                font-size: pToR(16);
                                line-height: pToR(24);
                                letter-spacing: normal;
                                margin-bottom: 2px;

                                @include media('<=360px') {
                                    font-size: pToR(14);
                                    line-height: pToR(20);
                                }

                                .brand-lincoln & {
                                    color: $fds-preimagine-lincoln-primary;
                                    font-family: $proxima-nova-regular;
                                }
                            }

                            a {
                                display: flex;
                                text-decoration: underline;
                            }
                        }
                    }

                    .ownership-links-container {
                        margin-top: pToR(24);
                    }

                    .ownership-links-container > *:not(:first-child) {
                        margin-top: pToR(10);
                    }

                    .owner-registration-button__container,
                    .vehicle-guide-button__container {
                        .brand-ford & .tertiary-button a {
                            outline-offset: pToR(5);
                        }
                        .brand-lincoln & {
                            .secondary-button {
                                position: relative;
                                width: max-content;

                                a {
                                    font-family: $proxima-nova-semibold;
                                    color: $fds-preimagine-lincoln-primary;
                                    font-size: pToR(16);
                                    line-height: pToR(24);
                                    letter-spacing: normal;

                                    .lincoln-underline-button-overlay {
                                        @include lincoln-underline-overlay();
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &--title {
            color: $fds-preimagine-dark-charcoal;
            font-family: $ford-f1-font-medium;
            font-size: pToR(32);
            line-height: pToR(40);
            letter-spacing: normal;
            margin-bottom: pToR(32);

            @include mobile-and-tablet {
                margin-bottom: pToR(20);
            }

            .brand-lincoln & {
                color: $fds-preimagine-lincoln-primary;
                font-family: $proxima-nova-semibold;
            }
        }
        &--subtitle {
            color: $fds-preimagine-dark-charcoal;
            font-family: $ford-f1-font-regular;
            font-size: pToR(24);
            line-height: pToR(32);
            letter-spacing: normal;
            margin-bottom: pToR(32);

            @include mobile-and-tablet {
                margin-bottom: pToR(24);
            }

            .brand-lincoln & {
                color: $fds-preimagine-lincoln-primary;
                font-family: $proxima-nova-regular;
            }

            .ymm {
                font-family: $ford-f1-font-bold;

                .brand-lincoln & {
                    font-family: $proxima-nova-bold;
                }
            }

            .status-sentence + span {
                font-family: $ford-f1-font-bold;

                .brand-lincoln & {
                    font-family: $proxima-nova-bold;
                }
            }
        }
        &--status-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            margin-bottom: pToR(8);
        }
        &--accessories-button-container {
            margin-top: pToR(12);
            margin-bottom: pToR(48);

            @include mobile-and-tablet {
                margin-bottom: pToR(24);
            }

            .fmc-text-button.accessories-button {
                .brand-ford & {
                    color: $fds-preimagine-primary-blue;
                    font-family: $ford-f1-font-bold;
                    font-size: pToR(14);
                    line-height: pToR(20);
                    letter-spacing: normal;
                    display: flex;
                    height: pToR(40);
                    border-radius: pToR(400);
                    border: 1px solid $fds-preimagine-primary-blue;
                    padding: pToR(12) pToR(16);

                    &:hover {
                        color: $fds-preimagine-primary-button-hover;
                        border-color: $fds-preimagine-primary-button-hover;
                        text-decoration: none;
                    }

                    &:focus-visible {
                        outline: 2px solid $fds-preimagine-dark-charcoal;
                        outline-offset: 2px;
                        text-decoration: none;
                    }
                }
            }
            .brand-lincoln & {
                .secondary-button {
                    position: relative;

                    a {
                        font-family: $proxima-nova-semibold;
                        color: $fds-preimagine-lincoln-primary;
                        font-size: pToR(16);
                        line-height: pToR(24);
                        letter-spacing: normal;

                        .lincoln-underline-button-overlay {
                            @include lincoln-underline-overlay();
                        }
                    }
                }
            }
        }
        &--status {
            margin: auto 0 pToR(12);
            font-family: $ford-f1-font-bold;
            letter-spacing: normal;

            .brand-lincoln & {
                color: $fds-preimagine-lincoln-primary;
                font-family: $proxima-nova-bold;
            }
        }
        &--status-subcopy {
            margin: 0;
            font-family: $ford-f1-font-regular;
            letter-spacing: normal;

            .brand-lincoln & {
                color: $fds-preimagine-lincoln-primary;
                font-family: $proxima-nova-regular;
            }
        }
    }

    &__tracker {
        color: $white;
        position: relative;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        width: 100%;

        @include desktop {
            flex-direction: column-reverse;
            justify-content: unset;
            align-self: center;
            height: unset;
            max-height: unset;
            max-width: 931px;
            margin-bottom: pToR(36);
        }

        &--graph-container {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: pToR(45);

            @include mobile-and-tablet {
                min-height: 100%;
            }

            @include desktop {
                max-height: unset;
                width: 100%;
                height: pToR(45);
            }

            hr {
                position: relative;
                width: 3px;
                height: 100%;
                color: white;
                border-radius: pToR(16);
                z-index: 2;
                display: flex;
                border-left: 2px solid $grey;
                box-shadow: none;
                max-height: calc(100% - 40px);
                justify-self: center;

                @include desktop {
                    height: 2px;
                    max-height: unset;
                    max-width: pToR(108);
                    width: 100%;
                    border-bottom: 2px solid $grey;
                    border-left: unset;
                    justify-self: center;
                    margin: 0 pToR(4);
                }

                .brand-lincoln & {
                    color: $brand-border;
                    border-left: 2px solid $fds-preimagine-lincoln-primary;

                    @include desktop {
                        border-bottom: 2px solid $fds-preimagine-lincoln-primary;
                    }
                }

                &.highlighted-step {
                    border-left: 2px solid $fds-preimagine-primary-blue;

                    @include desktop {
                        border-bottom: 2px solid $fds-preimagine-primary-blue;
                    }

                    .brand-lincoln & {
                        border-left: 2px solid $brand-border;

                        @include desktop {
                            border-bottom: 2px solid $brand-border;
                        }
                    }
                }
            }
        }

        &--graph {
            position: absolute;
            display: grid;
            grid-template-rows: repeat(6, 1fr);
            height: pToR(450);
            width: pToR(60);
            padding: pToR(20) 0;
            border-radius: pToR(400);
            list-style-type: none;
            z-index: 1;

            @include desktop {
                height: pToR(60);
                width: 100%;
                grid-template-columns: repeat(6, 1fr);
                grid-template-rows: unset;
                padding: 0 pToR(20);
            }

            .brand-lincoln & {
                border-radius: 0;
            }

            &-segment {
                display: flex;
                flex-direction: row;
                align-items: center;
                z-index: 3;

                @include mobile-and-tablet {
                    flex-direction: column;
                    justify-content: flex-start;
                }

                .dot {
                    position: relative;
                    height: pToR(32);
                    min-width: pToR(32);
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    @include mobile-and-tablet {
                        margin-bottom: pToR(4);
                    }
                }

                &-marker {
                    position: absolute;
                    color: $eyebrow-color;
                    font-size: pToR(16);
                    line-height: pToR(24);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: pToR(32);
                    height: pToR(32);
                    border-radius: 50%;
                    background-color: white;
                    border: 2px solid $grey;
                    letter-spacing: normal;

                    .brand-lincoln & {
                        color: $fds-preimagine-lincoln-primary;
                        font-family: $proxima-nova-regular;
                    }

                    &:has(+ .fds-font--ford-icons__success) {
                        border: 2px solid $fds-preimagine-primary-blue;
                        color: unset;

                        .brand-lincoln & {
                            border: 2px solid $brand-border;
                        }
                    }

                    & + .fds-font--ford-icons__success {
                        position: absolute;
                        color: $fds-preimagine-primary-blue;
                        background-color: white;
                        border-radius: 50%;
                        font-size: pToR(12);
                        font-weight: 700;
                        margin-left: 0;

                        .brand-lincoln & {
                            color: $brand-border;
                        }
                    }

                    &.is-current-status {
                        color: white;
                        background-color: $fds-preimagine-primary-blue;
                        border: 0;

                        .brand-lincoln & {
                            background-color: $brand-border;
                        }
                    }
                }
            }
        }

        &--dates {
            display: grid;
            grid-template-rows: repeat(6, 1fr);
            padding: pToR(20) 0 pToR(20) pToR(8);
            list-style-type: none;

            @include desktop {
                grid-template-columns: repeat(6, 1fr);
                grid-template-rows: unset;
                padding: 0 pToR(20);
            }

            .date {
                color: $fds-preimagine-dark-charcoal;
                font-family: $ford-f1-font-regular;
                font-size: pToR(14);
                line-height: pToR(20);
                letter-spacing: normal;
                margin-bottom: pToR(4);

                .brand-lincoln & {
                    color: $fds-preimagine-lincoln-primary;
                    font-family: $proxima-nova-regular;
                }
            }
        }

        &--status-list {
            display: grid;
            grid-template-rows: repeat(6, 1fr);
            flex-direction: row;
            justify-content: space-evenly;
            height: pToR(450);
            padding: pToR(20) 0 pToR(20) pToR(8);
            list-style-type: none;

            @include desktop {
                height: pToR(80);
                max-height: unset;
                width: 100%;
                grid-template-columns: repeat(6, 1fr);
                grid-template-rows: unset;
                padding: 0 pToR(20);
            }

            &-item {
                color: $eyebrow-color;
                font-family: $ford-f1-font-medium;
                font-size: pToR(16);
                line-height: pToR(24);
                letter-spacing: normal;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;

                @include mobile-and-tablet {
                    max-width: fit-content;
                    justify-content: flex-start;
                    line-height: pToR(22);
                }

                @include desktop {
                    justify-content: space-between;
                    align-items: flex-start;
                    text-align: start;
                }

                .brand-lincoln & {
                    font-family: $proxima-nova-semibold;
                }

                .current-step {
                    color: $fds-preimagine-dark-charcoal;
                    font-family: $ford-f1-font-bold;

                    .brand-lincoln & {
                        color: $fds-preimagine-lincoln-primary;
                        font-family: $proxima-nova-bold;
                    }
                }

                .previously-completed {
                    .brand-ford & {
                        color: $fds-preimagine-dark-charcoal;
                    }
                    .brand-lincoln & {
                        font-family: $proxima-nova-regular;
                    }
                }

                span {
                    max-width: 9ch;

                    @include mobile-and-tablet {
                        max-width: 11ch;
                    }

                    &:nth-of-type(2) {
                        display: inline-block;
                        font-size: pToR(16);

                        @include mobile-and-tablet {
                            height: unset;
                            font-size: pToR(16);
                            margin-right: pToR(24);
                            transform: rotate(90deg);
                        }

                        @include desktop {
                            height: pToR(32);
                        }
                    }
                }
            }
        }
    }
}
