@import '@styles/main.v5';
@import '@styles/common';

.active-vehicle-order-card {
    &__container {
        display: flex;
        flex-direction: column;
        width: pToR(320);
        min-height: pToR(423);
        height: 100%;
        border-radius: pToR(8);
        background-color: $fds-reimagine-accessories-card-background-color;

        .secondary-button {
            margin-top: auto;
        }

        @include media('<400px') {
            width: calc(100vw - 64px);
        }
    }

    &__media-area {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        height: pToR(159);
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0) 25.89%
        );
        border-top-right-radius: pToR(8);
        border-top-left-radius: pToR(8);

        img {
            position: absolute;
            left: 0;
            right: 0;
            margin-inline: auto;
            width: fit-content;
            max-width: 100%;
            max-height: 100%;
            transform: scale(0.8);
        }
    }

    &__content-area {
        display: flex;
        flex-direction: column;
        height: calc(100% - 159px);
        padding: 0 pToR(16) pToR(22);
    }

    &__ymm {
        color: $fds-preimagine-high-contrast;
        font-family: $ford-f1-font-medium;
        font-size: pToR(16);
        line-height: pToR(24);
        letter-spacing: normal;
        margin-bottom: pToR(9);
    }

    &__order-number,
    &__vin {
        color: $charcoal;
        font-family: $ford-f1-font-regular;
        font-size: pToR(12);
        line-height: pToR(16);
        letter-spacing: normal;
    }

    .secondary-button {
        margin-top: auto;
    }

    &__order-details-cta {
        @include ford-secondary-button-default();
        align-items: center;
    }

    &__divider {
        border-bottom: 1px solid $fds-reimagine-order-card-divider-color;
        box-shadow: none;
        margin-bottom: pToR(16);
    }

    &__status {
        color: $fds-preimagine-dark-text;
        font-size: pToR(12);
        line-height: pToR(16);
        letter-spacing: normal;
        width: fit-content;
        border-radius: pToR(4);
        padding: 0 pToR(8);
        margin-bottom: pToR(9);
    }
}
