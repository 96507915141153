@import '../../styles/main.v5';

.sign-in-credentials-view {
    max-width: 100%;
    padding: 0 pToR(10);
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;

    @include tablet {
        padding: 0 pToR(39) pToR(68);
        background-color: #f4f4f4;
    }

    hr {
        box-shadow: none;
    }

    .return-button {
        margin-top: unset;
        margin-bottom: pToR(26);
        padding-top: pToR(28);
        align-self: flex-start;

        .brand-lincoln & {
            padding-top: pToR(26);
            margin-bottom: pToR(23);

            @include mobile {
                margin-bottom: pToR(26);
            }
            .fmc-text-button {
                &.fmc-text-button--chevron-left:before {
                    --icon-size: 24px;
                }
            }
        }
    }
}
