@import '@styles/main.scss';

.preferred-dealer-modal {
    .modal-content {
        @include tablet {
            height: unset;

            .brand-ford & {
                min-height: pToR(336);
            }
        }
        @include desktop {
            .brand-lincoln & {
                max-width: pToR(867);
            }
        }

        .modal-footer {
            margin-bottom: pToR(15);
            bottom: 0;
            position: absolute;

            @include mobile {
                position: inherit;
            }

            .brand-ford & {
                justify-content: left;
            }
            .brand-lincoln & {
                position: inherit;
                @include desktop {
                    margin-bottom: 0;
                }
                @include tablet {
                    margin-top: 0;
                    margin-bottom: 0;
                    min-height: pToR(170);
                }
            }

            .secondary-button {
                .brand-ford & {
                    border-radius: unset;
                    border: unset;
                    padding: unset;

                    &:visited {
                        text-decoration: none;
                    }

                    &:hover {
                        text-decoration: none;
                        color: #055ecb;
                    }

                    &:focus {
                        outline: 1px solid #066fef;
                        outline-offset: 3px;
                        outline-width: 2px;
                        --scale-hover: unset;
                        text-decoration: none;
                        border-radius: pToR(20);
                    }
                }
                .brand-lincoln & {
                    margin-left: unset;

                    .fmc-text-button {
                        font-weight: 400;
                        font-size: pToR(18);
                    }
                }
            }
        }
    }

    .modal-body {
        @include mobile-v5 {
            padding-left: pToR(36);
            padding-right: pToR(36);
            .brand-ford & {
                padding-right: unset;
                padding-left: unset;
            }
        }
        @include tablet-v5 {
            padding-left: pToR(50);
            padding-right: pToR(50);
            .brand-ford & {
                padding-right: unset;
                padding-left: unset;
            }
        }
        @include desktop {
            padding-left: 0;
            padding-right: 0;
        }

        .brand-lincoln & {
            @include tablet {
                margin-top: 0;
            }

            .fmc-type--content2 {
                @include mobile {
                    text-align-last: center;
                }
            }
        }

        .fmc-type--content2 {
            .brand-lincoln & {
                @include mobile {
                    text-align-last: center;
                }
            }
        }

        .fmc-type--heading5 {
            font-family: $ford-f1-font-medium;
            font-size: pToR(24);
            font-style: normal;
            font-weight: 500;
            line-height: pToR(32);
            text-align: left;
            color: $fds-preimagine-dark-text;
            margin-bottom: pToR(16);

            .brand-lincoln & {
                font-family: $proxima-nova-regular;

                @include mobile {
                    margin-bottom: pToR(30);
                }
            }
        }

        .vehicle-listing {
            .vehicle-info {
                .fmc-type--heading4 {
                    font-size: pToR(24);
                    font-style: normal;
                    font-weight: 500;
                    line-height: pToR(32);
                    color: $fds-preimagine-dark-text;
                    margin-bottom: pToR(8);
                    letter-spacing: normal;

                    .brand-ford & {
                        font-family: $ford-f1-font-medium;
                    }
                }

                .fmc-type--body1 {
                    font-style: normal;
                    letter-spacing: normal;

                    .brand-ford & {
                        margin-bottom: pToR(8);
                        font-family: $ford-f1-font-medium;
                        font-size: pToR(24);
                        font-weight: 500;
                        line-height: pToR(32);
                        color: $fds-preimagine-dark-text;
                    }
                    .brand-lincoln & {
                        margin-bottom: pToR(10);
                        font-family: $proxima-nova-regular;
                        font-size: pToR(28);
                        font-weight: 400;
                        line-height: pToR(40);
                        max-width: unset;

                        @include mobile {
                            text-align: center;
                            margin-bottom: pToR(14);
                        }
                    }
                }

                .fmc-type--content2 {
                    font-size: pToR(16);
                    font-style: normal;
                    font-weight: 400;
                    line-height: pToR(24);
                    margin-bottom: pToR(8);
                    letter-spacing: normal;
                    height: unset;

                    .brand-ford & {
                        font-family: $ford-f1-font-regular;
                    }

                    .brand-lincoln & {
                        font-family: $proxima-nova-regular;

                        @include mobile {
                            text-align-last: center;
                        }
                    }
                }

                .preferred-dealer-modal__dealer-name {
                    font-family: $ford-f1-font-regular;
                    font-size: pToR(16);
                    font-style: normal;
                    font-weight: 700;
                    line-height: pToR(24);
                    margin-bottom: pToR(6);
                    letter-spacing: normal;

                    .brand-ford & {
                        font-family: $ford-f1-font-regular;
                    }

                    .brand-lincoln & {
                        font-family: $proxima-nova-regular;

                        @include mobile {
                            text-align: center;
                        }
                    }
                }

                .preferred-dealer-modal__dealer-info {
                    font-weight: 500;
                    height: auto;
                    font-size: pToR(16);
                    font-style: normal;
                    line-height: pToR(24);
                    margin-bottom: unset;
                    letter-spacing: normal;

                    .brand-ford & {
                        font-family: $ford-f1-font-regular;
                    }

                    .brand-lincoln & {
                        @include mobile {
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    .modal-header {
        margin-bottom: pToR(55);

        @include tablet {
            margin-bottom: pToR(50);
        }

        .brand-lincoln & {
            @include tablet {
                margin-bottom: 0;
                padding-right: 0;
            }
        }
    }

    .modal-close {
        background: none;

        .brand-lincoln & {
            @include tablet {
                margin-right: pToR(32);
                margin-top: pToR(29);
                line-height: pToR(19);
            }
        }
    }

    .vehicle-listing {
        border-bottom: 1px solid $border-grey;
        width: 100%;
        padding: pToR(47) 0 pToR(30);
        display: flex;
        flex-direction: column;

        .brand-ford & {
            justify-content: space-between;
            align-items: end;
            flex-direction: unset;
        }

        @include mobile {
            padding: 0 0 pToR(43);
        }

        @include tablet {
            flex-direction: row;
            justify-content: space-between;
            padding-left: pToR(30);
            padding-right: pToR(30);
        }

        @include desktop {
            padding: pToR(30) pToR(40) pToR(30) pToR(20);
        }

        .brand-ford & {
            padding: pToR(30) pToR(30) pToR(22) pToR(8);
            @include mobile {
                padding-right: unset;
                padding-left: unset;
            }
        }

        &:last-child {
            border-bottom: none;

            @include mobile {
                padding-bottom: pToR(5);
            }

            .brand-lincoln & {
                @include tablet {
                    padding-bottom: 0;
                }
            }
        }
    }

    .multiple-vehicle {
        @include mobile {
            padding-top: pToR(35);
            padding-bottom: pToR(43);
        }
    }

    .vehicle-info {
        text-align: left;
    }

    .preferred-dealer-button-container {
        align-self: center;
        padding-bottom: pToR(10);
        @include mobile {
            margin: pToR(33) auto auto auto;
        }
        @include tablet {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .brand-ford & {
            @include mobile {
                margin: unset;
            }
        }

        .fmc-button {
            [data-brand='lincoln'] & {
                width: auto;
            }
        }

        .fmc-button.edit-preferred-dealer {
            .brand-ford & {
                color: $fds-preimagine-primary-blue;
                border: 1px solid $fds-preimagine-primary-blue;

                &:hover {
                    color: $fds-preimagine-primary-button-hover;
                    border-color: $fds-preimagine-primary-button-hover;
                }
                &:focus {
                    outline: 2px solid $fds-preimagine-primary-blue;
                    outline-offset: 2px;
                }
                &:after {
                    background-color: white;
                }
            }
        }
    }
}
