@import '@styles/main.v5';

.brand-logo {
    width: pToR(148);

    @include mobile {
        width: pToR(128);
        margin-bottom: pToR(16);
    }

    .brand-lincoln & {
        width: pToR(160);

        @include mobile {
            width: pToR(138);
        }
    }

    &.from-webview {
        padding-top: pToR(48);

        @include mobile {
            padding-top: pToR(32);
        }
    }
}

.sign-in-credentials {
    &__content {
        background-color: transparent;
        max-width: pToR(560);
        width: 100%;
        padding: pToR(11) 0 pToR(48);
        margin-bottom: pToR(16);

        @include tablet {
            background-color: white;
            box-shadow: var(--fmc-elevation__box-shadow--layer1);
            margin: pToR(50) auto pToR(40);
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: pToR(36) pToR(72) pToR(48);
        }
        .mfa-section {
            .brand-lincoln & {
                display: flex;
                flex-direction: column;

                .sign-in-credentials__mfa--description {
                    align-self: center;
                }
            }

            .mfa-toggle-section {
                margin-top: pToR(-13);
                margin-bottom: pToR(38);

                &__header {
                    letter-spacing: normal;
                    margin-bottom: pToR(14);

                    @include mobile {
                        margin-bottom: pToR(24);
                    }
                }

                &__switch-container {
                    display: flex;
                    align-items: center;
                    gap: pToR(8);
                }

                &__switch-label {
                    letter-spacing: normal;
                    font-size: pToR(14);
                    line-height: pToR(14);
                }
            }
        }

        .sign-in-and-security-error-content {
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
                width: 100%;
                max-width: pToR(115);
                margin-bottom: pToR(43);
            }
            .sign-in-credentials__section-header {
                margin-bottom: pToR(42);
            }
            .fmc-type--body2 {
                text-align: center;
                margin-bottom: pToR(25);

                .brand-lincoln & {
                    letter-spacing: normal;
                    line-height: pToR(24);
                }
            }
        }
    }
    &__field-row {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: pToR(20);

        &:nth-of-type(2),
        &:nth-of-type(4),
        &:last-of-type {
            margin-top: pToR(32);
        }

        @include media('<390px') {
            flex-direction: column;
            align-items: flex-start;
        }
        .cta-button-box {
            display: flex;
            flex-direction: row;

            .fmc-text-button {
                &:nth-of-type(2) {
                    padding-left: pToR(34);

                    .brand-lincoln & {
                        padding-left: pToR(24);
                    }
                }
            }
        }

        .sign-in-credentials__field-label {
            @include media('<390px') {
                margin-bottom: pToR(12);
            }

            .sign-in-credentials__label {
                color: $fds-color--gray3;
                font-family: $antenna-font;
                font-size: pToR(16);
                line-height: pToR(20);
                letter-spacing: 1px;
                margin-bottom: pToR(14);

                .brand-lincoln & {
                    color: $brand-secondary;
                    font-size: pToR(16);
                    line-height: pToR(24);
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    margin-bottom: pToR(10);
                }
            }
            .sign-in-credentials__value {
                margin-bottom: pToR(8);
                font-size: pToR(14);
                line-height: pToR(20);
                letter-spacing: 1px;
                max-width: 24ch;

                .brand-lincoln & {
                    font-size: pToR(16);
                    line-height: pToR(20);
                    color: $brand-secondary;
                    letter-spacing: normal;
                    max-width: 25ch;
                }
            }
        }

        .fmc-text-button {
            margin-bottom: pToR(6);
        }
    }
    &__section-header {
        color: $fds-v4-primary;
        font-family: $antenna-font-condensed-light;
        font-size: pToR(24);
        letter-spacing: 2px;
        line-height: pToR(32);
        text-align: center;
        font-weight: normal;
        text-transform: uppercase;
        margin-bottom: pToR(47);

        .mfa-section & {
            margin-top: pToR(42);
            margin-bottom: pToR(12);
        }

        .brand-lincoln & {
            font-family: $proxima-nova-regular;
            letter-spacing: 1.5px;
            color: $brand-secondary;
        }
    }
    &__mfa--description {
        font-size: pToR(14);
        line-height: pToR(20);
        letter-spacing: 1px;
        color: $fds-color--gray3;
        margin-bottom: pToR(45);
        text-align: center;

        .brand-lincoln & {
            font-size: pToR(16);
            line-height: pToR(24);
            letter-spacing: normal;
            max-width: 40ch;
            align-self: center;
            margin-bottom: pToR(30);
        }
    }

    &__notification-container {
        margin-bottom: pToR(25);
    }
}

.modal {
    &.mfa-enable-modal,
    &.mfa-disable-modal {
        .modal-content {
            .modal-body {
                @include mobile-v5 {
                    padding: 0;
                }
                @include tablet-v5 {
                    margin-top: pToR(51);
                    padding: 0;
                }

                .fmc-type--heading6,
                h2 {
                    font-size: pToR(24);
                    line-height: pToR(29);
                    font-weight: $fds-weight--heading6;
                    letter-spacing: 0;
                    margin-bottom: pToR(23);

                    .brand-ford & {
                        font-family: $ford-f1-font-medium;
                        font-size: pToR(24);
                        font-style: normal;
                        line-height: pToR(32);
                        letter-spacing: normal;
                        color: $fds-preimagine-dark-text;
                        text-align: left;
                    }
                }

                .fmc-type--body2 {
                    .brand-lincoln & {
                        letter-spacing: normal;
                    }

                    .brand-ford & {
                        font-family: $ford-f1-font-regular;
                        font-size: pToR(16);
                        font-style: normal;
                        line-height: pToR(24);
                        letter-spacing: normal;
                        color: $fds-preimagine-dark-text;
                        text-align: left;
                    }
                }

                .brand-ford & {
                    margin-top: unset;
                    text-align: left;
                }
            }

            .modal-footer {
                .brand-ford & {
                    margin-bottom: unset;
                }

                .brand-lincoln & {
                    @include tablet-v5 {
                        margin-bottom: pToR(41);
                    }
                }

                .primary-button {
                    margin-right: 0;
                }

                .secondary-button {
                    margin-left: pToR(30);

                    .brand-lincoln & {
                        @include mobile-v5 {
                            margin-left: unset;
                        }
                    }

                    .fmc-text-button {
                        margin-left: unset;
                    }
                }
            }
        }
    }
}
.brand-lincoln {
    .fmc-dialog.mfa-enable-modal .fmc-dialog__content .fmc-button,
    .fmc-dialog.mfa-disable-modal .fmc-dialog__content .fmc-button {
        margin-bottom: auto;

        @include mobile-v5 {
            margin-bottom: pToR(24);
        }
    }
}
