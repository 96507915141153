@import '@styles/main.v5';

.marketing-options {
    &-content-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: pToR(1105);
        margin: auto;

        .marketing-options__header-and-button-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @include tablet {
                flex-direction: row;
                justify-content: space-between;
                align-items: baseline;
                width: 100%;
            }

            .marketing-options__header {
                font-family: $ford-f1-font-regular;
                font-size: pToR(28);
                line-height: pToR(36);
                letter-spacing: normal;
                color: $fds-v5-primary;

                @include mobile {
                    margin-bottom: pToR(9);
                }

                @include tablet {
                    font-size: pToR(32);
                    line-height: pToR(40);
                    margin-bottom: pToR(8);
                }
            }

            .marketing-options-edit-button,
            .marketing-options-select-deselect-all-button {
                font-family: $ford-f1-font-regular;
                font-size: pToR(16);
                line-height: pToR(24);
                letter-spacing: normal;
                color: $fds-preimagine-dark-charcoal;

                &:hover {
                    text-decoration: underline;
                }

                &:focus-visible {
                    outline: 2px solid $fds-preimagine-primary-blue;
                    outline-offset: 2px;
                }

                @include mobile {
                    margin-bottom: pToR(15);
                    text-decoration: underline;
                }
            }
        }

        hr {
            box-shadow: none;
            margin-bottom: pToR(22);

            &.edit-state {
                margin-bottom: pToR(22);
            }
        }

        .marketing-options-button-container {
            display: flex;
            flex-direction: row;
            align-self: flex-end;

            .fmc-button {
                margin-left: pToR(24);

                &:disabled {
                    --bg-color: #e6e8ea;
                    --bg-color-hover: #e6e8ea;
                    color: #6b7786;
                    border: 0;
                }
            }

            .edit-marketing-options-cancel-button {
                height: pToR(40);
                min-width: pToR(93);
                border-radius: pToR(400);
                padding: pToR(12) pToR(24);
                color: $fds-preimagine-primary-blue;
                border: 1px solid $fds-preimagine-primary-blue;
                font-family: $ford-f1-font-bold;

                &:hover {
                    color: $fds-preimagine-primary-button-hover;
                    border-color: $fds-preimagine-primary-button-hover;
                }

                &:focus-visible {
                    outline: 2px solid;
                    outline-offset: 2px;
                }
            }
        }
    }

    &-container {
        background-color: white;

        .bottom-border {
            box-shadow: none;

            @include mobile {
                display: none;
            }
        }

        &.eu-page {
            margin-left: pToR(100);
            margin-right: pToR(100);
        }

        .return-button {
            margin-top: pToR(8);
            transform: translateX(-4px);

            @include tablet {
                margin-top: pToR(23);
                transform: translateX(-27px);
                margin-bottom: pToR(38);
            }
        }

        .fmc-check legend {
            display: none;
        }

        .fmc-type--heading6 {
            font-weight: 500;
        }

        @include mobile {
            margin: 0 pToR(16);

            .return-button {
                margin-bottom: pToR(14);
            }

            .fmc-type--heading5 {
                font-size: pToR(32);
                line-height: pToR(38);
            }

            .fmc-type--body1 {
                max-width: 92%;
                margin: auto;
            }
        }

        @include tablet {
            margin: 0 pToR(56);
            padding-top: pToR(68);

            .marketing-preference-container & {
                margin-top: pToR(68);
            }
        }

        @include desktop {
            .fmc-type--body1 {
                margin: 0 auto;
                max-width: 69ch;
            }
        }
    }

    &-notification-container {
        width: 100%;
        margin: 0 auto pToR(41);

        @include mobile {
            margin-top: 0;
            margin-bottom: pToR(32);
        }
    }

    &-modal-content {
        @include mobile {
            height: auto;
        }
    }

    &-page-header {
        font-family: $ford-f1-font-regular;
        color: $fds-v5-primary;
        font-size: pToR(40);
        line-height: pToR(46);
        letter-spacing: normal;
        padding-bottom: pToR(12);
        margin-bottom: 0;
        text-align: center;

        @include tablet {
            font-size: pToR(54);
            line-height: pToR(62);
        }
    }

    &-page-description {
        padding-bottom: pToR(58);

        @include tablet {
            padding-bottom: pToR(103);
        }

        p {
            font-family: $ford-f1-font-regular;
            font-size: pToR(16);
            line-height: pToR(24);
            letter-spacing: normal;
            color: $fds-preimagine-dark-charcoal;

            @include tablet {
                max-width: 86ch;
            }
        }

        a {
            color: $dark-blue;
            text-decoration: underline;
        }

        a:hover,
        a:focus {
            color: $fds-preimagine-primary-blue;
        }
    }

    &-header-button-container {
        display: flex;
        justify-content: center;
        margin: pToR(30) 0 pToR(13);

        @include mobile {
            margin-bottom: pToR(38);
        }
        @include tablet {
            margin-bottom: pToR(40);
        }

        @include desktop {
            justify-content: flex-end;
            margin: pToR(58) 0 pToR(57);
        }

        .marketing-preference-container & {
            margin-bottom: pToR(13);

            @include tablet {
                margin-bottom: pToR(33);
            }
        }
    }

    &-header-button {
        font-size: pToR(18);
        line-height: pToR(28);
        letter-spacing: normal;
        color: $dark-blue;
        margin: pToR(30) 0 pToR(15) 0;
        font-family: $antenna-font-condensed-regular;

        &:hover {
            color: $fds-preimagine-primary-blue;
        }
    }

    &-name-description-ctn {
        max-width: 37ch;

        @include tablet {
            max-width: 47ch;
            margin-bottom: pToR(19);
        }
    }

    &-name {
        font-family: $ford-f1-font-medium;
        font-size: pToR(24);
        line-height: pToR(32);
        color: $fds-preimagine-dark-charcoal;
        letter-spacing: normal;

        @include mobile {
            padding-bottom: pToR(20);
        }

        .last-item & {
            @include mobile {
                padding-bottom: pToR(5);
            }
        }
    }

    &-description {
        font-size: pToR(16);
        line-height: pToR(24);
        font-family: $ford-f1-font-regular;
        letter-spacing: normal;
        color: $fds-preimagine-dark-charcoal;

        @include mobile {
            margin-bottom: pToR(14);
        }
    }

    &-list-wrapper {
        .dynamic-dropdown-container {
            max-width: pToR(396);
            width: 100%;
        }

        .fmc-floating-label {
            width: 100%;
            max-width: pToR(396);

            @include mobile {
                width: 100%;
                margin-left: 0;
            }
        }

        @include mobile {
            padding: pToR(30) 0 0;
        }
        @include tablet {
            padding-bottom: pToR(18);
        }
    }

    &-list {
        display: flex;
        flex-direction: column;
        margin-bottom: pToR(5);

        &.list-item,
        &.eu-post-registration {
            margin-bottom: pToR(37);

            .fmc-divider--horizontal {
                box-shadow: none;
            }

            .fmc-check {
                --button-diameter: 24px;
                --button-label-gap: 8px;
                --button-outline-thickness: 1px;
                --button-outline-color: #6b7786;
                --dot-color: #066fef;
                --dot-diameter: 18px;
                --vertical-gap: 24px;

                input[type='radio']:checked {
                    --button-outline-color: #066fef;
                    --button-outline-thickness-checked: 1px;
                }

                input[type='radio']:focus-visible {
                    outline-offset: 0;
                    outline: 2px solid $fds-preimagine-primary-blue;
                }

                label {
                    color: $fds-preimagine-dark-text;
                    font-family: $ford-f1-font-regular;
                    font-size: pToR(14);
                    line-height: pToR(20);
                    letter-spacing: normal;
                    align-items: center;
                }
            }

            :last-of-type {
                .fmc-divider--horizontal {
                    display: none;
                }
            }
        }

        &.eu-post-registration {
            margin-bottom: pToR(24);
        }

        @include mobile {
            display: block;
            justify-content: unset;
        }
    }

    &-section-wrapper {
        box-sizing: border-box;
        margin: 0 auto;
        max-width: pToR(1105);
        width: 100%;
        padding: 0;

        &.eu-page-section {
            margin-bottom: pToR(64);
            padding-bottom: pToR(93);
            border: pToR(1) solid $border-grey;
            background-color: white;
            box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1),
                0 10px 10px 0 rgba(0, 0, 0, 0.1);
            border-radius: 3px;

            @include mobile {
                margin-bottom: pToR(28);
            }
        }

        .marketing-options-select-deselect-button-container {
            display: flex;
            justify-content: center;

            @include mobile {
                justify-content: flex-start;
            }

            .marketing-options-select-deselect-all-button {
                font-family: $ford-f1-font-regular;
                font-size: pToR(16);
                line-height: pToR(24);
                letter-spacing: normal;
                color: $fds-preimagine-dark-charcoal;

                &:hover {
                    text-decoration: underline;
                    text-underline-offset: 2px;
                }

                &:focus-visible {
                    outline: 2px solid $fds-preimagine-primary-blue;
                    outline-offset: 2px;
                }

                @include mobile {
                    margin-bottom: pToR(15);
                    text-decoration: underline;
                    text-underline-offset: 2px;
                }
            }
        }

        .marketing-options-fields-plus-buttons-container {
            width: fit-content;
            display: flex;
            flex-direction: column;

            .last-item {
                @include mobile {
                    margin-bottom: pToR(32);
                }
            }
        }

        .fmc-divider--horizontal {
            box-shadow: none;
            margin-bottom: pToR(53);
        }

        @include mobile {
            margin-bottom: pToR(64);
        }

        @include tablet {
            margin-bottom: pToR(80);
        }
    }

    &-address-fields-container {
        display: flex;

        @include mobile {
            display: block;
        }

        @include tablet {
            .fmc-floating-label {
                margin-right: pToR(22);
            }
        }
    }

    &-button-save {
        display: flex;
        justify-content: center;

        @include mobile {
            margin-top: pToR(16);
        }
    }

    &-wrapper &-list-wrapper:last-of-type {
        border: none;
    }

    &-phone-disclaimer {
        color: $disabled-dark;
        font-family: $antenna-font;
        font-size: pToR(12);
        letter-spacing: pToR(1);
        line-height: pToR(21);

        ul {
            list-style-type: none;
        }

        li {
            padding-bottom: pToR(30);

            @include desktop {
                max-width: 105ch;
            }
        }
    }
}

.marketing-preference-container {
    padding-top: pToR(68);

    @include tablet {
        padding: 0;
    }
}

.marketing-preference-container-webview {
    padding-top: pToR(68);

    @include mobile {
        padding: 0;
    }
}

.server-errors {
    .error-text {
        padding-top: pToR(10);
        color: $severe !important;
        font-family: $antenna-font;
        font-size: pToR(12);
        letter-spacing: pToR(1);
    }
}

.self-help-container {
    .brand-ford & {
        @include mobile {
            padding-left: pToR(20);
            padding-right: pToR(20);
        }
    }
}
