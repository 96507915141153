@import '@styles/main.scss';

.input-section-name {
    border: 0;
    margin-bottom: pToR(18);

    @include tablet-v5 {
        margin-bottom: unset;
    }
}

.national-id-section {
    @include mobile {
        .form-field {
            &:first-of-type {
                margin-bottom: 0.75rem;
            }
            .form-field-error {
                margin-bottom: 0.7rem;
            }
        }
    }
}
.input-section-name ~ .input-fieldset {
    margin-top: unset;

    @include tablet-v5 {
        margin-top: pToR(11);
    }
}
.form-field .form-field__input {
    @include desktop {
        margin-top: pToR(12);
    }
}
