@import '@styles/main.v5';

.fmc-button {
    .brand-ford & {
        font-family: $ford-f1-font-bold;
        font-size: pToR(14);
        line-height: pToR(20);
        letter-spacing: normal;
        background-color: $fds-preimagine-primary-blue;
        --bg-color: #066fef;
        --bg-color-hover: #055ecb;
        padding: pToR(12) pToR(20);
        min-width: pToR(96);

        &:hover {
            transform: none;
        }
        &:focus-visible {
            outline: 2px solid $fds-preimagine-primary-blue;
            outline-offset: 2px;
        }
        &::after {
            transform: none;
        }
    }

    .brand-lincoln & {
        width: pToR(234);
        font-weight: 700;
        letter-spacing: normal;
        justify-content: center;
    }
}
