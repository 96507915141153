@import '@styles/main.v5';

.vehicle-order-tracking {
    &__view {
        background-color: white;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0 pToR(16);
        padding-bottom: pToR(48);

        @include tablet {
            margin: 0 pToR(56);
        }
    }
    &__component {
        max-width: pToR(1105);
        margin: auto;

        @include mobile {
            max-width: 100%;
        }

        .vehicle-order-tracking-error {
            color: $fds-preimagine-ford-error;
            font-size: pToR(12);
            font-family: $ford-f1-font-regular;
            padding-bottom: pToR(16);
            letter-spacing: normal;
            .brand-lincoln & {
                color: $brand-light-error;
                font-family: $proxima-nova-regular;
                font-size: pToR(13);
            }
        }
        .fmc-floating-label {
            max-width: pToR(542);

            &:has(> input[name='vin']) {
                margin-bottom: pToR(8);
            }
            .brand-lincoln & {
                label:after {
                    top: 31%;
                }
            }
        }
        .form-field-error {
            margin-bottom: pToR(16);

            .brand-lincoln & {
                margin-top: pToR(8);
            }

            .error-text {
                letter-spacing: normal;

                .brand-ford & {
                    font-family: $ford-f1-font-regular;
                }
            }
        }
        .fmc-button {
            min-width: fit-content;

            .brand-lincoln & {
                border-radius: 0;
            }
            .brand-ford & {
                &:disabled {
                    --bg-color: #e6e8ea;
                    color: $fds-preimagine-disabled-text-color;

                    &:hover:after {
                        --bg-color: #e6e8ea;
                    }
                }
            }
        }
        .fmc-floating-label
            input:not(:placeholder-shown)
            ~ label
            .fmc-floating-label__text:after {
            transform: none;
        }
        .fmc-input--error {
            &:not(:focus-visible) {
                border-color: $fds-preimagine-ford-error !important;
            }
        }
        .accessory-order-section {
            margin-top: pToR(48);
            margin-bottom: pToR(48);

            h3 {
                font-family: $ford-f1-font-regular;
                font-size: pToR(16);
                line-height: pToR(24);
                letter-spacing: normal;
            }

            .accessory-order-tracking-nav-button {
                border-radius: pToR(400);
                border: 1px solid $fds-reimagine-secondary-blue;
                height: pToR(40);
                min-width: pToR(109);
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: pToR(12) pToR(24);
                background-color: $fds-preimagine-primary-blue;
                --bg-color: #066fef;
                --bg-color-hover: #055ecb;

                .secondary-button-text {
                    display: inline-flex;
                    font-family: $ford-f1-font-bold;
                    font-size: pToR(14);
                    line-height: pToR(20);
                    letter-spacing: normal;
                }
            }

            .btn-chevron-offset-right {
                margin-left: pToR(16);
                display: flex;
                align-items: center;
            }
        }
    }
    &__header-text {
        color: $fds-preimagine-dark-text;
        font-family: $ford-f1-font-regular;
        font-size: pToR(44);
        line-height: pToR(52);
        letter-spacing: normal;
        margin-top: pToR(24);

        @include tablet {
            margin-top: pToR(48);
            margin-bottom: pToR(17);
        }

        .brand-lincoln & {
            color: $fds-preimagine-lincoln-primary;
            font-family: proxima-nova-regular, sans-serif;
        }
    }
    &__subtitle-text {
        font-family: $ford-f1-font-medium;
        font-size: pToR(24);
        letter-spacing: normal;
        margin-bottom: pToR(16);

        .brand-lincoln & {
            color: $fds-preimagine-lincoln-primary;
            font-family: proxima-nova-regular, sans-serif;
        }
    }
    &__terms-and-conditions-header-text,
    &__subtitle-text {
        color: $fds-preimagine-dark-charcoal;

        .brand-lincoln & {
            color: $fds-preimagine-lincoln-primary;
            font-family: proxima-nova-regular, sans-serif;
        }
    }
    &__numbered-instructions-section {
        margin-bottom: pToR(32);

        p {
            font-family: $ford-f1-font-regular;
            font-size: pToR(16);
            line-height: pToR(24);
            letter-spacing: normal;
            margin-bottom: pToR(16);

            .brand-lincoln & {
                color: $fds-preimagine-lincoln-primary;
                font-family: proxima-nova-regular, sans-serif;

                a {
                    color: $fds-preimagine-lincoln-primary;

                    &:hover {
                        text-decoration: underline;
                        text-decoration-color: $brand-border;
                        text-underline-offset: 3px;
                    }
                }
            }
        }
    }
    &__recaptcha-container {
        max-width: pToR(304);
        width: 100%;
        height: pToR(78);
        color: white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: pToR(32) 0;
    }
    &__terms-and-conditions-header-text {
        font-family: $ford-f1-font-medium;
        font-size: pToR(24);
        letter-spacing: normal;

        .brand-lincoln & {
            color: $fds-preimagine-lincoln-primary;
            font-family: proxima-nova-regular, sans-serif;
        }
    }
    &__terms-and-conditions-body-text {
        margin-bottom: pToR(48);

        p {
            font-family: $ford-f1-font-regular;
            font-size: pToR(12);
            line-height: pToR(16);
            letter-spacing: normal;

            .brand-lincoln & {
                color: $fds-preimagine-lincoln-primary;
                font-family: proxima-nova-regular, sans-serif;
            }
        }
    }
}
