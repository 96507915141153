@import '@styles/main.v5';
@import '@styles/common';

.window-sticker-button__container {
    .secondary-button {
        display: flex;
        height: pToR(40);
        width: fit-content;
        margin-top: pToR(24);

        .window-sticker-button {
            display: flex;
            height: 100%;
            color: $fds-preimagine-primary-blue;
            font-family: $ford-f1-font-bold;
            background-color: white;
            border: 1px solid $fds-preimagine-primary-blue;
            width: fit-content;
            border-radius: pToR(400);
            padding: pToR(12) pToR(24);
            text-align: center;
            font-size: pToR(14);
            line-height: pToR(20);
            letter-spacing: normal;

            &:hover {
                color: $fds-preimagine-primary-button-hover;
                border-color: $fds-preimagine-primary-button-hover;
            }

            &:focus {
                outline: 2px solid $fds-preimagine-primary-blue;
                outline-offset: 2px;
            }

            .brand-lincoln & {
                display: flex;
                align-items: center;
                height: pToR(50);
                color: white;
                background-color: $fds-preimagine-lincoln-primary;
                border-radius: 0;
                font-family: $proxima-nova-regular;
                font-size: pToR(16);
                line-height: pToR(20);
                padding: pToR(8) pToR(20);
                border: 0;
                position: relative;
                z-index: 1;

                &:focus {
                    outline: 2px solid $fds-preimagine-lincoln-primary;
                }

                &:hover {
                    .lincoln-underline-button-overlay {
                        @include lincoln-underline-overlay();
                    }
                }
            }

            .secondary-button-text {
                display: inline-flex;
                align-items: center;
            }
        }
    }
}
