@import '@styles/main.v5';
@import '@styles/main.scss';

.modal {
    &.delete-account-modal {
        .modal-content {
            @include desktop {
                width: pToR(819);
            }

            .fmc-dialog__close {
                font-size: pToR(24);
            }

            .modal-body {
                .brand-ford & {
                    width: 85%;
                }

                @include mobile-v5 {
                    padding: 0;
                }
                @include tablet-v5 {
                    padding: 0;
                }

                .fds-font--ford-icons__warning-filled {
                    color: var(--fds-color--caution1);
                    font-size: pToR(32);
                    margin-bottom: pToR(35);
                }

                .fmc-type--heading6 {
                    font-size: pToR(24);
                    line-height: pToR(26);
                    font-weight: $fds-weight--heading6;
                    letter-spacing: 0;
                    margin-bottom: pToR(17);

                    .brand-ford & {
                        font-family: $ford-f1-font-medium;
                        font-size: pToR(24);
                        font-style: normal;
                        line-height: pToR(32);
                        letter-spacing: normal;
                        color: $fds-preimagine-dark-text;
                    }
                }

                .fmc-type--body1 {
                    margin-bottom: pToR(20);

                    .brand-ford & {
                        font-family: $ford-f1-font-regular;
                        font-size: pToR(16);
                        font-style: normal;
                        line-height: pToR(24);
                        letter-spacing: normal;
                        color: $fds-preimagine-dark-text;
                    }
                }
            }

            .modal-footer {
                .primary-button {
                    margin-right: 0;
                }

                .secondary-button {
                    margin-left: pToR(48);

                    .brand-lincoln & {
                        @include mobile-v5 {
                            margin-left: unset;
                        }
                    }

                    .fmc-text-button {
                        margin-left: unset;
                    }
                }
            }
        }
    }
}
