@import '@styles/main.v5';

.smart-tile-icon {
    .icon {
        width: pToR(24);
        height: pToR(24);

        .brand-lincoln & {
            height: 100%;
            width: auto;
            max-height: pToR(70);
            max-width: pToR(70);
            margin: pToR(48) auto 0;

            @include mobile-v5 {
                width: 60px;
                height: 60px;
                margin-top: pToR(32);
            }
            @include tablet-v5 {
                margin-bottom: pToR(25);
            }
        }
    }

    .title {
        font-family: $antenna-font-condensed-light;
        font-size: pToR(20);
        margin: pToR(13) 0px;
        letter-spacing: 3px;

        .brand-lincoln & {
            font-size: pToR(24);
            line-height: pToR(32);
            letter-spacing: pToR(2.5);
            font-family: $proxima-nova-regular;
            max-width: 20ch;
            margin: 0 auto;

            @include mobile {
                font-size: pToR(16);
                line-height: pToR(26);
                letter-spacing: pToR(1.67);
                max-width: 20ch;
                margin: 0 auto pToR(17);
            }
            @include desktop {
                margin-top: pToR(13);
            }
        }
    }

    .description {
        font-family: $antenna-font;
        letter-spacing: pToR(1);
        line-height: pToR(26);
        text-align: center;
        padding: 0 pToR(8);

        .brand-lincoln & {
            font-size: pToR(16);
            letter-spacing: 0;
            font-family: $proxima-nova-regular;

            @include mobile {
                font-size: pToR(12);
                line-height: pToR(14);
                letter-spacing: normal;
            }
            @include tablet {
                margin-top: pToR(2);
            }
        }
    }
    .fmc-type--heading6 {
        display: none;

        @include tablet-v5 {
            display: block;
        }

        [data-brand='lincoln'] & {
            @include tablet-v5 {
                font-size: 24px;
                line-height: 32px;
                letter-spacing: 2.5px;
                max-width: 21ch;
                margin: 0 auto 10px;
            }
        }
    }
    .fmc-type--heading5 {
        font-size: pToR(20);
        line-height: pToR(29);
        letter-spacing: normal;
        font-weight: var(--fmc-type__weight-bold);

        @include mobile-v5 {
            display: none;
        }
        [data-brand='lincoln'] & {
            font-family: var(--fmc-font--proxima-nova);
            font-size: pToR(17);
            line-height: pToR(27);
            letter-spacing: pToR(2);
            font-weight: 700;
        }
    }
    .fmc-type--body1 {
        .brand-lincoln & {
            @include mobile-v5 {
                display: none;
            }
        }
    }
    .fmc-type--subtitle1 {
        display: block;
        overflow-wrap: break-word;

        @include tablet-v5 {
            display: none;
        }
    }
}
