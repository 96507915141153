@import '@styles/main.v5';
@import '@styles/common';

.preferred-dealer {
    &__view {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        min-height: pToR(500);
        margin: pToR(23) pToR(56) pToR(32);

        @include mobile {
            margin: pToR(8) pToR(16);
        }

        .eu & {
            @include mobile {
                margin: pToR(8) 0;

                .return-button {
                    margin-left: pToR(16);
                }
            }
        }

        .return-button {
            .secondary-button {
                a {
                    padding-left: 0;
                }
            }
        }

        .brand-lincoln & {
            background-color: $fds-color-surface;
            margin: 0;
            padding: pToR(20) pToR(70) pToR(32);

            @include mobile {
                padding: pToR(8) pToR(16);
            }
        }
    }

    &__notification-container {
        max-width: pToR(880);
        width: 100%;
        margin: pToR(16) auto pToR(0);

        @include mobile {
            margin-top: pToR(20);
            margin-bottom: pToR(10);
        }
        .notification-sub-copy {
            p {
                letter-spacing: normal;
            }
        }
    }

    &__component {
        max-width: pToR(880);
        width: 100%;
        margin: pToR(46) auto pToR(70);

        .eu & {
            margin: pToR(46) 0 pToR(70);
            max-width: unset;

            .content-above-map {
                margin: auto;
                max-width: 55rem;

                @include mobile {
                    margin: 0 pToR(16);
                }
            }

            @include mobile {
                margin: pToR(20) 0 pToR(10);
            }
        }

        @include mobile {
            margin-top: pToR(20);
            margin-bottom: pToR(10);
        }

        &--header {
            margin-bottom: pToR(26);

            @include mobile {
                margin-bottom: pToR(29);
            }

            h1 {
                font-family: $ford-f1-font-regular;
                color: $fds-color--primary;
                font-size: pToR(38);
                line-height: pToR(44);
                letter-spacing: normal;

                .brand-lincoln & {
                    color: $fds-preimagine-lincoln-primary;
                    font-family: $lincoln-miller-b-semibold;
                }
            }
            p {
                &:first-of-type {
                    color: #102b4e;
                    font-family: $ford-f1-font-bold;
                    font-size: pToR(16);
                    line-height: pToR(24);
                    letter-spacing: normal;

                    .brand-lincoln & {
                        font-family: $proxima-nova-bold;
                        color: $fds-preimagine-lincoln-primary;
                    }
                }
                &:last-of-type {
                    color: #102b4e;
                    font-family: $ford-f1-font-regular;
                    font-size: pToR(16);
                    line-height: pToR(24);
                    letter-spacing: normal;

                    .brand-lincoln & {
                        font-family: $proxima-nova-regular;
                        color: $fds-preimagine-lincoln-primary;
                    }
                }
            }
        }

        &--radio-button-container {
            display: flex;
            flex-direction: row;
            align-items: center;

            .radio-button-group__label {
                margin-right: pToR(8);
            }

            .fmc-check label span {
                margin-left: pToR(4);
            }

            .radio-button-group__label,
            .fmc-check label span {
                color: $fds-preimagine-dark-charcoal;
                font-family: $ford-f1-font-regular;
                font-size: pToR(14);
                line-height: pToR(24);
                letter-spacing: normal;

                .brand-lincoln & {
                    color: $fds-preimagine-lincoln-primary;
                    font-family: $proxima-nova-regular;
                }
            }

            .fmc-check {
                --checked-color: #0068d0;
                --dot-diameter: 24px;
                --button-diameter: 30px;

                .brand-lincoln & {
                    --checked-color: #f26147;
                    --dot-diameter: 12px;
                    --button-diameter: 22px;
                }

                @include mobile {
                    --dot-diameter: 18px;
                    --button-diameter: 24px;
                }

                &.fmc-check--horizontal label:not(:first-of-type) {
                    margin-left: pToR(4);
                }

                legend {
                    margin-bottom: unset;
                }

                input[type='radio'] {
                    --button-outline-color: #4d4d4d;
                    --button-outline-thickness: 1px;

                    .brand-lincoln & {
                        --button-outline-color: #717171;
                    }
                }

                input[type='radio']:checked {
                    --button-outline-color: #0068d0;

                    .brand-lincoln & {
                        --button-outline-color: #f26147;
                        --button-outline-thickness: 2px;
                    }
                }
            }
        }

        &--search-box {
            position: relative;
            margin-top: pToR(14);

            label {
                display: none;
            }

            input {
                color: black;
                font-family: $ford-f1-font-regular;
                font-size: pToR(14);
                line-height: pToR(24);
                border: 1px solid $text-gray;
                width: 100%;
                height: pToR(40);
                padding: 0 pToR(16);
                border-radius: pToR(8);

                @include mobile {
                    line-height: pToR(40);
                }

                .brand-lincoln & {
                    color: $fds-preimagine-lincoln-primary;
                    background-color: $fds-color-surface;
                    font-family: $proxima-nova-regular;
                }
            }

            .clear-button {
                position: absolute;
                padding: pToR(12);
                right: pToR(10);
                top: 2px;

                &:focus {
                    outline: 2px solid $fds-preimagine-dark-charcoal;
                }
            }

            .preferred-dealer-search-submit-button {
                border-radius: pToR(400);
                height: pToR(40);
                min-width: pToR(135);
                padding: pToR(12) pToR(16);
                background-color: $fds-preimagine-button-blue;
                color: white;
                font-size: pToR(14);
                line-height: pToR(20);
                letter-spacing: normal;
                font-family: $ford-f1-font-bold;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: pToR(24);

                &:hover {
                    background-color: $fds-preimagine-primary-button-hover;
                }

                &.disabled {
                    &:after {
                        background-color: $fds-reimagine-disabled-background-color;
                        color: $fds-reimagine-disabled-text-color;
                    }
                }

                .brand-lincoln & {
                    font-family: $proxima-nova-regular;
                    font-weight: 700;
                    border-radius: 0;

                    &:focus-visible {
                        outline: 1px solid $fds-preimagine-lincoln-primary;
                    }
                }
            }

            .preferred-dealer__error-message {
                font-family: $ford-f1-font-medium;
                font-size: pToR(14);
                line-height: pToR(20);
                color: $severe;
                letter-spacing: normal;
                margin-top: pToR(8);

                .brand-lincoln & {
                    color: $brand-light-error;
                    font-family: $proxima-nova-regular;
                }
            }
        }

        .preferred-dealer__component--search-and-map {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: pToR(16);
            margin-top: pToR(48);

            @include mobile {
                display: flex;
                flex-direction: column-reverse;
                margin-top: pToR(46);
            }

            .preferred-dealer__component--search-results {
                display: grid;
                grid-template-columns: 1fr;
                grid-gap: pToR(16);
                margin-bottom: pToR(48);
                max-height: pToR(656);
                overflow-y: scroll;

                @include mobile {
                    grid-gap: pToR(24);
                }
                &.remove-scroll {
                    overflow-y: unset;
                }
            }

            .map-area {
                height: pToR(344);
                width: 100%;
                background-color: mediumpurple;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border-radius: pToR(8);
                font-family: $ford-f1-font-medium;
                font-size: pToR(24);

                @include mobile {
                    height: pToR(202);
                }
            }
        }
        .preferred-dealer__component--eu-search-and-map {
            height: 100%;
            position: relative;
            width: 100%;
            margin: pToR(32) auto 0;
            max-width: pToR(1328);

            .eu-map-container {
                height: pToR(610);

                @include mobile {
                    max-height: pToR(458);
                }
                .gm-fullscreen-control,
                .gm-bundled-control {
                    @include tablet {
                        left: 0 !important;
                        right: unset !important;
                        bottom: pToR(103) !important;
                    }

                    @include mobile {
                        bottom: unset !important;
                        top: pToR(48) !important;
                    }
                }
            }

            .search-list-container {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                width: pToR(460);
                height: 100%;
                background-color: transparent;
                z-index: 1;
                overflow-y: auto;
                padding-right: pToR(10);
                margin-right: pToR(20);
                padding-top: pToR(64);

                @include mobile {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    position: relative;
                    top: unset;
                    bottom: pToR(64);
                    padding-top: 0;
                    overflow-x: hidden;
                    scrollbar-width: none;
                }
                .carousel__slide--dealer {
                    margin-right: pToR(16);
                }

                .filter-container {
                    margin-top: pToR(20);
                    display: flex;
                    align-items: center;
                    margin-bottom: pToR(20);
                }

                .filter-button {
                    display: flex;
                    align-items: center;
                    background-color: $white;
                    color: $fds-v4-primary;
                    border-radius: pToR(20);
                    padding: pToR(10);
                    font-size: pToR(16);
                    cursor: pointer;
                    margin-right: pToR(20);
                    margin-bottom: pToR(30);
                    min-height: pToR(40);
                    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2),
                        0 0 6px rgba(0, 0, 0, 0.2);
                    .filters-text {
                        color: $fds-v4-primary;
                        font-family: $ford-f1-font-regular;
                        font-size: pToR(18);
                        line-height: pToR(24);
                    }
                }

                .filter-button img {
                    margin-left: pToR(15);
                    margin-right: pToR(15);
                }
                .dealer-info-box-wrapper {
                    background-color: $white;
                    border-radius: pToR(10);
                    padding: pToR(16);
                    margin-bottom: pToR(28);
                    position: relative;
                    width: 100%;

                    @include mobile {
                        height: fit-content;
                    }

                    .preferred-dealer-label {
                        display: flex;
                        background-color: $background-color;
                        color: $white;
                        font-family: $ford-f1-font-regular;
                        font-size: pToR(12);
                        border-radius: pToR(10);
                        padding: pToR(5) pToR(15);
                        position: absolute;
                        top: pToR(-29);
                        left: 0;
                        margin-top: pToR(5);
                        box-sizing: border-box;
                        height: pToR(36);
                        z-index: -1;
                    }
                }

                .view-more-container {
                    margin: pToR(20) 0;
                    display: flex;
                    align-items: center;

                    .view-more-button {
                        display: flex;
                        align-items: center;
                        background-color: $white;
                        border-radius: pToR(20);
                        padding: pToR(10);
                        font-size: pToR(16);
                        cursor: pointer;
                        height: pToR(40);
                        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2),
                            0 0 6px rgba(0, 0, 0, 0.2);

                        .view-more-text {
                            color: $fds-v4-primary;
                            font-family: $ford-f1-font-regular;
                            font-size: pToR(18);
                            line-height: pToR(24);
                            padding-right: pToR(15);
                        }
                    }
                }
                /* Customize the scrollbar */
                &::-webkit-scrollbar {
                    width: pToR(8);
                    height: pToR(5);
                }

                &::-webkit-scrollbar-track {
                    background-color: $gray;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $quicklink-textactive;
                    border-radius: pToR(10);
                }

                &::-webkit-scrollbar-thumb:hover {
                    background-color: $quicklink-textactive;
                }
                .dealer-filter-options-container {
                    background-color: $white;
                    height: pToR(610);
                }
            }
        }

        .view-more-button__box {
            display: flex;
            border: 1px solid #c5c5c5;
            border-radius: pToR(8);
            width: 100%;
            padding: pToR(20);

            .eu & {
                border: none;

                @include mobile {
                    margin-top: pToR(8);
                }
            }

            .view-more-button {
                color: white;
                background-color: $fds-preimagine-primary-blue;
                font-family: $ford-f1-font-bold;
                font-size: pToR(14);
                line-height: pToR(20);
                border-radius: pToR(400);
                padding: pToR(12) pToR(24);
                margin: 0 auto;
                position: relative;
                z-index: 1;

                &:hover {
                    background-color: $fds-preimagine-primary-button-hover;
                }

                &:focus-visible {
                    outline: 2px solid $fds-preimagine-primary-blue;
                    outline-offset: 2px;
                }

                .brand-lincoln & {
                    background-color: $fds-preimagine-lincoln-primary;
                    font-family: $proxima-nova-regular;
                    display: flex;
                    align-items: center;
                    border-radius: 0;
                    height: pToR(50);
                    padding: pToR(8) pToR(20);

                    &:focus {
                        outline: 2px solid $fds-preimagine-dark-charcoal;
                        outline-offset: 2px;
                    }
                    &:hover {
                        .lincoln-underline-button-overlay {
                            @include lincoln-underline-overlay();
                        }
                    }
                }
            }
        }
    }
    &__horizontal-rule {
        max-width: calc(100vw - 112px);
        margin: 0 pToR(56);
    }

    &__self-help-wrapper {
        .brand-ford & {
            @include tablet {
                margin: 0 pToR(56);
            }
        }
        .self-help-container {
            padding: pToR(50) 0 pToR(151);

            .brand-ford & {
                padding-left: pToR(14);
                padding-right: pToR(14);

                @include mobile {
                    margin: unset;
                    margin-top: pToR(70);
                    padding-top: unset;
                }
            }
        }
    }
}

.replace-preferred-dealer-modal {
    .brand-ford & {
        .fmc-dialog__content {
            .modal-body {
                padding: pToR(24) pToR(40);
                margin-bottom: unset;
                h2 {
                    color: $fds-preimagine-dark-text;
                    font-family: $ford-f1-font-medium;
                    font-size: pToR(24);
                    line-height: pToR(32);
                    letter-spacing: normal;
                    margin-bottom: pToR(15);
                }
                p {
                    color: $fds-preimagine-dark-text;
                    font-family: $ford-f1-font-regular;
                    font-size: pToR(16);
                    line-height: pToR(24);
                    letter-spacing: normal;
                }
            }
            .modal-footer {
                padding: pToR(16) pToR(40);
                @include desktop {
                    margin-bottom: unset;
                }
            }
        }
    }
    .brand-lincoln & {
        .modal-content {
            background-color: #f4f4f4;

            .modal-body {
                align-items: center;
                margin-top: pToR(50);

                h2 {
                    color: $fds-preimagine-lincoln-primary;
                    font-family: $proxima-nova-regular;
                    font-size: pToR(24);
                    line-height: pToR(29);
                    letter-spacing: normal;
                    text-transform: uppercase;
                }
                p {
                    color: $fds-preimagine-lincoln-primary;
                    font-family: $proxima-nova-regular;
                    font-size: pToR(16);
                    line-height: pToR(24);
                    letter-spacing: normal;
                    margin-top: pToR(12);
                    margin-bottom: pToR(14);
                    text-align: center;
                    max-width: 37ch;
                }
            }
            .modal-footer {
                flex-direction: column;
                align-items: center;

                .fmc-button {
                    width: fit-content;
                }
                .secondary-button {
                    margin-top: pToR(40);
                }
            }
        }
    }
}
