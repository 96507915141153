@import '@styles/main.v5';

.delete-account-view {
    background-color: $background-color;
    display: flex;
    flex-direction: column;

    @include tablet-v5 {
        padding: pToR(40) pToR(100) pToR(90);
    }

    .return-to-account-button {
        align-self: flex-start;
        margin-bottom: pToR(45);

        @include mobile-v5 {
            margin-top: pToR(42);
            padding-left: pToR(20);
        }
    }
}
