@import '@styles/main.scss';

.language-toggle-container {
    color: $black;
    font-family: inherit;
    font-weight: inherit;
    margin-right: pToR(32);

    @include mobile {
        margin-right: unset;
    }

    @include desktop {
        margin-right: unset;
    }

    .language-toggle {
        display: inline-block;
        position: relative;
        height: 100%;
        text-align: left;
        margin-right: pToR(8);

        @include desktop {
            width: pToR(108);
            text-align: center;
            margin-right: unset;
        }

        img {
            margin-right: pToR(23);
            vertical-align: middle;
            width: pToR(18);
            height: pToR(18);

            @include tablet {
                display: inline-flex;
                margin-right: pToR(15);
                vertical-align: middle;
                width: auto;
                height: auto;
            }
        }
        .toggle-text {
            display: none;
            position: absolute;
            left: pToR(30);
            top: pToR(3);
            @include desktop {
                display: inline;
                position: static;
            }
        }
    }

    .language-panel {
        box-sizing: border-box;
        position: absolute;
        background-color: white;
        text-align: center;
        border: 1px solid $lightgrey;

        @media screen and (max-width: $mobile-width) {
            top: auto;
            right: 0;
            width: 100%;
        }

        @media screen and (min-width: $small-desktop-width) {
            min-width: pToR(103);
            top: pToR(83);
            right: pToR(17);
        }

        ul {
            list-style: none;
            padding: 0 pToR(15);

            li {
                padding: pToR(16) 0;
                margin-left: 0;

                .language-option {
                    padding: pToR(15) pToR(6);
                }

                button {
                    width: 100%;
                    border-top: 1px solid #ddd;

                    @include desktop {
                        width: auto;
                    }
                }
            }
        }
    }

    &__th {
        display: flex;
        line-height: pToR(16);

        .language-option {
            color: $fds-v5-primary;
            font-size: pToR(11);
            font-family: $antenna-font;
            line-height: pToR(16);
            letter-spacing: 1px;
            padding: pToR(15) pToR(8);

            @include mobile {
                font-size: pToR(16);
            }
        }

        .selected-language {
            color: $fds-grayed-out;
            pointer-events: none;
        }

        .language-separator {
            color: $fds-v5-primary;
            font-size: pToR(11);
            line-height: pToR(16);

            @include mobile {
                font-size: pToR(16);
            }
        }

        .language-separator-hide {
            display: none;
        }
    }
}
