@import '@styles/main.scss';

.communication-preference {
    &-container {
        display: flex;
        flex-direction: column;
        background-color: $background-color;
        .return-button {
            padding-left: pToR(20);
            margin-top: pToR(50);
            margin-bottom: pToR(20);

            @include tablet-v5 {
                padding-left: pToR(64);
            }
            @include desktop-v5 {
                padding-left: pToR(100);
                margin-top: pToR(40);
                margin-bottom: pToR(10);
            }
        }

        &-edit {
            display: flex;
            flex-direction: column;
            background-color: #ffffff;

            .return-button {
                padding-left: 0;
                margin-top: pToR(50);
                margin-bottom: pToR(20);

                @include tablet-v5 {
                    padding-left: 0;
                }
                @include desktop-v5 {
                    padding-left: 0;
                    margin-top: pToR(40);
                    margin-bottom: pToR(10);
                }
            }
            .communication-preferences-form-container {
                display: flex;
                max-width: pToR(1105);
                width: 100%;
                flex-direction: column;
                margin: auto;

                @include mobile {
                    max-width: -webkit-fill-available;
                    margin-left: pToR(14);
                    margin-right: pToR(14);
                }

                @include tablet {
                    max-width: -webkit-fill-available;
                    padding-left: pToR(58);
                    padding-right: pToR(58);
                }

                @include desktop {
                    max-width: pToR(1105);
                }

                &.email-channel-preferences-container {
                    padding-top: pToR(55);

                    @include desktop-v5 {
                        padding-top: pToR(65);
                    }

                    .fmc-type--heading6 {
                        font-size: pToR(24);
                        line-height: pToR(29);
                        letter-spacing: normal;
                        font-weight: 500;
                        margin-bottom: pToR(23);
                    }
                    .fmc-type--content2 {
                        font-size: pToR(12);
                        line-height: pToR(21);
                        letter-spacing: normal;
                        font-weight: normal;
                        margin-bottom: pToR(3);
                    }
                    .modal {
                        &-content {
                            .communication-preference-modal-footer {
                                @include tablet-v5 {
                                    margin-bottom: pToR(16);
                                }
                            }
                        }
                    }
                }

                .account-view-section-content {
                    font-family: $ford-f1-font-regular;
                    font-size: pToR(16);
                    line-height: pToR(24);
                    color: #010203;
                    margin-bottom: pToR(16);
                    margin-top: pToR(16);
                    @include mobile {
                        margin-bottom: pToR(55);
                        margin-left: pToR(14);
                    }
                }

                .account-view-section-content-unauth {
                    font-family: $ford-f1-font-regular;
                    font-size: pToR(16);
                    line-height: pToR(24);
                    color: #010203;
                    margin-bottom: pToR(76);
                    margin-top: pToR(16);
                    @include mobile {
                        margin-bottom: pToR(35);
                        margin-left: pToR(14);
                    }
                }

                .account-view-section {
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    line-height: pToR(44);
                    flex-direction: row;

                    &__title {
                        display: inline-flex;
                        align-items: center;
                        max-width: 30ch;
                        font-size: $fds-font-size__heading6--max;
                        line-height: $fds-line-height__heading6;
                        font-weight: 500;
                        margin-left: 0;
                        margin-top: pToR(32);
                        margin-bottom: pToR(96);

                        .brand-ford & {
                            font-family: $ford-f1-font-regular;
                            font-size: pToR(54);
                            line-height: pToR(60);
                            letter-spacing: normal;

                            @include mobile {
                                font-size: pToR(40);
                                line-height: pToR(46);
                                margin-bottom: pToR(96);
                                margin-top: pToR(38);
                            }
                            @include tablet {
                                font-size: pToR(40);
                                line-height: pToR(46);
                                margin-bottom: pToR(96);
                                margin-top: pToR(38);
                            }

                            @include desktop {
                                font-size: pToR(54);
                                line-height: $fds-line-height__heading6;
                                margin-bottom: pToR(96);
                                margin-left: 0;
                            }
                        }
                        .brand-lincoln & {
                            @include mobile {
                                margin-bottom: pToR(10);
                            }
                        }
                    }

                    &__title-unauth {
                        display: inline-flex;
                        align-items: center;
                        max-width: 30ch;
                        font-size: $fds-font-size__heading6--max;
                        line-height: $fds-line-height__heading6;
                        font-weight: 500;
                        margin-left: 0;
                        margin-top: pToR(32);
                        margin-bottom: pToR(10);

                        .brand-ford & {
                            font-family: $ford-f1-font-regular;
                            font-size: pToR(54);
                            line-height: pToR(60);
                            letter-spacing: normal;

                            @include mobile {
                                font-size: pToR(40);
                                line-height: pToR(46);
                                margin-bottom: pToR(10);
                                margin-top: pToR(38);
                            }
                            @include tablet {
                                font-size: pToR(40);
                                line-height: pToR(46);
                                margin-bottom: pToR(10);
                                margin-top: pToR(38);
                            }

                            @include desktop {
                                font-size: pToR(54);
                                line-height: $fds-line-height__heading6;
                                margin-bottom: pToR(10);
                                margin-left: 0;
                            }
                        }
                        .brand-lincoln & {
                            @include mobile {
                                margin-bottom: pToR(10);
                            }
                        }
                    }

                    &__subtitle {
                        display: inline-flex;
                        align-items: center;
                        max-width: 30ch;
                        font-size: $fds-font-size__heading6--max;
                        line-height: $fds-line-height__heading6;
                        font-weight: 500;
                        margin-left: 0;
                        margin-bottom: pToR(12);

                        .brand-ford & {
                            font-family: $ford-f1-font-regular;
                            font-size: pToR(32);
                            line-height: pToR(40);
                            letter-spacing: normal;

                            @include mobile {
                                margin-top: 0;
                                font-size: pToR(28);
                                line-height: pToR(36);
                                margin-bottom: pToR(10);
                            }

                            @include tablet {
                                margin-top: 0;
                                font-size: pToR(28);
                                line-height: pToR(36);
                                margin-bottom: pToR(10);
                            }

                            @include desktop {
                                font-size: pToR(32);
                                line-height: $fds-line-height__heading6;
                                margin-bottom: pToR(12);
                                margin-left: 0;
                            }
                        }
                        .brand-lincoln & {
                            @include mobile {
                                margin-bottom: pToR(10);
                            }
                        }
                    }

                    .edit-button-container {
                        padding: 0;
                        margin-left: 0;
                        @include mobile {
                            margin-bottom: pToR(16);
                            .fmc-text-button {
                                font-family: $ford-f1-font-regular;
                                line-height: 1.91rem;
                                text-decoration: underline;
                                font-size: pToR(16);
                                letter-spacing: normal;
                                .brand-lincoln & {
                                    line-height: 2.2rem;
                                }
                            }
                        }
                        @include tablet {
                            margin-bottom: pToR(16);
                            display: inline-flex;
                            align-items: center;
                            .fmc-text-button {
                                font-family: $ford-f1-font-regular;
                                line-height: 1.91rem;
                                text-decoration: underline;
                                .brand-lincoln & {
                                    line-height: 2.2rem;
                                }
                            }
                        }
                        @include desktop {
                            margin-left: 0;
                            margin-bottom: 0;
                            display: inline-flex;
                            align-items: center;
                            .fmc-text-button {
                                font-family: $ford-f1-font-regular;
                                line-height: 1.91rem;
                                text-decoration: underline;
                                .brand-lincoln & {
                                    line-height: 2.2rem;
                                }
                            }
                        }
                    }
                    .link-button {
                        padding: pToR(10) 0;
                    }

                    @include mobile {
                        display: block;
                        line-height: pToR(34);
                        width: unset;
                        max-width: 100%;
                        margin-bottom: 0;
                        flex-direction: column;

                        .brand-lincoln & {
                            .link-button {
                                padding: 0;
                            }
                        }
                    }
                    @include tablet {
                        margin-top: pToR(32);
                        flex-direction: column;
                    }

                    @include desktop {
                        margin-top: 0;
                        flex-direction: row;
                    }

                    h3 {
                        margin-bottom: unset;
                    }
                }

                .hr-line {
                    height: pToR(1);
                    border-bottom: pToR(1) solid $disabled-dark;
                    box-shadow: none;
                    margin-bottom: pToR(8);
                    width: 100%;

                    @include mobile {
                        visibility: visible;
                    }

                    @include tablet {
                        visibility: visible;
                    }

                    @include desktop {
                        width: 100%;
                        margin-left: 0;
                        visibility: visible;
                    }

                    .brand-lincoln & {
                        border: 0;
                        border-bottom: pToR(1) solid $brand-gray3;

                        @include mobile {
                            border: 0;
                            border-bottom: pToR(1) solid $brand-gray3;
                        }
                    }
                }

                .mB0 {
                    margin-bottom: 0;

                    .brand-lincoln & {
                        margin-top: pToR(2);

                        @include mobile {
                            margin-top: 0;
                        }
                    }
                }
                .email-communication-container {
                    display: flex;
                    flex-direction: row;
                    color: #010203;
                    margin-top: pToR(51);
                    margin-bottom: pToR(128);
                    .email-communication-label {
                        font-size: pToR(24);
                        font-family: $ford-f1-font-regular;
                        font-weight: 500;
                        line-height: pToR(30);
                        letter-spacing: 0;
                        @include mobile {
                            margin-bottom: pToR(5);
                            font-family: $ford-f1-font-regular;
                        }
                    }
                    .email-communication-status {
                        font-size: pToR(16);
                        line-height: pToR(30);
                        margin-left: 0;
                        @include mobile {
                            margin-left: 0;
                        }
                        @include tablet {
                            margin-left: 0;
                        }
                        @include desktop {
                            margin-left: pToR(40);
                        }
                    }
                    @include mobile {
                        flex-direction: column;
                        margin-top: pToR(24);
                        margin-bottom: pToR(64);
                    }

                    @include tablet {
                        flex-direction: column;
                        margin-top: pToR(24);
                        margin-bottom: pToR(64);
                    }

                    @include desktop {
                        flex-direction: row;
                        margin-left: 0;
                        margin-top: pToR(51);
                        margin-bottom: pToR(128);
                    }
                }
                @include mobile {
                    margin-bottom: pToR(5);
                }
                @include tablet {
                    margin-bottom: pToR(5);
                }
            }
            .hr-line {
                @include mobile {
                    visibility: hidden;
                }
                @include tablet {
                    visibility: hidden;
                }
                @include desktop {
                    max-width: pToR(1328);
                    margin: auto;
                    visibility: visible;
                }
            }
        }
    }

    &-top-section-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: pToR(12) auto pToR(29);
        width: 100%;
        max-width: calc(100% - 40px);

        @include tablet-v5 {
            max-width: calc(100% - 128px);
        }
        @include desktop-v5 {
            max-width: calc(100% - 200px);
        }
    }

    &-section-name {
        text-align: center;
        letter-spacing: normal;

        .brand-lincoln & {
            text-transform: uppercase;
        }

        @include mobile-v5 {
            text-align: center;
        }
    }

    &-section-content {
        text-align: center;

        @include mobile-v5 {
            max-width: 28ch;
        }

        @include tablet-v5 {
            max-width: 63ch;

            .brand-lincoln & {
                max-width: 54ch;
            }
        }
    }

    &-user-input-section-wrapper {
        box-sizing: border-box;
        border-radius: pToR(3);
        background-color: $white;
        width: 100%;
        margin: 0 auto;

        .brand-ford & {
            border: pToR(1) solid #979797;
            box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1),
                0 10px 10px 0 rgba(0, 0, 0, 0.1);
        }

        @include mobile-v5 {
            max-width: calc(100% - 40px);
            margin-bottom: pToR(77);
        }

        @include tablet-v5 {
            max-width: calc(100% - 128px);
            margin-bottom: pToR(96);
        }

        @include desktop-v5 {
            max-width: pToR(1240);
            width: calc(100% - 200px);
        }
    }

    &-user-input-section {
        border-radius: pToR(3);
        padding: pToR(66) pToR(64) pToR(42);

        @include mobile-v5 {
            padding: pToR(48) pToR(20) pToR(64);
        }
    }

    &-hr-line {
        transform: scaleY(-1);
        background-color: #d8d8d8;
        width: 75%;
        height: pToR(1);

        @include mobile {
            width: 100%;
        }
    }

    &-user-input {
        display: flex;
    }

    &-user-input-checkbox {
        width: auto;
        margin-top: pToR(7);
        cursor: pointer;
        margin-right: pToR(15);
    }

    &-user-input-label {
        font-weight: 500;

        &.fmc-type--heading6 {
            @include desktop-v5 {
                font-size: pToR(24);
                line-height: pToR(29);
            }
        }
    }

    &-user-input-help-text {
        padding-top: pToR(12);
        padding-left: pToR(41);

        [data-brand='lincoln'] & {
            padding-left: pToR(34);
        }

        @include mobile-v5 {
            padding-top: pToR(6);
        }
    }

    &-btn-wrapper {
        display: flex;
        margin-top: pToR(60);
        margin-bottom: pToR(30);

        @include mobile-v5 {
            margin-top: pToR(45);
            margin-bottom: 0;
        }
        [data-brand='ford'] & {
            .fmc-button {
                width: pToR(100);
            }
        }
    }

    &-btn-cancel {
        margin-left: pToR(40);
        font-size: pToR(18);
    }

    &-modal-content {
        @include desktop-v5 {
            width: 60%;
        }
    }
    &-modal-footer {
        margin-bottom: 4rem;
    }
    &-success-modal {
        color: $text-gray;
        font-family: $antenna-font-condensed-light;
        letter-spacing: pToR(3);
        line-height: pToR(34);
        text-align: center;
        font-size: pToR(24);
        padding-bottom: pToR(16);

        .brand-lincoln & {
            font-family: $proxima-nova-regular;
            font-size: pToR(24);
            line-height: pToR(32);
            letter-spacing: 0;
        }
    }
    &-unsubscribe-modal-header {
        color: $text-gray;
        font-family: $antenna-font-condensed-light;
        letter-spacing: pToR(3);
        line-height: pToR(34);
        text-align: center;
        font-size: pToR(24);
        padding-bottom: pToR(16);
        .brand-lincoln & {
            font-family: $proxima-nova-regular;
            font-size: pToR(24);
            line-height: pToR(32);
            letter-spacing: 0;
        }
    }
    &-unsubscribe-modal-content {
        color: $text-gray;
        font-family: $antenna-font-antenna;
        font-size: pToR(12);
        letter-spacing: pToR(1);
        line-height: pToR(21);
        text-align: center;
        margin: auto;
        max-width: 100%;
        padding: 0 pToR(100);
        .brand-lincoln & {
            font-family: $proxima-nova-regular;
            font-size: pToR(16);
            letter-spacing: 0;
            line-height: pToR(24);
        }
        @include mobile-v5 {
            padding: 0 pToR(32);
        }
        @include tablet-v5 {
            padding: 0 pToR(40);
        }
    }
}
.form-field__checkbox:checked + label,
.form-field__checkbox:not(:checked) + label {
    padding-top: unset;
}
