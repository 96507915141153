@import '@styles/main.scss';

.banner {
    .top {
        min-height: 0;
        background-position: top;
        background-size: cover;

        &.no-bottom {
            min-height: pToR(456);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .brand-ford & {
                min-height: pToR(160);
                margin-top: 0;
            }

            .brand-lincoln & {
                margin-bottom: pToR(20);
                @include mobile {
                    margin-bottom: unset;
                }
            }

            @include mobile {
                background-position: 50%;
                .brand-ford & {
                    min-height: pToR(140);
                }
            }
            @include tablet {
                .brand-ford & {
                    margin-top: pToR(25);
                    margin-bottom: unset;
                }
            }

            @include desktop {
                .dynamic-masthead-state & {
                    min-height: pToR(160);
                    margin-top: 40px;
                    background-position: 50%;
                }

                .no-carousel-market & {
                    min-height: unset;
                }
            }
        }

        .cta {
            text-align: center;
            padding: 0;
            max-width: pToR(700);
            margin: 0 auto;

            @include desktop {
                max-width: 100%;
                margin: 0 pToR(18);
            }
        }
    }

    .bottom-container {
        .brand-lincoln & {
            height: pToR(0);
        }

        .bottom {
            position: relative;
            display: flex;
            justify-content: center;
        }
    }
}

.search-bar {
    .banner {
        .top {
            min-height: pToR(450);
        }

        .search-eu {
            min-height: auto;
        }
    }
}
