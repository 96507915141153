@import '@styles/main.v5';
@import '@styles/common';

.user-account-tile-link {
    .secondary-button & {
        display: block;
        height: pToR(56);
        width: 100%;
        margin: pToR(5) 0 pToR(24);
        max-width: pToR(358);
    }
}

.user-account-tile {
    &__container {
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        border-radius: pToR(12);
        background-color: $fds-reimagine-accessories-card-background-color;
        border: 1px solid $fds-reimagine-divider-color;
        padding: 0 pToR(14) 0 pToR(16);

        span:first-child {
            display: flex;
            margin-right: pToR(11);
        }
        p {
            color: black;
            font-family: $ford-f1-font-regular;
            font-size: pToR(16);
            line-height: pToR(24);
            letter-spacing: normal;
            margin-right: auto;
        }
        .secondary-nav-person-icon {
            width: pToR(14.4);
        }
        .secondary-nav__right-caret {
            display: flex;
            margin-left: pToR(24);
        }
    }
}
