@mixin tablet {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: 992px) {
        @content;
    }
}

@mixin md-desktop {
    @media (min-width: 1440px) {
        @content;
    }
}

@mixin xl-desktop {
    @media (min-width: 1700px) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: 767px) {
        @content;
    }
}

@mixin mobile-v5 {
    @media (max-width: 599.99px) {
        @content;
    }
}
@mixin tablet-v5 {
    @media (min-width: 600px) {
        @content;
    }
}
@mixin desktop-v5 {
    @media (min-width: 1024px) {
        @content;
    }
}
@mixin mobile-and-tablet {
    @media (max-width: 991.99px) {
        @content;
    }
}
