@import '@styles/main.v5';
@import '@styles/common';
@import '@views/account-settings-view/account-settings-view.scss';

.user-account-edit-container {
    background-color: $white;
    width: 100%;
    border: 1px solid #979797;
    border-radius: 3px;

    .brand-ford & {
        border: 0;
        max-width: pToR(542);
    }

    .brand-lincoln & {
        padding: pToR(30) pToR(16) 0;

        @include tablet {
            padding: pToR(48) pToR(96) pToR(64);
        }
    }

    @include mobile-v5 {
        width: 100%;

        .brand-ford & {
            width: -webkit-fill-available;
            max-width: 100%;
            padding-bottom: 0;
            margin-bottom: 0;
        }

        .brand-lincoln & {
            padding: pToR(31) pToR(20) 0;
        }
    }
    @include tablet-v5 {
        &:first-of-type {
            margin-bottom: pToR(22);
        }
    }

    .input-section-container {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include mobile-v5 {
            &.input-city-state,
            &.email-container,
            &.phone-number {
                .form-field {
                    margin-bottom: pToR(34);

                    &:last-of-type {
                        margin-bottom: pToR(29);

                        .brand-ford & {
                            margin-bottom: pToR(24);
                        }
                    }
                }
            }
            &.email-container {
                margin-bottom: pToR(12);
            }
        }
    }

    .phone-number {
        label {
            @include mobile {
                letter-spacing: 1px;
            }
        }
    }
    .input-state-zip-container {
        display: flex;
        width: 100%;
    }

    .error-text {
        padding-top: pToR(2);
        color: $fds-preimagine-ford-error;
        font-family: $ford-f1-font-regular;
        font-size: pToR(12);
        line-height: pToR(16);
        letter-spacing: normal;

        .brand-lincoln & {
            color: $brand-light-error;
            font-family: $proxima-nova-regular;
            font-size: pToR(13);
        }
    }
    .user-address {
        color: $dark-blue;

        @include mobile-v5 {
            margin-bottom: pToR(10);
            .brand-lincoln & {
                margin-bottom: 4px;
            }
        }
    }
    .add-address {
        background: none;
        border: none;
        cursor: pointer;
        color: $dark-blue;
        font-family: $antenna-font-condensed-regular;
        @include mobile {
            width: auto;
        }
    }
    .add-address:hover {
        text-decoration: underline;
        color: $light-blue;
    }
    .add-address-text {
        font-size: pToR(18);
        line-height: pToR(13);
        margin-right: pToR(6);
        display: inline-block;
        margin-bottom: pToR(40);
        letter-spacing: normal;

        .brand-ford & {
            font-family: $ford-f1-font-medium;
        }
    }
    .add-address-icon-img {
        width: pToR(16);
        margin-left: pToR(10);
        height: pToR(16);
        vertical-align: bottom;
    }
    .add-address-icon {
        padding-left: pToR(10);
        color: inherit;
    }
    .user-input-select-container {
        padding: 0;

        &:first-of-type {
            margin-bottom: pToR(36);
        }
        .form-field {
            margin-bottom: unset;

            .brand-lincoln & {
                height: pToR(60);
                display: block;

                select {
                    height: 100%;
                    padding-left: pToR(14);
                }
            }
        }

        label {
            color: $fds-preimagine-dark-text;
            font-size: pToR(14);
            position: relative;
            font-family: $ford-f1-font-regular;
            line-height: pToR(20);

            .brand-lincoln & {
                font-size: pToR(13);
                position: absolute;
                font-family: $proxima-nova-regular;
                color: $brand-secondary;
                letter-spacing: 0;
                line-height: pToR(13);
                top: pToR(9);
                left: pToR(14);
            }
        }

        select {
            .brand-ford & {
                background-color: white;
                border-radius: pToR(8);
                height: pToR(40);
                font-family: $ford-f1-font-regular;
                font-size: pToR(14);
                border: 1px solid $fds-preimagine-disabled-text-color;

                &:focus {
                    box-shadow: none;
                }
                &:focus-visible {
                    outline: 2px solid $fds-preimagine-primary-blue;
                    outline-offset: 0;
                    border-color: transparent;
                }
            }

            display: inline-block;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-appearance: none;
            -moz-appearance: none;
        }

        @include mobile {
            .brand-lincoln & {
                .form-field {
                    margin-bottom: 3px;
                }
            }
        }
        @include tablet-v5 {
            margin-bottom: pToR(34);
            .brand-ford & {
                margin-bottom: pToR(16);
            }
        }
        @include desktop-v5 {
            padding: 0;
            text-align: left;
            color: $dark-blue;
            margin-bottom: pToR(36);

            .brand-ford & {
                margin-bottom: pToR(16);
            }
        }
    }

    .btn-container {
        @include mobile {
            padding-bottom: pToR(64);
        }
    }
    .btn-container,
    .units-of-measurement-buttons-container {
        display: flex;
        justify-content: center;
        padding-top: pToR(15);

        &.error {
            margin-top: pToR(20);
        }
        .brand-ford & {
            justify-content: flex-end;

            @include mobile {
                flex-direction: row;
            }
            .fmc-text-button {
                @include ford-secondary-button-default();
            }
            .fmc-button {
                margin-left: pToR(24);
                margin-right: 0;
            }
        }
        .brand-lincoln & {
            .fmc-button {
                @include tablet-v5 {
                    margin-right: pToR(40);
                }
            }
            .link-button {
                margin: 0;
                padding: 0;
            }
        }
        @include mobile-v5 {
            margin-top: 0;

            &.error {
                margin-top: pToR(20);
            }

            .brand-lincoln & {
                flex-direction: column;
                align-items: center;
                max-width: unset;
                width: 100%;
                margin: 0;

                .primary-button,
                .link-button {
                    width: unset;
                }
                .link-button {
                    margin-left: unset;
                    height: pToR(50);
                    margin-bottom: pToR(10);
                }
            }
        }
    }

    .user-edit-cancel-btn {
        margin-left: pToR(45);
        border: none;
        letter-spacing: pToR(1);
        background-color: transparent;
        color: $dark-blue;
        text-decoration: underline;
        font-size: pToR(18);
        line-height: pToR(13);
    }

    .input-fieldset {
        border: none;

        @include mobile {
            margin-top: pToR(12);

            .brand-lincoln & {
                margin-top: pToR(43);

                &:nth-of-type(2),
                &:nth-of-type(5) {
                    margin-top: pToR(34);
                }
                &:nth-of-type(3),
                &:nth-of-type(4) {
                    margin-top: pToR(15);
                }
            }
        }
    }
    .input-fieldset-name {
        margin-top: 0;
        border: none;
    }

    .user-input-error-notice {
        & ~ .form-field__label {
            color: $brand-light-error;
        }
    }
    .server-validation-errors {
        padding: pToR(17) pToR(15) pToR(0) 0;

        @include mobile {
            .brand-lincoln & {
                padding-bottom: pToR(7);
            }
        }
    }

    .form-field {
        .form-field__label {
            color: $fds-preimagine-dark-text;
            letter-spacing: normal;

            .brand-lincoln & {
                color: $brand-secondary;

                &.error-label {
                    color: $brand-light-error;
                }
            }
        }

        @include tablet-v5 {
            margin-bottom: pToR(36);
        }
    }

    .brand-lincoln & {
        .user-information-wrapper {
            padding-left: pToR(20);
        }
        .primary-button {
            &.user-edit-update-btn {
                &:hover {
                    background-color: $brand-secondary;
                }

                &:active:focus {
                    background-color: $brand-black;
                }
            }
        }
        .secondary-button {
            &.user-edit-cancel-btn {
                padding: pToR(24);
                height: pToR(56);
            }
        }
    }

    @include media('<fds-xs') {
        .btn-container {
            flex-direction: column;

            .fmc-button {
                margin: auto;
            }
        }
    }
}
.fmc-floating-label input:focus ~ label .fmc-floating-label__text:after {
    transform: none;
}
.fmc-input--error.syndicated-input {
    .brand-ford & {
        &:focus-visible {
            outline: 2px solid $fds-preimagine-ford-error;
            border-color: transparent;
        }
    }
}
