@import '@styles/main.scss';

.sidebar-container {
    background-color: white;
    display: flex;
    flex-direction: row;
    height: 100vh;
    position: absolute;
    top: pToR(64);
    width: 100%;
    z-index: 999;
    flex-wrap: nowrap;
    outline: none;
    overflow-y: auto;

    .mobile-language-container {
        padding: pToR(12);
        display: flex;
        justify-content: center;

        .toggle-text {
            display: inline;
            position: static;
        }
    }

    ul {
        margin: 0;
        list-style: none;
        padding-left: 0;

        li {
            list-style-type: none;
        }
    }

    .sidebar-menu-container {
        width: 100%;

        li {
            &.selected-sidebar-menu-item {
                border-right: 0;
                outline: none;
            }
        }

        .sidebar-menu-item {
            font-family: FordAntennaCondensedRegular, 'Arial', 'Helvetica',
                sans-serif;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0;
            height: 4rem;
            line-height: 4rem;
            width: 100%;
            padding-left: pToR(32);
            text-align: left;
            outline: none;

            .fmc-type--body1 {
                font-size: pToR(18);
                line-height: pToR(28.8);
            }

            .sidebar-menu-item-text {
                width: 100%;
            }

            .sidebar-menu-item-icon {
                margin-right: 23px;
                vertical-align: middle;
                width: 18px;
                height: 18px;
                display: none;
            }

            .chevron-icon-container {
                position: absolute;
                right: pToR(32);

                .drawer-open-chevron {
                    transform: rotate(180deg);
                    float: right;
                }
            }
        }
    }
}
