@import '@styles/main';
.fds-activity-indicator {
    margin-top: 32px;
}
.fds-activity-indicator--circular:not(.fds--ie11) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: relative;

    &.full-height {
        height: calc(100vh - 120px);
        @include mobile {
            height: calc(100vh - 70px);
        }
    }

    & .fds-activity-indicator__progress-wrapper {
        height: 81px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &::before {
            content: '';
            display: block;
            border: 1px solid $text-gray;
            border-radius: 50%;
            width: 75px;
            height: 75px;
        }
    }

    & .fds-activity-indicator__progress-line {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & .fds-activity-indicator__progress-line_svg {
        width: 81px;
        height: 81px;
        transform: rotate(-90deg);
    }

    & .fds-activity-indicator__progress-line_line--indeterminate {
        stroke-dasharray: 38, 162;
        stroke-dashoffset: 0;
        stroke-width: 2px;
        animation: fds-activity-indicator__animation--circular--indeterminate 3s
            linear infinite;
        stroke-linecap: round;
        stroke: $dark-blue;
    }

    @-webkit-keyframes fds-activity-indicator__animation--circular--indeterminate {
        0% {
            stroke-dashoffset: 200;
        }

        100% {
            stroke-dashoffset: 0;
        }
    }

    @keyframes fds-activity-indicator__animation--circular--indeterminate {
        0% {
            stroke-dashoffset: 200;
        }

        100% {
            stroke-dashoffset: 0;
        }
    }
}

.fds-activity-indicator__notification,
.fds-activity-indicator__instructions {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}
.fds-activity-indicator__center {
    z-index: 99999;
    top: calc(50vh - 35px);
    position: fixed !important;
    left: calc(50vw - 35px);
    @include mobile {
        top: calc(50vh - 35px);
    }
}
