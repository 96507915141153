@import '@styles/main';

.modal.terms-and-conditions {
    font-family: $antenna-font-light;

    .modal-header {
        margin: 0;
    }
    .modal-body {
        background: #ffffff;
        padding: 16px;
        word-break: break-word;

        .tac-header {
            font-family: $antenna-font;
            margin-bottom: 1rem;
            font-size: 1.5rem;
        }
        p {
            margin: 0.5rem 0;

            strong {
                font-family: $antenna-font;
            }
        }
        img {
            max-width: 100%;
        }
    }
}
