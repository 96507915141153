@import '@styles/main.scss';

.change-email-view {
    &-container {
        width: auto;
        height: auto;
        display: flex;
        padding: pToR(12) pToR(100);
        background-color: #f3f3f3;
        flex-direction: column;

        @include mobile {
            padding: pToR(10);
        }
    }
}
