@import '@styles/main.v5';

.account-view-container,
.turkey-credentials-view {
    width: auto;
    display: flex;
    background-color: $background-color;
    flex-direction: column;

    .brand-ford & {
        background-color: white;
        padding: 0;

        @include mobile {
            padding: 0 pToR(16);
        }

        .fmc-type--heading6 {
            font-family: $ford-f1-font-medium;
            color: $fds-preimagine-dark-charcoal;
            font-size: pToR(24);
            line-height: pToR(32);
            letter-spacing: normal;
        }
    }

    .return-button {
        padding-bottom: pToR(38);

        @include tablet {
            margin-left: pToR(28);
        }
        .brand-ford & {
            @include mobile {
                padding-bottom: pToR(18);
            }
            @include tablet {
                margin-top: pToR(26);
                padding-bottom: pToR(32);
            }
        }
        .brand-lincoln & {
            margin-left: 0;
            margin-top: pToR(32);

            @include mobile {
                margin-top: pToR(26);
            }
        }
    }

    .account-settings-notification-container {
        margin-bottom: pToR(37);

        @include tablet {
            margin: 0 pToR(170) pToR(42) pToR(170);
        }
    }

    .user-img-desc {
        margin: pToR(10) auto pToR(43);
        color: $text-gray;

        @include mobile-v5 {
            margin-bottom: pToR(10);
        }
        .brand-ford & {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 0;

            @include tablet {
                margin: 0 pToR(170);
            }
        }

        .user-edit-help-text {
            max-width: 58ch;
            word-break: break-word;
            overflow-wrap: break-word;

            .brand-lincoln & {
                max-width: 55ch;

                @include mobile {
                    max-width: 24ch;
                }
            }

            @include mobile {
                max-width: 26ch;
                margin: 0 auto;
            }
        }

        & + .account-view-section {
            .brand-lincoln & {
                margin-top: pToR(10);

                @include mobile {
                    margin-top: 0;
                }
            }
        }
    }

    .fds-layout-grid {
        padding: 0;
        width: 100%;
        max-width: pToR(1240);

        .brand-ford & {
            max-width: unset;

            .fds-layout-grid__inner {
                &.reskin-inner-grid {
                    @include tablet {
                        margin: 0 pToR(170);
                    }
                }
            }
        }

        .account-view-section {
            .fmc-type--heading6 {
                @include tablet-v5 {
                    margin-bottom: pToR(20);
                }
                .brand-ford & {
                    @include tablet {
                        margin-bottom: pToR(11);
                    }
                }
            }
        }
    }

    .account-settings-view {
        margin: pToR(31) 0 0 pToR(11);

        &:first-of-type {
            .brand-lincoln & {
                @include mobile {
                    margin-top: unset;
                }
            }
        }
    }

    .fmc-type--heading5 {
        font-weight: 500;
        letter-spacing: 0;
        word-break: break-word;

        .brand-ford & {
            font-family: $ford-f1-font-regular;
            font-size: pToR(54);
            line-height: pToR(62);
            text-align: unset;
            margin-bottom: 0;

            @include mobile {
                font-size: pToR(40);
                line-height: pToR(46);
            }
            @include tablet {
                padding-bottom: pToR(14);
            }
        }
    }

    .fmc-type--heading6 {
        .brand-lincoln & {
            @include mobile {
                font-size: pToR(16);
            }
        }
    }

    .account-view-section {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;
        line-height: pToR(44);
        padding-left: 0;

        .brand-ford & {
            justify-content: flex-end;

            @include mobile {
                margin-top: pToR(16);
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: baseline;
            }
        }

        .brand-lincoln & {
            justify-content: center;
        }

        &__title {
            .brand-ford & {
                color: $charcoal;
                font-family: $ford-f1-font-bold;
                font-size: pToR(16);
                line-height: pToR(24);
                letter-spacing: normal;
                margin-bottom: pToR(16);
            }
            .brand-lincoln & {
                text-transform: uppercase;

                @include mobile {
                    margin-bottom: pToR(10);
                }
            }
        }

        .edit-button-container {
            padding: 0;

            @include tablet {
                position: absolute;
                right: 0;
                display: inline-flex;
                align-items: center;

                .fmc-text-button {
                    line-height: 1.91rem;

                    .brand-lincoln & {
                        line-height: 2.2rem;
                    }
                }
            }
        }
        .link-button {
            margin: 0;
            padding: pToR(10) 0;
        }

        @include mobile {
            display: block;
            line-height: pToR(34);
            width: unset;
            max-width: 100%;
            margin-bottom: 0;

            .brand-lincoln & {
                .link-button {
                    padding: 0;
                }
            }
        }
        @include tablet {
            margin-top: pToR(32);
            flex-direction: column;
        }

        h3 {
            margin-bottom: unset;
        }
    }

    .edit-button-container {
        .brand-lincoln & {
            @include mobile-v5 {
                position: relative;
                top: pToR(10);
            }
        }

        .brand-ford & {
            margin-bottom: pToR(8);

            .fmc-text-button {
                font-size: pToR(16);
                font-family: $ford-f1-font-regular;
                line-height: pToR(24);
                letter-spacing: normal;
            }
        }

        .fmc-text-button {
            line-height: pToR(28.8);
        }

        .edit-link {
            background: none;
            letter-spacing: pToR(1);
            border: none;
            cursor: pointer;

            &.hide {
                opacity: 0;
                cursor: default;
            }

            .brand-ford & {
                display: flex;
                align-items: center;
                color: $fds-reimagine-secondary-blue;
                font-size: pToR(14);
                font-family: $ford-f1-font-bold;
                line-height: pToR(20);
                letter-spacing: normal;
                height: pToR(40);
                width: fit-content;
                padding: pToR(12) pToR(16);
                border-radius: pToR(400);

                &:focus-visible {
                    outline: 2px solid $fds-preimagine-high-contrast;
                }

                @include mobile-v5 {
                    margin-top: unset;
                }

                &:hover {
                    color: $fds-reimagine-link-hover-blue;
                }
            }

            .brand-lincoln & {
                font-size: pToR(16);
                font-weight: 700;
                letter-spacing: normal;

                &:hover {
                    text-decoration: underline;
                    text-decoration-color: $brand-border;
                    text-underline-offset: pToR(5);
                    text-decoration-thickness: pToR(2);
                }

                &:focus {
                    outline: unset;
                }

                &:focus-visible {
                    outline: 2px solid $brand-border;
                    outline-offset: 2px;
                }

                .label-text {
                    font-family: $proxima-nova-semibold;
                    font-size: pToR(16);
                    line-height: pToR(20);
                    letter-spacing: 0;

                    @include mobile {
                        border-bottom: 2px solid $brand-border;
                    }
                }
            }

            @include mobile {
                padding-left: 0;
                margin-left: 0;
            }
        }
    }

    .brand-ford & {
        .edit-button-container {
            margin-top: pToR(1);

            @include mobile {
                margin-top: unset;
            }
        }
    }

    .hr-line {
        height: 1px;
        border-bottom: 1px solid $disabled-dark;
        box-shadow: none;
        margin-bottom: pToR(8);
        width: 100%;

        @include mobile-v5 {
            width: 100%;
        }

        .brand-lincoln & {
            border: 0;
            border-bottom: 1px solid $brand-gray3;

            @include mobile {
                border: 0;
                border-bottom: 1px solid $brand-gray3;
            }
        }
    }

    .mB0 {
        margin-bottom: 0;

        .brand-lincoln & {
            margin-top: 2px;

            @include mobile {
                margin-top: 0;
            }
        }
    }
    .user-personal-info-container {
        .brand-lincoln & {
            margin-bottom: pToR(26);

            &:first-of-type {
                margin-bottom: pToR(26);

                @include mobile {
                    margin-bottom: pToR(54);
                }
            }

            &.edit {
                border: 1px solid #979797;
                border-radius: 3px;
            }
        }
        &.personal-information-view {
            .user-information-wrapper {
                align-items: baseline;

                &:first-of-type {
                    @include mobile {
                        padding-top: pToR(25);
                    }
                    @include desktop-v5 {
                        padding-top: pToR(50);
                    }
                }
                &:not(:first-of-type) {
                    @include mobile {
                        padding-top: pToR(30);
                    }
                }
            }
        }

        .sign-in-credentials-view {
            .user-information-wrapper {
                &:first-of-type {
                    @include mobile-v5 {
                        padding-top: pToR(36);
                    }
                    @include desktop-v5 {
                        padding-top: pToR(55);
                    }
                }
            }
        }

        .user-information-wrapper {
            width: 100%;
            color: $text-gray;

            .user-information-name {
                @include mobile-v5 {
                    margin-bottom: pToR(18);
                }
            }

            .brand-ford & {
                .fmc-type--heading6 {
                    font-family: $ford-f1-font-medium;
                    color: $fds-preimagine-dark-charcoal;
                    font-size: pToR(24);
                    line-height: pToR(32);

                    @include mobile {
                        margin-bottom: pToR(9);
                    }
                }
                .fmc-type--body1 {
                    font-family: $ford-f1-font-regular;
                    color: $fds-preimagine-dark-charcoal;
                    font-size: pToR(16);
                    line-height: pToR(24);
                    letter-spacing: normal;
                }
            }
            .brand-lincoln & {
                .fmc-type--heading6 {
                    color: $fds-preimagine-lincoln-primary;
                }
                &:first-of-type {
                    padding-top: pToR(33);

                    @include mobile {
                        padding-top: pToR(24);
                    }
                }

                &:not(:first-of-type) {
                    padding-top: pToR(29);
                }
            }

            @include mobile-v5 {
                display: inline-block;
            }
        }
        &.edit {
            width: 100%;
            background-color: $white;
            border: 1px solid #979797;
            border-radius: 3px;

            @include mobile {
                padding: 0;
                margin-top: 0;
            }
        }
        @include mobile-v5 {
            width: 100%;

            .brand-lincoln & {
                & + .account-view-section {
                    margin-top: 0;
                }
            }
        }
    }

    .fmc-button {
        [data-brand='lincoln'] & {
            @include mobile-v5 {
                width: 100%;
                margin-bottom: pToR(20);
            }
        }
    }
    .fmc-text-button {
        [data-brand='lincoln'] & {
            font-size: pToR(16);
            letter-spacing: normal;
            font-weight: 700;

            @include mobile-v5 {
                margin-bottom: pToR(23);
            }
        }
    }
    .mrgn-lt40 {
        [data-brand='lincoln'] & {
            @include mobile-v5 {
                display: none;
            }
        }
    }
    h1 {
        font-size: $fds-font-size__heading5--max;
        line-height: $fds-line-height__heading5;
    }
    .fmc-type--heading6 {
        font-weight: 500;
    }

    .user-member-id {
        font-weight: 500;

        .brand-ford & {
            font-family: $ford-f1-font-regular;
            color: $fds-preimagine-dark-charcoal;
            font-size: pToR(16);
            line-height: pToR(24);
            letter-spacing: normal;
        }
    }

    @include mobile-v5 {
        padding: 0 pToR(20);
    }
}
.eu-view-account-settings {
    .return-button {
        margin-bottom: pToR(22);

        @include mobile {
            margin-bottom: pToR(26);
        }
    }
    .has-notification-banner {
        .return-button {
            margin-top: pToR(60);
            @include mobile {
                margin-top: pToR(150);
            }
        }
    }
}
.personal-settings-divider {
    max-width: calc(100vw - 32px);
    width: 100%;

    &:first-of-type {
        display: none;

        @include tablet {
            display: block;
        }
    }

    @include tablet {
        max-width: calc(100vw - 112px);

        .brand-ford & {
            &:first-of-type {
                margin-top: pToR(131);
            }
        }
    }
}
