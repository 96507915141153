@import '@styles/main.scss';

.modal {
    &.communication-preference-modal {
        .modal-content {
            .modal-body {
                @include mobile-v5 {
                    padding: 0;
                }
                @include tablet-v5 {
                    margin-top: pToR(51);
                    padding: 0;
                }

                .fmc-type--heading6,
                h2 {
                    font-size: pToR(24);
                    line-height: pToR(29);
                    font-weight: $fds-weight--heading6;
                    letter-spacing: 0;
                    margin-bottom: pToR(23);

                    .brand-ford & {
                        font-family: $ford-f1-font-medium;
                        font-size: pToR(24);
                        font-style: normal;
                        line-height: pToR(32);
                        letter-spacing: normal;
                        color: $fds-preimagine-dark-text;
                        text-align: left;
                    }
                }

                .fmc-type--body2 {
                    .brand-lincoln & {
                        letter-spacing: normal;
                    }

                    .brand-ford & {
                        font-family: $ford-f1-font-regular;
                        font-size: pToR(16);
                        font-style: normal;
                        line-height: pToR(24);
                        letter-spacing: normal;
                        color: $fds-preimagine-dark-text;
                        text-align: left;
                    }
                }

                .brand-ford & {
                    margin-top: unset;
                    flex-direction: unset;
                    width: 85%;
                    text-align: left;
                }
            }

            .modal-footer {
                @include tablet-v5 {
                    margin-bottom: pToR(17);
                }

                .primary-button {
                    margin-right: 0;
                }

                .secondary-button {
                    margin-left: pToR(30);

                    .brand-lincoln & {
                        @include mobile-v5 {
                            margin-left: unset;
                        }
                    }

                    .fmc-text-button {
                        margin-left: unset;
                    }
                }

                .brand-ford & {
                    margin-bottom: unset;
                }
            }
        }
    }
}
.brand-lincoln {
    .fmc-dialog .fmc-dialog__content .fmc-button {
        @include mobile-v5 {
            margin-bottom: pToR(24);
        }
    }
}
