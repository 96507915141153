@import '@styles/main.scss';

.notification-banner-container {
    position: relative;
    width: 100%;

    .eu & {
        @include mobile {
            padding-top: pToR(70);
        }
    }

    .notification-text-wrapper-parent {
        position: absolute;
        height: 0;
        width: 100%;
    }

    &.dealer-consent-success {
        .brand-lincoln & {
            background-color: $brand-light-success-background;

            .fmc-top-banner {
                --alert-color: transparent;

                &__content {
                    margin: unset;
                    display: flex;
                    justify-content: center;
                    width: 100%;
                }

                &__inner {
                    margin-top: unset;
                    background-color: unset;

                    @include tablet {
                        padding-top: pToR(11);
                    }
                    @include media('>=1200px') {
                        padding-top: pToR(8);
                    }

                    .dealer-consent-success__container {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        background-color: $brand-light-success-background;

                        @include media('<390px') {
                            align-items: flex-start;
                        }

                        p {
                            font-size: pToR(16);
                            line-height: pToR(24);

                            &.title {
                                font-weight: 700;
                            }
                        }
                    }
                }
                &__dismiss {
                    position: absolute;
                    right: pToR(16);
                }
            }
        }
    }

    .fmc-top-banner {
        z-index: 99;
        position: relative;
        width: 100%;

        .brand-ford & {
            &.active {
                border-bottom: pToR(8) solid $fds-v5-primary;
            }
            &.fmc-top-banner--default {
                max-height: unset;
                --border-bottom: 0;
                background-color: white;
            }
            .fmc-top-banner__inner {
                padding-top: pToR(25);
                align-items: center;
                max-width: pToR(1440);
                margin: auto;
            }
            @include desktop {
                .fmc-top-banner__inner {
                    padding-top: pToR(20);
                }
            }
        }
    }
}
