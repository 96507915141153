@import '@styles/main.v5';
@import '@styles/common';

.guest-login-component {
    &__container {
        width: 100%;
        background-color: white;
        padding: 0 pToR(16);

        @include tablet {
            padding: 0 pToR(56);
        }

        .header-text {
            color: $charcoal;
            font-family: $ford-f1-font-regular;
            font-size: pToR(48);
            line-height: pToR(56);
            letter-spacing: normal;
            margin-bottom: pToR(28);

            @include mobile {
                font-size: pToR(36);
                line-height: pToR(44);
            }
        }

        .subheader-text {
            color: $fds-preimagine-dark-charcoal;
            font-family: $ford-f1-font-regular;
            font-size: pToR(16);
            line-height: pToR(24);
            letter-spacing: normal;
            margin-bottom: pToR(26);
        }

        .input-container {
            width: 100%;
            margin-bottom: pToR(23);

            @include tablet {
                max-width: pToR(542);
            }
        }

        .fmc-floating-label
            input:not(:placeholder-shown)
            ~ label
            .fmc-floating-label__text:after {
            transform: none;
        }

        .form-field-error {
            .error-text {
                font-family: $ford-f1-font-regular;
                font-size: pToR(12);
                line-height: pToR(16);
                letter-spacing: normal;
                color: $fds-preimagine-ford-error;
            }
        }

        .help-text {
            display: flex;
            flex-direction: row;

            @include mobile {
                flex-direction: column;
            }

            &__prefix,
            .contact-customer-service-link {
                color: $fds-preimagine-dark-charcoal;
                font-family: $ford-f1-font-regular;
                font-size: pToR(16);
                line-height: pToR(24);
                letter-spacing: normal;
            }

            .contact-customer-service-link {
                text-decoration: underline;

                @include tablet {
                    margin-left: pToR(4);
                }
            }
        }

        .fmc-button {
            margin-bottom: pToR(32);

            &:disabled {
                --bg-color: #e5e5e5;
                --bg-color-hover: #e5e5e5;
                background-color: $fds-reimagine-disabled-background-color;
                color: $fds-reimagine-disabled-text-color;
            }
        }
    }

    &__content-container {
        padding: pToR(48) 0 pToR(55);

        @include tablet {
            padding-top: pToR(52);
            padding-bottom: pToR(83);
        }
    }

    &__notification-container {
        margin-bottom: pToR(28);
    }

    &__divider {
        box-shadow: none;
    }
}
