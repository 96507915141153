.brand-ford {
    font-family: $antenna-font;
}

#global-ux {
    overflow-x: visible;
    padding-bottom: 0px !important;
    max-width: 100%;
    @media (min-width: 767px) and (max-width: 1266px) {
        padding-bottom: 0px;
    }
}
#global-ux .main-nav .categories {
    top: 70px;
    right: 0;
    left: 0;
    bottom: auto;
    overflow: visible;
    z-index: 99999;
}
