@import '../../../../styles/main.scss';
.brand-ford {
    .help-router {
        .chatbot-input-container {
            width: 100%;
            display: flex;
            position: relative;
            .chatbot-input {
                border-radius: pToR(3);
                border-style: none;
                font-size: 1rem;
                height: pToR(50);
                padding: pToR(12) pToR(30) pToR(12) pToR(15);
                width: 100%;
                font-family: $ford-f1-font-regular;
                color: rgb(102, 102, 102);
            }
            .chatbot-input:focus {
                border-radius: pToR(3);
                border-style: none;
                border: 1px solid $fds-preimagine-primary-button-hover;
                box-shadow: inset 0 0 0 1px $fds-preimagine-primary-button-hover;
            }
            .chatbot-input__conversation {
                box-shadow: inset 0 2px 7px 0 rgb(122 122 122 / 50%);
                border: 1px solid $fds-preimagine-primary-button-hover;
            }
            .chatbot-button {
                background: transparent;
                border: 0;
                align-items: center;
                width: pToR(37);
                font-size: pToR(21);
                height: pToR(50);
                padding: 0 pToR(10) 0 0;
                position: absolute;
                right: 0;
                color: $fds-preimagine-dark-text;
                vertical-align: middle;
                cursor: pointer;
            }
            .chatbot-button:focus {
                border-radius: pToR(3);
                border-style: none;
                border: 1px solid $fds-preimagine-primary-button-hover;
                box-shadow: inset 0 0 0 1px $fds-preimagine-primary-button-hover;
            }
        }
    }
}
