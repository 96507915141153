@import '@styles/main.scss';

.add-vehicle-container {
    .brand-lincoln & {
        display: flex;
        justify-content: center;

        &.add-vehicle-container-hidden {
            .secondary-button {
                margin-bottom: unset;
            }
        }
    }

    justify-content: unset;

    .add-vehicle-button.fmc-button {
        min-width: pToR(157);
        height: pToR(40);
        font-size: pToR(14);
        font-family: $ford-f1-font-bold;
        line-height: pToR(20);
        padding: 0 pToR(24);
        box-shadow: unset;
        letter-spacing: normal;
        background-color: none;
        color: $fds-preimagine-button-blue;
        border: 1px solid $fds-preimagine-button-blue;

        .add-vehicle-button-icon {
            display: flex;
            align-items: center;

            .plus-sign-icon {
                width: pToR(24);
                height: pToR(24);
                margin-left: pToR(8);
            }
        }

        &::after {
            background-color: $fds-preimagine-primary-blue;
        }
    }

    @include mobile {
        color: $fds-preimagine-primary-blue;
    }

    .fmc-cards__footer & {
        margin-bottom: pToR(40);
    }

    .fmc-button--outlined {
        min-width: pToR(183);
        justify-content: center;
    }
}

.add-vehicle-modal {
    .modal-content {
        .modal-footer {
            .secondary-button {
                margin-left: pToR(77);

                .brand-lincoln & {
                    margin-left: pToR(77);
                    @include mobile-v5 {
                        margin-left: unset;
                    }
                }
            }
        }
    }

    .fmc-type--heading6 {
        font-weight: $fds-weight__heading6;

        .brand-ford & {
            font-size: pToR(24);
            line-height: pToR(32);
            font-family: $ford-f1-font-medium;
            letter-spacing: normal;
            font-style: normal;
            margin-bottom: pToR(20);
            color: $fds-preimagine-dark-text;
        }

        [data-brand='lincoln'] & {
            @include tablet-v5 {
                font-size: pToR(38);
                line-height: pToR(38);
                letter-spacing: pToR(2.38);
                text-transform: uppercase;
            }
        }
    }

    .fmc-type--body1 {
        .brand-ford & {
            font-family: $ford-f1-font-regular;
            font-size: pToR(16);
            line-height: pToR(24);
            letter-spacing: normal;
            font-style: normal;
            margin-bottom: pToR(28);
            color: $fds-preimagine-dark-text;
            width: 85%;
        }
    }

    .brand-ford & {
        .user-input-container {
            .fmc-floating-label {
                .fmc-floating-label__text {
                    color: $fds-preimagine-dark-text;
                    font-family: $ford-f1-font-regular;
                    font-size: pToR(14);
                    font-style: normal;
                    line-height: pToR(20);
                    letter-spacing: normal;
                }
            }

            .form-field-error {
                font-family: $ford-f1-font-regular;
                font-size: pToR(16);
                font-style: normal;
                letter-spacing: normal;
                line-height: pToR(24);
            }
        }
    }

    .fmc-cards__footer & {
        margin-bottom: pToR(33);
    }

    .fmc-button--outlined {
        min-width: pToR(183);
        justify-content: center;
    }

    .add-vehicle-title {
        color: $dropdown-text;
        line-height: pToR(34);
        font-size: pToR(24);
        margin-bottom: pToR(16);
        font-family: $antenna-font-condensed-light;
        letter-spacing: pToR(3);
        margin-top: pToR(18);

        .brand-lincoln & {
            font-family: $proxima-nova-regular;
            font-size: pToR(38);
            letter-spacing: pToR(2.38);
            line-height: pToR(38);
            color: $brand-secondary;
            padding-bottom: pToR(40);
            margin: 0;

            @include mobile {
                font-size: pToR(28);
                line-height: pToR(34);
                letter-spacing: pToR(1.75);
            }
        }

        @include mobile {
            margin-bottom: pToR(19);
        }

        @include tablet {
            margin-bottom: pToR(17);
        }
    }

    .add-vehicle-description {
        margin-bottom: pToR(24);
        text-align: center;
        color: $dropdown-text;
        letter-spacing: pToR(1);
        font-family: $antenna-font;
        font-size: pToR(16);
        line-height: pToR(26);

        .brand-lincoln & {
            color: $brand-secondary;
            font-family: $proxima-nova-light;
            font-size: pToR(18);
            letter-spacing: 0;
            text-align: start;

            @include mobile {
                font-size: pToR(16);
                line-height: pToR(20);
                text-align: center;
            }

            @include tablet {
                margin-bottom: pToR(38);
            }
        }

        @include mobile {
            margin-bottom: pToR(27);
        }

        @include tablet {
            margin-bottom: pToR(37);
        }
    }

    .infoIcon {
        margin-left: pToR(5);
        font-weight: bold;
        font-size: medium;
    }

    .tooltip-container {
        display: flex;

        .brand-lincoln & {
            position: relative;
            margin-top: pToR(8);

            .tooltip-wrapper {
                margin-bottom: pToR(36);
            }
        }
    }

    .vin-tooltip-content {
        .brand-lincoln & {
            color: $white;
            font-family: $proxima-nova-regular;
            letter-spacing: normal;
        }

        &.fmc-type--body1 {
            [data-brand='lincoln'] & {
                max-width: unset;
                padding-bottom: unset;
            }
        }

        p {
            margin-bottom: pToR(15);
            font-weight: 500;
        }

        ol {
            padding-left: pToR(17);
            font-weight: 500;
        }
    }

    .tooltip-tip {
        .brand-lincoln & {
            background-color: $brand-secondary;

            &::before,
            &::after {
                color: $brand-secondary;
            }

            &.bottom {
                border-radius: 0;
            }
        }

        @include mobile {
            position: relative;
        }
    }

    .vin-help-text {
        display: flex;
        color: $dark-blue;
        font-family: $antenna-font;
        font-size: pToR(11);
        letter-spacing: pToR(1);
        line-height: pToR(21);
        text-decoration: underline;
        text-align: left;
        border: none;
        background: none;
        padding: 0;
        margin: 0;

        .brand-ford & {
            font-size: pToR(12);
            font-family: $ford-f1-font-regular;
            line-height: pToR(16);
            letter-spacing: normal;
            color: #4d5b6d;
        }

        .brand-lincoln & {
            color: $brand-secondary;
            font-family: $proxima-nova-regular;
            font-size: pToR(13);
            line-height: pToR(20);
            letter-spacing: normal;
            background: none;
        }

        &:hover {
            .brand-lincoln & {
                color: $brand-border;
            }
        }

        &:focus {
            background-color: $white;

            .brand-lincoln & {
                color: $brand-border;
            }
        }
    }
}

.vin-mismatch-modal {
    .modal-content {
        .modal-footer {
            .secondary-button {
                .brand-lincoln & {
                    margin-left: pToR(77);
                    @include mobile-v5 {
                        margin-left: unset;
                    }
                }
            }
        }
    }
}
