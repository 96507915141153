@import '@styles/main.scss';

.modal {
    z-index: 2500;
    background-color: #0000004d;
}

.fmc-dialog {
    .fmc-dialog__content {
        .brand-ford & {
            padding: pToR(16);
            border: pToR(1) solid #6b7786;
            border-radius: pToR(18);
            @include mobile {
                height: 100%;
                border: unset;
                border-radius: unset;
                width: unset;
                margin: unset;
                overflow-y: auto;
            }
        }

        @include mobile-v5 {
            [data-brand='lincoln'] & {
                padding-left: pToR(20);
                padding-right: pToR(20);

                .modal-body {
                    padding-left: 0;
                    padding-right: 0;
                }

                .modal-footer {
                    padding-left: 0;
                    padding-right: 0;

                    .primary-button {
                        margin-right: 0;
                        width: 100%;
                    }

                    .secondary-button {
                        [data-brand='lincoln'] & {
                            display: flex;
                        }
                    }
                }
            }
        }
        @include desktop {
            padding: pToR(45) pToR(105) pToR(30);

            .brand-ford & {
                padding: pToR(16);
            }

            .brand-lincoln & {
                padding-bottom: pToR(17);
            }
        }

        &.connected-services-modal-content {
            @include desktop {
                padding: unset;
            }
        }

        .primary-button {
            margin-right: pToR(45);
        }

        .fmc-button {
            width: auto;
            justify-content: center;

            [data-brand='lincoln'] & {
                width: pToR(234);
                letter-spacing: normal;
                font-weight: 700;

                @include mobile-v5 {
                    width: 100%;
                }
            }

            [data-brand='ford'] & {
                margin-left: pToR(16);
                padding: pToR(18) pToR(24);
                font-family: $ford-f1-font-bold;
                font-size: pToR(14);
                line-height: pToR(20);
                letter-spacing: normal;
                font-style: normal;

                &:after {
                    box-shadow: unset;
                    background-color: $fds-preimagine-primary-blue;
                }

                &:hover {
                    --scale-hover: unset;

                    &:after {
                        background-color: #055ecb;
                    }
                }

                &:focus {
                    outline: 2px solid #066fef;
                    outline-offset: 2px;
                    --scale-hover: unset;
                }
            }
        }

        .fmc-dialog__close {
            .brand-ford & {
                width: pToR(24);
                height: pToR(24);
            }

            &:hover,
            &:focus {
                background-color: transparent;
            }

            [data-brand='lincoln'] & {
                font-size: pToR(32);
            }
        }
    }

    .user-input-container {
        text-align: left;
        font-family: $antenna-font;

        .brand-ford & {
            width: pToR(320);
            margin-bottom: pToR(7);

            @include mobile {
                width: unset;
            }

            .fmc-floating-label {
                .fmc-input {
                    border-radius: pToR(8);
                    border: pToR(1) solid #6b7786;
                    box-shadow: unset;
                }
            }
        }

        @include mobile {
            padding: 0;
            width: 100%;
        }
        @include tablet {
            width: 100%;
        }

        .brand-ford & {
            .form-field {
                margin-bottom: pToR(15);
            }

            &:last-of-type {
                display: flex;
                flex-direction: column-reverse;

                @include tablet {
                    margin-top: pToR(10);
                }
            }
        }

        .edit-modal & {
            .brand-ford & {
                @include tablet {
                    margin-top: pToR(3);
                }
            }
        }

        .brand-lincoln & {
            font-family: $proxima-nova-regular;
            position: relative;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            &.edit-modal {
                .user-input-container {
                    .user-input {
                        height: pToR(60);
                    }
                }
            }

            .form-field {
                margin-bottom: 0;

                &:last-of-type {
                    @include tablet {
                        margin-bottom: pToR(5);
                    }
                }
            }

            .form-field-error {
                @include mobile-v5 {
                    margin-top: pToR(2);
                    line-height: pToR(16);
                }
                @include tablet-v5 {
                    line-height: pToR(23);
                }
            }
        }

        .fmc-floating-label {
            margin-bottom: 0;
        }
    }

    &.delete-modal {
        .brand-lincoln & {
            @include mobile-v5 {
                .modal-content {
                    padding-bottom: pToR(23);
                }
                .modal-footer {
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
            }
        }
    }

    &.error-modal {
        .modal-content {
            .brand-ford & {
                font-family: $ford-f1-font-regular;
                font-size: pToR(24);
                font-style: normal;
                line-height: pToR(32);
                color: $fds-preimagine-dark-text;
                letter-spacing: normal;
            }

            .modal-footer {
                .secondary-button {
                    .brand-lincoln & {
                        margin-left: pToR(40);
                        @include mobile-v5 {
                            margin-left: unset;
                        }
                    }
                }
            }
        }
    }
}

.edit-vehicle-modal {
    .modal-content {
        .modal-footer {
            .secondary-button {
                .brand-lincoln & {
                    margin-left: pToR(40);
                    @include mobile-v5 {
                        margin-left: unset;
                    }
                }
            }
        }
    }
}

.modal-content {
    position: relative;
    background-color: white;
    margin: auto auto 6rem;
    padding: 0;
    border: 1px solid #888;
    border-radius: 3px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;

    .add-vehicle-modal & {
        .secondary-button {
            display: flex;
            margin: unset;
            margin-left: pToR(40);

            [data-brand='lincoln'] & {
                margin-left: unset;
                @include mobile-v5 {
                    margin-top: pToR(20);
                }
            }
        }
    }

    .delete-modal & {
        .secondary-button {
            [data-brand='lincoln'] & {
                @include mobile-v5 {
                    margin-top: pToR(16);
                }
            }
        }
    }

    .brand-lincoln & {
        background-color: $white;
        border-radius: 0;

        @include desktop {
            max-width: pToR(867);
        }
    }

    @include mobile {
        width: 100%;
    }

    @include tablet {
        margin: 0 pToR(100);
    }

    @include desktop {
        max-width: pToR(820);
        border-radius: pToR(3);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
        margin: pToR(25) auto auto;
        .brand-ford & {
            max-width: pToR(928);
        }
    }
}

.modal-header {
    margin-bottom: 4rem;
    text-align: center;
    display: flex;
    justify-content: flex-end;

    .brand-ford & {
        margin-bottom: unset;
        padding-right: unset;
        position: absolute;
        top: 0;
        right: 0;
        @include tablet {
            margin-bottom: pToR(40);
        }
    }

    .brand-lincoln & {
        padding-right: 0;
    }

    .edit-modal & {
        .brand-ford & {
            @include tablet {
                margin-bottom: pToR(52);
            }
        }
    }

    .delete-modal & {
        .brand-ford & {
            @include tablet {
                margin-bottom: pToR(50);
            }
        }
    }
}

.modal-body {
    margin-top: 2rem;
    padding: pToR(2) pToR(16);
    display: flex;
    flex-direction: column;

    [data-brand='lincoln'] & {
        padding: unset;
    }

    .brand-ford & {
        margin-top: unset;
        padding: unset;
        margin-bottom: pToR(50);
        text-align: left;
    }

    .edit-modal & {
        padding-top: 0;
        margin-top: 0;
    }

    .fmc-type--heading4 {
        [data-brand='lincoln'] & {
            font-size: pToR(38);
            line-height: pToR(38);
            letter-spacing: pToR(2.38);

            @include tablet-v5 {
                margin-bottom: pToR(24);
            }
        }
    }

    .fmc-type--heading5 {
        [data-brand='lincoln'] & {
            @include tablet-v5 {
                font-size: pToR(38);
                line-height: pToR(38);
                letter-spacing: pToR(2.38);
                margin-bottom: pToR(39);
            }
        }
    }

    .fmc-type--heading6 {
        [data-brand='lincoln'] & {
            font-size: pToR(24);
        }

        .brand-ford & {
            text-align: left;
            width: 85%;
        }
    }

    .fmc-type--body1 {
        .brand-ford & {
            text-align: left;
        }
    }
}

.modal-footer {
    display: flex;
    justify-content: center;
    margin: pToR(32) 0;
    text-align: center;
    padding: pToR(15) pToR(16);

    .brand-ford & {
        padding: unset;
        justify-content: flex-end;
        margin: unset;
    }

    .brand-lincoln & {
        display: flex;
        flex-direction: row;
        justify-content: center;

        @include mobile-v5 {
            align-items: center;
            flex-direction: column;
            margin-top: pToR(40);
        }
    }

    .primary-button {
        [data-brand='lincoln'] & {
            @include mobile-v5 {
                margin-bottom: pToR(10);
            }
        }
    }

    .secondary-button {
        margin-left: pToR(40);
        display: flex;

        .brand-ford & {
            margin-left: unset;
            border: pToR(1) solid $fds-preimagine-primary-blue;
            border-radius: pToR(26);
        }

        [data-brand='lincoln'] & {
            margin-left: unset;
        }

        .fmc-text-button {
            .brand-ford & {
                padding: pToR(10) pToR(24);
                font-family: $ford-f1-font-bold;
                font-size: pToR(14);
                line-height: pToR(20);
                letter-spacing: normal;
                color: $fds-preimagine-primary-blue;

                &:hover {
                    text-decoration: none;
                    outline-color: #055ecb;
                    color: #055ecb;
                }

                &:focus {
                    outline: 1px solid #066fef;
                    outline-offset: 3px;
                    outline-width: 2px;
                    --scale-hover: unset;
                    text-decoration: none;
                    border-radius: pToR(20);
                }
            }
        }
    }
}

/* Add Animation */
@-webkit-keyframes animatetop {
    from {
        top: -300px;
        opacity: 0;
    }
    to {
        top: 0;
        opacity: 1;
    }
}

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0;
    }
    to {
        top: 0;
        opacity: 1;
    }
}

/* The Close Button */
.modal-close {
    color: $dark-blue;
    float: right;
    font-size: pToR(28);
    font-weight: bold;
    border: none;
    background: none !important;
    padding: 0;
    margin: 0;

    .brand-lincoln & {
        margin-right: pToR(32);
        margin-top: pToR(32);
        line-height: pToR(19);

        @include mobile {
            margin-right: pToR(25);
            margin-top: pToR(25);
        }
    }
}

.modal-close:hover,
.modal-close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.close-icon {
    height: pToR(24);
    width: pToR(24);

    .brand-lincoln & {
        height: pToR(19);
        width: pToR(19);
    }
}
