@import '@styles/main.v5';

.create-return {
    &-container {
        display: flex;
        flex-direction: column;

        .create-return-component {
            &-container {
                display: flex;
                max-width: pToR(1105);
                width: 100%;
                flex-direction: column;
                margin: auto;

                @include mobile {
                    max-width: -webkit-fill-available;
                    margin-left: pToR(16);
                    margin-right: pToR(16);
                }

                @include tablet {
                    max-width: -webkit-fill-available;
                    padding-left: pToR(58);
                    padding-right: pToR(58);
                }

                @include desktop {
                    max-width: pToR(1105);
                }

                .create-return-component-notification-container {
                    margin-bottom: pToR(12);
                }

                .create-return-component-title {
                    font-size: pToR(32);
                    font-family: $ford-f1-font-regular;
                    color: $charcoal;
                    letter-spacing: normal;
                    line-height: pToR(40);
                    padding-bottom: pToR(16);
                    padding-top: pToR(8);
                    margin-bottom: pToR(0);
                    @include mobile {
                        padding-top: pToR(8);
                        font-size: pToR(28);
                        line-height: pToR(36);
                    }
                }

                .create-return-component-order-number {
                    font-size: pToR(16);
                    font-family: $ford-f1-font-semibold;
                    color: $charcoal;
                    letter-spacing: normal;
                    line-height: pToR(24);
                    padding-bottom: pToR(7);
                    margin-bottom: 0;
                    @include mobile {
                        padding-top: pToR(1);
                        padding-bottom: pToR(22);
                    }
                }

                .create-return-component-items {
                    display: flex;
                    flex-direction: column;
                    padding-top: pToR(24);
                    padding-bottom: pToR(64);
                    @include mobile {
                        padding-bottom: 0;
                        padding-top: pToR(8);
                    }
                    @include tablet {
                        padding-bottom: 0;
                        padding-bottom: pToR(32);
                    }

                    .input-fieldset {
                        height: pToR(24);
                        width: pToR(24);
                        border: none;
                        font-size: pToR(24);
                        line-height: pToR(30);
                        color: #010203;

                        @include tablet {
                            margin-top: pToR(12);
                        }

                        @include mobile {
                            margin-top: pToR(12);

                            .brand-lincoln & {
                                margin-top: pToR(43);

                                &:nth-of-type(2),
                                &:nth-of-type(5) {
                                    margin-top: pToR(34);
                                }
                                &:nth-of-type(3),
                                &:nth-of-type(4) {
                                    margin-top: pToR(15);
                                }
                            }
                        }
                        @include desktop {
                            margin-top: pToR(12);
                        }
                        .fmc-type--heading6 {
                            font-size: pToR(24);
                            font-family: $ford-f1-font-regular;
                            font-weight: 500;
                            .fmc-mb-5 {
                                height: pToR(24);
                                width: pToR(24);
                                font-size: pToR(24);
                                font-family: $ford-f1-font-regular;
                                font-weight: 500;
                                line-height: pToR(30);
                                margin-bottom: 0;
                            }
                        }
                    }
                    .create-return-component-checkbox-container {
                        display: flex;
                        flex-direction: row;
                        padding-bottom: pToR(32);
                        .create-return-component-user-input {
                            margin-bottom: auto;
                            margin-top: auto;
                            margin-right: pToR(16);
                            @include mobile {
                                margin-top: pToR(36);
                            }
                            .create-return-component-user-checkbox {
                                height: pToR(24);
                                width: pToR(24);
                            }
                            .input-fieldset-name {
                                margin-top: 0;
                                border: none;
                            }

                            .fmc-check {
                                --button-diameter: 24px;
                                --button-label-gap: 8px;
                                --button-outline-thickness: 1px;
                                --button-outline-color: #6b7786;
                                --dot-color: #066fef;
                                --dot-diameter: 18px;
                                --vertical-gap: 24px;

                                input[type='radio']:checked {
                                    --button-outline-color: #066fef;
                                    --button-outline-thickness-checked: 1px;
                                    color: white;
                                    background-color: #066fef;
                                }
                                input[type='radio']:focus-visible {
                                    outline-offset: 0;
                                    outline: 2px solid
                                        $fds-preimagine-primary-blue;
                                }

                                label {
                                    color: $fds-preimagine-dark-text;
                                    font-family: $ford-f1-font-regular;
                                    font-size: 14px;
                                    line-height: 20px;
                                    letter-spacing: normal;
                                    align-items: center;
                                }
                            }

                            .form-field {
                                .form-field__label {
                                    color: $fds-preimagine-dark-text;
                                    letter-spacing: normal;

                                    .brand-lincoln & {
                                        color: $brand-secondary;

                                        &.error-label {
                                            color: $brand-light-error;
                                        }
                                    }
                                }

                                @include tablet-v5 {
                                    margin-bottom: pToR(36);
                                }
                            }

                            .brand-lincoln & {
                                .user-information-wrapper {
                                    padding-left: pToR(20);
                                }
                                .primary-button {
                                    &.user-edit-update-btn {
                                        &:hover {
                                            background-color: $brand-secondary;
                                        }

                                        &:active:focus {
                                            background-color: $brand-black;
                                        }
                                    }
                                }
                                .secondary-button {
                                    &.user-edit-cancel-btn {
                                        padding: pToR(24);
                                        height: pToR(56);
                                    }
                                }
                            }

                            @include media('<fds-xs') {
                                .btn-container {
                                    flex-direction: column;

                                    .fmc-button {
                                        margin: auto;
                                    }
                                }
                            }
                        }
                        .order-details-item__container {
                            padding-bottom: 0;
                            display: block;
                            .product-page-url {
                                margin-top: pToR(1);
                                .item__image {
                                    width: pToR(112);
                                    @include mobile {
                                        width: pToR(96);
                                        height: pToR(96);
                                    }
                                }
                            }
                        }
                    }
                }

                .create-return-component-support-info {
                    max-width: pToR(456);
                    margin-top: pToR(-16);
                    margin-bottom: pToR(18);
                    color: $fds-preimagine-high-contrast;
                    font-family: $ford-f1-font-regular;
                    line-height: pToR(16);
                    font-size: pToR(12);
                    letter-spacing: normal;

                    @include mobile {
                        margin-top: pToR(-23);
                        margin-bottom: pToR(18);
                    }

                    a {
                        color: $fds-preimagine-high-contrast;
                        text-decoration: underline;

                        &:focus {
                            outline: none;
                        }

                        &:focus-visible {
                            outline: 2px solid $fds-preimagine-primary-blue;
                            outline-offset: pToR(2);
                            border-radius: pToR(1);
                        }
                    }
                }

                .create-return-component-reason-box {
                    padding: pToR(16) 0;
                    @include mobile {
                        padding-top: pToR(32);
                        padding-bottom: 0;
                    }
                    @include tablet {
                        padding-bottom: 0;
                        position: relative;
                        height: auto;
                        width: 35%;
                    }
                    .dynamic-dropdown-container
                        .dynamic-dropdown-input-group
                        .menu-toggle {
                        @include mobile {
                            display: block;
                        }
                    }
                }
                .create-return-component-faq-container {
                    padding-top: pToR(96);
                    padding-bottom: pToR(128);
                    @include mobile {
                        padding-top: pToR(64);
                        padding-bottom: pToR(64);
                    }
                    .create-return-component-faq-title {
                        color: $charcoal;
                        font-family: $ford-f1-font-semibold;
                        font-size: pToR(24);
                        line-height: pToR(32);
                        letter-spacing: normal;
                    }
                    .create-return-component-faq-item {
                        color: $charcoal;
                        font-family: $ford-f1-font-semibold;
                        font-size: pToR(16);
                        line-height: pToR(24);
                        padding-top: pToR(32);

                        p {
                            letter-spacing: normal;
                        }
                    }
                }
                .hr-line {
                    height: pToR(1);
                    border-bottom: pToR(1) solid $disabled-dark;
                    box-shadow: none;
                    margin-bottom: pToR(8);
                    width: 100%;

                    @include mobile {
                        visibility: hidden;
                    }

                    @include tablet {
                        visibility: visible;
                    }

                    @include desktop {
                        width: 100%;
                        margin-left: 0;
                        visibility: visible;
                    }

                    .brand-lincoln & {
                        border: 0;
                        border-bottom: pToR(1) solid $brand-gray3;

                        @include mobile {
                            border: 0;
                            border-bottom: pToR(1) solid $brand-gray3;
                        }
                    }
                }

                .fmc-button:disabled {
                    --bg-color: #e5e5e5;
                    --bg-color-hover: #e5e5e5;
                    background-color: $fds-reimagine-disabled-background-color;
                    color: $fds-reimagine-disabled-text-color;
                }
            }
            .hr-line {
                height: pToR(1);
                border-bottom: pToR(1) solid $disabled-dark;
                box-shadow: none;
                margin-bottom: pToR(8);
                width: 100%;

                @include mobile {
                    visibility: visible;
                }

                @include tablet {
                    visibility: visible;
                }

                @include desktop {
                    width: 100%;
                    margin-left: 0;
                    visibility: visible;
                }

                .brand-lincoln & {
                    border: 0;
                    border-bottom: pToR(1) solid $brand-gray3;

                    @include mobile {
                        border: 0;
                        border-bottom: pToR(1) solid $brand-gray3;
                    }
                }
            }
        }
    }
}
