@import '@styles/lincoln/main.scss';
@import '@styles/main.scss';

.lincoln-na-footer {
    display: block;
    flex-direction: row;
    background-color: $brand-dark-grey;
    position: relative;

    @include mobile {
        display: block;
        flex-direction: column;
        background-color: $brand-dark-grey;
        position: relative;
        padding: 0 0 6rem;
    }

    .logo-link {
        @include mobile {
            display: none;
        }

        padding: pToR(16) pToR(12.8);
        left: 0;
        right: 0;
        display: block;
        float: left;
        margin: auto;
        width: 70px;
        position: relative;
        top: 30px;

        .lincoln-logo {
            background: transparent no-repeat -220px 0;
            width: 1rem;
            height: 3rem;
            background-size: 25rem 11.5rem;
            display: inline-block;
            padding: 0;
            margin: -3rem 0 3rem 3rem;
            position: relative;
            bottom: 0;
            @include mobile {
                position: relative;
                left: 5px;
                bottom: 5px;
            }
        }
    }
    .mobile-logo-link {
        @include mobile {
            width: 1rem;
            display: flex;
            bottom: 10px;
            flex-direction: column;
            top: unset;
            position: relative;
        }
        .lincoln-mobile-logo {
            @include mobile {
                left: 5px;
                background: transparent no-repeat -220px 0;
                width: 1rem;
                height: 3rem;
                background-size: 25rem 11.5rem;
                display: inline-block;
                padding: 0;
                margin: 2rem 1rem 2.5rem 1rem;
                position: relative;
                bottom: 0;
            }
        }
    }

    .button-label {
        white-space: nowrap;
        margin-right: 0.974rem;
        letter-spacing: 0.064rem;
        font-size: 1.125rem;
    }
    .language-toggle-container {
        display: inline-flex;
        padding-bottom: 25px;
        .language-panel-footer {
            @include mobile {
                width: -webkit-fill-available;
                display: flex;
                flex-direction: column;
                height: auto;
                left: 0;
                padding: 1rem;
            }
            box-sizing: border-box;
            height: 30%;
            width: 60%;
            left: 20%;
            text-align: center;
            position: fixed;
            overflow: hidden;
            z-index: 2560;
            top: 40%;
        }
    }
    .select-button {
        display: block;
        background: inherit;
        color: $black;
        border: 1px solid $black;
        cursor: none;
    }
    .close {
        cursor: pointer;
        position: absolute;
        right: 15px;
        color: $black;
        font-size: 40px;
        top: 10%;
    }
    .list-lang-item {
        margin-bottom: 25px;
        padding-right: 25px;
    }
    .language-option {
        background: $hover-blue;
        position: relative;
        width: 40%;
        left: 30%;
        right: 30%;
        text-align: center;
    }
    .list-wrapper {
        display: flex;
        overflow: auto;
        padding-top: 10px;
    }

    @include tablet {
        flex-direction: row;
        align-items: flex-start;
        padding: 0 0 5.3rem;
        justify-content: space-between;
    }

    .links {
        @include mobile {
            flex-direction: column;
            color: $white;
            font-family: $proxima-nova-regular;
            font-size: 11px;
            letter-spacing: 1px;
            line-height: 21px;
            align-items: flex-start;
            left: 20px;
        }
        font-family: $proxima-nova-regular;
        white-space: nowrap;
        flex-wrap: wrap;
        display: flex;
        position: relative;
        margin-left: 0;
        .link {
            color: $brand-offwhite;
            margin: 20px 0 0 0;
            text-decoration: none;
            font-size: 12px;
            font-weight: lighter;
            text-align: center;
            padding-left: 5px;
            padding-right: 5px;
            border-left: 1px solid $border-grey;
            height: 50%;
            -webkit-font-smoothing: antialiased;

            @include mobile {
                color: $brand-offwhite;
                margin: 20px 0 0 0;
                text-decoration: none;
                font-size: 12px;
                font-weight: lighter;
                border-left: none;
                text-align: center;
                align-items: center;
                justify-content: center;
            }
            &:hover,
            &:focus {
                border-bottom: 2px solid $brand-border;
            }
        }
        .primary-link {
            color: $brand-offwhite;
            margin: 20px 0 0 0;
            text-decoration: none;
            font-size: 12px;
            font-weight: lighter;
            text-align: center;
            padding-left: 5px;
            padding-right: 5px;
            height: 50%;
            &:hover,
            &:focus {
                border-bottom: 2px solid $brand-border;
            }
        }
        p {
            color: $brand-offwhite;
            margin: 20px 50px 0 0;
            text-decoration: none;
            text-shadow: $brand-black;
            font-size: 12px;
            letter-spacing: 1px;
            text-align: center;
        }
    }

    .skinny {
        @include mobile {
            color: $white;
            font-size: 14px;
            font-weight: lighter;
            width: 90%;
            max-width: 100%;
            list-style: none;
            .child-links {
                list-style: none;
            }
        }
        color: $white;
        padding: 10px 0 0 3rem;
        font-size: 14px;
        font-weight: lighter;
        max-width: 100%;
        list-style: none;
        .child-links {
            list-style: none;
        }
    }
    .footer-change-lang {
        padding: 25px 10px 15px 0;
        color: $white;
        margin: 10px 0 0 15px;
        text-decoration: none;
        font-size: 14px;
        font-weight: 100;
    }
    .footer-change-lang-block {
        @include mobile {
            padding-left: 25px;
        }
        padding-left: 50px;
        padding-right: 116px;
        padding-top: 15px;
        display: flex;
    }
    .language-toggle {
        display: flex;
        margin-right: 0;
    }
    .pipe-separated-list-container {
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        flex-wrap: wrap;
        padding-top: 30px;
        ul {
            $pipe-width: 1px;
            display: flex;
            list-style-type: none;
            position: relative;
            left: -($pipe-width);
            padding: 0 0 0 3.7rem;
            @include mobile {
                text-align: left;
                align-items: flex-start;
                justify-content: left;
                padding: 0 0 0 0;
            }
            li.lincoln-content-label {
                display: inline-block;
                @include mobile {
                    line-height: 1;
                    text-decoration: none;
                    font-size: 14px;
                    font-weight: 100;
                    border-left: none;
                    text-align: left;
                    padding-top: 20px;
                    padding-bottom: 30px;
                    padding-left: 30px;
                    width: 100%;
                    border-bottom: 1px solid $border-grey;
                    height: 50%;
                }
                line-height: 3;
            }
        }
    }
    p {
        margin: 1em;
    }
    .button-separated-list-container ul {
        display: flex;
        justify-content: center;
        list-style-type: none;
        position: relative;
        left: -1px;
        padding: 0;
    }
    .sec-link-color {
        color: $brand-offwhite;
        text-transform: uppercase;
        font-family: $proxima-nova-semibold;
        font-size: 14px;
        letter-spacing: 1px;
        font-weight: 10;
        line-height: 1px;
        text-align: center;
        text-decoration: none;
        padding-right: 35px;
        @include mobile {
            color: $white;
            font-family: $proxima-nova-semibold;
            font-weight: lighter;
            font-size: 16px;
            letter-spacing: 2px;
            line-height: 26px;
            padding-bottom: 50px;
        }
    }
    .tertiary-first-title {
        @include mobile {
            margin-left: 25px;
            background-color: $brand-dark-grey;
            float: left;
        }
        margin-left: 26px;
        text-decoration: none;
        font-size: 12px;
        font-weight: lighter;
        font-family: $proxima-nova-semibold;
        color: $white;
        padding-left: 50px;
        line-height: 26px;
        letter-spacing: 1px;
    }
}
