@import '@styles/main.v5';

.hero-tile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $white;
    border: 0.125rem solid $white;
    box-sizing: border-box;
    text-decoration: none;
    align-items: center;
    text-align: left;
    height: pToR(150);
    width: 100%;
    vertical-align: middle;
    letter-spacing: 1px;
    line-height: pToR(24);
    font-family: $antenna-font-condensed-light;
    font-weight: var(--fmc-type__weight-light);
    .brand-ford & {
        border-radius: pToR(3);
        box-shadow: $outer-drop-shadow;
        transition: 0.5s;
    }
    .brand-lincoln & {
        position: relative;
        height: pToR(50);
        padding-top: 0;
        border: 0;

        .coral-border {
            position: absolute;
            background: transparent;
            z-index: 5;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    @include tablet {
        margin: 0;
        display: inline-flex;
        text-align: center;
    }

    &:hover {
        .brand-ford & {
            transform: scale(1.07);
            box-shadow: pToR(-2) pToR(-4) pToR(15) hsla(0, 0%, 58%, 0.25),
                pToR(2) pToR(4) pToR(15) hsla(0, 0%, 58%, 0.25);
        }
        .brand-lincoln & {
            .coral-border {
                border-bottom: 2px solid $brand-border;
            }
        }
    }

    .tile-icon {
        margin-top: 1rem;
        height: pToR(24);
        width: pToR(24);
        color: $fds-color--primary;
        .brand-lincoln & {
            display: none;
        }
    }

    .content {
        color: $fds-color--primary;
        font-size: pToR(18);
        text-transform: uppercase;

        @include tablet-v5 {
            margin-top: 14px;
        }

        @include mobile-v5 {
            margin-top: 10px;
            font-size: pToR(16);
            line-height: 25px;
            letter-spacing: 4px;
        }

        .brand-lincoln & {
            width: 100%;
            display: flex;
            justify-content: center;
            color: $brand-secondary;
            font-family: $proxima-nova-semibold;
            font-size: pToR(16);
            letter-spacing: 0;
            text-transform: capitalize;
            margin: auto;

            span {
                padding-right: pToR(5);
            }

            @include mobile {
                letter-spacing: normal;
                margin: unset;
                height: 50px;
                align-items: center;

                img {
                    width: pToR(24);
                    height: auto;
                }
            }
            @include tablet {
                margin: auto;
            }
        }
    }

    .beta-superscript {
        font-style: italic;
        text-transform: lowercase;
        font-size: 0.875rem;
        line-height: 1.875rem;
        letter-spacing: 0.131rem;
    }
}
