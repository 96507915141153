@import '@styles/main.v5';
@import '@styles/common';

.permission {
    &__container {
        .permission-header__container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: pToR(9);
            gap: pToR(16);

            .email-preferences-header {
                margin-bottom: 0;
            }
        }
        .email-preferences {
            &-header {
                color: $fds-preimagine-dark-charcoal;
                font-family: $ford-f1-font-bold;
                font-size: pToR(16);
                line-height: pToR(24);
                letter-spacing: normal;

                .brand-lincoln & {
                    color: $fds-preimagine-lincoln-primary;
                    font-family: $proxima-nova-bold;
                    line-height: pToR(32);
                }

                & + .secondary-button {
                    line-height: 1;
                }
                .brand-lincoln & {
                    color: $fds-preimagine-lincoln-primary;
                    font-family: $proxima-nova-bold;
                    font-size: pToR(16);
                    line-height: pToR(32);
                    letter-spacing: normal;
                }
            }

            &-divider {
                width: 100%;
                box-shadow: none;
                border-color: $disabled-dark;
            }
        }

        .channel {
            &__content {
                margin-top: pToR(57);

                @include mobile {
                    margin-top: pToR(51);
                }

                .toggle__container {
                    margin-bottom: pToR(28);
                }

                .channel-types__heading {
                    color: $fds-reimagine-dark-gray;
                    font-family: $ford-f1-font-regular;
                    font-size: pToR(12);
                    line-height: pToR(16);
                    letter-spacing: normal;
                    margin-bottom: pToR(18);

                    .brand-lincoln & {
                        font-family: $proxima-nova-regular;
                        margin-bottom: pToR(11);
                    }
                }

                .checkbox_ctn {
                    margin-bottom: pToR(16);

                    .brand-lincoln & {
                        margin-bottom: pToR(23);
                    }
                }

                .fmc-check {
                    input[type='checkbox'] {
                        height: pToR(24);
                        width: pToR(24);
                        border: 1px solid $fds-preimagine-disabled-text-color;
                        border-radius: pToR(4);
                        margin-top: 0;

                        &:focus-visible {
                            outline: 2px solid black;
                            outline-offset: 0;
                        }

                        &:checked {
                            border: 0;
                            background-color: $fds-reimagine-secondary-blue;

                            .brand-lincoln & {
                                background-color: $brand-border;
                            }
                        }

                        &:checked:before {
                            color: white;
                            --icon-size: 12px;

                            .brand-lincoln & {
                                --icon-size: 18px;
                            }
                        }

                        & + span {
                            color: $charcoal;
                            font-family: $ford-f1-font-regular;
                            font-size: pToR(14);
                            line-height: pToR(20);
                            letter-spacing: normal;
                            margin-left: pToR(4);

                            .brand-lincoln & {
                                color: $fds-preimagine-lincoln-primary;
                                font-family: $proxima-nova-regular;
                                font-size: pToR(16);
                                line-height: pToR(24);
                            }
                        }
                    }

                    label {
                        align-items: center;
                    }
                }
            }

            &__header {
                color: $fds-preimagine-high-contrast;
                font-family: $ford-f1-font-regular;
                font-size: pToR(16);
                line-height: pToR(24);
                letter-spacing: normal;

                .brand-lincoln & {
                    color: $fds-preimagine-lincoln-primary;
                    font-family: $proxima-nova-regular;
                }
            }
        }

        .disclaimer p {
            color: $fds-reimagine-dark-gray;
            font-family: $ford-f1-font-regular;
            font-size: pToR(12);
            line-height: pToR(16);
            letter-spacing: normal;

            .brand-lincoln & {
                font-family: $proxima-nova-regular;
            }
        }

        .permission-edit__container {
            max-width: pToR(542);

            .brand-lincoln & {
                max-width: pToR(557);
                margin-bottom: pToR(36);
            }

            @include mobile {
                margin-bottom: pToR(64);
            }

            @include tablet {
                margin-bottom: pToR(115);
            }

            .buttons-container {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                gap: pToR(20);
                margin: pToR(61) 0 pToR(32);

                @include mobile {
                    margin: pToR(53) 0 pToR(47);
                }

                .brand-lincoln & {
                    margin-top: pToR(24);
                    gap: pToR(13);
                }

                .fmc-button {
                    min-width: fit-content;

                    .brand-lincoln & {
                        width: pToR(115);
                        font-family: $proxima-nova-light;
                        border-radius: 0;
                    }

                    &.permission__cancel-button-lincoln {
                        color: $fds-preimagine-lincoln-primary;
                        border: 1px solid $fds-preimagine-lincoln-primary;
                        &::after {
                            background: none;
                        }
                    }
                }

                .secondary-button {
                    @include ford-secondary-button-default();
                    min-width: fit-content;
                    padding: 0;

                    .permission__cancel-button {
                        padding: pToR(12) pToR(20);

                        &:focus-visible {
                            outline: 2px solid $fds-reimagine-secondary-blue;
                            outline-offset: 2px;
                            border-radius: pToR(400);
                        }
                    }

                    .secondary-button-text {
                        align-items: center;
                    }
                }
            }
        }
    }
}

.your-account-info {
    &__section {
        display: flex;
        flex-direction: column;
        margin-top: pToR(32);

        .brand-lincoln & {
            margin-top: pToR(24);
        }

        .tertiary-button {
            .secondary-button {
                a {
                    height: unset;

                    &:focus-visible {
                        border-radius: 0;
                        outline: 2px solid $fds-reimagine-secondary-blue;
                        outline-offset: 2px;
                    }
                }
            }

            &__label-text {
                color: $fds-preimagine-high-contrast;
                font-family: $ford-f1-font-regular;
                font-size: pToR(16);
                line-height: pToR(24);
                letter-spacing: normal;
                text-decoration: underline;
                text-underline-offset: 1px;
            }
        }
    }

    &__heading {
        color: $fds-preimagine-high-contrast;
        font-family: $ford-f1-font-regular;
        font-size: pToR(16);
        line-height: pToR(24);
        letter-spacing: normal;
        margin-bottom: pToR(16);

        .brand-lincoln & {
            color: $fds-preimagine-lincoln-primary;
            font-family: $proxima-nova-regular;
        }
    }

    &__grid {
        min-width: pToR(320);
        width: fit-content;
    }

    &__row {
        display: flex;
        flex-direction: row;
        gap: pToR(16);
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: pToR(6);

        @include mobile {
            flex-direction: column;
            gap: 0;
            align-items: unset;
        }

        .name,
        .email {
            font-family: $ford-f1-font-bold;
            font-size: pToR(16);
            line-height: pToR(24);
            letter-spacing: normal;

            .brand-lincoln & {
                color: $fds-preimagine-lincoln-primary;
                font-family: $proxima-nova-bold;
            }
        }

        .update-button-lincoln {
            color: $fds-preimagine-lincoln-primary;
            text-decoration: underline;
            letter-spacing: normal;
            text-decoration-thickness: from-font;
            text-underline-position: from-font;

            &:focus-visible {
                outline: 1px solid $fds-preimagine-lincoln-primary;
                outline-offset: pToR(2);
            }
        }
    }
}
.permission-display {
    &__selections {
        display: flex;
        flex-direction: column;
    }

    &__preferences {
        display: flex;
        flex-direction: column;
        gap: pToR(16);
    }

    &__container {
        padding-top: pToR(32);
        padding-bottom: pToR(42);

        @include tablet {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
            padding-top: pToR(50);
        }

        .permission-category {
            color: $fds-preimagine-high-contrast;
            font-family: $ford-f1-font-regular;
            font-size: pToR(16);
            line-height: pToR(24);
            letter-spacing: normal;
            margin-bottom: pToR(31);
            .brand-lincoln & {
                color: $fds-preimagine-lincoln-primary;
                font-family: $proxima-nova-regular;
                font-size: pToR(16);
                line-height: pToR(28);
                letter-spacing: normal;
            }
        }

        .selected-preference {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            gap: pToR(16);

            p {
                color: $charcoal;
                font-family: $ford-f1-font-regular;
                font-size: pToR(16);
                line-height: pToR(24);
                letter-spacing: normal;

                @include mobile {
                    font-size: pToR(14);
                    line-height: pToR(20);
                }

                .brand-lincoln & {
                    color: $fds-preimagine-lincoln-primary;
                    font-family: $proxima-nova-regular;
                    font-size: pToR(14);
                    line-height: pToR(20);
                }
            }
        }

        .is-opted-in {
            color: $fds-preimagine-high-contrast;
            font-family: $ford-f1-font-bold;
            font-size: pToR(16);
            line-height: pToR(24);
            letter-spacing: normal;
            margin-bottom: pToR(15);
            .brand-lincoln & {
                color: $fds-preimagine-lincoln-primary;
                font-family: $proxima-nova-bold;
                font-size: pToR(16);
                line-height: pToR(24);
                letter-spacing: normal;
                margin-bottom: pToR(19);
            }
        }
    }
}

.checkbox-with-tooltip-container {
    display: flex;
    flex-direction: row;

    .brand-ford & {
        @include media('<505px') {
            justify-content: space-between;
        }
    }

    .brand-lincoln & {
        @include media('<590px') {
            justify-content: space-between;
        }
    }

    .tooltip-wrapper {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: baseline;
        width: fit-content;
        height: fit-content;
        margin-left: pToR(2);
        transform: translateY(-1px);
        z-index: 5;

        &:has(.tooltip-tip) {
            @include mobile-and-tablet {
                position: fixed;
                z-index: 5500;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                height: 100vh;
                width: 100vw;
                margin-left: 0;
            }
        }
        .tooltip-icon {
            display: block;

            &:hover {
                cursor: pointer;
            }

            &.open {
                @include mobile-and-tablet {
                    display: none;
                }
            }

            &.change-icon-layers {
                @include desktop {
                    display: none;
                }
            }

            button {
                padding: pToR(2);

                &:focus {
                    outline: unset;
                }
                &:focus-visible {
                    outline: pToR(2) solid $fds-reimagine-secondary-blue;
                    outline-offset: pToR(1);

                    .brand-lincoln & {
                        outline: 1px solid $fds-preimagine-lincoln-primary;
                        outline-offset: pToR(2);
                    }
                }
            }
        }
        .tooltip-tip {
            width: max-content;
            max-width: pToR(358);
            white-space: normal;
            box-shadow: none;
            position: absolute;
            right: 0;
            top: 27%;
            border-radius: pToR(8);
            transform: translateX(17px);

            @include mobile-and-tablet {
                position: relative;
                top: 0;
                left: 0;
                height: 100vh;
                width: 100vw;
                transform: none;
                max-width: 100%;
            }

            button {
                height: fit-content;
                position: absolute;
                right: pToR(15);
                top: pToR(14);
            }
        }

        .fmc-dialog__close {
            &:focus-visible {
                outline: pToR(2) solid $fds-reimagine-secondary-blue;
                outline-offset: pToR(2);
            }
        }
    }
}
