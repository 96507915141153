@import '@styles/main.v5';
@import '@styles/common';

.slide {
    &__container {
        background-color: white;
        width: pToR(656);
        height: 100%;
        border-radius: pToR(8);
        min-width: pToR(313);

        @include mobile {
            width: calc(100vw - 64px);
            max-width: pToR(313);
            height: pToR(368);
        }
    }

    &__media-area {
        position: relative;
        height: pToR(205);

        @include mobile {
            height: pToR(116);
        }

        .slide__background-image {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-position: top;
            background-size: cover;
            border-top-left-radius: pToR(8);
            border-top-right-radius: pToR(8);
            opacity: 0.8;

            @include mobile {
                background-position: bottom;
            }
        }
    }

    &__content-area {
        position: relative;
        letter-spacing: normal;
        margin-top: pToR(24);
        background: linear-gradient(
            to bottom right,
            #e4f1ff 0%,
            #e4f1ff 50%,
            #ffffff 120%
        );
        border-radius: pToR(8);
        padding-left: pToR(16);
        padding-right: pToR(16);
        padding-bottom: pToR(24);
        min-height: 100%;
        @include mobile {
            margin-top: pToR(0);
            min-height: pToR(368);
            height: fit-content;
        }

        @include tablet {
            margin-top: pToR(0);
        }

        .order-slide & {
            @include mobile {
                margin-top: pToR(47);
            }
        }

        .multiple-vehicles-state & {
            @include mobile {
                margin-top: pToR(62);
            }
        }

        .center-content-without-image & {
            @include tablet {
                max-width: pToR(664);
            }
        }

        .slide__secondary-button {
            margin-left: auto;
            color: $fds-preimagine-button-blue;
            display: flex;
            height: pToR(40);
            font-family: $ford-f1-font-bold;
            font-size: pToR(16);
            line-height: pToR(24);
            letter-spacing: normal;
            border-radius: pToR(400);
            justify-content: flex-end;
            padding-right: pToR(16);
            margin-top: pToR(16);
            position: relative;
            z-index: 100;

            @include mobile {
                padding-top: pToR(16);
                padding-right: 0;
            }

            @include tablet {
                padding-top: pToR(16);
                padding-right: 0;
            }

            &:hover {
                color: $fds-preimagine-primary-button-hover;
                cursor: pointer;
            }

            &:focus,
            &:focus-visible {
                outline: 2px solid $fds-preimagine-button-blue;
            }

            .eu & {
                @include mobile {
                    justify-content: flex-start;
                }
            }

            .slide__secondary-button--icon {
                display: flex;
                width: pToR(17);
                height: pToR(17);
            }
        }

        .no-padding {
            min-width: unset;
            padding: 0 pToR(6);
        }

        .vehicle-container {
            max-height: pToR(100);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            .ymm-and-vin {
                display: flex;
                flex-direction: column;
                margin-top: pToR(8);
                @include mobile-and-tablet {
                    width: 100%;
                }
                .ymm {
                    color: $fds-preimagine-high-contrast;
                    font-family: $ford-f1-font-medium;
                    font-size: pToR(24);
                    line-height: pToR(32);
                    margin-bottom: pToR(8);
                    @include mobile {
                        font-size: pToR(20);
                        line-height: pToR(28);
                    }

                    .multiple-vehicles-state & {
                        @include mobile {
                            padding-top: pToR(4);
                        }
                    }
                }

                .vin-and-mileage__container {
                    color: $fds-preimagine-high-contrast;
                    font-family: $ford-f1-font-regular;
                    font-size: pToR(12);
                    line-height: pToR(16);
                    display: flex;
                    flex-direction: row;
                    margin-bottom: pToR(16);

                    @include mobile {
                        flex-direction: row;
                    }
                }
            }
            .slide__vehicle-image--container {
                display: block;
                width: 50%;
                max-width: pToR(456);
                margin-left: auto;
                top: pToR(-65);
                right: pToR(-30);
                z-index: 5;

                @include media('<1289px') {
                    width: pToR(265);
                }

                @include mobile {
                    width: pToR(279);
                    max-width: pToR(192);
                    top: pToR(-125);
                    right: 0;
                    margin-left: 0;
                }
            }

            .slide__vehicle-image {
                display: flex;
                margin-top: pToR(120);
                margin-left: auto;
                margin-right: pToR(16);
                width: 70%;
                @include media('<1289px') {
                    width: 100%;
                    margin-top: pToR(120);
                }
                @include mobile {
                    padding-right: 0;
                    width: 110%;
                    margin-top: 0;
                }
            }
        }

        .vehicle-container-no-cta {
            max-height: pToR(200);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: pToR(50);
            @include mobile {
                flex-direction: column-reverse;
                margin-top: 0;
            }
            .ymm-and-vin {
                display: flex;
                flex-direction: column;
                margin-top: pToR(8);
                @include mobile-and-tablet {
                    width: 100%;
                }
                .ymm {
                    color: $fds-preimagine-high-contrast;
                    font-family: $ford-f1-font-medium;
                    font-size: pToR(24);
                    line-height: pToR(32);
                    margin-bottom: pToR(8);
                    @include mobile {
                        font-size: pToR(20);
                        line-height: pToR(28);
                    }

                    .multiple-vehicles-state & {
                        @include mobile {
                            padding-top: pToR(4);
                        }
                    }
                }

                .vin-and-mileage__container {
                    color: $fds-preimagine-high-contrast;
                    font-family: $ford-f1-font-regular;
                    font-size: pToR(12);
                    line-height: pToR(16);
                    display: flex;
                    flex-direction: row;
                    margin-bottom: pToR(16);

                    @include mobile {
                        flex-direction: row;
                    }
                }
            }
            .slide__vehicle-image--container {
                display: block;
                width: 50%;
                max-width: pToR(456);
                margin-left: auto;
                top: pToR(-65);
                right: pToR(-30);
                z-index: 5;

                @include media('<1289px') {
                    width: pToR(265);
                }

                @include mobile {
                    width: pToR(279);
                    max-width: pToR(192);
                    top: pToR(-125);
                    right: 0;
                    margin-left: 0;
                }
            }

            .slide__vehicle-image--container-no-cta {
                display: block;
                width: 50%;
                max-width: pToR(456);
                margin-left: auto;
                top: pToR(-65);
                right: pToR(-30);
                z-index: 5;

                @include media('<1289px') {
                    width: pToR(265);
                }

                @include mobile {
                    width: pToR(279);
                    max-width: pToR(192);
                    top: pToR(-125);
                    right: 0;
                    margin-left: pToR(-150);
                }
            }

            .slide__vehicle-image {
                display: flex;
                margin-top: pToR(-8);
                margin-left: auto;
                margin-right: pToR(32);
                width: 80%;
                @include tablet {
                    padding-right: 0;
                    margin-top: 0;
                }
                @include mobile {
                    padding-right: 0;
                    width: 80%;
                    margin-top: 0;
                }
            }
        }

        .mileage__container {
            @include tablet {
                margin-left: pToR(36);
            }
        }

        .mmota-container {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: pToR(16);
            max-width: pToR(654);

            @include mobile {
                grid-column-gap: pToR(8);
            }

            .mmota-box {
                background-color: #f2f2f2;
                height: pToR(72);
                display: flex;
                flex-direction: column;
                justify-content: center;
                border-radius: pToR(8);
                padding: 0 pToR(24);

                h5 {
                    color: $fds-preimagine-dark-charcoal;
                    font-family: $ford-f1-font-medium;
                    font-size: pToR(12);
                    line-height: pToR(16);
                    letter-spacing: normal;
                    margin-bottom: 0;
                }

                p {
                    color: $fds-preimagine-dark-charcoal;
                    font-family: $ford-f1-font-bold;
                    font-size: pToR(16);
                    line-height: pToR(24);
                    letter-spacing: normal;
                }
            }
        }

        .fmc-cards__menu-links {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: pToR(16);
            max-width: pToR(654);
            margin-top: pToR(10);
            @include mobile {
                margin-top: pToR(32);
                grid-template-columns: 1fr;
            }

            .fmc-cards__menu-link {
                display: flex;
                align-items: center;
                min-height: pToR(40);
                border-radius: pToR(12);
                border: 1px solid #b2b2b2;
                background-color: $white;
                &:hover {
                    cursor: pointer;
                    background-color: $fds-preimagine-background-gray;
                }

                .secondary-button {
                    min-width: max-content;
                    width: 100%;

                    .fmc-type--body2 {
                        &:focus-visible {
                            border-radius: pToR(12);
                        }
                    }
                }

                .secondary-button-text {
                    color: $fds-preimagine-high-contrast;
                    font-family: $ford-f1-font-regular;
                    font-size: pToR(16);
                    line-height: pToR(24);
                    letter-spacing: normal;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    padding: pToR(8) pToR(16);
                    .fds-icon {
                        line-height: pToR(24);
                    }

                    @include media('<414px') {
                        .show-prognostics-alerts & {
                            align-items: center;
                        }
                    }
                    .span-styling {
                        display: flex;
                        flex-direction: column;
                        color: $fds-preimagine-high-contrast;
                        font-family: $ford-f1-font-regular;
                        font-size: pToR(16);
                        line-height: pToR(24);
                        letter-spacing: normal;
                        .span-second-row {
                            flex-direction: row;
                            color: $fds-preimagine-high-contrast;
                            font-family: $ford-f1-font-bold;
                            font-size: pToR(16);
                            line-height: pToR(24);
                            letter-spacing: normal;
                        }
                    }
                }

                &.show-prognostics-alerts {
                    .secondary-button-text {
                        @include media('<414px') {
                            align-items: center;
                        }
                    }
                }
            }
        }

        .slide__button-container {
            margin-top: pToR(24);
            @include mobile {
                margin-top: pToR(64);
            }

            .order-slide & {
                @include tablet {
                    margin-top: pToR(32);
                }
            }
            .go-to-vehicle-button {
                display: inline-block;
                vertical-align: bottom;
                .slide__primary-button {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    background-color: $fds-preimagine-button-blue;
                    height: pToR(40);
                    width: fit-content;
                    font-family: $ford-f1-font-bold;
                    font-size: pToR(14);
                    line-height: pToR(20);
                    letter-spacing: normal;
                    border-radius: pToR(400);
                    padding: pToR(12) pToR(24);

                    @include tablet {
                        margin-right: pToR(24);
                    }

                    &:hover {
                        background-color: $fds-preimagine-primary-button-hover;
                        cursor: pointer;
                    }

                    &:focus-visible {
                        outline-offset: pToR(3);
                    }

                    .order-slide & {
                        min-width: pToR(131);
                    }
                }
            }
        }

        .slide__button-container-no-cta {
            margin-top: pToR(0);
            @include mobile {
                margin-top: pToR(64);
            }

            .order-slide & {
                @include tablet {
                    margin-top: pToR(32);
                }
            }
            .go-to-vehicle-button-no-cta {
                height: unset;
                display: flex;
                align-items: end;
                @include mobile {
                    height: unset;
                }
                .slide__primary-button {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    background-color: $fds-preimagine-button-blue;
                    height: pToR(40);
                    width: fit-content;
                    font-family: $ford-f1-font-bold;
                    font-size: pToR(14);
                    line-height: pToR(20);
                    letter-spacing: normal;
                    border-radius: pToR(400);
                    padding: pToR(12) pToR(24);

                    @include tablet {
                        margin-right: pToR(24);
                    }

                    &:hover {
                        background-color: $fds-preimagine-primary-button-hover;
                        cursor: pointer;
                    }

                    &:focus-visible {
                        outline-offset: pToR(3);
                    }

                    .order-slide & {
                        min-width: pToR(131);
                    }
                }
            }
        }

        .slide__notification-container {
            max-width: pToR(654);
        }
    }
}
