@import '@styles/main.v5';
@import '@styles/common';

.email-channel-preferences-view {
    &__wrapper {
        width: 100%;

        .brand-lincoln & {
            background-color: $fds-color-surface;
        }
    }
    &__container {
        min-height: 50vh;
        width: 100%;
        padding: 0 pToR(16);

        @include tablet {
            padding: 0 pToR(56);
        }
        .brand-lincoln & {
            background-color: $fds-color-surface;
            margin-left: auto;
            margin-right: auto;
        }
        .hr-line {
            height: pToR(1);
            border-bottom: pToR(1) solid $disabled-dark;
            box-shadow: none;
            margin-bottom: pToR(8);
            width: 100%;

            .brand-lincoln & {
                border: 0;
                border-bottom: pToR(1) solid $brand-gray3;

                @include mobile {
                    border: 0;
                    border-bottom: pToR(1) solid $brand-gray3;
                }
            }
        }
    }

    &__content {
        margin: 0 auto;
        max-width: pToR(1105);

        @include tablet {
            margin-bottom: pToR(64);
        }
        &__notification-container {
            margin-top: pToR(32);
            @include mobile {
                margin-top: pToR(32);
            }

            @include tablet {
                margin-top: pToR(-19);
                margin-bottom: pToR(40);
            }
        }
        .email-channel-preferences-view__notification-container {
            margin-bottom: 0;
            .notification-container.no-sub-copy {
                margin-top: pToR(32);
            }
        }
        .brand-lincoln & {
            .fmc-top-banner .fmc-top-banner__inner {
                margin-top: pToR(32);
            }
        }
    }

    &__header {
        color: $charcoal;
        font-family: $ford-f1-font-regular;
        font-size: pToR(48);
        line-height: pToR(56);
        letter-spacing: normal;
        padding-top: pToR(48);
        @include mobile {
            padding-top: pToR(24);
            font-size: pToR(36);
            line-height: pToR(44);
        }
        .brand-lincoln & {
            font-family: $lincoln-miller-b-semibold;
            color: $fds-preimagine-lincoln-primary;
            font-size: pToR(38);
            line-height: pToR(44);
            letter-spacing: normal;
            padding-top: pToR(49);
        }
    }

    &__description {
        p {
            color: $fds-preimagine-high-contrast;
            font-family: $ford-f1-font-regular;
            font-size: pToR(16);
            line-height: pToR(24);
            letter-spacing: normal;
            max-width: 81ch;
            margin-top: pToR(11);
            margin-bottom: pToR(32);

            @include mobile {
                margin-bottom: pToR(32);
            }
            @include tablet {
                margin-bottom: pToR(40);
            }
            .brand-lincoln & {
                font-family: $proxima-nova-regular;
                color: $fds-preimagine-lincoln-primary;
                font-size: pToR(16);
                line-height: pToR(24);
                letter-spacing: normal;
                margin-top: pToR(11);
                margin-bottom: pToR(48);

                @include mobile {
                    margin-top: pToR(16);
                    margin-bottom: pToR(32);
                }
            }
        }
    }
}

.brand-lincoln {
    .fmc-top-banner .fmc-top-banner__inner {
        margin-top: pToR(32);
    }
}

.brand-ford .email-channel-preferences-view__container .self-help-container {
    padding: pToR(43) 0 pToR(118);

    @include mobile {
        padding: pToR(30) 0 pToR(100);
    }

    .banner .top.no-bottom .vehicle-tabs .search-container .fmc-type--heading1 {
        font-size: pToR(32);
        line-height: pToR(40);

        @include mobile {
            font-size: pToR(28);
            line-height: pToR(36);
        }
    }
}
