@import '@styles/main.v5';

.search-bar {
    margin-top: 0;

    &.search-banner {
        .banner {
            @include desktop {
                height: pToR(335);
            }

            .bottom-container {
                @include mobile-v5 {
                    min-height: unset;
                }
            }
        }
    }

    .banner {
        @include desktop {
            margin: 0 auto 20px;

            .brand-lincoln & {
                background-color: $brand-white;
                height: pToR(438);
                margin: 0 auto 27px auto;
            }
        }
    }

    .top {
        @include mobile-v5 {
            max-height: pToR(433);
            .brand-lincoln & {
                max-height: pToR(516);
            }
        }
        @include tablet-v5 {
            max-height: pToR(335);
        }

        .vehicle-tabs {
            min-height: pToR(335);
            align-items: center;
            padding-top: 0;

            @include mobile-v5 {
                min-height: pToR(516);
                margin-top: 0;
            }

            @include tablet-v5 {
                min-height: pToR(335);
                display: flex;
                align-items: center;
            }

            &.justify-vehicle-tabs {
                justify-content: center;
                @include mobile-v5 {
                    min-height: pToR(433);
                }
            }

            .brand-lincoln & {
                align-items: flex-start;
            }
        }
    }

    .search-container {
        align-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        position: relative;
        top: pToR(112);

        &.help-cards-only {
            top: unset;

            .fmc-type--heading1 {
                margin-bottom: 0;
            }
        }

        &.search-eu {
            top: pToR(-48);
        }

        @include mobile-v5 {
            top: pToR(50);
            max-width: 90%;
        }

        .brand-lincoln & {
            @include mobile {
                padding: 0 pToR(18);
                width: 100%;
                max-width: 100%;
            }
        }

        &.search-only {
            padding: 0;
            top: unset;

            @include mobile {
                max-width: 90%;
            }

            .top {
                max-height: pToR(438);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }

        .fmc-type--heading1 {
            @include mobile-v5 {
                font-size: $fds-font-size__heading4--max;
                line-height: 43px;
                margin-bottom: 76px;
                text-align: center;
                max-width: 80%;
            }
            @include mobile {
                font-size: $fds-font-size__heading4--max;
            }

            @include tablet-v5 {
                margin-bottom: 40px;
            }
        }

        .search-title {
            font-size: $fds-font-size__heading1--min;
            font-weight: normal;
            text-transform: uppercase;
            line-height: pToR(44);
            letter-spacing: 4px;
            margin-bottom: pToR(28);
            text-align: center;
            color: $white;
            font-family: $antenna-font-condensed-light;

            &.help-cards-only {
                margin-bottom: unset;
            }

            @include desktop {
                font-size: pToR(48);
                line-height: pToR(58);
                letter-spacing: 0.19rem;
            }

            .brand-lincoln & {
                font-family: $proxima-nova-regular;
                font-weight: 400;
                line-height: 32px;
                font-size: pToR(24);
                letter-spacing: pToR(2.5);
                text-align: center;

                @include mobile {
                    font-size: pToR(22);
                    line-height: pToR(32);
                    letter-spacing: 2.29px;
                }
            }
        }

        .placeholder-text-offset {
            visibility: hidden;
            margin-right: pToR(60);
        }

        form {
            align-items: center;
            background-color: $white;
            border: pToR(1) solid $border-grey;
            border-radius: pToR(30);
            box-sizing: border-box;
            display: flex;
            text-align: center;
            width: 100%;
            height: pToR(50);

            @include tablet-v5 {
                width: 90%;
            }
            @include tablet {
                width: 100%;
                max-width: pToR(674);
            }

            .search-icon {
                background: none;
                border: none;
                cursor: pointer;
                outline: none;
                position: absolute;
                padding: pToR(3) pToR(16) 0 pToR(20);
                margin: 0;

                use {
                    transition: 0.3s;
                }
            }

            .search-input {
                border-radius: pToR(30);
                font-family: $antenna-font-light;
                border: none;
                box-sizing: border-box;
                color: $search-text;
                outline: none;
                font-size: pToR(16);
                padding: pToR(12) pToR(16) pToR(12) 0;
                transition: color 0.3s;
                width: 100%;
                height: 100%;
                line-height: pToR(26);
                letter-spacing: 1px;

                &::placeholder {
                    color: $search-text;
                    opacity: 1;
                    transition: color 0.3s;
                }

                .brand-lincoln & {
                    color: $brand-mild-gray2;
                    font-family: $proxima-nova-regular;
                    letter-spacing: 0;
                    line-height: pToR(20);
                    border-radius: unset;
                    @include desktop {
                        width: pToR(608);
                    }
                }
            }

            :focus {
                outline-width: 2px;
                outline-style: solid;
                outline-color: $highlight-blue;

                @media (-webkit-min-device-pixel-ratio: 0) {
                    outline-color: -webkit-focus-ring-color;
                    outline-style: auto;
                    outline-width: 5px;
                }

                .search-icon {
                    use {
                        fill: $black;
                    }
                }

                .search-input {
                    color: $black;

                    &::placeholder {
                        color: $black;
                    }
                }
            }

            ::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                color: $text-gray;
            }

            ::-moz-placeholder {
                /* Firefox 19+ */
                color: $text-gray;
            }

            :-ms-input-placeholder {
                /* IE 10+ */
                color: $text-gray;
            }

            :-moz-placeholder {
                /* Firefox 18- */
                color: $text-gray;
            }

            .brand-lincoln & {
                border-radius: unset;
                height: pToR(60);
            }
        }
    }
}

.self-help-container {
    .brand-ford & {
        display: flex;
        align-items: flex-start;
        padding: pToR(48) pToR(56) pToR(160);

        @include mobile {
            padding-bottom: pToR(100);
            padding-top: pToR(30);
        }

        .banner {
            .top.no-bottom {
                margin-bottom: unset;
                margin-top: unset;

                .vehicle-tabs {
                    padding-top: unset;

                    .search-container {
                        .fmc-type--heading1 {
                            font-family: $ford-f1-font-regular;
                            font-size: pToR(54);
                            font-style: normal;
                            line-height: pToR(64);
                            letter-spacing: normal;
                            color: $fds-preimagine-dark-text;

                            @include mobile {
                                font-size: pToR(48);
                            }
                        }

                        .self-help-body {
                            font-family: $ford-f1-font-regular;
                            font-size: pToR(16);
                            font-style: normal;
                            line-height: pToR(24);
                            letter-spacing: normal;
                            color: $fds-preimagine-dark-text;
                            margin-bottom: pToR(36);
                        }

                        .self-help-search {
                            width: pToR(448);
                            height: pToR(40);
                            border-radius: pToR(7);
                            border: 1px solid #6b7786;
                            margin-bottom: pToR(48);

                            @include mobile-v5 {
                                width: 100%;
                                margin-bottom: pToR(36);
                            }

                            .search-icon {
                                position: relative;

                                img {
                                    position: absolute;
                                    top: -50px;
                                    z-index: 10;
                                    left: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.banner {
    .brand-ford & {
        @include mobile-v5 {
            width: 100%;
        }

        .bottom-container {
            .bottom {
                display: unset;
                justify-content: unset;
                position: unset;

                .self-help-buttons-container {
                    .self-help-buttons-wrapper {
                        display: flex;
                        justify-content: flex-start;

                        @include mobile {
                            flex-direction: column;
                        }

                        .secondary-button {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            font-family: $ford-f1-font-bold;
                            font-size: pToR(14);
                            font-style: normal;
                            line-height: pToR(20);
                            letter-spacing: normal;
                            height: pToR(40);
                            border-radius: pToR(400);
                            border: 1px solid $fds-preimagine-primary-blue;

                            @include tablet {
                                margin-right: pToR(32);
                            }

                            @include mobile {
                                width: fit-content;
                                margin-bottom: pToR(24);
                            }

                            &:hover {
                                border-color: #055ecb;
                            }

                            .secondary-button-text {
                                display: flex;
                                align-items: center;

                                span {
                                    display: inline-flex;

                                    img {
                                        top: unset;
                                    }
                                }
                            }

                            a {
                                padding: pToR(12) pToR(20);
                                color: $fds-preimagine-primary-blue;
                                height: 100%;
                                display: flex;
                                align-items: center;

                                img {
                                    position: relative;
                                    top: pToR(6);
                                    left: pToR(8);
                                }

                                &:hover {
                                    border-color: #055ecb;
                                    color: #055ecb;
                                }

                                &:focus {
                                    outline: 2px solid #066fef;
                                    outline-offset: 3px;
                                    border-radius: pToR(400);
                                    --scale-hover: unset;
                                }
                            }
                        }

                        @include tablet {
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

#search-bar-desktop {
    .brand-ford & {
        color: $fds-color--primary;

        &::placeholder {
            color: $fds-color--primary;
        }
    }
}

.fgx-brand-css {
    .disclosures {
        .title {
            padding: pToR(10);
            @include mobile {
                padding-top: pToR(20);
            }
        }
    }
}
