@import '@styles/main.v5';

.smart-tile {
    &.smart-tile-fpr-lincoln {
        .labeled-number {
            display: flex;
            flex-direction: column;
            padding: 0 0.5rem;

            margin-top: pToR(47);
            @include mobile-v5 {
                margin-top: pToR(32);
            }
            .container {
                height: pToR(70);
                margin: auto;
                .brand-lincoln & {
                    display: flex;
                    flex-direction: column;
                }
            }
            .icon {
                margin: 0 auto;
                max-width: pToR(70);
                max-height: pToR(70);

                @include mobile-v5 {
                    max-height: pToR(60);
                    max-width: pToR(60);
                }
                .brand-lincoln & {
                    margin: pToR(0) auto pToR(0);
                    max-height: pToR(70);
                    max-width: pToR(70);
                    @include mobile-v5 {
                        max-height: pToR(60);
                        max-width: pToR(60);
                    }
                }
            }
            .title {
                font-family: $antenna-font-condensed-light;
                font-size: pToR(20);
                margin-top: pToR(13);
                margin-bottom: pToR(12);
                letter-spacing: pToR(3);
                .brand-lincoln & {
                    font-size: pToR(24);
                    font-family: $proxima-nova-regular;
                    line-height: pToR(32);
                    letter-spacing: pToR(2.5);
                    color: $brand-secondary;
                    @include mobile {
                        font-size: pToR(16);
                    }
                }
            }

            .number {
                font-size: pToR(24);
                font-family: $antenna-font-condensed-bold;
                letter-spacing: pToR(3);
                display: flex;
                margin: 0px auto;
                color: $dark-blue;
                .brand-lincoln & {
                    font-size: pToR(17);
                    font-family: $proxima-nova-bold;
                    line-height: pToR(27);
                    letter-spacing: pToR(2);
                    color: $brand-secondary;
                    @include mobile {
                        font-size: pToR(16);
                    }
                }
                span {
                    display: inline-block;
                    font-size: pToR(20);
                    line-height: pToR(29);
                    font-weight: 700;
                    letter-spacing: normal;
                    margin: auto;
                }
                @include mobile-v5 {
                    display: none;
                }
            }

            .label {
                letter-spacing: pToR(0.77);
                font-size: pToR(10);
                line-height: pToR(26);
                text-align: center;
                font-family: $antenna-font;
                color: $text-gray;
            }

            .tier-title {
                font-weight: 400;
                font-size: pToR(14);

                .brand-lincoln & {
                    letter-spacing: normal;
                    @include mobile-v5 {
                        padding-top: 0;
                        margin-bottom: 0;
                    }
                }
                @include mobile {
                    font-size: pToR(12);
                    margin-bottom: pToR(2);
                }
            }
        }
        .fmc-type--heading6,
        .fmc-type--body1 {
            @include mobile-v5 {
                display: none;
            }
        }
        .fmc-type--heading6 {
            margin-bottom: pToR(16);
            margin-top: pToR(26);

            [data-brand='lincoln'] & {
                @include tablet-v5 {
                    font-size: 24px;
                    line-height: 32px;
                    letter-spacing: 2.5px;
                    margin-bottom: 10px;
                }
            }
        }

        .fmc-type--subtitle1 {
            @include tablet-v5 {
                display: none;
            }
            @include mobile-v5 {
                margin-top: pToR(15);
            }
        }
    }
}
