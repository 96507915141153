@import '@styles/main.scss';

.return-button {
    @include mobile {
        margin-top: pToR(12);
    }

    .brand-ford & {
        .secondary-button {
            @include mobile {
                transform: translateX(-3px);
            }

            a {
                padding: pToR(12) pToR(20);

                .preferred-dealer__view & {
                    padding-left: 0;
                }

                @include mobile {
                    padding-left: 0;
                }
            }
        }
    }
}
