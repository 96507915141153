@import '@styles/main.scss';

.tertiary-button {
    display: inline-block;

    .brand-ford & {
        .secondary-button {
            display: inline-block;

            .secondary-button-text {
                display: inline-flex;
                align-items: center;
                .plus-icon {
                    padding-left: pToR(16);
                    padding-bottom: pToR(2);
                }
            }

            a {
                display: flex;
                font-family: $ford-f1-font-bold;
                color: $fds-reimagine-secondary-blue;
                font-size: pToR(14);
                line-height: pToR(20);
                letter-spacing: normal;
                height: pToR(40);
                width: fit-content;

                &:focus-visible {
                    border-radius: pToR(400);
                }

                .caret {
                    height: pToR(24);
                }

                .left-caret {
                    margin-right: pToR(8);
                }

                .right-caret {
                    margin-left: pToR(8);
                }

                svg {
                    fill: $fds-reimagine-secondary-blue;
                }
            }
        }
    }
    .brand-lincoln & {
        .caret {
            display: none;
        }
    }
}
