@import '@styles/main.scss';

@media screen and (max-width: $fds-min-desktop) {
    .sidebar-subnav {
        padding-left: 1.375rem;
        padding-top: pToR(10);
        padding-bottom: pToR(24);
        width: 100%;
        background-color: white;
        position: sticky;

        .sidebar-subnav-title {
            font-family: FordAntennaExtraLight, arial, helvetica, sans-serif;
            font-size: 1.3125rem;
            height: 4rem;
            display: block;
            line-height: 4rem;
            color: #555;
        }
        .subnav-drawer {
            padding-left: pToR(45);

            .fmc-type--heading6 {
                font-size: pToR(14);
                font-weight: 500;
                line-height: pToR(24);
            }
            .links-container {
                margin-top: pToR(24);
                padding-left: pToR(16);

                .subnav-link {
                    font-size: pToR(13);
                    line-height: pToR(13);
                    letter-spacing: normal;
                    color: $fds-color--primary;
                    padding-bottom: pToR(20);
                }
            }
        }

        .subnav-title-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 4rem;
            width: 100%;
            border-top: 1px solid #f7f7f7;
            padding: 0;
            outline: none;

            .drawer-title {
                box-sizing: border-box;
                display: block;
                font-size: 1rem;
                height: 4rem;
                line-height: 4rem;
                margin: 0;
                padding: 0;
                width: 100%;
                text-align: left;
                color: $hover-blue;
            }
            &.selected-drawer {
                outline: 1px solid $fds-color--secondary;
                .drawer-title {
                    color: rgb(16, 43, 78);
                }
                svg {
                    stroke: black;
                }
            }

            .chevron-icon-container {
                flex-grow: 1;
                margin-right: 2rem;
            }
        }
    }
}
