@import '@styles/main.v5';
@import '@styles/common';

.order-history-drawer {
    width: 100%;
    height: 100%;
    padding: 0 pToR(56) pToR(57);
    display: flex;
    flex-direction: column;

    @include mobile {
        padding: 0 pToR(16) pToR(125);
    }

    &__header {
        color: $fds-reimagine-dark-blue;
        font-family: $ford-f1-font-semibold;
        font-size: pToR(36);
        line-height: pToR(44);
        letter-spacing: normal;
        padding-top: pToR(83);
        margin-bottom: pToR(50);
        align-self: flex-start;
        text-align: start;

        @include mobile {
            padding-top: pToR(58);
            margin-bottom: pToR(47);
        }
    }

    &__subheader {
        align-self: flex-start;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-bottom: pToR(20);

        h2 {
            color: black;
            font-family: $ford-f1-font-semibold;
            font-size: pToR(24);
            line-height: pToR(40);
            letter-spacing: normal;
            margin-bottom: pToR(40);

            &.order-history-drawer-subheader-accessories {
                margin-bottom: pToR(26);

                @include mobile {
                    margin-bottom: pToR(34);
                }
            }

            &.order-history-drawer-subheader-vehicles {
                margin-bottom: pToR(34);

                @include mobile {
                    margin-bottom: pToR(16);
                }
            }
        }
    }

    .vehicle-item-container {
        margin-bottom: pToR(12);
        width: 100%;
    }

    .secondary-button {
        align-self: flex-end;
        margin: pToR(20) 0 pToR(32);

        @include mobile {
            margin-top: pToR(13);
        }

        .view-vehicle-orders-button,
        .view-accessories-orders-button {
            @include ford-secondary-button-default();
            align-items: center;
            height: fit-content;
        }

        &:has(> .view-accessories-orders-button) {
            margin-top: pToR(8);
        }
    }

    &__divider {
        width: 100%;
        margin: 0;
        border-bottom: 1px solid $fds-reimagine-disabled-text-color;
        box-shadow: none;
    }

    .accessories-item-container {
        width: 100%;

        &.last-item {
            .hr-line {
                display: none;
            }
        }

        .order-history-drawer-item-container {
            width: 100%;

            & + .hr-line {
                margin-bottom: pToR(18);
                border-bottom-color: $fds-reimagine-disabled-background-color;
            }

            .order-history-drawer-item {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .order-history-drawer-order-date-label {
                    color: $charcoal;
                    font-family: $ford-f1-font-regular;
                    font-size: pToR(12);
                    line-height: pToR(16);
                    letter-spacing: normal;
                    order: 1;
                    margin-bottom: pToR(7);
                }

                .order-history-drawer-order-date {
                    color: $fds-reimagine-dark-blue;
                    font-family: $ford-f1-font-semibold;
                    font-size: pToR(20);
                    line-height: pToR(28);
                    letter-spacing: normal;
                    order: 2;
                    margin-bottom: pToR(18);
                }

                .order-history-drawer-order-info {
                    color: $charcoal;
                    font-family: $ford-f1-font-regular;
                    font-size: pToR(16);
                    line-height: pToR(24);
                    letter-spacing: normal;

                    &.number {
                        order: 4;
                    }

                    &.amount {
                        order: 3;
                    }

                    &.total {
                        display: none;
                    }
                }

                .tertiary-button {
                    order: 5;
                    margin-bottom: pToR(24);

                    .secondary-button {
                        margin: 0;
                    }
                }
            }

            .order-history-drawer-order-items-images-container {
                .inner-container {
                    margin-bottom: pToR(24);

                    .individual-items-container {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        gap: pToR(8);
                        height: fit-content;

                        .individual-item {
                            height: pToR(100);
                            width: pToR(100);

                            @include mobile {
                                height: pToR(83);
                                width: pToR(83);
                            }
                        }
                    }

                    .order-history-drawer-item-status {
                        color: $fds-preimagine-dark-text;
                        width: fit-content;
                        padding: 0 pToR(4);
                        font-family: $ford-f1-font-regular;
                        font-size: pToR(12);
                        line-height: pToR(16);
                        letter-spacing: normal;
                        border-radius: pToR(4);
                        margin-bottom: pToR(16);
                    }

                    .order-history-drawer-item-link {
                        display: inline-grid;
                        width: pToR(100);
                        height: pToR(100);
                        position: relative;
                        border-radius: pToR(8);
                        overflow: hidden;

                        @include mobile {
                            width: pToR(83);
                            height: pToR(83);
                        }

                        img {
                            display: block;
                            border-radius: pToR(8);
                            position: absolute;
                            height: 100%;
                            transform: translate(-12%, 0);
                            max-width: unset;
                        }
                    }
                }
            }
        }
    }
}
