@import '@styles/main.v5';

.shared-view {
    background-color: $gray;
    overflow-x: clip;

    .brand-ford & {
        background-color: $white;

        .vehicle-tabs {
            background-color: $white;
        }
    }

    &.has-vehicles {
        #Hero {
            .vehicle-tabs {
                padding-top: pToR(40);

                .brand-lincoln & {
                    padding-top: pToR(40);
                }

                .brand-ford & {
                    @include desktop {
                        padding-top: 0;
                    }
                }

                @include tablet {
                    padding-top: unset;
                }
                @include mobile {
                    padding-top: unset;
                }
            }
        }
    }

    &.no-vehicles {
        #Hero {
            .vehicle-tabs {
                @include tablet {
                    padding-top: pToR(40);
                }
                @include desktop {
                    .brand-ford & {
                        padding-top: 0;
                    }
                }
            }
        }
    }

    &.no-vehicles {
        #Hero {
            .banner {
                .top.no-bottom {
                    background-position: center;
                    background-size: cover;

                    @include mobile {
                        .eu & {
                            margin-top: pToR(70);
                        }
                    }

                    .vehicle-tabs {
                        justify-content: center;
                        margin-top: 0;

                        .brand-ford & {
                            padding-top: 0;
                        }

                        .vehicle-masthead-dashboard {
                            height: auto;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            padding-top: pToR(118);

                            .brand-lincoln & {
                                @include mobile {
                                    flex-direction: unset;
                                }
                            }

                            @include mobile-v5 {
                                margin: 0;
                                padding-top: unset;
                            }

                            @include mobile {
                                margin-bottom: 0;
                            }

                            .featured-articles-carousel-container {
                                margin: pToR(-5) auto 0;
                                padding: 0;
                                max-width: 30ch;

                                .featured-articles-carousel-subtitle {
                                    max-width: 30ch;
                                }
                            }
                        }
                    }
                }
            }
        }

        @include tablet-v5 {
            #Hero {
                .banner {
                    .top.no-bottom {
                        .vehicle-tabs {
                            justify-content: center;

                            .vehicle-masthead-dashboard {
                                padding-top: 0;
                                margin: auto;

                                .featured-articles-carousel-container {
                                    max-width: 100%;
                                    margin-left: unset;
                                    margin-right: unset;
                                }
                            }
                        }
                    }
                }
            }
        }

        @include mobile {
            #Hero {
                .banner {
                    .top.no-bottom {
                        .vehicle-tabs {
                            .vehicle-masthead-dashboard {
                                .featured-articles-carousel-container {
                                    margin-left: unset;
                                    margin-right: unset;
                                }
                            }
                        }
                    }
                }
            }
        }

        @include mobile-v5 {
            #Hero {
                .banner {
                    .top.no-bottom {
                        .vehicle-tabs {
                            .vehicle-masthead-dashboard {
                                .featured-articles-carousel-container {
                                    margin: unset;
                                }
                            }
                        }
                    }
                }
            }
        }
        @include desktop {
            #Hero {
                .banner {
                    .top.no-bottom {
                        .vehicle-tabs {
                            min-height: unset;

                            .vehicle-masthead-dashboard {
                                .featured-articles-carousel-container {
                                    margin-top: 0;
                                    margin-bottom: 0;
                                    padding: 0;

                                    .featured-articles-carousel-subtitle {
                                        max-width: 77ch;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.dynamic-masthead-state {
        .vehicle-masthead-dashboard {
            max-width: unset;
        }

        .add-vehicle-modal {
            .fmc-button {
                margin-bottom: unset;
            }
        }

        .featured-articles-carousel-container {
            @include mobile {
                margin-left: pToR(20);
                margin-right: pToR(20);
            }

            .has-vehicles & {
                @include mobile {
                    margin-top: pToR(32);
                }
            }
        }

        &.dynamic-masthead-state__orientation--left {
            #Hero {
                .banner {
                    .top.no-bottom {
                        background-position-y: center;

                        .no-vehicles & {
                            background-position: bottom;
                        }

                        @include mobile {
                            background-position: center;

                            .no-vehicles & {
                                background-position: bottom;
                            }
                        }
                        @include tablet {
                            .no-vehicles & {
                                background-position: bottom;
                            }
                        }

                        .vehicle-tabs {
                            .vehicle-masthead-dashboard {
                                .featured-articles-carousel-container {
                                    display: flex;
                                    flex-direction: column;
                                    max-width: unset;

                                    @include mobile {
                                        margin-left: pToR(20);
                                        margin-right: pToR(20);
                                    }

                                    .has-vehicles & {
                                        @include mobile {
                                            margin-top: pToR(32);
                                        }
                                    }

                                    .greeting-container {
                                        margin-top: pToR(24);
                                        align-self: flex-start;

                                        @include tablet {
                                            margin-top: 0;
                                        }

                                        .fmc-type--heading1 {
                                            font-size: pToR(24);
                                            line-height: pToR(29);
                                            font-weight: 700;
                                            margin-bottom: pToR(3);
                                            text-align: start;
                                            letter-spacing: normal;

                                            @include mobile {
                                                color: $dark-blue;
                                                font-size: pToR(28);
                                                line-height: pToR(36);
                                                text-align: start;
                                                margin-bottom: pToR(10);
                                                padding-bottom: 0;
                                                margin-top: pToR(10);
                                                font-family: $ford-f1-font-regular;
                                            }
                                        }
                                    }

                                    .fmc-type--content2,
                                    .no-vehicles-button {
                                        align-self: flex-start;
                                        letter-spacing: normal;

                                        .brand-ford {
                                            color: $fds-preimagine-primary-blue;
                                            padding-top: pToR(25);
                                        }

                                        @include mobile {
                                            height: pToR(30);
                                            color: $fds-reimagine-dark-blue;
                                            font-size: pToR(16);
                                            line-height: pToR(24);
                                            text-align: start;
                                            font-family: $ford-f1-font-regular;
                                            margin-bottom: pToR(8);
                                            padding-bottom: unset;
                                        }
                                    }

                                    .no-vehicles-button {
                                        padding-left: 0;

                                        .add-vehicle-container {
                                            margin-top: pToR(2);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            @include tablet {
                #Hero {
                    max-height: unset;

                    .banner {
                        .top.no-bottom {
                            justify-content: unset;

                            .vehicle-tabs {
                                padding: 0 pToR(100) 0 pToR(64);

                                .vehicle-masthead-dashboard {
                                    .featured-articles-carousel-container {
                                        max-width: 100%;
                                        display: flex;
                                        flex-direction: column;
                                        padding-top: pToR(32);

                                        .greeting-container,
                                        .fmc-type--content2 {
                                            align-self: flex-start;
                                        }

                                        .greeting-container {
                                            .fmc-type--heading1 {
                                                font-size: pToR(54);
                                                line-height: pToR(64);
                                                margin-bottom: pToR(6);
                                                text-align: start;
                                                padding-bottom: 0;
                                                margin-top: pToR(2);
                                                font-family: $ford-f1-font-regular;
                                                color: $dark-blue;
                                                min-width: pToR(400);
                                            }

                                            & ~ .fmc-type--content2 {
                                                font-family: $ford-f1-font-regular;
                                                line-height: pToR(24);
                                                margin-bottom: pToR(16);
                                                font-size: pToR(16);
                                                height: pToR(30);
                                                color: $dark-blue;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.has-vehicles {
            &.dynamic-masthead-state__orientation--right {
                #Hero {
                    .banner {
                        .top.no-bottom {
                            @include media('<=414px') {
                                background-position: right;
                            }

                            .vehicle-tabs {
                                .vehicle-masthead-dashboard {
                                    .featured-articles-carousel-container {
                                        padding-right: 0;
                                        padding-left: 0;

                                        .dynamic-masthead__content {
                                            padding-top: pToR(70);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            #Hero {
                .banner {
                    .top.no-bottom {
                        .vehicle-tabs {
                            @include mobile {
                                padding-top: pToR(70);
                            }

                            .vehicle-masthead-dashboard {
                                .featured-articles-carousel-container {
                                    @include mobile {
                                        margin-top: pToR(12);
                                        margin-bottom: pToR(-175);
                                        padding: 0;

                                        .fmc-cards {
                                            padding-bottom: pToR(33);
                                        }
                                        .greeting-container {
                                            margin-top: unset;

                                            .fmc-type--heading1 {
                                                .dynamic-masthead-state__orientation--right
                                                    & {
                                                    text-align: end;
                                                }

                                                .dynamic-masthead-state__orientation--left
                                                    & {
                                                    text-align: start;
                                                }
                                            }
                                        }
                                    }
                                }

                                &.no-indicators-desktop {
                                    .featured-articles-carousel-container-margin {
                                        margin-bottom: pToR(-150);

                                        @include tablet {
                                            margin-bottom: pToR(-216);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        #Hero {
            .banner {
                .top.no-bottom {
                    @include media('>1440px') {
                        background-position: center;
                    }
                }
            }
        }
    }
}

.shared-view.eu-view.no-vehicles.no-carousel-market
    #Hero
    .banner
    .top.no-bottom
    .vehicle-tabs {
    @include desktop {
        min-height: pToR(382);
        margin-bottom: pToR(33);
    }
}
