@import '@styles/main.scss';

.content-label {
    font-size: 16px;
    text-align: center;
    margin: 10px 0;
    border-left: 2px solid $border-grey;
}

.footer {
    display: block;
    flex-direction: row;
    background-color: $white;
    position: relative;
    padding: 0 !important;

    .footer-bottom-copyright {
        max-width: 100% !important;
    }

    .footer-search {
        max-width: 100% !important;
    }

    .logo-link {
        position: absolute;
        right: 25px;
        top: -50px;
        padding: 1rem 0;
        max-width: fit-content;

        @media screen and (max-width: $mobile-width) {
            display: flex;
            left: 10px;
            flex-direction: column;
            top: unset;
            position: relative;
        }

        .ford-logo {
            background: transparent no-repeat -200px 0;
            width: 5.8125rem;
            height: 2.1875rem;
            background-size: 25rem 12.5rem;
            display: inline-block;
            padding: 0;
            margin: 0 0 0 0;
        }
    }

    .button-label {
        white-space: nowrap;
        margin-right: 0.974rem;
        letter-spacing: 0.064rem;
        font-size: 1.125rem;
    }

    .language-toggle-container {
        display: inline-flex;
        padding-bottom: 25px;
        width: 240px;

        .language-panel-footer {
            @media screen and (max-width: $mobile-width) {
                width: -webkit-fill-available;
                display: flex;
                flex-direction: column;
                height: auto;
                left: 0;
            }
            padding: 30px 15px 0;
            box-sizing: border-box;
            height: 30%;
            width: 60%;
            left: 20%;
            text-align: center;
            position: fixed;
            overflow: hidden;
            z-index: 2560;
            background-color: #d9d9d9;
            top: 40%;
        }
    }

    .select-button {
        display: block;
        background: inherit;
        color: $black;
        border: 1px solid $black;
        cursor: none;
    }

    .close {
        cursor: pointer;
        position: absolute;
        right: 16px;
        top: 16px;
        color: $black;
        font-size: 40px;
    }

    .list-lang-item {
        margin-bottom: 25px;
        padding-right: 25px;

        .primary-button.disabled {
            border: 1px solid #000;
            background: transparent;
            color: #000;
            cursor: default;
        }
    }

    .language-option {
        background: $hover-blue;
        position: fixed;
        width: 40%;
        left: 30%;
        right: 30%;
        padding-bottom: 10%;
    }

    .list-wrapper {
        display: flex;
        padding-top: 40px;
        list-style-type: none;
        justify-content: center;
        flex-wrap: wrap;
    }

    @include tablet {
        flex-direction: row;
        align-items: flex-start;
        padding: 0 0 1.5rem;
        justify-content: space-between;
    }

    .links {
        @media screen and (max-width: $mobile-width) {
            flex-direction: column;
            padding-left: 20px;
            color: $text-gray;
            font-family: $antenna-font;
            font-size: 11px;
            letter-spacing: 1px;
            line-height: 21px;
        }
        font-family: $antenna-font;
        white-space: break-spaces;
        flex-wrap: wrap;
        display: flex;
        position: relative;

        .link {
            color: #555;
            margin: 20px 15px 0 0;
            text-decoration: none;
            font-size: 14px;
            font-weight: 100;
        }
    }

    .skinny {
        color: $white;
        padding: 25px 25px 35px 35px;
        font-size: 25px;
        font-weight: 100;
        background: $text-gray;
        width: 100%;
        max-width: 100%;
        list-style: none;
        line-height: 5rem;

        li {
            display: contents;
            text-align: center;
        }

        .child-links {
            list-style: none;
        }
    }

    .footer-change-lang {
        padding: 25px 10px 15px 0;
        color: #555;
        margin: 10px 0 0 15px;
        text-decoration: none;
        font-size: 14px;
        font-weight: 100;
    }

    .footer-change-lang-block {
        @media screen and (max-width: $mobile-width) {
            padding-left: 41px;
        }
        padding-left: 98px;
        padding-right: 116px;
        padding-top: 15px;
        display: flex;
    }

    .pipe-separated-list-container {
        overflow-x: hidden;
        padding-bottom: 50px;
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        flex-wrap: wrap;

        ul {
            $pipe-width: 1px;
            display: flex;
            justify-content: space-evenly;
            list-style-type: none;
            left: -($pipe-width);

            padding: 0;
            @media screen and (max-width: $mobile-width) {
                text-align: center;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }

            li.content-label {
                display: inline-block;
                @media screen and (max-width: $mobile-width) {
                    line-height: 1;
                    text-decoration: none;
                    font-size: 14px;
                    font-weight: 100;
                    border-left: none;
                    text-align: inherit;
                    padding-top: 45px;
                    padding-bottom: 45px;
                    width: 75%;
                    border-bottom: 1px solid $white;
                }
                line-height: 5;
                padding: 0 1em;
                border-left: 2px solid $border-grey;
            }
        }
    }

    p {
        margin: 1em;
    }

    .sec-link-last {
        font-size: 16px;
        letter-spacing: 2px;
        text-decoration: none;
        border-bottom: none;
        color: $white;
        font-family: $antenna-font-condensed-regular;
        font-weight: lighter;
        line-height: 5rem;
    }

    .sec-link-color {
        color: $white;
        font-family: $antenna-font-condensed-regular;
        font-size: 16px;
        letter-spacing: 2px;
        font-weight: lighter;
        line-height: 5rem;
        text-align: center;
        text-decoration: none;

        @media screen and (max-width: $mobile-width) {
            color: $white;
            font-family: $antenna-font-condensed-regular;
            font-weight: lighter;
            font-size: 16px;
            letter-spacing: 2px;
            line-height: 5rem;
            border-bottom: 2px solid white;
            width: 75%;
        }
    }

    .tertiary-first-title {
        @media screen and (max-width: $mobile-width) {
            padding-left: 41px;
        }
        padding-left: 98px;
        text-decoration: none;
        font-size: 16px;
        font-weight: lighter;
        font-family: $antenna-font;
        color: $text-gray;
        line-height: 21px;
        letter-spacing: 1px;
    }

    .primary-first-item {
        @media screen and (max-width: $mobile-width) {
            line-height: 1;
            padding-top: 20px;
            padding-bottom: 45px;
            width: 75%;
            justify-content: center;
            text-align: center;
            border-bottom: 1px solid $white;
        }
        display: none;
        border-left: none;
        line-height: 5;
        font-size: 16px;
        text-align: center;
        margin: 10px 0;
    }

    .primary-last-item {
        display: inline-block;
        line-height: 5;
        font-size: 16px;
        text-align: center;
        margin: 10px 0;
        padding: 0 1em;
        border-left: 2px solid $border-grey;
        @media screen and (max-width: $mobile-width) {
            line-height: 1;
            text-decoration: none;
            font-size: 14px;
            font-weight: 100;
            border-left: none;
            text-align: center;
            padding-left: 41px;
            width: 70%;
            border-bottom: none;
        }
    }

    .toggle-button {
        background: inherit;
        border: 0;
        display: flex;
        cursor: pointer;
    }

    .toggle-text-footer {
        padding: 0.4rem;
        text-decoration: underline;
        font-size: 16px;
        color: $dark-blue;
        line-height: 26px;
        font-family: $antenna-font;
        letter-spacing: 1px;
    }

    .footer-logo {
        margin: 1.25rem 1.25rem 1.25rem 2.25rem;
    }

    .tertiary-section {
        padding-left: 102px;
        @media screen and (max-width: $mobile-width) {
            padding-left: 41px;
        }

        .link {
            font-size: 11px;
            font-family: $antenna-font;
            letter-spacing: 2px;
        }
    }
}

.eu-footer {
    width: 100%;

    .eu-footer-nav-container {
        padding: pToR(0) 8% pToR(40) 8%;
        background-color: $gray;
        @include mobile {
            padding-left: unset;
        }

        .eu-footer-nav {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            max-width: pToR(1400);
            margin: auto;

            @include mobile {
                padding-left: pToR(40);
            }

            @include mobile {
                padding: pToR(5) pToR(50) pToR(40) pToR(50);
            }

            .footer-categories-container {
                width: pToR(280);
                padding: pToR(20) pToR(20);
                min-width: pToR(280);

                @include tablet {
                    width: pToR(250);
                }
                @include mobile {
                    min-width: unset;
                }

                .footer-categories {
                    overflow: auto;
                    list-style: none;

                    .footer-sub-nav-title-container {
                        .footer-sub-nav-title {
                            text-transform: uppercase;
                            letter-spacing: pToR(3);
                            color: $text-gray;
                            font-family: $antenna-font-antenna;
                            min-height: pToR(30);
                        }

                        .footer-nav-categories {
                            font-size: pToR(14);
                            line-height: pToR(28);
                            list-style: none;

                            a:hover {
                                color: unset;
                            }

                            .footer-nav-link {
                                a {
                                    outline-color: $black;
                                    margin-left: pToR(2);
                                }
                                span {
                                    font-family: $antenna-font-antenna;
                                    color: $fds-v4-primary;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .eu-footer-social-icons {
        background-color: $fds-v4-primary;
        display: flex;
        justify-content: space-between;
        padding: pToR(30) pToR(100);

        @include desktop {
            padding: pToR(30) pToR(280);
        }
        @include mobile {
            padding: pToR(30) pToR(60);
        }

        .social-icons {
            height: 20px;
            a {
                outline-color: $black;
            }
        }
    }

    .eu-footer-secondary-links-container {
        .eu-footer-secondary-links {
            .eu-secondary-links-list {
                list-style: none;
                width: fit-content;
                .eu-tertiary-link {
                    display: flex;
                    align-items: center;
                    justify-content: left;
                    padding: pToR(24) 0 0 pToR(40);
                    @include tablet {
                        padding: pToR(24) 0 0 pToR(24);
                    }
                    cursor: pointer;
                    .icon {
                        margin-top: pToR(5);
                    }
                    .text {
                        margin-left: pToR(12);
                        font-size: 1rem;
                        font-family: $antenna-font-condensed-light;
                        letter-spacing: pToR(2);
                        color: $fds-color--primary;
                        text-decoration: underline;
                        white-space: normal;
                    }
                }
            }
        }
    }

    .footer-tertiary-logo-container {
        display: flex;
        padding: pToR(20) pToR(0) pToR(10) pToR(0);
        background-color: unset;
        width: 100%;
        flex-wrap: wrap;

        @include mobile {
            width: 100%;
        }
        @include tablet {
            width: 80%;
        }

        .copy-right-text {
            font-size: 12px;
            font-family: $antenna-font-antenna;
            color: $text-gray;
            padding-left: pToR(30);
        }

        .tertiary-links-container {
            display: flex;
            justify-content: space-between;
            width: 90%;
            padding-left: pToR(40);
            padding-top: pToR(10);

            .tertiary-text {
                list-style: none;
                font-size: 12px;

                li {
                    display: inline-block;
                    padding: pToR(0) pToR(10) pToR(20) pToR(10);
                }

                a:hover {
                    color: unset;
                }

                li,
                a {
                    font-family: $antenna-font-antenna;
                    color: $text-gray;
                    outline-color: $black;
                }
            }
        }

        .ford-logo-container {
            position: unset;
            right: pToR(163);
            padding: pToR(20) pToR(0) pToR(10) pToR(40);

            @include desktop {
                position: absolute;
                padding-left: unset;
                padding-top: unset;
            }
            @include mobile {
                position: unset;
            }
            a {
                outline-color: $black;
            }
        }
    }
}
