@import '@styles/main.v5';
@import '@styles/common';
@import '@views/account-settings-view/account-settings-view.scss';

.user-account-edit-container {
    background-color: $white;
    width: 100%;
    border: pToR(1) solid #979797;
    border-radius: pToR(3);

    .brand-ford & {
        border: 0;
        max-width: pToR(542);
    }

    .brand-lincoln & {
        padding: pToR(30) pToR(16) 0;

        @include tablet {
            padding: pToR(48) pToR(96) pToR(64);
        }
    }

    @include mobile-v5 {
        width: 100%;

        .brand-ford & {
            width: -webkit-fill-available;
            max-width: 100%;
            padding-bottom: 0;
            margin-bottom: 0;
        }

        .brand-lincoln & {
            padding: pToR(31) pToR(20) 0;
        }
    }
    @include tablet-v5 {
        &:first-of-type {
            margin-bottom: pToR(22);
        }
    }

    .input-section-container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .btn-container {
        @include mobile {
            padding-bottom: pToR(64);
            margin-left: 0;
        }
    }
    .btn-container {
        display: flex;
        justify-content: center;
        padding-top: pToR(15);

        &.error {
            margin-top: pToR(20);
        }
        .brand-ford & {
            justify-content: flex-end;

            @include mobile {
                flex-direction: row;
            }
            .fmc-text-button {
                @include ford-secondary-button-default();
            }
            .fmc-button {
                margin-left: pToR(24);
                margin-right: 0;
            }
        }
        .brand-lincoln & {
            .fmc-button {
                @include tablet-v5 {
                    margin-right: pToR(40);
                }
            }
            .link-button {
                margin: 0;
                padding: 0;
            }
        }
        @include mobile-v5 {
            margin-top: 0;

            &.error {
                margin-top: pToR(20);
            }

            .brand-lincoln & {
                flex-direction: column;
                align-items: center;
                max-width: unset;
                width: 100%;
                margin: 0;

                .primary-button,
                .link-button {
                    width: unset;
                }
                .link-button {
                    margin-left: unset;
                    height: pToR(50);
                    margin-bottom: pToR(10);
                }
            }
        }
    }

    .user-edit-cancel-btn {
        margin-left: pToR(45);
        border: none;
        letter-spacing: pToR(1);
        background-color: transparent;
        color: $dark-blue;
        text-decoration: underline;
        font-size: pToR(18);
        line-height: pToR(13);
    }

    .input-fieldset {
        border: none;
        font-size: pToR(24);
        line-height: pToR(30);
        color: #010203;

        @include tablet {
            margin-top: pToR(12);
        }

        @include mobile {
            margin-top: pToR(12);

            .brand-lincoln & {
                margin-top: pToR(43);

                &:nth-of-type(2),
                &:nth-of-type(5) {
                    margin-top: pToR(34);
                }
                &:nth-of-type(3),
                &:nth-of-type(4) {
                    margin-top: pToR(15);
                }
            }
        }
        @include desktop {
            margin-top: pToR(12);
        }
        .fmc-type--heading6 {
            font-size: pToR(24);
            font-family: $ford-f1-font-regular;
            font-weight: 500;
            .fmc-mb-5 {
                font-size: pToR(24);
                font-family: $ford-f1-font-regular;
                font-weight: 500;
                line-height: pToR(30);
                margin-bottom: 0;
            }
        }
    }
    .email-communication-user-input {
        .input-fieldset-name {
            margin-top: 0;
            border: none;
        }

        .fmc-check {
            --button-diameter: 24px;
            --button-label-gap: 8px;
            --button-outline-thickness: 1px;
            --button-outline-color: #6b7786;
            --dot-color: #066fef;
            --dot-diameter: 18px;
            --vertical-gap: 24px;

            input[type='radio']:checked {
                --button-outline-color: #066fef;
                --button-outline-thickness-checked: 1px;
            }
            input[type='radio']:focus-visible {
                outline-offset: 0;
                outline: 2px solid $fds-preimagine-primary-blue;
            }

            label {
                color: $fds-preimagine-dark-text;
                font-family: $ford-f1-font-regular;
                font-size: pToR(14);
                line-height: pToR(20);
                letter-spacing: normal;
                align-items: center;
            }
        }

        .form-field {
            .form-field__label {
                color: $fds-preimagine-dark-text;
                letter-spacing: normal;

                .brand-lincoln & {
                    color: $brand-secondary;

                    &.error-label {
                        color: $brand-light-error;
                    }
                }
            }

            @include tablet-v5 {
                margin-bottom: pToR(36);
            }
        }

        .brand-lincoln & {
            .user-information-wrapper {
                padding-left: pToR(20);
            }
            .primary-button {
                &.user-edit-update-btn {
                    &:hover {
                        background-color: $brand-secondary;
                    }

                    &:active:focus {
                        background-color: $brand-black;
                    }
                }
            }
            .secondary-button {
                &.user-edit-cancel-btn {
                    padding: pToR(24);
                    height: pToR(56);
                }
            }
        }

        @include media('<fds-xs') {
            .btn-container {
                flex-direction: column;

                .fmc-button {
                    margin: auto;
                }
            }
        }
    }
}
